import React, { useContext } from "react";
import Context from "../context";
import ImagesButton from "../../../components/imagesButton";

const ProfileImage = () => {
    const context = useContext(Context);

    return (
        <>
            <div className="col-auto">
                <div className="avatar-xl position-relative">
                    <ImagesButton
                        images = {[
                            {
                                url: context.state.customer.customer_profile_image || "/assets/img/lock_user.png",
                                title: 'View',
                            }
                        ]}
                    />
                </div>
            </div>
            <div className="col-auto my-auto">
                <div className="h-100">
                    <h5 className="mb-1">
                        {context.state.customer.customer_full_name}
                    </h5>
                </div>
            </div>
        </>
    );
}

export default ProfileImage;