import React from "react";
import Template from "../../components/template";
import UsersList from "./components/usersList";
import UserSelectionButton from "./components/userSelectionButton";
import ChatBody from "./components/chatBody";
import PageHeader from "../components/pageHeader";
import AdminInfo from "../components/adminInfo";

const Html = () => {
    return (
        <Template>
            <div className="container-fluid px-2 px-md-4">
                <PageHeader/>
                <div className="card card-body mx-3 mx-md-4 mt-n6" style={{ backgroundColor: '#efefef' }}>
                    <div className="row gx-4 mb-2">
                        {/* <AdminInfo/> */}
                        <div className="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
                            <UserSelectionButton />
                        </div>
                    </div>
                    <div className="row">
                        <div className="row mt-4">
                            <div className="col-md-5 col-lg-4">
                                <UsersList/>
                            </div>
                            <div className="col-md-7 col-lg-8">
                                <ChatBody />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Template>
    );
};

export default Html;
