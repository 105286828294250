import Swal from 'sweetalert2';
import { GET } from "../../../API/get";
import { theme } from '../../../theme';

export const loadData = async ({page_number, search_value, dispatch, filer}) => {
    dispatch({ type: "IS_LOADING" });
    let URL = `cancel/job/list/${page_number}?search=${search_value}`;
    if(filer === 1){
        URL = URL+`&type=1`
    }

    if(filer === 2){
        URL = URL+`&type=2`
    }

    let response = await GET(URL);

    if(response.status === "Success"){
        dispatch({ 
            type: "IS_LOADED", 
            total_jobs: response.total_jobs, 
            total_pages: response.total_pages, 
            jobs_list: response.list, 
            current_page: page_number 
        });
    }
}

export const isReview = (job_id, page_number, dispatch) => {
    Swal.fire({
        title: "Are you sure?",
        text: "Do you want to review this cancel job ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: theme.action_btn_code,
        cancelButtonColor: theme.cancel_btn_code,
        confirmButtonText: "Yes, review it!",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
            let response = await GET(`cancel/job/review/${job_id}`);
            return response;
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
        console.log('result :', result);
        if (result.isConfirmed) {
            console.log('result :', result);

            if(result.value.status === "Success"){
                loadData({page_number, dispatch});
            }
        }
    });
}