import React from 'react';

const Checkbox = (props) => {
  return (
    <div className="form-check form-check-inline">
        {props.job_is_pay_ftm != 1 ? (
          <input 
            className="form-check-input" 
            type="checkbox" 
            checked={props.checked}
            onChange={() => props.onChange(props.id)}
            style={{borderColor: 'black'}}
          />
        ) : null}
    </div>
  );
}

export default Checkbox;
