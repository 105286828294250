import React, { useContext } from "react";
import Context from "../context";
import Edit from "./edit";
import CardWithHeaderTemplate from "../../../components/cardWithHeaderTemplate";

const BusinessDetails = () => {
    const context = useContext(Context);

    return (
        <CardWithHeaderTemplate
            header={
                <div className="row">
                    <div className="col-8 my-auto">
                        <div className="numbers">
                            <h6 className="text-white font-weight-bolder mb-0 ms-3">
                                BUSINESS DETAILS
                            </h6>
                        </div>
                    </div>
                </div>
            }
            body={
                <ul className="list-group">
                    <li className="list-group-item border-0 ps-0 pt-0 text-sm">
                        <strong className="text-dark">Business UTR Number:</strong> 
                        &nbsp; {context.state.ftm.ftm_business_utr_number || "N/A"}
                    </li>
                    <li className="list-group-item border-0 ps-0 pt-0 text-sm">
                        <strong className="text-dark">Business Name:</strong>
                        &nbsp; {context.state.ftm.ftm_name_of_business || "N/A"}
                    </li>
                    <li className="list-group-item border-0 ps-0 text-sm">
                        <strong className="text-dark">Company Registration Number:</strong>
                        &nbsp; {context.state.ftm.ftm_company_reg_number || "N/A"}
                    </li>
                </ul>
            }
            footer={null}
            extraCardClassName={`blur shadow-blur max-height-vh-70 h-100`}
            extraBodyClassName={`overflow-auto overflow-x-hidden`}
        />
    );
}

export default BusinessDetails;