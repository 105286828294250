export default function Reducer(prevState, action){
    console.log("🚀 ~ Reducer ~ action:", action)
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true,
            };
        case "IS_LOADED": 
            return {
                ...prevState,
                ftm: action.ftm,
                address_details: action.address_details,
                is_loading: false,
            };
        case "SET_FTM_PROFILE_IMAGE":
            return {
                ...prevState,
                ftm_profile_image: action.ftm_profile_image
            };
        case "SET_ADDRESS_DETAILS":
            return {
                ...prevState,
                is_loading_address_detail: false,
                address_details: action.address_details
            };
        case "IS_LOADING_ADDRESS_DETAIL":
            return {
                ...prevState,
                is_loading_address_detail: true,
            };
        case 'SET_SEARCH_VALUE':
            return { 
                ...prevState,
                search_value: action.search_value 
            };
        case "IS_FTM_UPDATING":
            return {
                ...prevState,
                is_ftm_updating: action.is_ftm_updating,
            };
        default:
            throw new Error();
    }
}