import React from "react";

import {theme} from '../../theme';

const SocialMedia = () => {
    return(
        <div className={`bg-gradient-${theme.main}  shadow-${theme.main}  border-radius-lg py-3 pe-1`}>
            <h4 className="text-white font-weight-bolder text-center mt-2 mb-0">{process.env.REACT_APP_SYSTEM_NAME}</h4>
            <h6 className="text-white font-weight-bolder text-center mt-2 mb-0">SIGN IN</h6>
            <div className="row mt-3"></div>
        </div>
    );
}

export default SocialMedia;