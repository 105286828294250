import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';

import ActionsList from './actionsList';

import { getSearchList } from '../service';
import Context from '../context';

export default function SearchInput(props) {
    const context = React.useContext(Context);

    return (
        <Paper
            component="form"
            sx={{  display: 'flex', alignItems: 'center' }}
        >
            <IconButton sx={{ p: '10px' }} aria-label="menu">
                <SearchIcon/>
            </IconButton>
            <InputBase
                id="search-input"
                type={context.state.selector === "JOBS_CREATEDAT" ? "date" : "text"}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                fullWidth
                autoFocus
                onChange={(event) => getSearchList({
                    text: event.target.value,
                    type: context.state.selector,
                    dispatch: context.dispatch,
                    search_option: context.state.search_option
                })}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                {/* <SearchIcon /> */}
                {context.state.is_loading ? 
                    <CircularProgress color="inherit" size={22}/> : 
                    <CloseIcon 
                        onClick={() => {
                            context.dispatch({ 
                                type: "SET_LIST",
                                list: [],
                            });

                            document.getElementById('search-input').value = '';
                        }}
                    />
                }
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton color="dark" sx={{ p: '10px' }} aria-label="directions">
                <ActionsList 
                    search_type_list = {props.search_type_list}
                    style={{marginBottom: '5px'}}
                />
            </IconButton>
        </Paper>
    );
}