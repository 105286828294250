/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from "react";
import Context from "../context";
import { getAdminDetailsInFirebase, openChatView } from "../service";
import { Badge } from "@mui/material";
import { theme } from "../../../theme";

const Btn = () => {
    const context = useContext(Context);

    useEffect(() => {
        getAdminDetailsInFirebase(context.dispatch);
    }, []);

    return (
        <div className="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
            <div className="nav-wrapper position-relative end-0">
                <ul className="nav nav-pills nav-fill p-1" role="tablist">
                    <li className="nav-item">
                        <Badge 
                            badgeContent={context.state.admin_unread_message_count} 
                            color={theme.sidebar_notification_count_color}
                        >
                            <a 
                                className="nav-link mb-0 px-0 py-1" 
                                data-bs-toggle="tab" 
                                href="#" 
                                role="tab" 
                                aria-selected="false"
                                onClick={() => openChatView(context.state.customer.customer_firebase_document_id, context.dispatch)}
                            >
                                <i className="material-icons text-lg position-relative">forum</i>
                                <span className="ms-1"><b>CHATS</b></span>
                            </a>
                        </Badge>
                    </li>
                </ul>
                
            </div>
        </div>
    );
}

export default Btn;