export const initialState = {
    is_loading: true,
    sub_skills: [],
    is_open_certificate_types: false,
    is_show_add_sub_skills: false,
    is_show_edit_sub_skills: false,
    is_show_material_commissions: false,
    btn_loading: false,
    selected_sub_skills: {},
    error: [],
    charge_types: [],
    is_loading_charge_types: true,
    selected_charge_type: 0
}