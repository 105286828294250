import React, { useContext } from "react";
import Context from "../context";

const JobValues = () => {
    const context = useContext(Context);

    return(
        <div className="card">
            <div className="card-body p-3">
                <ul className="list-group">
                    <li className="list-group-item border-0 d-flex justify-content-between px-4 pb-0 pt-4 border-radius-lg bg-gray-300">
                        <div className="d-flex flex-column">
                            <h6 className="text-dark font-weight-bold text-md">SUBTOTAL </h6>
                        </div>
                        <div className="d-flex align-items-center text-md">
                            <span className="text-dark ms-sm-2 font-weight-bold">
                                &#163; {context.state.details.job_value_calculator.final_total_value_without_vat}
                            </span>
                        </div>
                    </li>

                    <li className="list-group-item border-0 d-flex justify-content-between px-4 py-0 border-radius-lg bg-gray-300">
                        <div className="d-flex flex-column">
                            <h6 className="text-dark font-weight-bold text-md">TAX ({context.state.details.company_settings.company_vat}%) </h6>
                        </div>
                        <div className="d-flex align-items-center text-md">
                            <span className="text-dark ms-sm-2 font-weight-bold">
                                &#163; {context.state.details.job_value_calculator.vat}
                            </span>
                        </div>
                    </li>

                    <li className="list-group-item border-0 d-flex justify-content-between px-4 pt-0 pb-3 border-radius-lg bg-gray-300">
                        <div className="d-flex flex-column">
                            <h6 className="text-dark font-weight-bold text-md">TOTAL </h6>
                        </div>
                        <div className="d-flex align-items-center text-md">
                            <span className="text-dark ms-sm-2 font-weight-bold">
                                &#163; {context.state.details.job_value_calculator.final_total_value_with_vat}
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default JobValues;