export default function Reducer(prevState, action) {
    switch (action.type) {
        case 'IS_LOADING':
            return {
                ...prevState,
                is_loading: true,
            };
        case 'IS_LOADED':
            return {
                ...prevState,
                is_loading: false,
                job_details: action.job_details,
                images: action.images,
                appointments: action.appointments,
                ftms: action.ftms,
                extra_payments: action.extra_payments,
                job_sheet: action.job_sheet,
                job_status_change_logs: action.job_status_change_logs,
            }; 
        case "OPEN_CHAT":
            return {
                ...prevState,
                open_chat: true,
            };
        case "CLOSE_CHAT":
            return {
                ...prevState,
                open_chat: false,
            };
        case "IS_SHOW_INVOICE":
            return {
                ...prevState,
                is_show_invoice: action.is_show_invoice,
            }; 
        case "IS_SHOW_JOBSHEET":
            return {
                ...prevState,
                is_show_jobsheet: action.is_show_jobsheet,
            }; 
        case "IS_SHOW_CHAT":
            return {
                ...prevState,
                is_show_chat: action.is_show_chat,
            }; 
        case "IS_SHOW_FTM_REQUEST":
            return {
                ...prevState,
                is_show_ftm_request: action.is_show_ftm_request,
            };    
        case "JOB_UNREAD_MESSAGE_COUNT":
            return {
                ...prevState,
                job_unread_message_count: action.job_unread_message_count,
            }; 
        case "IS_SHOW_JOB_TIMELINE":
            return {
                ...prevState,
                is_show_job_timeline: action.is_show_job_timeline,
            };    
        default:
            throw new Error();
    }
}