export default function  Reducer(prevState, action){
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true,
            };
        case "IS_LOADED": 
            return {
                ...prevState,
                pre_auth_amount: action.pre_auth_amount,
                charge_amount: action.charge_amount,
                release_amount: action.release_amount,
                refund_amount: action.refund_amount,
                payments: action.payments,
                current_page: action.current_page,
                total_pages: action.total_pages,
                is_loading: false,
            };
        case "UPDATE_CURRENT_PAGE":
            return {
                ...prevState,
                current_page: action.current_page,
            };
        case "SET_SERACH_VALUE":
            return {
                ...prevState,
                current_page: 1,
                search_value: action.search_value,
            };
        default:
            throw new Error();
    }
}