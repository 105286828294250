import React, {useContext} from "react";
import Context from "../context";

const ExtraPayments = () => {
    const context = useContext(Context);

    return(
        <div className="card ">
            <div className="card-header pb-0 p-3">
                <div className="row">
                    <div className="col-6 d-flex align-items-center">
                        <h6 className="mb-0">EXTRA PAYMENTS</h6>
                    </div>
                </div>
            </div>
            <div className="card-body p-3 pb-0">
                <ul className="list-group">
                    {context.state.details.extra_payments.map((item, index) => (
                        <li
                            className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                            key={index}
                        >
                            <div className="d-flex flex-column" style={{ maxWidth: '150px' }}>
                                <h6 className="mb-1 text-dark font-weight-bold text-sm">
                                    {item.extra_payment_description}
                                </h6>
                                <span className="text-sm">{item.extra_payment_item}</span>
                            </div>
                            <div className="d-flex align-items-center text-sm">
                                <div className="d-flex flex-column align-items-end">
                                    <span>QTY: {item.extra_payment_qty}</span>
                                    <span>UP: &#163; {item.extra_payment_unit_price}</span>
                                    <span>
                                        ST: &#163; {item.extra_payment_qty * item.extra_payment_unit_price * item.extra_payment_commissions}
                                    </span>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            {/* <div className="col-12">
                <div className="card my-4">
                    <div className="card-body px-0 pb-2">
                        <div className="table-responsive p-0">
                            <table className="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"></th>
                                        <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">QTY</th>
                                        <th className="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-7">UNIT PRICE</th>
                                        <th className="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-7">SUB TOTAL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {context.state.details.extra_payments.map((item, index) => (
                                        <tr>
                                            <td>
                                                <div className="d-flex px-2 py-1" style={{maxWidth: '170px', minWidth: '170px'}}>
                                                    <div className="d-flex flex-column justify-content-center">
                                                        <h6 className="mb-0 text-sm">{item.extra_payment_description}</h6>
                                                        <p className="text-xs text-secondary mb-0">{item.extra_payment_item}</p>
                                                    </div>
                                                </div>
                                            </td>

                                            <td className="align-middle text-center">
                                                <span className="text-secondary text-xs font-weight-bold">{item.extra_payment_qty}</span>
                                            </td>
                                            <td className="align-middle text-center">
                                                <span className="text-secondary text-xs font-weight-bold">&#163; {item.extra_payment_unit_price}</span>
                                            </td>
                                            <td className="align-middle text-center">
                                                <span className="text-secondary text-xs font-weight-bold">&#163; {item.extra_payment_qty*item.extra_payment_unit_price*item.extra_payment_commissions}</span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div> */}

        </div>
    );
}

export default ExtraPayments;