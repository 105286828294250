import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import CardMembershipIcon from '@mui/icons-material/CardMembership';

import DeleteIcon from '@mui/icons-material/Delete';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

import Context from '../context';
import DivSpinner from '../../../components/divSpinner';
import ActionButtons from '../../components/actionButtons';
import { deleteSkills } from '../service';

export default function SkillsList() {
    const context = useContext(Context);

    return (
        <>
            {context.state.is_loading ? (
                <div style={{height : `${window.innerHeight-387}px`}}>
                    <DivSpinner/>
                </div>
            ) : (
                <Box sx={{ flexGrow: 1}} className="ms-4 me-4">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <List>
                                {context.state.skills.map((skill, index) => (
                                    <ListItem
                                        key = {index}
                                        secondaryAction={
                                            <>
                                                <IconButton edge="end">
                                                    <VisibilityIcon 
                                                        onClick = {() => context.dispatch({
                                                            type: 'SET_SELECTED_SKILLS',
                                                            selected_skills: skill
                                                        })}
                                                    />
                                                </IconButton>
                                                
                                                <IconButton edge="end">
                                                    <CardMembershipIcon 
                                                        onClick = {() => context.dispatch({
                                                            type: 'IS_SHOW_CERTIFICATE_TYPES',
                                                            is_show_certificate_types: true,
                                                            selected_skills: skill
                                                        })}
                                                    />
                                                </IconButton>
                                                
                                                <IconButton edge="end">
                                                    <DriveFileRenameOutlineIcon 
                                                        onClick = {() => context.dispatch({
                                                            type: 'IS_SHOW_EDIT_SKILLS',
                                                            is_show_edit_skills: true,
                                                            selected_skills: skill
                                                        })}
                                                    />
                                                </IconButton>
                                                <IconButton edge="end">
                                                    <DeleteIcon 
                                                        onClick = {() => deleteSkills(skill.skill_id, context.dispatch)}
                                                    />
                                                </IconButton>
                                            </>
                                        }
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                <HomeRepairServiceIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={skill.skill_name}
                                            secondary={`SERVICE TYPE COUNT : ${skill.sub_skill_count}`}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
}