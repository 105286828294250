import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import ListItemIcon from '@mui/material/ListItemIcon';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';

const CommissionsList = ({material_commissions}) => {
    return(
        <Box sx={{ flexGrow: 1}} >
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <List dense={true}>
                        {material_commissions?.map((material_commission, index) => (
                            <ListItem
                                key = {index}
                                secondaryAction={<b>{material_commission?.material_commission_value}</b>}
                            >
                                <ListItemIcon>
                                    {material_commission?.charge_type.charge_type_name === "Material" ? <AllInboxIcon /> : null}
                                    {material_commission?.charge_type.charge_type_name === "Parking" ? <LocalParkingIcon /> : null}
                                    {material_commission?.charge_type.charge_type_name === "Waste disposal" ? <DeleteSweepIcon /> : null}
                                </ListItemIcon>
                                <ListItemText
                                    primary={material_commission?.charge_type.charge_type_name}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>
        </Box>
    );
}

export default CommissionsList;