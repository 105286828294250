import React, { useContext } from "react";
import { Box, TextField, InputAdornment, IconButton } from '@mui/material';
import { AccountCircle, ArrowForward } from '@mui/icons-material';
import Context from '../context';
import { submitLockScreenVerify } from "../service";
import useCustomNavigate from '../components/navigation';

const PasswordField = () => {
    const context = useContext(Context);
    const { navigateBack } = useCustomNavigate();

    return(
        <Box
            p={1}>
                <AccountCircle 
                    sx={{ mr: 1, my: 0.5, fontSize: '60px' }} />
                <TextField 
                    id="input-with-sx" 
                    label="Password" 
                    variant="standard" 
                    type="password"
                    onChange={(event) => context.dispatch({ type: "ADMIN_PASSWORD", admin_password: event.target.value })}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment 
                                position="end">
                                    <IconButton 
                                        onClick={() => submitLockScreenVerify({
                                            admin_id: context.state.admin_id,
                                            admin_password: context.state.admin_password,
                                            dispatch: context.dispatch,
                                            navigateBack: navigateBack
                                        })} 
                                        edge="end"
                                        color="primary"
                                        style={{ backgroundColor: "transparent" }}>
                                            <ArrowForward 
                                                sx={{ fontSize: '24px' }} />
                                    </IconButton>
                            </InputAdornment>
                        )
                    }}/>
        </Box>
    );
}

export default PasswordField;