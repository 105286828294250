import React, { useEffect, useContext } from "react";
import { useGoogleMaps } from "react-hook-google-maps";
import { addFtmLocation } from "../service";
import Context from "../context";

const FtmMap = () => {
    const { ref, map, google } = useGoogleMaps(
        process.env.REACT_APP_GOOGLE_MAP_KEY,
        {
            center: { lat: 51.5286416, lng: -0.1015987 },
            disableDefaultUI: true,
            // zoom: 12
        }
    );

    const context = useContext(Context);

    useEffect(() => {
        addFtmLocation({
            map: map,
            google: google,
            ftm_latitude: context.state.ftm.ftm_latitude,
            ftm_longitude: context.state.ftm.ftm_longitude,
        });
    }, [google, map]);

    return(
        <div 
            className="page-header min-height-300 border-radius-xl mt-4" 
            // style={{backgroundImage: `url('https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80')`}}
        >
            <div ref={ref} style={{ width: '100%', height: 350 }} />
            <span className="mask    opacity-6"></span>
        </div>
    );
}

export default FtmMap;