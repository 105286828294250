import { GET } from "../../API/get";

export const fetchData = async ({page_number, search_value}) => {
    let url = `get-remedial-jobs/${page_number}?search=${search_value}`;
    let response = await GET(url);

    if(response.status === "Success"){
        return response;
    }

    return null;
}