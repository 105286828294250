import { GET } from "../../../API/get";

export const loadData = async (job_id, dispatch) => {
    dispatch({ type: "LOADING" });
    let response = await GET(`get-invoice-details/${job_id}`);

    if(response.status === "Success"){
        dispatch({ 
            type: "SET_DETAILS", 
            details: response.details
        });
    }
}

export const getJobsheetPDF = async (job_id, selected_invoice_type, dispatch) => {
    dispatch({ type: "IS_BTN_LOADING", is_btn_loading: true });
    let response = await GET(`get-invoice-pdf/${job_id}/${selected_invoice_type}`);

    if(response.status === "Success"){
        window.open(response.pdf, '_blank', 'noopener,noreferrer');

        dispatch({ 
            type: "IS_BTN_LOADING", 
            is_btn_loading: false
        });
    }
}