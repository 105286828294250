import Swal from 'sweetalert2';

export const openImagePreview = (image) => {
    Swal.fire({
        imageUrl: image.url,
        imageWidth: 450,
        imageHeight: 250,
        imageAlt: "Custom image",
        showConfirmButton: false,
    });
} 