import React, {useContext} from "react";
import Select from 'react-select';

import Context from "../context";
import { customStylesInSelect } from "../../../customStyles";

const Skills = () => {
    const context = useContext(Context);

    return(
        <>
            <div>
                <label>Service Category</label>
                <div className="mb-3">
                    <div>
                        <Select
                            defaultValue={context.state.selected_skills}
                            options={context.state.skills?.map((skill) => {
                                return {
                                    value: skill, 
                                    label: skill.skill_name
                                }
                            })}
                            onChange={(event) => context.dispatch({ type: "SELECTED_SKILL", selected_skills: event })}
                            styles={customStylesInSelect}
                        />
                    </div>
                </div>
            </div>

            <div>
                <label>Service Type</label>
                <div className="mb-3">
                    <div>
                        <Select
                            defaultValue={context.state.selected_sub_skills}
                            options={context.state.selected_skills?.value?.sub_skills?.map((data) => {
                                return {
                                    value: data, 
                                    label: data.sub_skill_name
                                }
                            })}
                            onChange={(event) => context.dispatch({ type: "SELECTED_SUB_SKILL", selected_sub_skills: event })}
                            styles={customStylesInSelect}
                        />
                    </div>
                </div>
            </div>

            <div>
                <label>Certificate</label>
                <div className="mb-3">
                    <div>
                        <Select
                            defaultValue={context.state.selected_document_type}
                            options={(context.state.selected_sub_skills?.value?.documents_types || context.state.selected_skills?.value?.documents_types)?.map((data) => {
                                return {
                                    value: data, 
                                    label: data.documents_types_name
                                }
                            })}
                            onChange={(event) => context.dispatch({ type: "SELECTED_DOCUMENT_TYPE", selected_document_type: event })}
                            styles={customStylesInSelect}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Skills;