import React, {useContext} from "react";
import Button from 'react-bootstrap/Button';

import Context from "../context";
import { theme } from '../../../../theme';
import CreditNotesList from "./creditNotesList";
import ModalTemplate from "../../../../components/modal/modalTemplate";

const CreditNotes = () => {
    const context = useContext(Context);

    return (
        <ModalTemplate
            show={context.state.is_show_credit_notes}
            closeFun = {() => context.dispatch({
                type: 'IS_SHOW_CREDIT_NOTES',
                is_show_credit_notes: false
            })}
            size = {"lg"}
            centered = {true}
            title = {"Credit Notes"}
            body = {<CreditNotesList />}
            footer = {
                <Button
                    variant={theme.close_btn}
                    onClick={() => context.dispatch({
                        type: 'IS_SHOW_CREDIT_NOTES',
                        is_show_credit_notes: false
                    })}
                    size="sm"
                >
                    CLOSE
                </Button>
            }
        />
    );
}

export default CreditNotes;