import toast from 'react-hot-toast';

import { GET } from "../../API/get";

// export const loadData = async (option) => {
//     option.dispatch({ type: "IS_LOADING" });

//     if (option.state.nav_type && option.state.nav_type !== option.nav_type) {
//         option.page_number = 1;
//     }
    
//     let url = getUrl(option.search_type, option.search_value, option.page_number, option.nav_type);
//     let response = await GET(url);

//     if(response.status === "Success"){
//         option.dispatch({ 
//             type: "IS_LOADED", 
//             total_jobs: response.total_jobs, 
//             total_pages: response.total_pages, 
//             jobs_list: response.list, 
//             current_page: option.page_number,
//             nav_type: option.nav_type 
//         });
//     }
// }

export const loadData = async ({page_number, nav_type, search_value, dispatch}) => {
    dispatch({ type: "IS_LOADING" });
    
    let response = await GET(`get-complied-jobs/${page_number}?nav_type=${nav_type}&search=${search_value}`);
    if(response.status === "Success"){
        dispatch({ 
            type: "IS_LOADED", 
            total_jobs: response.total_jobs, 
            total_pages: response.total_pages, 
            jobs_list: response.list, 
            current_page: page_number,
            nav_type: nav_type 
        });
    }
}

const getUrl = (search_type, search_value, page_number, nav_type) => {
    switch(search_type) {
        case 'FTM Name':
            return "get-complied-jobs/"+page_number+"?ftm_name="+search_value+"&nav_type="+nav_type;
        case 'Customer Name':
            return "get-complied-jobs/"+page_number+"?customer_name="+search_value+"&nav_type="+nav_type;
        case 'Postcode':
            return "get-complied-jobs/"+page_number+"?postcode="+search_value+"&nav_type="+nav_type;
        case 'Job Create Date':
            return "get-complied-jobs/"+page_number+"?job_create_date="+search_value+"&nav_type="+nav_type;
        default:
            return "get-complied-jobs/"+page_number+"?nav_type="+nav_type;
    }
}

export const chargeJobPayment = async (job_id, context) => {
    context.dispatch({ 
        type: "IS_PAYMENT_SUCCESS", 
        is_payment_success: true,
        job_id: job_id
    });

    let response = await GET("charge-job-payment/"+job_id);

    if(response.status === "Success"){
        toast.success(response.message, {duration: 8000});
        context.dispatch({ 
            type: "IS_PAYMENT_SUCCESS", 
            is_payment_success: false,
            job_id: job_id
        });
        loadData({
            page_number: context.state.current_page, 
            search_type: context.state.search_type, 
            search_value: context.state.search_value, 
            nav_type: context.state.nav_type,
            dispatch: context.dispatch
        });
    } else {
        context.dispatch({ 
            type: "IS_PAYMENT_SUCCESS", 
            is_payment_success: false,
            job_id: job_id
        });
    }
}