export default function Reducer(prevState, action) {
    switch (action.type) {
        case 'IS_LOADING':
            return {
                ...prevState,
                is_loading: true,
            };
        case 'IS_LOADED':
            return {
                ...prevState,
                is_loading: false,
                sales_invoices: action.sales_invoices,
                total_sales_invoices: action.total_sales_invoices,
                total_pages: action.total_pages,
            };
        case 'SET_CURRENT_PAGE':
            return {
                ...prevState,
                current_page: action.current_page,
            };
        default:
            throw new Error();
    }
}