import React from "react";
import Card from 'react-bootstrap/Card';

const JobsheetImages = (props) => {
    return(
        <Card>
            <Card.Body>
                <Card.Title>JOBSHEET IMAGES</Card.Title>
                <div className="scroll-container">
                    {props.images?.map((image, imageIndex) => (
                        <a href={image.job_sheet_image_path} target="_blank" rel="noreferrer">
                            <img src={image.job_sheet_image_path} alt="Cinque Terre" key={imageIndex} className="mb-1"/>
                        </a>
                    ))}
                </div>
            </Card.Body>
        </Card>
    );
}

export default JobsheetImages