import React, { useReducer, useEffect } from "react";
import Html from "./html";
import { useParams } from "react-router-dom";
import { loadData } from "./service";
import Reducer from "./reducer";
import { initialState } from "./initialState";
import Context from "./context";
import Loader from "../../components/loading";

const FtmView = () => {
    let { ftm_id } = useParams();
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        loadData(ftm_id, dispatch);
    }, [ftm_id, state.ftm_edit_count]);

    return(
        <Context.Provider value={{ftm_id, state, dispatch}}>
            {!state.is_loading ? <Html/> : <Loader/>}
        </Context.Provider>
    )
}

export default FtmView;