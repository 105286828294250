import React, { useContext } from "react";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import NotificationsIcon from '@mui/icons-material/Notifications';

import Context from "../context";
import DivSpinner from "../../components/divSpinner";
import { theme } from "../../theme";
import { checkAdminNotificationsStatus } from "../service";
import { fullDateFormat } from "../../utils";

const Body = () => {
    const context = useContext(Context);

    return (
        <React.Fragment>
            {context.state.is_loading ? (
                <div style={{ height: `${window.innerHeight - 387}px` }}>
                    <DivSpinner />
                </div>
            ) : (
                <div className="p-3 pb-0">
                    {context.state.notifications.length > 0 ? (
                        <React.Fragment>
                            {context.state.notifications?.map((notification, index) => (
                                <div className="pb-3" key={index}>
                                    <Stack sx={{ width: '100%' }} spacing={2} >
                                        <Alert
                                            severity={theme.main}
                                            action={
                                                <Button 
                                                    color="inherit" 
                                                    size="small" 
                                                    sx={{ marginTop: '15px' }} 
                                                    onClick={() => checkAdminNotificationsStatus({
                                                        current_page: context.state.current_page, 
                                                        notification: notification, 
                                                        dispatch: context.dispatch,
                                                        search_value: context.state.search_value
                                                    })}
                                                >
                                                    <NotificationsIcon/>
                                                </Button>
                                            }
                                        >
                                            <AlertTitle>
                                                {notification.notification_title.replace(/_/g, " ")} &nbsp;
                                                {notification.notification_job_id > 0 && "#"+notification.notification_job_id } &nbsp;
                                                {notification.notification_user_type == 1 ? "( TRADESPERSONS )" : notification.notification_user_type == 2 ? "( CUSTOMER )" : notification.notification_user_type == 3 && "( ADMIN )"}
                                            </AlertTitle>
                                            Date #{fullDateFormat(notification.notification_timestamp)}
                                        </Alert>
                                    </Stack>
                                </div>
                            ))}
                        </React.Fragment>
                    ) : (
                        <div className="pb-3" >Sorry, no data found!</div>
                    )}
                </div>
            )}
        </React.Fragment>
    );
}

export default Body;