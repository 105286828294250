import React from "react";
import Box from '@mui/material/Box';

import CertificateTypesList from "./components/certificateTypesList";

const Html = () => {
    
    return(
        <Box sx={{ bgcolor: 'background.paper' }} className="pt-1 pb-1">
            <CertificateTypesList />
        </Box>
    );
}

export default Html;