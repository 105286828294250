import React, { useContext } from 'react';
import Button from '@mui/material/Button';

import OffcanvasTemplate from "../../../components/offcanvasTemplate";
import ListBody from "./components/listBody";
import { theme } from '../../../theme';
import { Context } from './provider';
import AddNewAttachment from './components/addNewAttachment';
import DivSpinner from '../../../components/divSpinner';

const Html = ({ open, handleClose }) => {
    const context = useContext(Context);

    return (
        <>
            <OffcanvasTemplate
                open={open} 
                handleClose = {handleClose} 
                placement = {'end'}  
                title = {`ATTACHMENTS`}
                body = {!context.state.is_loading ? <ListBody/> : (
                    <div style={{height : `${window.innerHeight-300}px`}}>
                        <DivSpinner/>
                    </div>
                )} 
                footer = {
                    <div className="container">
                        <div className="row" style={{marginTop: '10px'}}>
                            <div className="col">
                                <div className="d-grid gap-2 mb-3" >
                                    <Button 
                                        variant="contained" 
                                        fullWidth 
                                        color={theme.main}
                                        onClick={() => context.dispatch({
                                            type: "IS_SHOW_ADD_ATTACHMENT_MODAL",
                                            is_show_add_attachment_modal: true
                                        })}
                                    >ADD</Button>   
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />

            <AddNewAttachment/>
        </>
    );
};

export default Html;
