import React, { useContext } from "react";
import Context from "../../context";
import { Pagination, Stack } from "@mui/material";
import { getJobsOfCustomer } from "../../service";
import { theme } from "../../../../theme";

const PaginationView = () => {
    const context = useContext(Context);

    return(
        <>
            {!context.state.is_loading ? (
                <Stack 
                    spacing={2} 
                    className="mb-3" 
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center'
                    }}      
                >
                    <Pagination 
                        count={context.state.customer_jobs_total_pages} 
                        page={context.state.page_number} 
                        onChange={(event, newPage) => getJobsOfCustomer(context.customer_id, newPage, context.dispatch)}
                        color={theme.main}
                    />
                </Stack>
            ) : null }
        </>
    );
}

export default PaginationView;