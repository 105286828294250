import React, { useContext } from 'react';
import Button from '@mui/material/Button';

import Form from 'react-bootstrap/Form';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';

import { useForm } from "react-hook-form";
import { theme } from "../../../../theme";
import { Context } from '../provider';
import InputFileUpload from './inputFileUpload';
import File from './file';
import BtnSpinner from '../../../../components/btnSpinner';

const FormSubmit = () => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const context = useContext(Context);

    return (
        <Form onSubmit={handleSubmit((data) => context.submitAttachments(data, reset))}>
            <FormControl error={errors.title} fullWidth className="mb-3">    
                <label>Title</label>
                <TextField
                    error={errors.title}
                    placeholder="Enter your title"
                    type="text"
                    size="small"
                    fullWidth
                    InputProps={{
                        style: { backgroundColor: '#fff' },
                    }}
                    {...register("title", { required: true })}
                />
                {errors.title && <FormHelperText>Title is required</FormHelperText>}
            </FormControl>  

            <FormControl error={errors.description} fullWidth className="mb-3">
                <label>Description</label>
                <TextField
                    error={errors.description}
                    placeholder="Enter your description"
                    size="small"
                    type="text"
                    multiline
                    rows={4}
                    InputProps={{
                        style: { backgroundColor: '#fff' },
                    }}
                    {...register("description", { required: true })}
                />
                {errors.description && <FormHelperText>Description is required</FormHelperText>}
            </FormControl>  

            <InputFileUpload errors = {errors} register = {register}/>
            <File/>

            <div style={{ textAlign: 'right' }}>
                <Button type="submit" variant="contained"  color={theme.main} disabled={context.state.is_loading_save_btn}>
                    {context.state.is_loading_save_btn ? (
                        <BtnSpinner/>
                    ) : "SAVE"}
                </Button>
            </div>
        </Form>
    );
}

export default FormSubmit;