import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Alert from '@mui/material/Alert';
import { useForm } from 'react-hook-form';
import { Container } from 'react-bootstrap';
import { Stack, TextField } from '@mui/material';

import { saveNewSubSkill } from '../service';
import { theme } from '../../../theme';
import BtnSpinner from '../../../components/btnSpinner';
import Context from '../context';
import ModalTemplateWithForm from '../../../components/modal/modalTemplateWithForm';

const AddSubSkills = () => {
    const {
        register,
        handleSubmit,
        reset
    } = useForm();

    const context = useContext(Context);

    return (
        <>
            <ModalTemplateWithForm
                show={context.state.is_show_add_sub_skills} 
                closeFun={() => context.dispatch({type: 'IS_SHOW_ADD_SUB_SKILLS', is_show_add_sub_skills: false})}
                size = {null}
                centered = {false}
                fullscreen = {false}
                dialogClassName = {null}
                title = {`Add a new service type`}
                body = {
                    <Container>
                        {context.state.error.length > 0 && context.state.error.map((item, index) => (
                            <Stack sx={{ width: '100%' }} spacing={2} className="mb-3" key={index}>
                                <Alert severity="error">{item}</Alert>
                            </Stack>
                        ))}

                        <label style={{ color: 'black' }} >NAME</label>
                        <TextField
                            placeholder="Service type name"
                            fullWidth
                            size="small"
                            InputProps={{
                                style: { backgroundColor: '#fff' },
                            }}
                            {...register('skill_name', { required: false })}
                        />

                        <label style={{ color: 'black' }} className="mt-3">RATE</label>
                        <TextField
                            placeholder="Rate"
                            fullWidth
                            size="small"
                            InputProps={{
                                style: { backgroundColor: '#fff' },
                            }}
                            {...register('rate', { required: false })}
                        />

                        <label style={{ color: 'black' }} className="mt-3">COMMISSION</label>
                        <TextField
                            placeholder="Commission"
                            fullWidth
                            size="small"
                            InputProps={{
                                style: { backgroundColor: '#fff' },
                            }}
                            {...register('commission', { required: false })}
                        />
                    </Container>
                }
                footer = {
                    <>
                        <Button
                            variant={theme.close_btn}
                            onClick={() => context.dispatch({type: 'IS_SHOW_ADD_SUB_SKILLS', is_show_add_sub_skills: false})}
                            size="sm"
                            disabled={context.state.btn_loading}
                        >
                            {context.state.btn_loading ? <BtnSpinner /> : 'CLOSE'}
                        </Button>
                        <Button
                            variant={theme.action_btn}
                            type="submit"
                            size="sm"
                            disabled={context.state.btn_loading}
                        >
                            {context.state.btn_loading ? <BtnSpinner /> : 'SAVE'}
                        </Button>
                    </>
                }
                onSubmit = {handleSubmit((data) => saveNewSubSkill(context.skill_id, data, context.dispatch, reset))}
            />
        </>
    );
};

export default AddSubSkills;