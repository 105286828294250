import { GET } from "../../API/get";

export const loadData = async ({ftm_id, page_number, dispatch}) => {
    dispatch({ type: "IS_LOADING" });
    let response = await GET(`all/ftm/payment/histories/${ftm_id}/${page_number}`);

    if(response.status === "Success"){
        dispatch({ 
            type: "SET_LIST", 
            total_jobs: response.total_jobs, 
            total_pages: response.total_pages, 
            ftm: response.details.ftm,
            list: response.details.list, 
            current_page: page_number 
        });
    }
}

export const getPaymentHistory = async (history_id, dispatch) => {
    if(history_id != undefined){
        dispatch({ type: "IS_LOADING_HISTORY" });
        let response = await GET(`ftm/payment/histories/${history_id}`);

        if(response.status === "Success"){
            dispatch({ 
                type: "SET_HISTORY", 
                history: response.payment_history
            });
        }
    }
}

export const getFtmPaymentPDF = async (history_id, dispatch) => {
    dispatch({ type: "IS_BTN_LOADING", is_btn_loading: true });
    let response = await GET(`ftm/payment/pdf/${history_id}`);

    if(response.status === "Success"){
        window.open(response.pdf, '_blank', 'noopener,noreferrer');

        dispatch({ 
            type: "IS_BTN_LOADING", 
            is_btn_loading: false
        });
    }
}