import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ListItemIcon from '@mui/material/ListItemIcon';

const SettingList = ({hourly_rate, company_commission}) => {
    return(
        <Box sx={{ flexGrow: 1}} >
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <List dense={true}>
                        <ListItem
                            secondaryAction={<b>{hourly_rate}</b>}
                        >
                            <ListItemIcon>
                                <HourglassTopIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="HOURLY RATE"
                            />
                        </ListItem>

                        <ListItem
                            secondaryAction={<b>{company_commission}%</b>}
                        >
                            <ListItemIcon>
                                <LocationCityIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="COMPANY COMMISSION"
                            />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Box>
    );
}

export default SettingList;