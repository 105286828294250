import React, { useContext } from "react";
import Context from "../context";
import { Pagination, Stack } from "@mui/material";
import { loadData } from "../service";
import { theme } from "../../../theme";

const PaginationView = () => {
    const context = useContext(Context);

    return(
        <>
            {!context.state.is_loading ? (
                <Stack 
                    spacing={2} 
                    className="mb-3" 
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center'
                    }}      
                >
                    <Pagination 
                        count={context.state.total_pages} 
                        page={context.state.current_page} 
                        onChange={(event, newPage) => context.dispatch({
                            type: "UPDATE_CURRENT_PAGE",
                            current_page: newPage
                        })}
                        color={theme.main}
                    />
                </Stack>
            ) : null }
        </>
    );
}

export default PaginationView;