import React, { useContext } from "react";
import AddBoxIcon from '@mui/icons-material/AddBox';
import IconButton from '@mui/material/IconButton';
import Context from "../context";

const HeaderView = () => {
    const context = useContext(Context);

    return(
        <div className="row">
            <div className="col-lg-6 col-7">
                <h6 className="text-uppercase text-white text-capitalize ps-3">
                    SERVICE CATEGORIES
                </h6>
            </div>

            <div className="col-lg-6 col-5 my-auto text-end">
                <div className="dropdown  pe-4">
                    <IconButton
                        edge="end"
                        className="text-white cursor-pointer"
                        onClick={() => context.dispatch({type: 'IS_SHOW_ADD_SKILLS', is_show_add_skills: true})}
                    >
                        <AddBoxIcon />
                    </IconButton>
                </div>
            </div>
        </div>
    );
}

export default HeaderView;