export const initialState = {
    open_chat: false,
    is_loading: true,
    job_details: [],
    images: [],
    appointments: [],
    ftms: [],
    extra_payments: [],
    job_sheet: {},
    is_show_invoice: false,
    is_show_jobsheet: false,
    is_show_chat: false,
    is_show_ftm_request: false,
    job_unread_message_count: 0,
    job_status_change_logs: [],
    is_show_job_timeline: false,
}