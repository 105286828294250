import React, { useContext } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';

import List from "./components/list";
import DivSpinner from "../../components/divSpinner";
import { Context } from "./provider";
import OffcanvasTemplate from "../../components/offcanvasTemplate";

const Html = ({
    show, 
    job_id, 
    handleClose
}) => {
    const context = useContext(Context);

    return (
        <OffcanvasTemplate
            open={show} 
            handleClose = {() => handleClose()} 
            placement = {'end'}  
            title = {`FTM REQUEST : #${job_id}`}
            body = {context.state.is_loading ? (<DivSpinner/>) : (<List/>)}
            footer = {null}
        />
    );
}

export default Html;