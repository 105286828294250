import React, { useContext } from "react";
import IconButton from '@mui/material/IconButton';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Context from "../context";
import { ftmTrackerDispatch } from "../service";
import { theme } from "../../../theme";

const TrackerDispatch = () => {
    const context = useContext(Context);

    return (
        <div className="col-md-6 mb-lg-0 mb-4">
            <div className="card card-plain">
                <div className="card-body p-3">
                    <div className="row">
                        <div className="col-md-12 mb-md-0 mb-4">
                            <div className="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
                                <img className="w-10 me-3 mb-0" src="/assets/img/trackerDispatchenew.png" alt="logo"/>
                                <h5 className="mb-0">Tracker dispatched</h5>
                                <div className="d-flex flex-column ms-auto">
                                    {context.state.ftm.ftm_gps_tracker_is_dispatch == 1 ? (
                                        <CheckCircleIcon sx={{color: theme.main_code}}/>
                                    ) : (
                                        <IconButton 
                                            aria-label="TrackerDispatch" 
                                            color="danger" 
                                            onClick={() => ftmTrackerDispatch(context.ftm_id, context.dispatch)}
                                            disabled = {context.state.ftm.ftm_is_verify == -2}
                                        >
                                            <DoDisturbOnIcon />
                                        </IconButton>
                                    )}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TrackerDispatch;