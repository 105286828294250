import { FORMPOST } from "../../../API/post";
import { PUT } from "../../../API/put";
import { GET } from "../../../API/get";
import { disputesRef } from "../../../firebase";
import { getUserInfo } from "../../../storage";
import { theme } from '../../../theme';

import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

// export the loadMessages function which takes in dispute and dispatch as arguments
export const loadMessages = async (dispute, dispatch) => {
    console.log('loadMessages dispute :', dispute);
    try {
        if(dispute.doc_id){                                                                                      
            let query = disputesRef.doc(dispute.doc_id).collection('dispute_messages').orderBy('message_time');

            query.onSnapshot(async (snapshot) => {
                console.log("snapshot :", snapshot.docs.map(doc => doc.data()));
                dispatch({
                    type: "IS_LOADED",
                    messages_list: snapshot.docs.map(doc => doc.data()),
                });
            });
            checkDisputeInvoicesAvailability(dispute.dispute_job.job_id, dispatch);
        }
    } catch (error) {
        console.error("Error getting documents: ", error);
    }
}

// Function to send text messages
export const sendTextMessages = async (data, dispute, reset = () => {}) => {
    // Get user info
    let user_info = getUserInfo();

    // Add a new message to the messages collection
    await disputesRef.doc(dispute.doc_id).collection('dispute_messages').add({
        message_content: {
            text: data.text,
            files: []
        },
        message_sender: {
            user_doc_id: user_info.admin_firebase_document_id,
            user_id: user_info.admin_id,
            user_name: user_info.admin_name,
            user_type: "ADMIN"
        },
        message_time: new Date().getTime()
    });

    // Reset the state or props as needed
    reset?.();
}

export const filePreView = (images, dispatch) => {
    let content = [];
    let count = 0;

    if (typeof images !== 'undefined') {
        for (let i = 0; i < images.length; i++) {
            content.push(
                <div className="img-wraps" key={count++}>
                    <span className="closes" title="Delete" onClick={() => removeImage(i, images, dispatch)}>×</span>
                    <img
                        src={URL.createObjectURL(images[i])}
                        style={{
                            height: "70px",
                            width: "70px"
                        }}
                        alt="Avatar"
                        className="img-responsive"
                    />
                </div>
            );
        }
    }

    return count > 0 ? content : <p><b>YOUR IMAGES</b></p>;
}

export const removeImage = (index, images, dispatch) => {
    images.splice(index, 1);

    dispatch({
        type: "SET_IMAGES",
        images: images
    });

    filePreView(images);
}

export const loadImage = (images, files, dispatch) => {
    console.log('files :', files);
    let files_list = typeof images !== 'undefined' ? images : [];

    for (let file of files) {
        files_list.push(file);
    }

    dispatch({
        type: "SET_IMAGES",
        images: files_list
    });
}

export const sendMessageWithImages = async ({ data, dispute, images, dispatch, reset, setIsOpen }) => {
    console.log('sendMessageWithImages data :', data);
    dispatch({ type: "IS_LOADING_BTN", is_loading_btn: true });

    // Get user info
    let user_info = getUserInfo();

    let upload_images = [];
    if (images.length > 0) {
        let response = await FORMPOST("massages/files/upload", {
            images: images
        });

        if (response.status === "Success") {
            dispatch({ type: "IS_LOADING_BTN", is_loading_btn: false });
            for (let item of response.list) {
                upload_images.push({
                    path: item.image_path
                });
            }
        }
    }

    // Add a new message to the messages collection
    await disputesRef.doc(dispute.doc_id).collection('dispute_messages').add({
        message_content: {
            text: data.caption,
            files: upload_images
        },
        message_sender: {
            user_doc_id: user_info.admin_firebase_document_id,
            user_id: user_info.admin_id,
            user_name: user_info.admin_name,
            user_type: "ADMIN"
        },
        message_time: new Date().getTime()
    });

    reset();
    dispatch({
        type: "SET_IMAGES",
        images: []
    });
    setIsOpen(false);
}

export const markDisputeResolved = async (dispute, dispatch) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to close this chat? The dispute will be marked as resolved.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.action_btn_code,
        cancelButtonColor: theme.cancel_btn_code,
        confirmButtonText: 'Yes',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
            let ref = disputesRef.doc(dispute.doc_id);
            try {
                // Update firestore document
                ref.update({
                    dispute_is_resolved: 1
                });
    
                // Make PUT request to close dispute
                let response = await PUT("close-dispute", {
                    dispute_job_id: dispute.dispute_job.job_id
                });

                // Check if both firestore update and PUT request were successful
                if (response.status === "Success") {
                    return ref; // Resolve the promise with ref
                } else {
                    throw new Error(response.error); // Reject the promise with an error
                }
            } catch(error) {
                toast.error(`Request failed: ${error.message}`);
            }
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
        if (result.isConfirmed) {
            toast.success("Dispute marked as resolved.");
            let data = {
                text: "I'm glad we were able to resolve the dispute today. This chat will now be closed. Thanks for your patience and understanding."
            }

            dispatch({type: "DISPUTE_IS_RESOLVED"});

            sendTextMessages(data, dispute);
            loadMessages(dispute, dispatch);
        }
    })
}

export const refundJobPayment = async (event, dispute, dispatch, reset) => {
    event.preventDefault();
    let refund_amount = 0;
    if(event.target.elements.refund_amount) {
        refund_amount = parseFloat(event.target.elements.refund_amount.value);
    }

    dispatch({
        type: 'PROCESSING_REFUND',
        processing_refund: true,
        is_open_refund_job_payment_modal: true
    });

    let response = await PUT("refund-job-payment", {
        job_id: dispute.dispute_job.job_id,
        refund_type: event.target.elements.refund_type.value,
        refund_amount: refund_amount
    });

    if(response.status === "Success"){
        toast.success(response.message);
        reset();
        dispatch({
            type: 'PROCESSING_REFUND',
            processing_refund: false,
            is_open_refund_job_payment_modal: false
        });
        loadMessages(dispute, dispatch);
    }else{
        toast.error(response.message);
    }
}

const checkDisputeInvoicesAvailability = async (job_id, dispatch) => {
    try {
        const response = await GET("check-credit-note-and-sales-invoice-availability/"+job_id);
        if (response.status === "Success") {
            dispatch({
                type: 'JOB_PAYMENT_REFUNDED',
                job_payment_refunded: response.documents_available
            });
        } else {
            throw new Error(response.error);
        }
    } catch (error) {
        toast.error(`Error checking dispute invoices availability: ${error.message}`);
    }
}