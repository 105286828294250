export default function Reducer(prevState, action){
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true,
            };
        case "IS_LOADED": 
            return {
                ...prevState,
                details: action.details,
                is_loading: false,
            }; 
        case "IS_SHOW_ALERT":
            return {
                ...prevState,
                show_alert: action.show_alert,
                is_resetting_password: false,
            };
        case "IS_RESETTING_PASSWORD":
            return {
                ...prevState,
                is_resetting_password: action.is_resetting_password,
            };
        default:
            throw new Error();
    }
}