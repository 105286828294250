import React from "react";
import Html from "./html";
import { Provider } from "./provider";

const SalesInvoices = ({
    show,
    closeFun
}) => {
    return (
        <Provider>
            <Html 
                show = {show}
                closeFun = {closeFun}
            />
        </Provider>
    );
}

export default SalesInvoices;