import React from "react";
import { NavLink } from "react-router-dom";
import { theme } from "../../../theme";
import Header from "./header";
import { data } from "../data";
import { Badge } from "@mui/material";

const FullSidebar = ({ ftmCount, jobCount, isFullSidebar, setIsFullSidebar }) => {                                                     
    return (
        <aside
            className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark"
            id="sidenav-main"
            style={{ overflowX: 'hidden', zIndex: 1 }}
        >
            <Header />
            <hr className="horizontal light mt-0 mb-2" />

            <div className="collapse navbar-collapse  w-auto" id="sidenav-collapse-main">
                <ul className="navbar-nav">
                    {data.map((item, data_index) => (
                        <React.Fragment key={data_index}>
                            {item.heder !== "" ? (
                                <li className="nav-item mt-3">
                                    <h6 className="ps-4 ms-2 text-uppercase text-xs text-white font-weight-bolder opacity-8">{item.heder}</h6>
                                </li>
                            ) : null}

                            {item.list.map((list_item, index) => (
                                <li className="nav-item" key={index}>
                                    <NavLink
                                        className={({ isActive }) => (isActive ? `nav-link text-white active bg-gradient-${theme.main}` : 'nav-link text-white')}
                                        to={list_item.to}
                                        style={{ backgroundColor: list_item.to ? 'none' : 'transparent' }}
                                    >
                                        {list_item.name === "Applications" || list_item.name === "Cancelled Jobs" ? (
                                            <Badge
                                                badgeContent={list_item.name === "Applications" ? ftmCount : jobCount}
                                                color={theme.sidebar_notification_count_color}
                                            >
                                                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                                    <i className="material-icons opacity-10">{list_item.icon}</i>
                                                </div>
                                                <span className="nav-link-text ms-1">{list_item.name}</span>
                                            </Badge>
                                        ) : (
                                            <>
                                                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                                    <i className="material-icons opacity-10">{list_item.icon}</i>
                                                </div>
                                                <span className="nav-link-text ms-1">{list_item.name}</span>
                                            </>
                                        )}
                                    </NavLink>
                                </li>
                            ))}
                        </React.Fragment>
                    ))}
                </ul>
            </div>

            {/* <FooterBtn
                isFullSidebar={isFullSidebar}
                setIsFullSidebar={setIsFullSidebar}
            /> */}

        </aside>
    );
}

export default FullSidebar;

// import React, { useState, useEffect } from "react";
// import { NavLink, useLocation } from "react-router-dom";
// import { theme } from "../../../theme";
// import Header from "./header";
// import { data } from "../data";
// import { Badge } from "@mui/material";
// import FooterBtn from "./footerBtn";

// const FullSidebar = ({ ftmCount, jobCount, isFullSidebar, setIsFullSidebar }) => {
//     const [collapsedSections, setCollapsedSections] = useState(
//         Array(data.length).fill(true)
//     );

//     const location = useLocation();

//     useEffect(() => {
//         console.log("Location changed:", location);
//         findIndexByLink(location.pathname)
//         // Reset the collapsed state when the route changes
//         // setCollapsedSections(Array(data.length).fill(true));
//     }, [location]);

//     const toggleCollapse = (idx) => {
//         setCollapsedSections((prevState) => {
//             const newState = [...prevState];
//             newState[idx] = !newState[idx];
//             return newState;
//         });
//     };

//     const findIndexByLink = (link) => {
//         for (let i = 0; i < data.length; i++) {
//             for (let j = 0; j < data[i].list.length; j++) {
//                 if (data[i].list[j].to === link) {
//                     toggleCollapse(i);
//                     return i;
//                 }
//             }
//         }
//         return -1; // If link is not found
//     }

//     return (
//         <aside
//             className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark"
//             id="sidenav-main"
//             style={{ overflowX: 'hidden', zIndex: 1 }}
//         >
//             <Header />
//             <hr className="horizontal light mt-0 mb-2" />

//             <div className="collapse navbar-collapse  w-auto" id="sidenav-collapse-main">
//                 <ul className="navbar-nav">
//                     {data.map((item, idx) => (
//                         <li className="nav-item" key={idx}>
//                             {item.heder !== "" && (
//                                 <>
//                                     <div
//                                         className="nav-link text-white"
//                                         role="button"
//                                         aria-expanded={!collapsedSections[idx]}
//                                         onClick={() => toggleCollapse(idx)}
//                                     >
//                                         <i className="material-icons-round opacity-10">dashboard</i>
//                                         <span className="nav-link-text ms-2 ps-1">{item.heder}</span>
//                                     </div>
//                                     <div
//                                         className={`collapse ${collapsedSections[idx] ? '' : 'show'}`}
//                                     >
//                                         <ul className="nav" style={{paddingLeft: "15px"}}>
//                                             {item.list.map((list_item, index) => (
//                                                 <li className="nav-item" key={index}>
//                                                     <NavLink
//                                                         className={({ isActive }) => (isActive ? `nav-link text-white active bg-gradient-${theme.main}` : 'nav-link text-white')}
//                                                         to={list_item.to}
//                                                         style={{ backgroundColor: list_item.to ? 'none' : 'transparent' }}
//                                                     >
//                                                         <i className="material-icons opacity-10" style={{fontSize: '18px'}}>{list_item.icon}</i>
//                                                         <span className="sidenav-normal ms-2 ps-1"> {list_item.name} </span>
//                                                     </NavLink>
//                                                 </li>
//                                             ))}
//                                         </ul>
//                                     </div>
//                                 </>
//                             )}
//                         </li>
//                     ))}
//                 </ul>
//             </div>

//             <FooterBtn
//                 isFullSidebar={isFullSidebar}
//                 setIsFullSidebar={setIsFullSidebar}
//             />

//         </aside>
//     );
// }

// export default FullSidebar;
