/* eslint-disable react/jsx-no-target-blank */
import React, { useContext } from "react";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import Context from "../context";
import SetExpiryDate from "./setExpiryDate";
import { approveFtmDocument, deleteFtmDocument } from "../service";
import { theme } from "../../../theme";
import CardWithHeaderTemplate from "../../../components/cardWithHeaderTemplate";

const FtmDocuments = () => {
    const context = useContext(Context);

    const ItemView = ({ document, index }) => {
        return (
            <div className="col-xl-3 col-md-6 mb-xl-0 mb-4" key={index}>
                <div className="card card-blog card-plain">
                    <div className="card-header p-0 mt-n4 mx-3">
                        <a
                            className="d-block shadow-xl border-radius-xl mb-4"
                            href={document.ftm_document_path}
                            alt="documentImage"
                            target="_blank"
                            style={{ width: "200px", height: "120px" }}
                        >
                            <img
                                src={document.ftm_document_path}
                                alt="img-blur-shadow"
                                className="img-fluid shadow border-radius-xl"
                                style={{ width: "200px", height: "120px" }}
                            />
                        </a>

                        <Box display="flex" justifyContent="center" flexDirection="column" width={"200px"}>
                            <SetExpiryDate
                                ftm_document_id={document.ftm_document_id}
                                ftm_document_expiry_date={document.ftm_document_expiry_date}
                                ftm_document_comment={document.ftm_document_comment}
                            />
                            
                            <>
                                <Box display="flex" justifyContent="space-between" mt={1}>
                                    <Button 
                                        variant="contained" 
                                        startIcon={<i className="material-icons text-lg position-relative">check_circle</i>}
                                        size="small" 
                                        color={theme.action_btn} 
                                        style={{ marginRight: '8px' }}
                                        onClick={() => approveFtmDocument(context.ftm_id, document.ftm_document_id, context.dispatch)}
                                        disabled = {document.ftm_document_is_approve == 1 ? true : false}
                                    >
                                        {document.ftm_document_is_approve == 1 ? "APPROVED" : "APPROVE"}
                                    </Button>
                                    <Button 
                                        variant="contained" 
                                        startIcon={<i className="material-icons text-lg position-relative">delete</i>}
                                        size="small" 
                                        onClick={() => deleteFtmDocument(context.ftm_id, document.ftm_documents_types_id, context.dispatch)}
                                        color="error"
                                    >
                                        Delete
                                    </Button>
                                </Box>
                            </>
                        </Box>
                    </div>
                    <div className="card-body p-3 mb-4">
                        {/* <p className="mb-0 text-sm">Document #{index + 1}</p> */}
                        <h5 className="mb-0">{document.ftm_document_type}</h5>
                        <h6>{document.documents_type.sub_skill.skill.skill_name} | {document.documents_type.sub_skill.sub_skill_name}</h6>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            {[
                {
                    title: "CERTIFICATES - SERVICE CATEGORY",
                    body : <div className="row mt-4">
                                {context.state.documents?.parent?.map((document, index) => (
                                    <ItemView
                                        key={`parent-${index}`}
                                        document={document}
                                        index={index}
                                    />
                                ))}
                            </div>
                },
                {
                    title: "CERTIFICATES - SERVICE TYPE",
                    body : <div className="row mt-4">
                                {context.state.documents?.sub?.map((document, index) => (
                                    <ItemView
                                        key={`sub-${index}`}
                                        document={document}
                                        index={index}
                                    />
                                ))}
                            </div>
                }
            ].map((item, index) => (
                <div className="col-12 mt-4" key={index}>
                    <CardWithHeaderTemplate
                        header={
                            <div className="row">
                                <div className="col-8 my-auto">
                                    <div className="numbers">
                                        <h6 className="text-white font-weight-bolder mb-0 ms-3">
                                            {item.title}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        }
                        body={item.body}
                        footer={null}
                        key={index}
                        extraCardClassName={`blur shadow-blur max-height-vh-70 `}
                        extraBodyClassName={`overflow-auto overflow-x-hidden`}
                    />
                </div>
            ))}
            
            {/* <div className="col-12 mt-4">
                <div className="mb-5 ps-3">
                    <h5 className="mb-1">PARENT QUALIFICATION CERTIFICATES</h5>
                </div>

                <div className="row">
                    {context.state.documents?.parent?.map((document, index) => (
                        <ItemView
                            key={`parent-${index}`}
                            document={document}
                            index={index}
                        />
                    ))}
                </div>
            </div>

            <div className="col-12 mt-4">
                <div className="mb-5 ps-3">
                    <h5 className="mb-1">SUB QUALIFICATION CERTIFICATES</h5>
                </div>

                <div className="row">
                    {context.state.documents?.sub?.map((document, index) => (
                        <ItemView
                            key={`sub-${index}`}
                            document={document}
                            index={index}
                        />
                    ))}
                </div>
            </div> */}
        </>
    );
};

export default FtmDocuments;