import React, { useContext } from 'react';
import AsyncSelect from 'react-select/async';
import { Context } from '../provider';

const PostcodeSearch = ({setValue}) => {
    const context = useContext(Context);

    const handleAddressChange = (selectedOption) => {
        console.log("🚀 ~ handleAddressChange ~ selectedOption:", selectedOption)
        if (selectedOption) {
            context.getPostcodeAddressDetails(selectedOption.value, setValue);
        }
    };

    return (
        <div style={{ width: "100%" }}>
            <AsyncSelect
                isClearable
                cacheOptions
                defaultOptions
                loadOptions={context.getPostcodeAddressList}
                onChange={handleAddressChange}
                value={context.state.search_value}
                styles={{
                    control: (base) => ({
                        ...base,
                        width: "100%",
                    }),
                }}
            />
        </div>
    );
};

export default PostcodeSearch;