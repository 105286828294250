import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';

import Context from "../context";

export default function File() {
    const context = useContext(Context);

    return (
        <>
            {context.state.uploaded_document != null ? (
                <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>

                            <Typography sx={{ mt: 4, mb: 2 }} variant="h7" component="div">
                                Uploaded file
                            </Typography>

                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                        <FolderIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={context.state.uploaded_document?.name}
                                    />
                                </ListItem>,
                            </List>

                        </Grid>
                    </Grid>
                </Box>
            ) : null}
        </>
    );
}