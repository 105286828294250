import { DOWNLOADFILE, GET } from "../../API/get";

export const fetchCreditNotes = async ({ current_page, search_value }) => {
    let response = await GET(`get-all-credit-notes/${current_page}`);
    if(response.status === "Success"){
        return response;
    }

    return null;
}

export const openCreditNotePDF = async (id) => {
    await DOWNLOADFILE(`credit_note/pdf/${id}`);
}