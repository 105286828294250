import api from "./api";
import {errorAlert, badRequestError} from "./errorHandling";

export const GET = async (URL) => {
    try{
        let response = await api.get(URL);
        if(response.data.status === "Success"){
            return response.data
        }else{
            return errorAlert(response?.data?.message);
        }
    }catch(error){
        console.log('error :', error);
        return badRequestError(error);
    }
};

export const GETFILTER = async (URL, params) => {
    try{
        let response = await api.get(URL, { params });
        if(response.data.status === "Success"){
            return response.data
        }else{
            return errorAlert(response?.data?.message);
        }
    }catch(error){
        console.log('error :', error);
        return badRequestError(error);
    }
};

export const DOWNLOADFILE = async (URL, file_ext = null) => {
    try {
        let response = await api.get(URL, { responseType: 'blob' });
        if (response.status === 200) {
            let file_name = new Date().getTime();
            if(file_ext != null){
                file_name = file_name + "." + file_ext;
            }else{
                file_name = file_name + ".pdf";
            }

            let url = window.URL.createObjectURL(new Blob([response.data]));
            let link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file_name);
            document.body.appendChild(link);
            link.click();

            return {
                status: "Success"
            }
        } else {
            return errorAlert(response?.data?.message);
        }
    } catch (error) {
        return badRequestError(error);
    }
}