import React from "react";

const EmptyChat = () => {
    return (
        <div className="col-lg-8 col-md-10 mx-auto ">
            <div className="" style={{ marginTop: '200px' }}>
                <div className="card-body p-3 pt-0">
                    <div className="row">
                        <div className="col-4">
                            <img src="/assets/img/voice-and-chat-support.webp" alt="kal" className="border-radius-lg shadow shadow-dark w-70 mt-n4"/>
                        </div>
                        <div className="col-8 my-auto">
                            <h5 className="mb-0">Customers and Tradespersons direct messages</h5>
                            <p className="text-muted text-sm mt-3">
                                Click and continue
                            </p>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmptyChat;