import React, { useContext } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useNavigate} from "react-router-dom";

import Context from "../context";
import DivSpinner from "../../../components/divSpinner";
import { moneyFormat } from "../../../utils";
import { theme } from "../../../theme";

const Table = () => {
    const context = useContext(Context);
    let navigate = useNavigate();

    return(
        <>
            {context.state.is_loading ? (
                <div style={{height : `${window.innerHeight-387}px`}}>
                    <DivSpinner/>
                </div>
            ) : (
                <div className="table-responsive p-0 m-3">
                    <table className="table align-items-center mb-0">
                        <thead>
                            <tr>
                                {[
                                    'FTM', 
                                    <span>CONTACT<br/>NUMBER</span>, 
                                    'EMAIL', 
                                    'TOTAL CHARGES', 
                                    'TOTAL PENALTY'
                                ].map((text, index) => (
                                    <th 
                                        key={index}
                                        className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                                    >
                                        {text}
                                    </th>
                                ))}
                                <th className="text-secondary opacity-7"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {context.state.list.length > 0 ? (
                                <>
                                    {context.state.list.map((ftm, index) => (
                                        <tr key={index}>
                                            <td>
                                                <div className="d-flex px-2 py-1">
                                                    <div>
                                                        <img src={ftm.ftm_profile_image_path || "/assets/img/lock_user.png"} className="avatar avatar-sm me-3 border-radius-lg" alt="user1"/>
                                                    </div>
                                                    <div className="d-flex flex-column justify-content-center">
                                                        <h6 className="mb-0 text-sm">{ftm.ftm_forename} {ftm.ftm_surname}</h6>
                                                        <p className="text-xs text-secondary mb-0">Created {ftm.ftm_created_ISO_string}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            {[
                                                ftm.ftm_contact_number,
                                                ftm.ftm_email,
                                                theme.currency+" "+moneyFormat(ftm.ftm_total_charges),
                                                theme.currency+" "+ftm.ftm_total_penalty ===  null ? "N/A" : theme.currency+" "+moneyFormat(ftm.ftm_total_penalty)
                                            ].map((text, index) => (
                                                <td className="align-middle" key={index}>
                                                    <span className="text-secondary text-sm font-weight-bold">
                                                        {text}
                                                    </span>
                                                </td>
                                            ))}
                                            <td className="align-middle">
                                                <VisibilityIcon 
                                                    style={{cursor: 'pointer'}}
                                                    onClick={() => navigate(`/ftm/view/${ftm?.ftm_id}`)} 
                                                />
                                            </td> 
                                        </tr>
                                    ))}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan="8">Sorry, no data found!</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
}

export default Table;