import React, { useContext } from "react";
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { confirmFTMHoliday } from "../service";
import Context from "../context";

const HolidayStatuses = (props) => {
    const context = useContext(Context);

    return(
        <>
            {props.holiday.ftm_holiday_status == -1 ? (
                <Chip label="CANCELLED" sx={{fontWeight: 'bold'}} />
            ) : props.holiday.ftm_holiday_status == 1 ? (
                <Chip label="APPROVED" color="success" sx={{fontWeight: 'bold'}} />
            ) : props.holiday.ftm_holiday_status == -2 ? (
                <Chip label="REJECTED" color="error" sx={{fontWeight: 'bold'}} />
            ) : (
                <Tooltip title="Approve Holiday Request" arrow>
                    <Chip label="PENDING" color="warning" sx={{fontWeight: 'bold', width: '90px'}} onClick={() => confirmFTMHoliday(props.holiday.ftm_holiday_id, context.state.current_page, context.dispatch)} />
                </Tooltip>
            )}
        </>
    );
}

export default HolidayStatuses;