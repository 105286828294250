import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { theme } from '../theme';

const SpeedDialBtn = ({ actions, icon }) => {
    return (
        <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1 }}>
            <SpeedDial
                ariaLabel="SpeedDial"
                sx={{
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                }}
                icon={icon}
                FabProps={{ color: theme.action_btn }}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={action.onClickFn}
                    />
                ))}
            </SpeedDial>
        </Box>
    );
}

export default SpeedDialBtn;
