import React, { useContext } from "react";
import { Form } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useForm } from "react-hook-form";
import Context from "../context";
import { createUser } from "../service";
import { theme } from "../../../theme";
import BtnSpinner from "../../../components/btnSpinner";

const CreateForm = () => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const context = useContext(Context);

    return(
        <div className="ps-4 pe-4">
            <Form className="mt-3" onSubmit={handleSubmit((data) => createUser(data, context.dispatch, reset))}>
                <FormControl error={errors.admin_full_name} fullWidth>
                    <label><b>Full name</b></label>
                    <TextField
                        error={errors.admin_full_name}
                        placeholder="Full name"
                        size="small"
                        type="text"
                        {...register("admin_full_name", { required: true })}
                    />
                    {errors.admin_full_name && <FormHelperText>Full name is required</FormHelperText>}
                </FormControl>

                <FormControl error={errors.admin_contact_number} fullWidth className="mt-3">
                    <label><b>Contact number</b></label>
                    <TextField
                        error={errors.admin_contact_number}
                        placeholder="Contact number"
                        size="small"
                        type="tel"
                        {...register("admin_contact_number", { required: true })}
                    />
                    {errors.admin_contact_number && <FormHelperText>Contact number is required</FormHelperText>}
                </FormControl>

                <FormControl error={errors.admin_email} fullWidth className="mt-3">
                    <label><b>Email</b></label>
                    <TextField
                        error={errors.admin_email}
                        placeholder="Email"
                        size="small"
                        type="email"
                        {...register("admin_email", { required: true })}
                    />
                    {errors.admin_email && <FormHelperText>Email is required</FormHelperText>}
                </FormControl>

                <FormControl error={errors.admin_password} fullWidth className="mt-3">
                    <label><b>Password</b></label>
                    <TextField
                        error={errors.admin_password}
                        placeholder="Password"
                        size="small"
                        type="password"
                        {...register("admin_password", { required: true })}
                    />
                    {errors.admin_password && <FormHelperText>Password is required</FormHelperText>}
                </FormControl>

                <div className="mt-4">
                    <Button 
                        type="submit" 
                        variant="contained" 
                        fullWidth
                        color={theme.action_btn} 
                        disabled={context.state.is_creating_new_user}
                    >
                        {context.state.is_creating_new_user ? (
                            <BtnSpinner/>
                        ): "CREATE NEW USER"}
                    </Button>
                </div> 
            </Form>
        </div>
    )
}

export default CreateForm;