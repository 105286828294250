import React, { useReducer, useEffect } from "react";
import Html from "./html";
import { useParams } from "react-router-dom";

import { loadData } from "./service";
import Reducer from "./reducer";
import { initialState } from "./initialState";
import Context from "./context";
import Loader from "../../components/loading";
import { handlePaymentSuccess } from "./service";

const CustomerView = () => {
    let { customer_id } = useParams();
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        loadData(customer_id, dispatch);

        window.addEventListener('message', function(event) {
            handlePaymentSuccess(event, customer_id, dispatch);
        });

        return () => {
            window.removeEventListener('message', handlePaymentSuccess);
        };
    }, [customer_id]);

    return(
        <Context.Provider value={{customer_id, state, dispatch}}>
            {!state.is_loading ? <Html/> : <Loader/>}
        </Context.Provider>
    )
}

export default CustomerView;