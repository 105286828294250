import React, { useEffect, useReducer } from 'react';
import Collapse from '@mui/material/Collapse';

import Reducer from './reducer';
import { initialState } from './initialState';
import { loadData } from './service';
import Html from './html';
import Context from "./context";
import DivSpinner from '../../components/divSpinner';
import CollapseButton from './components/collapseButton';
import AddCertificate from './components/addCertificate';
import EditCertificate from './components/editCertificate';

const CertificateTypes = ({skill, key, type}) => {
    console.log("CertificateTypes : " ,  skill);
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        loadData(skill?.skill_id, type, dispatch);
    }, [skill?.skill_id, type]);

    return (
        <Context.Provider value={{skill_id:skill?.skill_id, skill_name: skill?.skill_name, state: state, type: type, dispatch: dispatch}}>
            <CollapseButton
                key = {key}
                type = {type}
            />
            
            {type === 0 ? (
                <>
                    {state.is_loading ? <DivSpinner/> : <Html />}
                </>
            ) : (
                <>
                    <Collapse key={key} in={state.is_open_certificate_types === key} timeout="auto" unmountOnExit>
                        {state.is_loading ? <DivSpinner/> : <Html />}
                    </Collapse>
                </>
            )}
            

            <AddCertificate />
            <EditCertificate/>
        </Context.Provider>
    );
}

export default CertificateTypes;