import React from "react";
import { theme } from "../../../../theme";

const PageHeader = () => {
    return(
        <div className="page-header min-height-300 border-radius-xl mt-4" style={{backgroundImage: `url('/assets/img/profile-page-header.jpg')`}}>
            <span className={`mask  bg-gradient-${theme.main}  opacity-6`}></span>
        </div>
    );
}

export default PageHeader;