import React, { useContext } from "react";
import Header from "./header";
import JobsChat from "../../../jobs/components/chat";
import { Context } from "../provider";
import EmptyChat from "../../components/emptyChat";

const ChatBody = () => {
    const context = useContext(Context);

    return (
        <JobsChat
            job_id = {context.state.selected_job?.job_id}
            job_firebase_document_id = {context.state?.selected_job?.doc_id}
            show = {false}
            is_inbox = {true}
            handleClose = {() => {}}
            header = {<Header/>}
            emptyChat = {<EmptyChat/>}
            is_have_selected_job = {context.state.selected_job !== null}
        /> 
    );
}

export default ChatBody;