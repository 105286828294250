import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import HistoryIcon from '@mui/icons-material/History';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from "react-router-dom";

import Context from "../context";
import OptionList from './optionList';
import { Chip } from '@mui/material';
export default function SearchList() {
    let navigate = useNavigate();
    const context = React.useContext(Context);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <OptionList/>
            <Grid container spacing={2}>
                {context.state.list.length > 0 ? (
                    <Grid item xs={12} md={12}>
                        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                            Search list
                        </Typography>
                        <>
                            <List>
                                {context.state.list.map((item, index) => (
                                    <ListItem
                                        key={index}
                                        secondaryAction={
                                            <IconButton 
                                                edge="end" 
                                                onClick={() => navigate(item.type === "ORDER" ? `/jobs/view/${item.id}` : `/ftm/view/${item.id}`)}
                                            >
                                                <ArrowForwardIcon />
                                            </IconButton>
                                        }
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                <HistoryIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <>
                                                    {item.primary_text}&#160;
                                                    {item.chip_obj.show && <Chip label={item.chip_obj.label} size="small" color={item.chip_obj.color} variant="outlined"/>}
                                                </>
                                            }
                                            secondary={item.secondary_text}
                                        />
                                        
                                    </ListItem>
                                ))}
                            </List>
                        </>
                    </Grid>
                ) : null}
            </Grid>
        </Box>
    );
}