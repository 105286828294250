import React from "react";

import Html from "./html";
import { Provider } from "./provider";

const FtmRequest = ({
    show, 
    job_id, 
    handleClose
}) => {
    return(
        <Provider job_id={job_id}>
            <Html
                show = {show}
                job_id = {job_id}
                handleClose = {handleClose}
            />
        </Provider>
    )
} 

export default FtmRequest;