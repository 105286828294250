import React, { useContext } from "react";
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';

import Context from "../context";
import { updateFtmOnlineStatus } from "../service";

const FtmOnlineOfflineSwitch = () => {
    const context = useContext(Context);

    return (
        <Tooltip title={context.state.ftm.ftm_is_online ? "Online" : "Offline"} arrow>
            <Switch 
                checked={context.state.ftm.ftm_is_online}
                sx={{ fontSize: 'small' }} 
                color="success"
                onClick={(event) => updateFtmOnlineStatus(context.state.ftm.ftm_id, event.target.checked, context.dispatch)} 
            />
        </Tooltip>
    );
}

export default FtmOnlineOfflineSwitch;