import { GET } from "../../API/get";
import { PUT } from "../../API/put";
import { getUserInfo } from "../../storage";

export const loadData = async (dispatch) => {
    dispatch({ type: "IS_LOADING" });
    let userInfo = getUserInfo();
    let response = await GET("admin/"+userInfo.admin_id);

    if(response.status === "Success"){
        dispatch({ 
            type: "IS_LOADED",
            details: response.admin_info,
        });
    }
}

export const resetPassword = async (data, context, reset) => {
    console.log('data :', data);

    if(data.new_password !== data.confirm_new_password){
        context.dispatch({
            type: 'IS_SHOW_ALERT',
            show_alert: {
                show: true,
                type: 'error',
                msg: 'New password and confirm password do not match'
            }
        });
    }else{
        context.dispatch({
            type: 'IS_RESETTING_PASSWORD',
            is_resetting_password: true
        });

        let userInfo = getUserInfo();
        let response = await PUT("reset/my/password", {
            admin_id: userInfo.admin_id,
            current_password: data.current_password,
            new_password: data.new_password
        });

        if(response.status === "Success"){
            reset();
            context.dispatch({
                type: 'IS_SHOW_ALERT',
                show_alert: {
                    show: true,
                    type: 'success',
                    msg: response.message
                }
            });
        }else{
            context.dispatch({
                type: 'IS_SHOW_ALERT',
                show_alert: {
                    show: true,
                    type: 'error',
                    msg: response.message
                }
            });
        }
    }
}