import React,{ useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import "./App.css";
 
import SnackbarAlert from "./components/snackbar";
import { getUserInfo } from "./storage";
import { routes } from "./routes";


function App() {
    const [snackbar, setSnackbar] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const isForgotPasswordRoute = window.location.pathname.includes("/forgot/password/");

        if (!isForgotPasswordRoute) {
            const userInfo = getUserInfo();
            if (!userInfo) {
                navigate("/auth");
            }
        }
    }, [navigate]);

    return (
            <>
                <Routes>
                    {routes.map((route, index) => (
                        <Route key={index} path={route.path} element={route.element} />
                    ))}
                </Routes>

                <Toaster 
                    position="top-right"
                    reverseOrder={false}
                />

                <SnackbarAlert
                    open = {snackbar?.is_show}
                    handleClose = {() => setSnackbar({})}
                    type={snackbar?.type}
                    msg={snackbar?.msg}
                />
            </>
    );
}

export default App;
