import React from "react";
import Card from 'react-bootstrap/Card';

const ImageGallery = (props) => {
    return(
        <Card body>
            <div className="scroll-container">
                {props.images?.map((image, imageIndex) => (
                    <img src={image.ftm_request_image_path} alt="Cinque Terre" key={imageIndex} className="mb-1"/>
                ))}
            </div>
        </Card>
    );
}

export default ImageGallery