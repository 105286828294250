import React, { useReducer, useEffect } from "react";

import Reducer from "./reducer";
import Context from "./context";
import { initialState } from "./initialState";
import Html from "./html";

const Search = ({ onSearch, width, is_filter }) => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    const sendSearchTextToParent = () => {
        onSearch(state.search_text);
    };

    useEffect(() => {
        !is_filter && sendSearchTextToParent();
    }, [state.search_text]);

    return(
        <Context.Provider value={{state: state, dispatch: dispatch}}>
            <Html 
                width = {width} 
                is_filter = {is_filter}
                onSearch = {onSearch}
            />
        </Context.Provider>
    );
}

export default Search;