import React from "react";

import CardWithHeaderTemplate from "../../../components/cardWithHeaderTemplate";
import ListHeader from "./listHeader";
import JobsListBody from "./jobsListBody";

const JobsList = () => {

    return (
        <CardWithHeaderTemplate
            header={<ListHeader/>} 
            body={<JobsListBody/>} 
            footer={null}
            extraCardClassName = {`blur shadow-blur max-height-vh-70 h-fix-100`}
            extraBodyClassName = {`overflow-auto overflow-x-hidden`}
        />
    );
};

export default JobsList;