import React, {useEffect, useReducer} from "react";

import Html from './html';
import Reducer from "./reducer";
import {loadData} from "./service";
import Context from './context';
import { initialState } from "./initialState";

const Summary = () => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        loadData(dispatch, state.current_page, state.search_value);
    }, [state.current_page, state.search_value]);

    return(
        <Context.Provider value={{state, dispatch}}>
            <Html/>
        </Context.Provider>
    );
}

export default Summary;