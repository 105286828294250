import React from "react";
import { theme } from "../theme";

const ExtraMaterialsTotal = (props) => {
    return(
        <p 
            style={{
                marginBottom: '0px'
            }}
        >
            Extra Materials Total : {theme.currency} {props.total_material_price}
        </p>
    );
}

export default ExtraMaterialsTotal