/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Template from "../../components/template";
import Avatar from "./components/avatar";
import Info from "./components/info";
import ResetPassword from "./components/resetPassword";
import { theme } from "../../theme";

const Html = () => {
    return(
        <Template>
            <section className="">
                <div className="container">
                    <div className="row removable">
                        <div className="col-md-8 mx-auto text-center">
                            <h4 className={`text-gradient text-${theme.main}`}>MY PROFILE</h4>
                        </div>
                    </div>

                    <div className="row removable rounded-lg mt-5">
                        <div className="col-xl-6 col-sm-6 mb-4">
                            <Avatar/>
                            <Info/>
                        </div>

                        {/* <div className="col-xl-6 col-sm-6 mb-4">
                            <Avatar/>
                            <Info/>
                        </div> */}

                        <ResetPassword/>
                    </div>
                </div>
            </section>
        </Template>
    );
}

export default Html;