import React, { useContext } from "react";
import { Box, InputAdornment, IconButton, TextField } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import LockResetIcon from '@mui/icons-material/LockReset';
import CircularProgress from '@mui/material/CircularProgress';
import { useForm } from "react-hook-form";

import Form from 'react-bootstrap/Form';

import Context from '../context';
import { resetPassword } from "../service";
import useCustomNavigate from '../components/navigation';
import { theme } from "../../theme";

const PasswordField = () => {
    const context = useContext(Context);
    const { navigateBack } = useCustomNavigate();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    return(
        <Box
            p={1}
        >
            <LockResetIcon 
                sx={{ mr: 1, my: 0.5, fontSize: '60px' }} 
            />

            <Form onSubmit={handleSubmit((data) => resetPassword(context.forgot_password_key, data, context.dispatch, navigateBack, reset))}>
                <TextField  
                    label="Enter your new password" 
                    variant="standard" 
                    type="password"
                    {...register("new_password", { required: true })}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton 
                                    type="submit"
                                    edge="end"
                                    color="primary"
                                    style={{ backgroundColor: "transparent" }}
                                >
                                    {!context.state.is_sending ? <ArrowForward sx={{ fontSize: '24px' }} color={theme.main}/> : 
                                    <CircularProgress size={20} color={theme.main}/> }
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Form>
        </Box>
    );
}

export default PasswordField;