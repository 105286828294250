import {useNavigate} from "react-router-dom";
import { theme } from "../../theme";
import JobTable from "./jobTable";
import CardWithHeaderTemplate from "../../components/cardWithHeaderTemplate";

const Box = (props) => {
    let navigate = useNavigate();

    return(
        <div className="col-6">
            <CardWithHeaderTemplate
                header={
                    <h6 className="text-white text-capitalize ps-3">{props.title}</h6>
                } 
                body={
                    <div className="card-body pt-4 p-3">
                        <JobTable
                            jobs = {props.jobs}
                        />
                    </div>
                } 
                footer={
                    <button 
                        className={`btn btn-sm btn-${theme.redirect_btn} float-right`}
                        onClick={() => navigate(props.link)}
                    >{props.btn_name}</button>
                }
            />
        </div>
    );
}

export default Box;