/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IconButton from '@mui/material/IconButton';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import DeleteIcon from '@mui/icons-material/Delete';

import Context from "../context";
import { deleteSkill } from "../service";
import { approveSkill } from "../service";
import { theme } from "../../../theme";
import CardWithHeaderTemplate from "../../../components/cardWithHeaderTemplate";

const Skills = () => {
    const context = useContext(Context);

    return(
        <CardWithHeaderTemplate
            header={
                <div className="row">
                    <div className="col-8 my-auto">
                        <div className="numbers">
                            <h6 className="text-white font-weight-bolder mb-0 ms-3">
                                SERVICE CATEGORIES & TYPES
                            </h6>
                        </div>
                    </div>
                    <div className="col-4 text-end">
                        <IconButton 
                            sx={{color: '#fff'}}
                            style={{marginRight: '10px'}}
                            onClick={() => context.dispatch({
                                type: "IS_SHOW_SKILL_MODAL",
                                is_show_skill_modal: true
                            })}
                            size="small"
                        >
                            <AddBoxIcon style={{fontSize: '20px'}}/>
                        </IconButton>
                    </div>
                </div>
            }
            body={
                <ul 
                    className="list-group"
                    style={{
                        height: '500px',
                        overflow: 'auto'
                    }}
                >
                    {context.state.ftm_skills.map((skill, index) => (
                        <li className="list-group-item border-0 d-flex align-items-center px-0 mb-2 pt-0" key={index}>
                            <div className="me-3"></div>
                            <div className="d-flex align-items-start flex-column justify-content-center">
                                <h6 className="mb-0 text-sm">{skill.skill_name}</h6>
                                {skill?.skill_list?.map((sub_skill, sub_index) => (
                                    <Box display="flex" justifyContent="space-between" width="100%" key={sub_index}>
                                        <Typography variant="body2" component="p" marginRight={2} sx={{width: '250px'}}>
                                            {sub_skill.sub_skill_name}
                                            {sub_skill.ftm_skill_is_approve ? (
                                                <Tooltip title="Approved">
                                                    <CheckCircleIcon fontSize="small" sx={{color: theme.action_btn_code}} />
                                                </Tooltip>
                                            ) : (
                                                null
                                            )}
                                        </Typography>
                                        
                                        <IconButton 
                                            sx={{
                                                visibility: sub_skill.ftm_skill_is_approve? 'hidden' : 'visible'
                                            }}
                                            color={theme.main} 
                                            style={{marginRight: '10px'}}
                                            onClick={() => approveSkill(context.ftm_id, sub_skill.ftm_skill_id, context.dispatch)}
                                            size="small"
                                        >
                                            <ThumbUpIcon style={{fontSize: '20px'}}/>
                                        </IconButton>

                                        <IconButton 
                                            color={theme.action_btn_code} 
                                            style={{marginRight: '10px'}}
                                            onClick={() => deleteSkill(context.ftm_id, sub_skill.sub_skill_id, context.dispatch)}
                                            size="small"
                                        >
                                            <DeleteIcon style={{fontSize: '20px'}}/>
                                        </IconButton>
                        
                                    </Box>
                                ))}
                            </div>
                            
                            <hr/>
                        </li>
                    ))}
                </ul>
            }
            footer={null}
            extraCardClassName={`blur shadow-blur h-100`}
            extraBodyClassName={`overflow-auto overflow-x-hidden`}
        />
    );
}

export default Skills