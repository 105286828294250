import * as React from 'react';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReceiptIcon from '@mui/icons-material/Receipt';

import Context from "../context";
import { getJobInvoiceInfo } from "../service";

export default function ActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const context = React.useContext(Context);

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Action">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <MoreVertIcon sx={{ width: 32, height: 32 }}/>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {/* <Divider /> */}
                <MenuItem 
                    onClick={() => {
                        handleClose();
                        context.dispatch({
                            type: 'IS_SHOW_INVOICE_MODAL', 
                            is_show_invoice_modal: true
                        });
                        getJobInvoiceInfo(props.job_id, "with_commissions", context.dispatch);
                    }}
                >
                    <ListItemIcon>
                        <ReceiptIcon fontSize="small" />
                    </ListItemIcon>
                    Invoice with commissions
                </MenuItem>
                <MenuItem 
                    onClick={() => {
                        handleClose();
                        context.dispatch({
                            type: 'IS_SHOW_INVOICE_MODAL', 
                            is_show_invoice_modal: true
                        });
                        getJobInvoiceInfo(props.job_id, "without_commissions", context.dispatch);
                    }}
                >
                    <ListItemIcon>
                        <ReceiptIcon fontSize="small" />
                    </ListItemIcon>
                    Invoice without commissions
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}