export default function Reducer(prevState, action) {
    switch (action.type) {
        case 'IS_LOADING':
            return {
                ...prevState,
                is_loading: true,
            };
        case 'SET_DETAILS':
            return {
                ...prevState,
                is_loading: false,
                details: action.details
            };
        default:
            throw new Error();
    }
}