import React, { useContext } from "react";

import Context from "../context";
import { getStatusColor, getStatusText} from "../service";
import ActionMenu from "./actionMenu";
import DivSpinner from "../../../components/divSpinner";

const Table = () => {
    const context = useContext(Context);

    return(
        <>
            {context.state.is_loading ? (
                <div style={{height : `${window.innerHeight-387}px`}}>
                    <DivSpinner/>
                </div>
            ) : (
                <div className="table-responsive p-0">
                    <table className="table align-items-center mb-0">
                        <thead>
                            <tr>
                                {[
                                    'FTM', 
                                    <span>Contact<br/>Number</span>,
                                    'Email', 
                                    'GPS device', 
                                    'Completed',
                                    'Availability', 
                                    'Status'
                                ].map((text, index) => (
                                    <th 
                                        key={index}
                                        className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                                    >
                                        {text}
                                    </th>
                                ))}
                                <th className="text-secondary opacity-7"></th>
                            </tr>
                        </thead>
                        <tbody>
                        {context.state.list?.map((ftm, index) => (
                            <tr key={index}>
                                <td>
                                    <div className="d-flex px-2 py-1">
                                        <div>
                                            <img src={ftm.ftm_profile_image_path || "/assets/img/lock_user.png"} className="avatar avatar-sm me-3 border-radius-lg" alt="user1"/>
                                        </div>
                                        <div className="d-flex flex-column justify-content-center">
                                            <h6 className="mb-0 text-sm">{ftm.ftm_forename} {ftm.ftm_surname}</h6>
                                            <p className="text-xs text-secondary mb-0">Created {ftm.ftm_createdAt}</p>
                                        </div>
                                    </div>
                                </td>
                                <td className="align-middle">
                                    <span className="text-secondary text-sm font-weight-bold">{ftm.ftm_contact_number}</span>
                                </td>
                                <td className="align-middle">
                                    <span className="text-secondary text-sm font-weight-bold">{ftm.ftm_email}</span>
                                </td>
                                <td className="align-middle">
                                    <span className="text-secondary text-sm font-weight-bold">{ftm.ftm_device_id}</span>
                                </td>
                                <td className="align-middle">
                                    <span 
                                        className={`badge badge-sm bg-gradient-secondary`}
                                    >
                                        {ftm.ftm_total_completed_jobs}
                                    </span>
                                </td>
                                <td className="align-middle text-center text-sm">
                                    <span className={`badge badge-sm bg-gradient-${ftm.ftm_is_online === 1 ? 'success' : 'secondary'}`}>
                                        {ftm.ftm_is_online === 1 ? 'Online' : 'Offline'}
                                    </span>
                                </td>
                                <td className="align-middle text-sm">
                                    <span 
                                        className={`badge badge-sm bg-gradient-${getStatusColor(ftm.ftm_is_verify)}`}
                                    >
                                        {getStatusText(ftm.ftm_is_verify)}
                                    </span>
                                </td>
                                <td className="align-middle">
                                    <ActionMenu
                                        ftm_id = {ftm.ftm_id}
                                    />
                                </td> 
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
}

export default Table;