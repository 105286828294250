import React, { createContext, useEffect, useReducer } from "react";
import { initialState } from "./initialState";
import { useParams } from "react-router-dom";
import Swal from 'sweetalert2';

import Reducer from "./reducer";
import { fetchAllJobsStatusLogs, fetchData, fetchJobsStatusLogs } from "./service";

const Context = createContext();

const Provider = ({ children }) => {
    let { ftm_id } = useParams();
    const [state, dispatch] = useReducer(Reducer, initialState);

    useEffect(() => {
        const getData = async () => {
            try {
                dispatch({ type: "IS_LOADING" });
                let response = await fetchData(ftm_id);

                dispatch({
                    type: "IS_LOADED",
                    details: {
                        ftm: response.details.ftm,
                        job_statuses: response.details.job_statuses,
                    },
                    selected_job: response.details.jobs_details,
                });
            } catch (error) {
                console.log(error);
            }
        };

        getData();
    }, []);

    const getAllJobsStatusLogs = async () => {
        try {
            dispatch({
                type: "IS_LOADING_JOBS",
                is_loading_job_list: true
            });

            let response = await fetchAllJobsStatusLogs({
                ftm_id: ftm_id,
                page_number: state.jobs_current_page,
                serach_value: state.jobs_list_serach_value
            });

            dispatch({
                type: "SET_JOBS_LIST",
                job_list: response.details
            });

        } catch (error) {
            console.log(error);
        }
    }

    const getJobsStatusLogs = async (job_id) => {
        Swal.fire({
            title: 'Loading...',
            html: 'Please wait while we fetch the data.',
            timerProgressBar: true,
            didOpen: async () => {
                Swal.showLoading();
                let response = await fetchJobsStatusLogs(job_id);

                dispatch({
                    type: 'SET_SELECTED_JOB',
                    selected_job: response.details
                });

                Swal.close();
            }
        });
    }

    return (
        <Context.Provider value={{ state, dispatch, getAllJobsStatusLogs, getJobsStatusLogs }}>
            {children}
        </Context.Provider>
    );
}

export { Context, Provider };