import Dashboard from "./dashboard";

import FtmList from "./ftm/list";
import FtmView from "./ftm/view";
import FtmPendingApplication from "./ftm/pendingApplication";
import AllLogs from "./ftm/logs/all";
import FTMRejectedJobs from "./ftm/rejectedJobs";
import TradespersonHolidays from "./ftm/holidays";

import AllJobs from "./jobs/all";
import OngoingJobs from "./jobs/ongoing";
import JobView from "./jobs/view";
import CompliedJobs from "./jobs/completed";
import NotAttended from "./jobs/notAttended";
import DisputesList from "./jobs/disputes/list";
import Chat from "./jobs/disputes/chat";
import RemedialJobs from "./jobs/remedial";

import AllPayments from "./payments/all";
import FtmPayments from "./payments/ftm";
import Skills from "./serviceCategory/skills";
import SearchResults from "./jobs/searchResults";
import Lockscreen from "./lockscreen";
import CancelJobs from "./jobs/cancelJobs/all";
import FtmCancelJobs from "./jobs/cancelJobs/ftm";
import Summary from "./payments/summary";
import Profile from "./user/profile";
import UsersList from "./user/list";
import ForgotPassword from "./forgotPassword";
import FtmPaymentsHistories from "./payments/ftmPaymentsHistories";
import Notifications from "./notifications";
import FtmRevenue from "./ftm/revenue";

import AllCustomers from "./customers/all";
import CustomerView from "./customers/view";
import Auth from "./auth";
import FtmHistory from "./ftm/history";
import DisputesInbox from "./jobs/disputes/inbox";
import RunningJobsList from "./jobs/runningJobs/list";
import OfficeMessagesInbox from "./messages/office";
import JobsMessagesInbox from "./messages/jobs";
import DisputeMessagesInbox from "./messages/disputes";

export const routes = [
    { path: "/", element: <Auth />, page_name: "Auth" },
    { path: "/auth", element: <Auth />, page_name: "Auth" },
    { path: "/dashboard", element: <Dashboard />, page_name: "Dashboard" },
    { path: "/ftm/list", element: <FtmList />, page_name: "Tradesperson List" },
    { path: "/ftm/view/:ftm_id", element: <FtmView />, page_name: "Tradesperson" },
    { path: "/ftm/pending/application", element: <FtmPendingApplication />, page_name: "Pending Application" },
    { path: "/ftm/all/logs", element: <AllLogs />, page_name: "All Logs" },
    { path: "/ftm/rejected/jobs", element: <FTMRejectedJobs />, page_name: "Rejected Jobs" },
    { path: "/ftm/holidays", element: <TradespersonHolidays />, page_name: "Tradesperson Holidays" },
    { path: "/customers/all", element: <AllCustomers />, page_name: "All Customers" },
    { path: "/customers/view/:customer_id", element: <CustomerView />, page_name: "Customer" },
    { path: "/jobs/all", element: <AllJobs />, page_name: "All Jobs" },
    { path: "/jobs/ongoing", element: <OngoingJobs />, page_name: "Ongoing Jobs" },
    { path: "/jobs/complied/:nav_type", element: <CompliedJobs />, page_name: "Complied Jobs" },
    { path: "/jobs/not/attended", element: <NotAttended />, page_name: "Not Attended" },
    { path: "/jobs/view/:job_id", element: <JobView />, page_name: "Job View" },
    { path: "/jobs/disputes/list", element: <DisputesList />, page_name: "Disputes List" },
    { path: "/jobs/disputes/inbox", element: <DisputesInbox />, page_name: "Disputes Inbox" },
    { path: "/jobs/disputes/chat", element: <Chat />, page_name: "Chat" },
    { path: "/jobs/cancel", element: <CancelJobs />, page_name: "Cancel Jobs" },
    { path: "/ftm/jobs/cancel/:ftm_id", element: <FtmCancelJobs />, page_name: "Tradesperson Cancel Jobs" },
    { path: "/all/payments", element: <AllPayments />, page_name: "All Payments" },
    { path: "/ftm/payments/:ftm_id", element: <FtmPayments />, page_name: "Tradesperson Payments" },
    { path: "/ftm/payments/histories/:ftm_id", element: <FtmPaymentsHistories />, page_name: "Tradesperson Payments Histories" },
    { path: "/ftm/revenue", element: <FtmRevenue />, page_name: "Tradesperson Revenue" },
    { path: "/payments/summary", element: <Summary />, page_name: "Summary" },
    { path: "/skills", element: <Skills />, page_name: "Service Categories" },
    { path: "/job/search/results", element: <SearchResults />, page_name: "Search Results" },
    { path: "/lockscreen", element: <Lockscreen />, page_name: "Lockscreen" },
    { path: "/profile", element: <Profile />, page_name: "Profile" },
    { path: "/users/list", element: <UsersList />, page_name: "Users List" },
    { path: "/notifications", element: <Notifications />, page_name: "Notifications" },
    { path: "/forgot/password/:forgot_password_key", element: <ForgotPassword />, page_name: "Forgot Password" },
    { path: "/ftm/history/:ftm_id", element: <FtmHistory />, page_name: "Tradesperson History" },
    { path: "/jobs/remedial", element: <RemedialJobs />, page_name: "Remedail Jobs" },
    { path: "/running/jobs/schedule", element: <RunningJobsList />, page_name: "Schedule Jobs" },
    { path: "/messages/office/inbox", element: <OfficeMessagesInbox />, page_name: "Office Messages" },
    { path: "/messages/jobs/inbox", element: <JobsMessagesInbox />, page_name: "Jobs Messages" },
    { path: "/messages/disputes/inbox", element: <DisputeMessagesInbox />, page_name: "Disputes Messages" },
];
