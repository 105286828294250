import React, { useContext } from "react";
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { Context } from "../provider";

const BusinessDetails = ({ register, reset, errors, control }) => {
    const context = useContext(Context);

    return (
        <div className="row">
            <div className="col-4">
                    <FormControl error={!!errors.ftm_business_utr_number} fullWidth>
                        <label><b>Business UTR number</b></label>
                        <Controller
                            name="ftm_business_utr_number"
                            control={control}
                            defaultValue={context.state.ftm?.ftm_business_utr_number || ''}
                            // {...register("ftm_business_utr_number", { required: false })}
                            render={({ field }) => (
                                <InputMask
                                    {...field}
                                    mask="9999999999"
                                    maskChar=""
                                >
                                    {(inputProps) => (
                                        <TextField
                                            {...inputProps}
                                            placeholder="Business UTR number"
                                            size="small"
                                            type="text"
                                            error={!!errors.ftm_business_utr_number}
                                        />
                                    )}
                                </InputMask>
                            )}
                        />
                        {errors.ftm_business_utr_number && <FormHelperText>{errors.ftm_business_utr_number.message}</FormHelperText>}
                    </FormControl>
                </div>
            <div className="col-4">
                <FormControl error={!!errors.ftm_name_of_business} fullWidth>
                    <label><b>Business name</b></label>
                    <TextField
                        error={!!errors.ftm_name_of_business}
                        placeholder="Business name"
                        size="small"
                        type="text"
                        defaultValue={context.state.ftm?.ftm_name_of_business || ''} 
                        {...register("ftm_name_of_business", { required: false })}
                    />
                    {errors.ftm_name_of_business && <FormHelperText>Business name is required</FormHelperText>}
                </FormControl>
            </div>
            <div className="col-4">
                <FormControl error={!!errors.ftm_company_reg_number} fullWidth>
                    <label><b>Company registration number</b></label>
                    <TextField
                        error={!!errors.ftm_company_reg_number}
                        placeholder="Ex: AB123456"
                        size="small"
                        type="text"
                        defaultValue={context.state.ftm?.ftm_company_reg_number || ''} 
                        {...register("ftm_company_reg_number", { required: false })}
                    />
                    {errors.ftm_company_reg_number && <FormHelperText>Company registration must be in the format AB123456</FormHelperText>}
                </FormControl>
            </div>
        </div>
    );
}

export default BusinessDetails;