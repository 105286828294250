export default function reducer(prevState, action){
    switch (action.type) {
        case "SET_SEARCH_TEXT":
            return {
                ...prevState,
                search_text: action.search_text
            };
        default:
            throw new Error();
    }
}