import Swal from 'sweetalert2';
import { theme } from '../theme';

export const errorAlert = (message) => {
    Swal.fire({
        title: 'ERROR',
        text: message.includes("Cannot read properties of undefined") ? "NOT CONNECTION": message,
        icon: message.includes("Cannot read properties of undefined") ? 'warning' : 'error',
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonColor: theme.close_btn_code,
    });

    return {
        status: "Failed",
        message: message
    }
}

export const badRequestError = (error) => {
    if (error.response) { 
        // status code out of the range of 2xx
        console.log("Data :" , error.response.data);
        console.log("Status :" + error.response.status);
        return errorAlert(error?.response?.data?.message);
    } else if (error.request) { 
        // The request was made but no response was received
        console.log(error.request);
        return errorAlert(error?.request);
    } else {
        // Error on setting up the request
        console.log('Error 123', error.message);
        return errorAlert(error?.message);
    }
}