import React from "react";
import Html from "./html";
import { Provider } from "./provider";

const History = () => {
    return(
        <Provider>
            <Html/>
        </Provider>
    )
}

export default History;