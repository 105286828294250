import React,{useContext,useEffect} from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';

import Context from "../../context";
import BoxView from "./box";
import { getRejectedJobs } from "../../service";
import { Pagination, Stack } from "@mui/material";
import DivSpinner from "../../../../components/divSpinner";
import Search from "../../../../components/searchField";
import { theme } from "../../../../theme";
import OffcanvasTemplate from "../../../../components/offcanvasTemplate";

const JobList = () => {
    const context = useContext(Context);
    
    useEffect(() => {
        getRejectedJobs(context.state.job_list_current_page, context.state.selected_ftm.ftm_id, context.state.job_list_search_value, context.dispatch);
    }, [context.state.selected_ftm.ftm_id, context.state.job_list_current_page, context.state.job_list_search_value]);

    return(
        <OffcanvasTemplate
            open={context.state.is_show_jobs} 
            handleClose = {() => context.dispatch({
                type: 'CLOSE_SHOW_JOBS',
                is_show_jobs: false
            })} 
            placement = {'end'}  
            title = {`JOBS`}
            body = {!context.state.is_job_list_loading ? (
                <>
                    {context.state.job_list?.map((details, index) => (
                        <React.Fragment key={index}>
                            <BoxView
                                details={details}
                            />
                            <hr/>
                        </React.Fragment>
                    ))}
                </>
            ) : <DivSpinner/> } 
            footer = {
                <div className="d-grid gap-2 m-3" style={{marginTop: '20%'}}>
                    <Stack 
                        spacing={2} 
                        className="mb-3" 
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center'
                        }}      
                    >
                        <Pagination
                            count={context.state.job_list_total_pages} 
                            page={context.state.job_list_current_page} 
                            // onChange={(event, newPage) => getRejectedJobs(newPage, context.state.selected_ftm.ftm_id,context.dispatch)}
                            onChange={(event, newPage) => {
                                context.dispatch({ type: "UPDATE_JOB_LIST_CURRENT_PAGE", job_list_current_page: newPage });
                            }}
                            color={theme.main}
                        />
                    </Stack>
                </div>
            }
            search = {
                <Search
                    onSearch = {(search_text) => {
                        console.log("🚀 ~ JobList ~ search_text:", search_text)
                        context.dispatch({ type: "SET_JOB_LIST_SEARCH_VALUE", job_list_search_value: search_text });
                    }}
                    width= {'150px'}
                />
            }
        />
    );
}

export default JobList