import { GET } from "../../API/get";

export const loadData = async (page_number, dispatch) => {
    dispatch({ type: "IS_LOADING" });
    
    let response = await GET(`/ftms/revenue/${page_number}`);

    if(response.status === "Success"){
        dispatch({ 
            type: "IS_LOADED",
            total_ftms: response.total_ftms,
            total_pages: response.total_pages,
            list: response.list,
            page_number: page_number
        });
    }
}