import React, { useContext } from 'react';
import TextField from '@mui/material/TextField';
import { Container } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import BtnSpinner from '../../../../components/btnSpinner';

import ModalTemplateWithForm from '../../../../components/modal/modalTemplateWithForm';
import Context from "../context";
import { theme } from "../../../../theme";
import { editNote } from "../service";

const EditNote = () => {
    const context = useContext(Context);

    const {
        register,
        handleSubmit,
        reset,
    } = useForm();

    return (
        <ModalTemplateWithForm
            show={context.state.show_edit_note} 
            closeFun = {() => context.dispatch({type: 'SHOW_EDIT_NOTE', show_edit_note: false, ftm_note_id: null, ftm_id: null, ftm_note: ""})}
            size = {null}
            centered = {false}
            title = {`Edit note`}
            body = {
                <Container>
                    <TextField
                        defaultValue={context.state.ftm_note}
                        placeholder="Enter your note"
                        fullWidth
                        size="small"
                        name="ftm_note"
                        multiline
                        rows={4}
                        InputProps={{
                            style: { backgroundColor: '#fff' },
                        }}
                        onChange={(e) => context.dispatch({type: 'SET_EDIT_NOTE', ftm_note: e.target.value})}
                    />
                </Container>
            }
            footer = {
                <>
                    <Button
                        variant={theme.close_btn}
                        onClick={() => context.dispatch({type: 'SHOW_EDIT_NOTE', show_edit_note: false, ftm_note_id: null, ftm_id: null, ftm_note: ""})}
                        size="sm"
                        disabled={context.state.btn_loading}
                    >
                        {context.state.btn_loading ? <BtnSpinner /> : 'CLOSE'}
                    </Button>
                    <Button
                        variant={theme.action_btn}
                        type="submit"
                        size="sm"
                        disabled={context.state.btn_loading || !context.state.ftm_note}
                    >
                        {context.state.btn_loading ? <BtnSpinner /> : 'SAVE'}
                    </Button>
                </>
            }
            onSubmit = {(event) => editNote(event, context.state.ftm_note_id, context.state.ftm_id, context.dispatch, reset)}
        />
    );
}

export default EditNote;