import CircularProgress from '@mui/material/CircularProgress';

const PleaseWait = () => {
    return (
        <div className="d-block p-2">
            <div className="d-flex p-2">
                <CircularProgress size={15} sx={{marginTop: '5px'}} color="inherit"/>
                <div className="ms-3">
                    <h6 className="mb-0" >
                        Please wait...
                    </h6>
                </div>
            </div>
        </div>
    );
}

export default PleaseWait;