import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { Badge } from "@mui/material";

import useCustomNavigate from '../../hooks/useCustomNavigate';
import { theme } from '../../../theme';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function DialogBox({ onClose, selectedOption, open }) {
    const { navigateToCustom } = useCustomNavigate();

    const handleClose = () => onClose(selectedOption);

    const handleListItemClick = (value) => {
        navigateToCustom(value.to);
        onClose(value);
    };

    const listItem = (item) => {
        const primaryText = (item.name === "Applications" || item.name === "Cancelled Jobs") ? (
            <Badge
                badgeContent={item.name === "Applications" ? selectedOption?.ftmCount : selectedOption?.jobCount}
                color={theme.sidebar_notification_count_color}
            >
                <ListItemText primary={item.name} />
            </Badge>
        ) : (
            <ListItemText primary={item.name} />
        );

        return (
            <ListItemButton onClick={() => handleListItemClick(item)} key={item.name}>
                <ListItemAvatar>
                    <Avatar sx={{ bgcolor: '#2E2E33' }}>
                        <div className="text-white text-center d-flex align-items-center justify-content-center">
                            <i className="material-icons opacity-10">{item.icon}</i>
                        </div>
                    </Avatar>
                </ListItemAvatar>
                {primaryText}
            </ListItemButton>
        );
    };

    return (
        <Dialog
            onClose={handleClose}
            TransitionComponent={Transition}
            open={open}
            fullWidth={true}
            maxWidth={"xs"}
        >
            <DialogTitle>{selectedOption?.heder}</DialogTitle>
            <List sx={{ pt: 0 }}>
                {selectedOption?.list?.map((item) => (
                    <ListItem disableGutters fullWidth key={item.name}>
                        {listItem(item)}
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}

DialogBox.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedOption: PropTypes.object.isRequired, // Change the type accordingly
};

export default function SubMenu({ open, setOpen, selectedOption, setSelectedOption }) {
    return (
        <DialogBox
            selectedOption={selectedOption}
            open={open}
            onClose={(value) => {
                setOpen(false);
                setSelectedOption(value);
            }}
        />
    );
}
