import React, {useState, useEffect} from "react";

import Sidebar from "./sidebar";
import Navbar from "./navbar";
import { getIsShowFullSidebar, setIsShowFullSidebar } from "../storage";
// import useCurrentLocation f///////rom "./hooks/useCurrentLocation";
import useWindowDimensions from "./hooks/useWindowDimensions";

const Template = ({children, footer}) => {
    let is_full_sidebar = getIsShowFullSidebar();
    const { width } = useWindowDimensions();

    // const { pathname } = useCurrentLocation();
    const [isFullSidebar, setIsFullSidebar] = useState(is_full_sidebar);

    useEffect(() => {
        setIsFullSidebar(width < 1320 ? false : is_full_sidebar);
    },[is_full_sidebar, width]);

    const showFullSidebar = () => {
        setIsShowFullSidebar(!isFullSidebar);
        setIsFullSidebar(!isFullSidebar);
    }

    return(
        <>
            <Sidebar
                isFullSidebar = {isFullSidebar}
                setIsFullSidebar = {showFullSidebar}
            />
            <main 
                className={`${isFullSidebar ? 'main-content' : ''} position-relative max-height-vh-100 h-100 border-radius-lg`} 
                style={{marginLeft: !isFullSidebar ? '6rem' : ''}}
            >
                <Navbar
                    isFullSidebar = {isFullSidebar}
                    setIsFullSidebar = {showFullSidebar}
                />
                <div className="container-fluid py-4">
                    {children}
                    {footer && (
                        <footer className="footer py-4 fixed-bottom">
                            <div className="container-fluid">
                                <div className="row align-items-center justify-content-lg-between">
                                    {footer}
                                </div>
                            </div>
                        </footer>
                    )}
                </div>
            </main>
        </>
    );
}

export default Template;