import React, { useContext } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../provider";
import { theme } from "../../theme";
import ModalTemplate from "../../components/modal/modalTemplate";

const RunningJobModal = () => {
    const context = useContext(Context);
    let navigate = useNavigate();

    return (
        <ModalTemplate
            show = {context.state.is_open_running_jobs_details_modal}
            closeFun = {context.handleCloseRunningJobsDetailsModal}
            size = {null}
            centered = {true}
            title = {
                <>
                    <Link to={`/jobs/view/${context.state.selected_running_job?.job_id}`} style={{color: theme.main}}> #{context.state.selected_running_job?.job_id}</Link> Details
                </>
            }
            body = {
                <>
                    <p className="text-sm">{context.state.selected_running_job?.job_description}</p>
                    <hr className="horizontal gray-light my-4"/>
                    <ul className="list-group">
                        {[
                            {
                                title: 'Type',
                                value: `${context.state.selected_running_job?.sub_skill?.skill?.skill_name} - ${context.state.selected_running_job?.sub_skill?.sub_skill_name}`
                            },
                            {
                                title: 'Address',
                                value: `${context.state.selected_running_job?.job_address}`
                            },
                            {
                                title: 'Created',
                                value: `${context.state.selected_running_job?.job_createdAt}`
                            },
                            {
                                title: 'Tradesperson',
                                value: `${context.state.selected_running_job?.ftm?.ftm_forename} ${context.state.selected_running_job?.ftm?.ftm_surname}`,
                                ftm_id: context.state.selected_running_job?.ftm?.ftm_id
                            }
                        ].map((item, index) => (
                            <li className="running-job-modal-list list-group-item border-0 ps-0 pt-0 text-sm" key={index}>
                                <strong className="text-dark">{item.title} :</strong> 
                                &nbsp; {item.value}
                                
                                {item.title === 'Tradesperson' && (
                                    <>
                                        &nbsp;  <VisibilityIcon 
                                                    style={{cursor: 'pointer'}}
                                                    onClick={() => navigate(`/ftm/view/${item?.ftm_id}`)} 
                                                />
                                    </>
                                )}
                            </li>
                        ))}
                    </ul>
                </>
            }
            footer = {null}
        />
    );
}

export default RunningJobModal;