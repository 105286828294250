import api from "./api";
import {errorAlert, badRequestError} from "./errorHandling";

export const DELETE = async (URL) => {
    try{
        let response = await api.delete(URL);
        if(response.data.status === "Success"){
            return response.data
        }else{
            return errorAlert(response?.data?.message);
        }
    }catch(error){
        console.log('error :', error);
        return badRequestError(error);
    }
}