import React, { useContext } from "react";
import { Alert, Stack } from "@mui/material";

import Context from "../context";

const Notification = () => {
    const context = useContext(Context);

    const getAlert = (option) => {
        return(
            <Stack sx={{ width: '100%' }} spacing={2} className=" mb-3">
                <Alert variant="filled" severity={option.alert_type}>
                    {option.msg}
                </Alert>
            </Stack>
        );
    }

    return(
        <React.Fragment>
            {context.state?.ftm?.ftm_is_verify != 1 && (
                <React.Fragment>
                    {context.state?.ftm?.ftm_is_new == 1 ? (
                        getAlert({
                            alert_type: context.state?.ftm?.ftm_is_verify == -3 ? "info" : "warning", 
                            msg: context.state?.ftm?.ftm_is_verify == -2 ? "The new tradesperson has not yet completed the required compliance process." : context.state?.ftm?.ftm_is_verify == -3 ? "The tracker has been successfully dispatched." : "A new tradesperson application requires your review. Please take a moment to examine their associated information, documents, and certificates to determine their approval." 
                        })
                    ) : (
                        <React.Fragment>
                            {context.state?.ftm?.ftm_is_new == -1 && (
                                <React.Fragment>
                                    <React.Fragment>
                                        {context.state?.ftm?.ftm_verify_skill == 0 ? (
                                            getAlert({
                                                alert_type: "warning", 
                                                msg: "The Tradesperson has updated their skills. Please review their changes and consider any necessary actions."
                                            })
                                        ) : null}
                                    </React.Fragment>
                                    <React.Fragment>    
                                        {(context.state?.ftm?.ftm_verify_details == 0 && context.state?.ftm?.ftm_verify_documents == 0) ? (
                                            getAlert({
                                                alert_type: "warning", 
                                                msg: "The Tradesperson has expired documents and has also updated their details. Please review the changes and address the expired documents promptly."
                                            })
                                        ) : 
                                            <React.Fragment>
                                                <React.Fragment>    
                                                    {context.state?.ftm?.ftm_verify_documents == 0 ? (
                                                        getAlert({
                                                            alert_type: "warning", 
                                                            msg: "A Tradesperson has documents that have expired. Please take prompt action to address this issue."
                                                        })
                                                    ) : (
                                                        <React.Fragment>    
                                                            {context.state?.ftm?.ftm_verify_details == 0 ? (
                                                                getAlert({
                                                                    alert_type: "warning", 
                                                                    msg: "A Tradesperson has updated their details. Please review the changes for accuracy and completeness."
                                                                })
                                                            ) : (
                                                                getAlert({
                                                                    alert_type: "warning", 
                                                                    msg: context.state?.ftm?.ftm_reject_reason != "" || context.state?.ftm?.ftm_reject_reason != null ? context.state?.ftm?.ftm_reject_reason : "Waiting for the approval ! Please approve the updated FTM's documents"
                                                                })
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}         
        </React.Fragment>
    );
}

export default Notification;