import React, { useEffect, useReducer } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';

import Html from "./html";
import { loadMessages } from "./service";
import Reducer from "./reducer";
import Context from "./context";
import { initialState } from "./initialState";
import FormSubmit from "./components/formSubmit";
import ChatClosedWithRefundBtn from "./components/chatClosedWithRefundBtn";
import CardWithHeaderTemplate from "../../../components/cardWithHeaderTemplate";
import OffcanvasTemplate from "../../../components/offcanvasTemplate";

const Chat = ({
    show,
    dispute,
    handleClose,
    is_inbox,
    header, 
    emptyChat,
    is_have_selected_dispute
}) => {
    const [state, dispatch] = useReducer(Reducer, initialState);

    useEffect(() => {
        // loadMessages(dispute, dispatch);
        // state.dispute_is_resolved = false;
        let unsubscribe;
        const fetchData = async () => {
            if (dispute) {
                unsubscribe = await loadMessages(dispute, dispatch);
            }
        };
        
        fetchData();

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [dispute]);

    // return (
    //     <Context.Provider value={{ dispute, state, dispatch }}>
    //         {isInbox ? (
    //             <>
    //                 <Html />
    //                 {(dispute.dispute_is_resolved || state.dispute_is_resolved) ? (
    //                     <ChatClosedWithRefundBtn />
    //                 ) : (
    //                     <FormSubmit isInbox = {isInbox}/>
    //                 )}
    //             </>
    //         ) : (
    //             <Offcanvas show={show} onHide={() => handleClose()} placement={'end'}>
    //                 <Offcanvas.Header closeButton>
    //                     <Offcanvas.Title>DISPUTE : {dispute?.dispute_details?.type}</Offcanvas.Title>
    //                     <ResolveDispute />
    //                 </Offcanvas.Header>
    //                 <Offcanvas.Body>
    //                     <Html />
    //                 </Offcanvas.Body>
    //                 {(dispute?.dispute_is_resolved || state.dispute_is_resolved) ? (
    //                     <ChatClosedWithRefundBtn />
    //                 ) : (
    //                     <FormSubmit isInbox = {isInbox}/>
    //                 )}
    //             </Offcanvas>
    //         )}
    //     </Context.Provider>
    // )

    if(is_inbox){
        return (
            <Context.Provider value={{dispute, state, dispatch}}>
                <CardWithHeaderTemplate
                    header={header} 
                    body={is_have_selected_dispute ? <Html/> : emptyChat} 
                    footer= {
                        is_have_selected_dispute && (
                            <div className="card-footer d-block">
                                {/* <FormSubmit/> */}
                                {(dispute.dispute_is_resolved || state.dispute_is_resolved) ? (
                                    <ChatClosedWithRefundBtn />
                                ) : (
                                    <FormSubmit/>
                                )}
                            </div>
                        )
                    }
                    extraCardClassName = {`blur shadow-blur max-height-vh-70 h-fix-100`}
                    extraBodyClassName = {`overflow-auto overflow-x-hidden`}
                />
            </Context.Provider>
        );
    }else{
        return(
            <Context.Provider value={{dispute, state, dispatch}}>
                <OffcanvasTemplate
                    open={show} 
                    handleClose = {() => handleClose()} 
                    placement = {'end'}  
                    title = {`DISPUTE : ${dispute?.dispute_details?.type}`}
                    body = {<Html/>} 
                    footer = {
                        <div className="container">
                            <div className="row" style={{marginTop: '10px'}}>
                                <div className="col">
                                    <div className="d-grid gap-2 mb-3" >
                                        {(dispute?.dispute_is_resolved || state.dispute_is_resolved) ? (
                                            <ChatClosedWithRefundBtn />
                                        ) : (
                                            <FormSubmit/>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                />
            </Context.Provider>
        )
    }
}

export default Chat;