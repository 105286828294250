import React, { useContext, useMemo } from "react";
import Template from "../../components/template";
import { Ftm } from "./components/ftm";
import { JobStatuses } from "./components/jobStatuses";
import { JobsList } from "./components/jobsList";
import { Map } from "./components/map";
import { JobDetails } from "./components/jobDetails";
import { Context } from "./provider";
import Loader from "../../components/loading";

const Html = () => {
    const context = useContext(Context);
    const jobsList = useMemo(() => <JobsList/>, [context.state.jobs_current_page]);

    return (
        <>
            {!context.state.is_loading ? (
                <Template>
                    <div className="container-fluid my-3 py-3">
                        <div className="row mb-5">
                            <div className="col-lg-3">
                                <div className="card card-plain h-100">
                                    {jobsList}
                                    {context.state.selected_job != null && <JobDetails/>}
                                </div>
                            </div>
                            <div className={`col-lg-6 mt-lg-0 mt-4`}>
                                <div className="card card-plain h-100">
                                    <Ftm />
                                    <Map />
                                </div>
                            </div>
                            <div className={`col-lg-3 col-12 mx-auto position-relative`}>
                                <div className="card card-plain h-100">
                                    <JobStatuses />
                                </div>
                            </div>
                        </div>
                    </div>
                </Template>
            ) : <Loader/>}
        </>
    )
}

export default Html;