export default function Reducer(prevState, action){
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true,
            };
        case "IS_LOADED": 
            return {
                ...prevState,
                total_users: action.total_users,
                total_pages: action.total_pages,
                page_number: action.page_number,
                list: action.list,
                is_loading: false,
                is_creating_new_user: false,
            }; 
        case "IS_CREATING_NEW_USER":
            return {
                ...prevState,
                is_creating_new_user: action.is_creating_new_user,
            };
        case "UPDATE_CURRENT_PAGE":
            return {
                ...prevState,
                page_number: action.page_number,
            };
        default:
            throw new Error();
    }
}