import React, { useContext } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useNavigate} from "react-router-dom";

import DivSpinner from "../../../components/divSpinner";
import { truncateText } from "../../../utils";
import { Context } from "../provider";

const Table = () => {
    const context = useContext(Context);
    let navigate = useNavigate();

    return(
        <>
            {context.state.is_loading ? (
                <div style={{height : `${window.innerHeight-387}px`}}>
                    <DivSpinner/>
                </div>
            ) : (
                <div className="table-responsive p-0 m-3">
                    <table className="table align-items-center mb-0">
                        <thead>
                            <tr>
                                {[
                                    '#', 
                                    'Tradespersons', 
                                    'Customer', 
                                    'Description', 
                                    'Address',
                                    'Postcode',
                                    'Service Categories',
                                    'Service Types',
                                ].map((text, index) => (
                                    <th 
                                        key={index}
                                        className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                                    >
                                        {text}
                                    </th>
                                ))}
                                <th className="text-secondary opacity-7"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {context.state.jobs_list.length > 0 ? (
                                <>
                                    {context.state.jobs_list.map((job, index) => (
                                        <tr key={index}>
                                            <td className="align-middle">
                                                <span className="text-secondary text-sm font-weight-bold">{job?.job_id}</span>
                                            </td>
                                            <td>
                                                <div className="d-flex px-2 py-1">
                                                    <div className="d-flex flex-column justify-content-center">
                                                        <h6 className="mb-0 text-sm">{job?.ftm?.ftm_forename} {job?.ftm?.ftm_surname}</h6>
                                                        <p className="text-xs text-secondary mb-0">Created {job?.job_createdAt}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            {[
                                                job?.customer?.customer_full_name,
                                                truncateText(job?.job_description, 20),
                                                job?.job_address === null ? 'N/A' : truncateText(job?.job_address, 20),
                                                job?.job_postcode === null ? 'N/A' : job?.job_postcode,
                                                job?.sub_skill?.skill?.skill_name,
                                                job?.sub_skill?.sub_skill_name
                                            ].map((text, index) => (
                                                <td className="align-middle" key={index}>
                                                    <span className="text-secondary text-sm font-weight-bold">
                                                        {text}
                                                    </span>
                                                </td>
                                            ))}
                                            <td className="align-middle">
                                                <VisibilityIcon 
                                                    style={{cursor: 'pointer'}}
                                                    onClick={() => navigate(`/jobs/view/${job?.job_id}`)} 
                                                />
                                            </td> 
                                        </tr>
                                    ))}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan="8">Sorry, no data found!</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
}

export default Table;