export const initialState = {
    is_loading: true,
    details: {},
    selected_job: null,
    selected_job_markers: null,
    jobs_current_page: 1,
    is_loading_job: true,
    job_list: [],
    is_loading_job_list: true,
    jobs_list_serach_value: ""
}