import { GET } from "../../../API/get";
import { FORMPUT } from "../../../API/put";

export const fetchFtmProfile = async ({ftm_id}) => {
    let response = await GET(`ftm-profile/${ftm_id}`);
    if(response.status === "Success"){
        return response;
    }

    return null;
}

export const fetchPostcodeAddress = async (label) => {
    let response = await GET(`postcode/address/details/${label}`);
    if(response?.status === "Success"){
        return response;
    }

    return null;
}

export const fetchPostcodeAddressList = async (value) => {
    let response = await GET(`postcode/address/list/${value}`);
    if(response?.status === "Success"){
        return response;
    }

    return null;
}

export const updateFtm = async ({ftm_id, data, ftm_profile_image}) => {
    let ftm = {
        ftm_id: ftm_id,
        ftm_forename: data.ftm_forename,
        ftm_surname: data.ftm_surname,
        ftm_vehicle_number: data.ftm_vehicle_number,
        ftm_contact_number: data.ftm_contact_number,
        ftm_next_of_kin: data.ftm_next_of_kin,
        ftm_next_of_kin_contact_number: data.ftm_next_of_kin_contact_number,
        ftm_utr_number: data.ftm_utr_number,
        ftm_ni_number: data.ftm_ni_number,
        ftm_bank_name: data.ftm_bank_name,
        ftm_bank_sort_code: data.ftm_bank_sort_code,
        ftm_bank_account_number: data.ftm_bank_account_number,
        ftm_name_of_business: data.ftm_name_of_business,
        ftm_company_reg_number: data.ftm_company_reg_number,
        ftm_business_utr_number: data.ftm_business_utr_number,
        ftm_street_address: data.street_address,
        ftm_place: data.city,
        ftm_postcode: data.postcode,
        is_ftm_app: 0,
        ftm_availability_status: data.ftm_availability_status,
    }

    if(ftm_profile_image != null){
        ftm.ftm_document = ftm_profile_image;
    }

    let response = await FORMPUT("update-ftm-details", ftm);
    if(response?.status === "Success"){
        return response;
    }

    return null;
}