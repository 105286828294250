export const initialState = {
    is_loading: true,
    total_jobs: 0,
    total_pages: 0,
    current_page: 1,
    payments: [],
    start_date: '',
    end_date: '',
    is_show_date_filter: false,
    error: null,
    ftm: null,
    is_show_ftm_pay_modal: false,
    is_filtering: true,
    filter_data: [],
    total_ftm_payment: 0,
    is_show_invoice_modal: false,
    is_loading_invoice_info: true,
    invoice_info: [],
    inovoice_type: ''
}