export const initialState = {
    is_loading: true,
    customer: {},
    customer_job_count: 0,
    customer_profile_image: null,
    show_edit_canvas: false,
    customer_jobs: [],
    is_saving_edit_customer_info: false,
    is_open_add_card_modal: false,
    is_iframe_loaded: false,
    page_number: 1,
    is_show_chat: false,
    admin_unread_message_count: 0
}