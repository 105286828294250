import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Alert from '@mui/material/Alert';
import { useForm } from 'react-hook-form';
import { Container } from 'react-bootstrap';
import { Stack, TextField } from '@mui/material';
import { editSubSkill } from '../service';
import { theme } from '../../../theme';
import BtnSpinner from '../../../components/btnSpinner';
import Context from '../context';
import ModalTemplateWithForm from '../../../components/modal/modalTemplateWithForm';

const EditSubSkills = () => {
    const {
        reset
    } = useForm();

    const context = useContext(Context);

    return (
        <>
            <ModalTemplateWithForm
                show={context.state.is_show_edit_sub_skills} 
                closeFun={() => context.dispatch({
                    type: 'IS_SHOW_EDIT_SUB_SKILLS', 
                    is_show_edit_sub_skills: false,
                    selected_sub_skills: {}
                })}
                size = {null}
                centered = {false}
                fullscreen = {false}
                dialogClassName = {null}
                title = {`Edit ${context.state.selected_sub_skills.sub_skill_name}`}
                body = {
                    <Container>
                        {context.state.error.length > 0 && context.state.error.map((item, index) => (
                            <Stack sx={{ width: '100%' }} spacing={2} className="mb-3" key={index}>
                                <Alert severity="error">{item}</Alert>
                            </Stack>
                        ))}

                        <label style={{ color: 'black' }}>NAME</label>
                        <TextField
                            placeholder="Service type name"
                            defaultValue={context.state.selected_sub_skills.sub_skill_name}
                            fullWidth
                            size="small"
                            name="skill_name"
                            InputProps={{
                                style: { backgroundColor: '#fff' },
                            }}
                        />

                        <label style={{ color: 'black' }} className="mt-3">RATE</label>
                        <TextField
                            placeholder="Rate"
                            defaultValue={context.state.selected_sub_skills.sub_skill_hourly_rate}
                            fullWidth
                            size="small"
                            InputProps={{
                                style: { backgroundColor: '#fff' },
                            }}
                            name="rate"
                        />

                        <label style={{ color: 'black' }} className="mt-3">COMMISSION</label>
                        <TextField
                            placeholder="Commission"
                            defaultValue={context.state.selected_sub_skills.sub_skill_company_commission}
                            fullWidth
                            size="small"
                            InputProps={{
                                style: { backgroundColor: '#fff' },
                            }}
                            name="commission"
                        />
                    </Container>
                }
                footer = {
                    <>
                        <Button
                            variant={theme.close_btn}
                            onClick={() => context.dispatch({
                                type: 'IS_SHOW_EDIT_SUB_SKILLS', 
                                is_show_edit_sub_skills: false,
                                selected_sub_skills: {}
                            })}
                            size="sm"
                            disabled={context.state.btn_loading}
                        >
                            {context.state.btn_loading ? <BtnSpinner /> : 'CLOSE'}
                        </Button>
                        <Button
                            variant={theme.action_btn}
                            type="submit"
                            size="sm"
                            disabled={context.state.btn_loading}
                        >
                            {context.state.btn_loading ? <BtnSpinner /> : 'SAVE'}
                        </Button>
                    </>
                }
                onSubmit = {(event) => editSubSkill(event, context.state.selected_sub_skills, context.dispatch, reset)}
            />
        </>
    );
};

export default EditSubSkills;