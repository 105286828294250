import { useContext } from "react";
import { Context } from "../provider";

export const Ftm = () => {
    const context = useContext(Context);

    return (
        <div className="card card-body" id="profile">
            <div className="row justify-content-center align-items-center">
                <div className="col-sm-auto col-4">
                    <div className="avatar avatar-xl position-relative">
                        <img src={context.state.details?.ftm?.ftm_profile_image_path || "/assets/img/lock_user.png"} alt="bruce" className="w-100 rounded-circle shadow-sm" />
                    </div>
                </div>
                <div className="col-sm-auto col-8 my-auto">
                    <div className="h-100">
                        <h5 className="mb-1 font-weight-bolder">
                            {context.state.details?.ftm?.ftm_forename} {context.state.details?.ftm?.ftm_surname}
                        </h5>
                        <p className="mb-0 font-weight-normal text-sm">
                            {context.state.details?.ftm?.ftm_contact_number} / {context.state.details?.ftm?.ftm_email}
                        </p>
                        <p className="mb-0 font-weight-bolder text-sm">
                            {context.state.details?.ftm?.ftm_vehicle_number}
                        </p>
                    </div>
                </div>
                <div className="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
                    <label className="form-check-label mb-0">
                        <small id="profileVisibility">
                            {/* Switch to invisible */}
                        </small>
                    </label>
                    {/* <div className="form-check form-switch ms-2 my-auto">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault23" checked="" onchange="visible()" />
                    </div> */}
                </div>
            </div>
        </div>
    )
}