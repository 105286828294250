import { useContext } from "react";
import Badge from '@mui/material/Badge';

import { Context } from "../provider";
import { theme } from "../../../theme";
import PleaseWait from "../../components/pleaseWait";
import NoData from "../../components/noData";

const JobsListBody = () => {
    const context = useContext(Context);

    return(
        <>
            {context.state.jobs.length > 0 ? (
                <>
                    {context.state.jobs.map((job, index) => (
                        <div 
                            className="d-block p-2" 
                            key={index} 
                            onClick={() => context.handleChatLoading(job)}
                            style={{
                                cursor: 'pointer',
                                backgroundColor: context.state.selected_job?.doc_id === job?.doc_id && theme.main_code,
                                borderRadius: context.state.selected_job?.doc_id === job?.doc_id && '10px'
                            }}
                        >
                            <div className="d-flex p-2">
                                <Badge badgeContent={job?.job_admin_unread_message_count} color={theme.sidebar_notification_count_color}>
                                    <img
                                        alt="profile_image"
                                        src="/assets/img/job.png"
                                        // className="avatar shadow"
                                        style={{
                                            width: '40px',
                                            height: '40px'
                                        }}
                                    />
                                </Badge>
                                <div className="ms-3">
                                    <h6 
                                        className="mb-0"
                                        style={{color: context.state.selected_job?.doc_id === job?.doc_id && 'white'}}
                                    >
                                        #{job.job_id} {job.job_details.service_category.sub_skill.name}
                                    </h6>
                                    <span 
                                        className={`${context.state.selected_job?.doc_id === job?.doc_id ? 'text-white' : 'text-muted'} text-sm col-11 p-0 text-truncate d-block`}
                                    >
                                        {job.job_details.service_category.skill.name}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </>  
            ) : (
                <>
                    {context.state.is_loading_jobs ? <PleaseWait/> : <NoData/>}
                </>
            )}  
        </>
    );
}

export default JobsListBody;