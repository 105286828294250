export const initialState = {
    is_loading: true,
    skills: [],
    is_show_sub_skills: false,
    is_show_add_skills: false,
    is_show_edit_skills: false,
    btn_loading: false,
    selected_skills: {},
    error: null,
    is_show_certificate_types: false
}