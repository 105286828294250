import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { theme } from "../../../theme";
import Header from "./header";
import { data } from "../data";
import { Badge } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import FooterBtn from "./footerBtn";
import SubMenu from "./subMenu";

const IconSidebar = ({ ftmCount, jobCount, isFullSidebar, setIsFullSidebar }) => {
    const [open, setOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState({});

    const listItem = (icon) => {
        return (
            <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                <i className="material-icons opacity-10">{icon}</i>
            </div>
        )
    }

    return (
        <aside
            className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark" id="sidenav-main"
            style={{
                overflowX: 'hidden',
                zIndex: 1,
                width: '84px'
            }}
        >
            <Header is_icon_sidebar={true} />
            <hr className="horizontal light mt-0 mb-2" />

            <div className="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
                <ul className="navbar-nav" style={{ overflowX: 'hidden' }}>
                    {data.map((item, data_index) => (
                        <React.Fragment key={data_index}>
                            {item.is_navigation ? (
                                <>
                                    {item.list.map((list_item, index) => (
                                        <Tooltip title={list_item.iconName} arrow placement="right">
                                            <li className="nav-item" key={index}>
                                                <NavLink
                                                    className={({ isActive }) => (isActive ? `nav-link text-white active bg-gradient-${theme.main}` : 'nav-link text-white')}
                                                    to={list_item.to}
                                                >
                                                    {list_item.name === "Applications" || list_item.name === "Cancelled Jobs" ? (
                                                        <Badge
                                                            badgeContent={list_item.name === "Applications" ? ftmCount : jobCount}
                                                            color={theme.sidebar_notification_count_color}
                                                        >
                                                            {listItem(list_item.icon)}
                                                        </Badge>
                                                    ) : listItem(list_item.icon)}
                                                </NavLink>
                                            </li>
                                        </Tooltip>
                                    ))}
                                </>
                            ) : (
                                <Tooltip title={item.heder} arrow placement="right">
                                    <li className="nav-item">
                                        <NavLink
                                            className={({ isActive }) => (isActive ? `nav-link text-white active bg-gradient-${theme.main}` : 'nav-link text-white')}
                                            to={item.heder}
                                            onClick={(event) => {
                                                setOpen(true);
                                                setSelectedOption({
                                                    ...item,
                                                    ftmCount: ftmCount,
                                                    jobCount: jobCount
                                                });
                                                event.preventDefault();
                                            }}
                                        >
                                            {(item.heder === "TRADESPERSONS" || item.heder === "JOBS") ? (
                                                <Badge
                                                    badgeContent={item.heder === "TRADESPERSONS" ? ftmCount : jobCount}
                                                    color={theme.sidebar_notification_count_color}
                                                >
                                                    {listItem(item.heder_icon)}
                                                </Badge>
                                            ) : listItem(item.heder_icon)}
                                        </NavLink>
                                    </li>
                                </Tooltip>
                            )}
                        </React.Fragment>
                    ))}
                </ul>
            </div>

            <SubMenu
                open={open}
                setOpen={setOpen}
                selectedOption = {selectedOption}
                setSelectedOption = {setSelectedOption}
            />

            {/* <FooterBtn
                isFullSidebar = {isFullSidebar}
                setIsFullSidebar = {setIsFullSidebar}
            /> */}

        </aside>
    );
}

export default IconSidebar;