export const initialState = {
    pre_auth_amount: {},
    charge_amount: {},
    release_amount: {},
    refund_amount: {},
    payments: [],
    is_loading: false,
    current_page: 1,
    total_pages: 0,
    search_value: ""
}