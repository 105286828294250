import { GET } from "../../API/get";

export const fetchData = async ({
    type,
    text,
    column,
    option
}) => {
    let URL = `full/search?type=${type}&text=${text}&column=${column}&option=${option}`;
    let response = await GET(URL);

    if(response.status === "Success"){
        return response;
    }

    return null;
}