import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';

import Context from '../context';
import InvoiceInfo from './invoiceInfo';
import DivSpinner from '../../../components/divSpinner';
import { theme } from '../../../theme';
import BtnSpinner from '../../../components/btnSpinner';
import ModalTemplate from '../../../components/modal/modalTemplate';

const InvoiceView = () => {
    const context = useContext(Context);

    return (
        <>
            <ModalTemplate
                show={context.state.is_show_invoice_modal}
                closeFun={() => context.dispatch({
                    type: 'IS_SHOW_INVOICE_MODAL', 
                    is_show_invoice_modal: false
                })}
                size = {"xl"}
                centered = {true}
                title = {`INVOICE ${context.state.invoice_type == 'with_commissions' ? "WITH COMMISSIONS" : "WITHOUT COMMISSIONS"}`}
                body = {context.state.is_loading_invoice_info ? <DivSpinner/> : <InvoiceInfo/>}
                footer = {
                    <Button 
                        variant={theme.close_btn} 
                        size="sm" 
                        onClick={() => context.dispatch({
                            type: 'IS_SHOW_INVOICE_MODAL', 
                            is_show_invoice_modal: false
                        })}
                        disabled={context.state.is_loading_invoice_info}
                    >
                        {context.state.is_loading_invoice_info ? <BtnSpinner/> : "CLOSE"}
                    </Button>
                }
            />
        </>
    );
}

export default InvoiceView;