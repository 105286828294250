import React, { useEffect, useReducer } from "react";

import Reducer from "./reducer";
import Context from "./context";
import { initialState } from "./initialState";
import Html from "./html";
import { getLogs } from "./service";

const Logs = (props) => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        getLogs(props.ftm_id, 1, state.search_text, dispatch);
    }, [props.ftm_id, props.open]);

    return(
        <Context.Provider value={{state, dispatch}}>
            <Html
                ftm_id = {props.ftm_id}
                open = {props.open}
                handleClose = {props.handleClose}
            />
        </Context.Provider>
    );
}

export default Logs;