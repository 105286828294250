export default function Reducer(prevState, action){
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true,
            };
        case "IS_LOADED": 
            return {
                ...prevState,
                jobs_list: action.jobs_list,
                total_jobs: action.total_jobs,
                // current_page: action.current_page, // nav_type !== state.nav_type ? 1 : state.current_page
                current_page: (prevState.nav_type !== action.nav_type) ? 1 : action.current_page,
                total_pages: action.total_pages,
                nav_type: action.nav_type,
                is_loading: false,
            }; 
        case "SET_SEARCH_TYPE":
            return {
                ...prevState,
                search_type: action.search_type,
            };
        case "UPDATE_CURRENT_PAGE":
            return {
                ...prevState,
                current_page: action.current_page,
            };
        case "SET_SERACH_VALUE":
            return {
                ...prevState,
                current_page: 1,
                search_value: action.search_value,
            };
        case "IS_SHOW_SEARCH_MODAL":
            return {
                ...prevState,
                is_show_search_modal: action.is_show_search_modal,
            };
        case "IS_PAYMENT_SUCCESS":
            return {
                ...prevState,
                is_payment_success: action.is_payment_success,
                job_id: action.job_id
            };
        default:
            throw new Error();
    }
}