import { usersRef } from "../../firebase";

export const fetchUsers = ({ users_type, is_get_archive_user }) => {
    console.log("🚀 ~ fetchUsers ~ is_get_archive_user:", is_get_archive_user)
    console.log("🚀 ~ fetchUsers ~ users_type:", users_type)
    return new Promise((resolve, reject) => {
        let query = usersRef
            .where('user_type', '==', users_type)
            .where('user_archive', '==', is_get_archive_user)
            .orderBy('user_total_office_admin_unread_message_count', 'desc')
            .orderBy('user_office_last_message_received_timestamp', 'desc');

        query.onSnapshot((snapshot) => {
            let users = [];
            snapshot.forEach((doc) => {
                let data = doc.data();
                users.push({
                    doc_id: doc.id,
                    ...data
                });
            });
            resolve(users);
        }, (error) => {
            reject(error);
        });
    });
};

export const updateAll = () => {
    // for(let user of state.users){
    //     let ref = usersRef.doc(user.doc_id);

    //     ref.update({
    //         user_archive: true
    //     });
    // }   

        usersRef.onSnapshot((snapshot) => {
            snapshot.forEach((doc) => { 
                let ref = usersRef.doc(doc.id);

                ref.update({
                    user_archive: false
                });
            });
        });
}

