import React, { useContext } from "react";

import Template from "../../../components/template";
import Context from "./context";
import DivSpinner from "../../../components/divSpinner";
import PageHeader from "./components/pageHeader";
import { HeaderAvatar } from "./components/headerAvatar";
import { DisputesList } from "./components/disputesList";
import { Chat } from "./components/chat";
import { OrderDetails } from "./components/orderDetails";
import IconMenu from "./components/iconMenu";
import CreditNotes from "./components/creditNotes";
import SalesInvoices from "./components/salesInvoices";
import useWindowDimensions from "../../../components/hooks/useWindowDimensions";

const Html = () => {
    const context = useContext(Context);
    const { width } = useWindowDimensions();

    return (
        <Template>
            {width}
            <div className="container-fluid px-2 px-md-4">
                <PageHeader />
                <div className="card card-body mx-3 mx-md-4 mt-n6" style={{backgroundColor: '#efefef'}}>
                    {context.state.is_loading && context.state.disputes.length === 0 ? (
                        <div style={{ height: `${window.innerHeight - 387}px` }}>
                            <DivSpinner />
                        </div>
                    ) : (
                        <React.Fragment>
                            <HeaderAvatar />
                            <div className="row">
                                <div className="row mt-4">
                                    <div className={`col-md-5 col-lg-1`} style={{marginTop: '-30px', width: '5%'}}>
                                        <IconMenu/>
                                    </div>
                                    <DisputesList />
                                    {Object.keys(context.state.selected_dispute).length > 0 && (
                                        <React.Fragment>
                                            <Chat />
                                            {width > 1320 && (
                                                <OrderDetails /> 
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>

            <CreditNotes/>
            <SalesInvoices/>
        </Template>
    );
}

export default Html;