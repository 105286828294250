import React, { useContext } from "react";
import { Context } from "../provider";

const ChangeProfile = () => {
    const { state, dispatch } = useContext(Context);
    const { ftm, ftm_profile_image } = state;
    const { ftm_profile_image_path, ftm_forename, ftm_surname, ftm_postcode } = ftm;

    const handleImageChange = (event) => {
        dispatch({
            type: 'SET_FTM_PROFILE_IMAGE',
            ftm_profile_image: event.target.files[0]
        });
    };

    const profileImageSrc = ftm_profile_image 
        ? URL.createObjectURL(ftm_profile_image) 
        : (ftm_profile_image_path || '/assets/img/lock_user.png');

    return (
        <div className="card card-body" id="profile">
            <div className="row justify-content-center align-items-center">
                <div className="col-sm-auto col-4">
                    <div className="avatar avatar-xl position-relative">
                        <img
                            src={profileImageSrc}
                            alt="profile"
                            className="w-100 rounded-circle shadow-sm"
                            style={{ height: '100%' }}
                        />
                    </div>
                </div>
                <div className="col-sm-auto col-8 my-auto">
                    <div className="h-100">
                        <h5 className="mb-1 font-weight-bolder">
                            {ftm_forename} {ftm_surname}
                        </h5>
                        <p className="mb-0 font-weight-normal text-sm">
                            POSTCODE #{ftm_postcode}
                        </p>
                    </div>
                </div>
                <div className="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
                    <div>
                        <input
                            type="file"
                            id="upload"
                            hidden
                            onChange={handleImageChange}
                        />
                        <label
                            htmlFor="upload"
                            style={{
                                display: "inline-block",
                                backgroundColor: "#212529",
                                color: "white",
                                padding: "0.4rem",
                                fontFamily: "sans-serif",
                                borderRadius: "0.3rem",
                                cursor: "pointer",
                                marginTop: '15px'
                            }}
                        >
                            <i className="fas fa-camera"></i>{"  "}PHOTO
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangeProfile;