import React, { createContext, useEffect, useReducer } from "react";
import Reducer from "./reducer";
import { initialState } from "./initialState";
import { fetchSalesInvoices } from "./service";

const Context = createContext();

const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        const getSalesInvoices = async () => {
            try {
                dispatch({type: 'IS_LOADING'});
                const response = await fetchSalesInvoices({
                    current_page: state.current_page,
                    search_value: state.search_value
                });
    
                dispatch({
                    type: 'IS_LOADED',
                    sales_invoices: response.sales_invoices,
                    total_sales_invoices: response.total_sales_invoices,
                    total_pages: response.total_pages,
                }); 
            } catch (error) {
                console.error('Error fetching disputes:', error);
            }
        };
    
        getSalesInvoices();
    }, [state.current_page, state.search_value]);
    
    return (
        <Context.Provider value={{state, dispatch}}>
            {children}
        </Context.Provider>
    );
    
}

export { Context, Provider };