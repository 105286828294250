import React, { createContext, useEffect, useReducer } from "react";
import { initialState } from "./initialState";
import Reducer from "./reducer";
import { fetchData, fetchRunningJobs } from "./service";

const Context = createContext();

const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        const getData = async () => {
            try{
                dispatch({ type: "IS_LOADING" });
                let response = await fetchData();

                dispatch({ 
                    type: "IS_LOADED",
                    details: response.details,
                });
            }catch(error){
                console.log(error);
            }
        };

        getData();
    }, []); 

    const getAllRunningJobs = async () => {
        try{
            dispatch({ 
                type: "IS_LOADING_RUNNING_JOBS", 
                is_loading_running_jobs: true 
            });

            let response = await fetchRunningJobs({
                page_number: state.running_jobs_current_page,
                search_value: state.running_jobs_search_value
            });

            dispatch({ 
                type: "SET_RUNNING_JOBS",
                running_jobs: response.running_jobs,
                total_pages: response.total_pages,
                job_count: response.job_count,
            });
        }catch(error){
            console.log(error);
        }
    }

    const handleCloseRunningJobsDetailsModal = () => dispatch({ 
        type: "IS_OPEN_RUNNING_JOBS_DETAILS_MODAL",
        selected_running_job: null, 
        is_open_running_jobs_details_modal: false
    });

    const handleShowRunningJobsDetailsModal = (details) => dispatch({ 
        type: "IS_OPEN_RUNNING_JOBS_DETAILS_MODAL", 
        selected_running_job: details,
        is_open_running_jobs_details_modal: true
    });

    return (
        <Context.Provider value={{
            state, 
            dispatch, 
            getAllRunningJobs, 
            handleCloseRunningJobsDetailsModal,
            handleShowRunningJobsDetailsModal
        }}>
            {children}
        </Context.Provider>
    );

}

export { Context, Provider };