import React, { useEffect, useContext } from "react";
import { useGoogleMaps } from "react-hook-google-maps";
import { addJobLocation } from "../service";
import Context from "../context";

const JobMap = () => {
    const { ref, map, google } = useGoogleMaps(
        process.env.REACT_APP_GOOGLE_MAP_KEY,
        {
            center: { lat: 51.5286416, lng: -0.1015987 },
            disableDefaultUI: true,
            // zoom: 12
        }
    );

    const context = useContext(Context);

    useEffect(() => {
        addJobLocation({
            map: map,
            google: google,
            job_latitude: context.state.job_details.job_latitude,
            job_longitude: context.state.job_details.job_longitude,
        });
    }, [google, map]);

    return(
        <div 
        className="page-header min-height-300 border-radius-xl mt-4" 
        style={{cursor: 'pointer'}}
        onClick={() => window.open(`http://maps.google.com/maps?q=${context.state.job_details.job_latitude},${context.state.job_details.job_longitude}`, '_blank')}>
            <div 
                ref={ref} 
                style={{ width: '100%', height: 350 }} 
            />
            <span className="mask    opacity-6"></span>
        </div>
    );
}

export default JobMap;