import React, { useEffect, useReducer } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from '@mui/material/Button';
import { loadData } from "./service";
import Reducer from "./reducer";
import { initialState } from "./initialState";
import Context from "./context";
import DivSpinner from "../../../components/divSpinner";
import Html from "./html";
import OffcanvasTemplate from "../../../components/offcanvasTemplate";

const JobsheetOffcanvas = (props) => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        loadData(props.job_id, dispatch);
    }, []);

    return(
        <Context.Provider value={{job_id: props.job_id, state: state, dispatch: dispatch}}>
            <OffcanvasTemplate
                open={props.show} 
                handleClose = {props.handleClose} 
                placement = {'end'}  
                title = {`JOB SHEET - #${props.job_id}`}
                body = {state.is_loading ? (<DivSpinner/>) : (<Html/>)} 
                footer = {null}
            />
        </Context.Provider>
    );
}

export default JobsheetOffcanvas;