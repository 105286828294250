import { useContext } from "react";
import { Link } from "react-router-dom";

import Context from "../context";
import ChatDisputes from "../../chat";
import { OrderDetailsHeader } from "./orderDetailsHeader";
import ImageGallery from "../../chat/components/imageGallery";
import { formatTimestampInMap, truncateText } from "../../../../utils";


export const OrderDetails = () => {
    const context = useContext(Context);

    return (
        <div className="col-md-7 col-lg-3">
            <div className="card blur shadow-blur max-height-vh-70  h-100">
                <OrderDetailsHeader/>
                <div className="card-body overflow-auto overflow-x-hidden">

                    <div className="card card-plain h-100">
                        <div className="card-header pb-0 p-3">
                            <div className="row">
                                <div className="col-md-8 d-flex align-items-center">
                                    <h6 className="mb-0">Description</h6>
                                </div>
                                {/* <div className="col-md-4 text-end">
                                    <a href="#">
                                        <i className="fas fa-user-edit text-secondary text-sm" data-bs-toggle="tooltip" data-bs-placement="top" aria-hidden="true" aria-label="Edit Profile" data-bs-original-title="Edit Profile"></i><span className="sr-only">view</span>
                                    </a>
                                </div> */}
                            </div>
                        </div>
                        <div className="card-body p-3">
                            <p className="text-sm">
                                {context.state.selected_dispute?.dispute_details?.description && truncateText(context.state.selected_dispute?.dispute_details?.description, 200)}
                            </p>
                            {context.state.selected_dispute?.dispute_details?.images?.length > 0 && <ImageGallery images = {context.state.selected_dispute?.dispute_details?.images}/>}

                            <ul className="list-group pt-3">
                                {[
                                    {
                                        title: "Created:",
                                        content: formatTimestampInMap(context.state.selected_dispute?.dispute_details?.createAt)
                                    },
                                    {
                                        title: "Job id:",
                                        content: <Link to={`/jobs/view/${context.state.selected_dispute?.dispute_job?.job_id}`} >
                                                    {context.state.selected_dispute?.dispute_job?.job_id}
                                                </Link>
                                    },
                                    {
                                        title: "Customer:",
                                        content: <Link to={`/customers/view/${context.state.selected_dispute?.dispute_details?.customer?.id}`} >
                                                    {context.state.selected_dispute?.dispute_details?.customer?.name}
                                                </Link>
                                    },
                                    {
                                        title: "Tradesperson:",
                                        content: <Link to={`/customers/view/${context.state.selected_dispute?.dispute_details?.ftm?.id}`} >
                                                    {context.state.selected_dispute?.dispute_details?.ftm?.name}
                                                </Link>
                                    },
                                    {
                                        title: "Address:",
                                        content: context.state.selected_dispute?.dispute_job?.address
                                    },
                                    {
                                        title: "Category:",
                                        content: context.state.selected_dispute?.dispute_job?.service_category.skill.name+" - "+context.state.selected_dispute?.dispute_job?.service_category.sub_skill.name
                                    }
                                ].map((item, index) => (
                                    <li className="list-group-item border-0 ps-0 pt-1 text-sm" key={index}>
                                        <strong className="text-dark">{item.title}</strong> &nbsp; 
                                        {item.content}
                                    </li>
                                ))}
                            </ul>
                            <hr className="horizontal gray-light my-4" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}