import React from "react";
import { useNavigate } from "react-router-dom";

const Box = ({details}) => {
    let navigate = useNavigate();

    return(
        <div className="card">
            <div className="card-body pt-4 p-3">
                <ul className="list-group">
                    <li className="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg">
                        <div className="d-flex flex-column">
                            <h6 className="mb-3 text-sm">
                                #{details.job?.job_id} {details.job?.sub_skill?.skill?.skill_name} {details.job.sub_skill?.sub_skill_name}
                            </h6>
                            <span className="mb-2 text-xs">
                                Customer: 
                                <span className="text-dark font-weight-bold ms-sm-2">{details.job?.customer?.customer_full_name}</span>
                            </span>
                            <span className="mb-2 text-xs">
                                Address: 
                                <span className="text-dark ms-sm-2 font-weight-bold">{details.job?.job_address}</span>
                            </span>
                            <span className="text-xs">
                                Created At: 
                                <span className="text-dark ms-sm-2 font-weight-bold">{details.job?.job_createdAt}</span>
                            </span>
                            <span 
                                className="text-xs mt-3 text-danger" 
                                style={{cursor: 'pointer'}} 
                                onClick={() => navigate(`/jobs/view/${details.job?.job_id}`)}
                            >
                                <b>VIEW</b>
                            </span>
                        </div>
                        {/* <div className="ms-auto text-end">
                            <a className="btn btn-link text-danger text-gradient px-3 mb-0" href="#">
                                VIEW
                            </a>
                        </div> */}
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Box;