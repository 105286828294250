import React, { useContext, useEffect, useRef } from "react";
import Context from "./context";
import Left from "./components/left";
import Right from "./components/right";

const Html = () => {
    const context = useContext(Context);
    const lastMessageRef = useRef();

    useEffect(() => {
        // Scroll down to the last message when messages are updated
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [context.state.messages_list]);

    return (
        // <div className="chat-body">
        //     <section className="msger">
        //         <main className="msger-chat">
        //             {context.state.messages_list?.map((message, index) => (
        //                 <div className="my-2" key={index} ref={index === context.state.messages_list.length - 1 ? lastMessageRef : null}>
        //                     {message?.message_sender?.user_type !== "ADMIN" ? <Left message={message} /> : <Right message={message} />}
        //                 </div>
        //             ))}
        //         </main>
        //     </section>
        // </div>

        <div className="card-body overflow-auto overflow-x-hidden">
            {context.state.messages_list?.map((message, index) => (
                <div className="my-2" key={index} ref={index === context.state.messages_list.length - 1 ? lastMessageRef : null}>
                    {message?.message_sender?.user_type !== "ADMIN" ? <Left message={message} /> : <Right message={message} />}
                </div>
            ))}
        </div>
    );
};

export default Html;