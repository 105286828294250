export default function Reducer(prevState, action){
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true 
            };
        case "IS_LOADED":
            return {
                ...prevState,
                is_loading: false,
            };
        case "SET_NOTE_LIST":
            return {
                ...prevState,
                is_loading: false,
                notes_list: action.notes_list
            };
        case "SHOW_EDIT_NOTE":
            return {
                ...prevState,
                show_edit_note: action.show_edit_note,
                ftm_note_id: action.ftm_note_id,
                ftm_id: action.ftm_id,
                ftm_note: action.ftm_note
            };
        case "BTN_LOADING":
            return {
                ...prevState,
                btn_loading: action.btn_loading
            };
        case "RESET_EDIT_NOTE":
            return {
                ...prevState,
                show_edit_note: false,
                ftm_note_id: null,
                ftm_id: null,
                ftm_note: "",
                btn_loading: false
            };
        case "SET_EDIT_NOTE":
            return {
                ...prevState,
                ftm_note: action.ftm_note
            };
        default:
            throw new Error();
    }
}