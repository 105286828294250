import { GET } from "../../API/get";

export const loadData = async (page_number, serach_text, dispatch) => {
    dispatch({ type: "IS_LOADING" });
    let response = await GET(`pending/applications/${page_number}?search=${serach_text.trim()}`);

    if(response.status === "Success"){
        dispatch({ 
            type: "IS_LOADED",
            total_ftms: response.total_ftms,
            total_pages: response.total_pages,
            // page_number: page_number,
            ftms: response.list
        });
    }
}

export const getStatusColor = (ftm_is_new, ftm_is_verify) => {
    if(ftm_is_new  == 1){
        return "info";
    }else{
        if(ftm_is_verify == 2){
            return "warning";
        }else{
            return "";
        }
    }
}

export const getStatusText = (ftm_is_new, ftm_is_verify) => {
    if(ftm_is_new  == 1){
        return "NEW TRADESPERSON";
    }else{
        if(ftm_is_verify == 2){
            return "WAITING FOR APPROVAL";
        }else{
            return "BLOCK";
        }
    }
}

export const getAddress = (ftm) => {
    return (ftm?.ftm_street_address !== "" || ftm?.ftm_street_address !== null ? ftm?.ftm_street_address : "")+" "+(ftm?.ftm_place !== "" || ftm?.ftm_place !== null ? ftm?.ftm_place : "")+" "+(ftm?.ftm_postcode !== "" || ftm?.ftm_postcode !== null ? ftm?.ftm_postcode : "")
}