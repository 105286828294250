export default function Reducer(prevState, action){
    switch (action.type) {
        case "LOADING":
            return {
                ...prevState,
                is_loading: true,
            };
        case "LOADED":
            return {
                ...prevState,
                is_loading: false
            };
        case "IS_BTN_LOADING":
            return {
                ...prevState,
                is_btn_loading: action.is_btn_loading
            };    
        case "SET_JOBSHEET":
            return {
                ...prevState,
                is_loading: false,
                job_sheet: action.job_sheet
            };
        default:
            throw new Error();
    }
}