import React, { useContext } from "react";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HistoryIcon from '@mui/icons-material/History';
import Context from "../context";
import { getPaymentStatus, localeDateStringFormat } from "../../../utils";
import { refundPayment } from "../service";

const Payments = () => {
    const context = useContext(Context);

    return (
        <div className="col-lg-12 col-md-6">
            <div className="card h-100 mb-4">
                <div className="card-header pb-0 px-3">
                    <div className="row">
                        <div className="col-md-12">
                            <h6 className="mb-0">TRANSACTION'S</h6>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-4 p-3">
                    <ul className="list-group">
                        {context.state.extra_payments.length > 0 ? (
                            <>
                                {context.state.job_details.job_payments.map((payment, index) => {
                                    let status = getPaymentStatus(payment.payment_status);
                                    return(
                                        <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg" key={index}>
                                            <div className="d-flex align-items-center">
                                                <button className={`btn btn-icon-only btn-rounded btn-outline-${status.text_color} mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center`}>
                                                        <i className="material-icons text-lg">{status.icon}</i>
                                                </button>
                                                <div className="d-flex flex-column">
                                                    <h6 className={`mb-1 text-${status.text_color} text-sm`}>
                                                        {payment.payment_type.replace("_", ' ')} - {payment?.payment_card?.card_type}
                                                    </h6>
                                                    {/* <h6 className={`mb-1 text-${status.text_color} text-sm`}>
                                                        {getCardTypeImage(payment?.payment_card?.card_type)}
                                                    </h6> */}
                                                    <span className="text-xs">{payment.payment_reference}</span>
                                                    <span className="text-xs">{localeDateStringFormat(payment.payment_date_time)}</span>
                                                </div>
                                            </div>
                                            <div className={`d-flex align-items-center text-${status.text_color} text-gradient text-sm font-weight-bold`}>
                                                {payment.payment_status == 2 ? (
                                                    <Tooltip title="Refund Payment" arrow placement="left">
                                                        <IconButton 
                                                            onClick={() => refundPayment({
                                                                job_id: context.job_id, 
                                                                payment_id: payment.payment_id, 
                                                                dispatch: context.dispatch,
                                                            })}
                                                        >
                                                            <HistoryIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                ) : null}
                                                &#163; {payment.payment_amount} 
                                            </div>
                                        </li>
                                    );
                                })}
                            </>
                        ) : (
                            <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                <div className="d-flex align-items-center">
                                    <div className="d-flex flex-column">
                                        <span className="text-md">Sorry, no data found!</span>
                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Payments;