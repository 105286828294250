import React, {useContext} from "react";
import Template from "../../components/template";
import Box from "./components/box";
import Context from './context';
import CardWithHeaderTemplate from "../../components/cardWithHeaderTemplate";
// import Table from "./components/table";
// import CardWithHeaderTemplate from "../../components/cardWithHeaderTemplate";
import Table from "./components/table";
import HeaderView from "./components/headerView";
import PaginationView from "./components/paginationView";
import { convertToMillions } from "../../utils";

const Html = () => {
    const context = useContext(Context);

    return(
        <Template>
            <div className="container-fluid py-4">
                <div className="row">
                    <div className="col-12">
                        
                        <div className="row mb-5">
                            {[
                                {
                                    icon: "settings",
                                    title: "Total pre auth amount",
                                    value: context.state.is_loading ? "Loading..." : convertToMillions(context.state.pre_auth_amount.total),
                                },
                                {
                                    icon: "thumb_up",
                                    title: "Total charge amount",
                                    value: context.state.is_loading ? "Loading..." : convertToMillions(context.state.charge_amount.total),
                                },
                                {
                                    icon: "shopping_cart",
                                    title: "Total release amount",
                                    value: context.state.is_loading ? "Loading..." : convertToMillions(context.state.release_amount.total),
                                },
                                {
                                    icon: "group",
                                    title: "Total refund amount",
                                    value: context.state.is_loading ? "Loading..." : convertToMillions((context.state.refund_amount.total*-1)),
                                }
                            ].map((item) => (
                                <Box
                                    icon = {item.icon}
                                    title = {item.title}
                                    value = {item.value}
                                />
                            ))}
                        </div>

                        <CardWithHeaderTemplate
                            header={<HeaderView/>} 
                            body={<Table/>} 
                            footer={<PaginationView/>}
                        />
                    </div>
                </div>
            </div>
        </Template>
    );
}

export default Html;