/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

import Form from 'react-bootstrap/Form';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import { theme } from "../../../theme";
import Context from "../context";
import { resetPassword } from "../service";
import BtnSpinner from "../../../components/btnSpinner";

const ResetPassword = () => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const context = useContext(Context);

    return(
        <div className="col-12 col-xl-6 mt-xl-0 mt-4">
            <div className="card mb-4">
                <div className="card-header">
                    <h5>RESET YOUR PASSWORD</h5>
                </div>
                <Form className="mt-3" onSubmit={handleSubmit((data) => resetPassword(data, context, reset))}>
                    <div className="card-body pt-0">
                        {context.state.show_alert?.show && 
                            <Alert 
                                onClose={() => context.dispatch({
                                    type: 'IS_SHOW_ALERT', 
                                    show_alert: {
                                        show: false,
                                        type: '',
                                        msg: ''
                                    }
                                })} 
                                className="mb-4"
                                severity={context.state.show_alert?.type}
                            >
                                {context.state.show_alert?.msg}
                            </Alert>
                        }
                        
                        <FormControl error={errors.current_password} fullWidth>
                            <label><b>Current Password</b></label>
                            <TextField
                                error={errors.current_password}
                                placeholder="Current Password"
                                size="small"
                                type="password"
                                {...register("current_password", { required: true })}
                            />
                            {errors.current_password && <FormHelperText>Current password is required</FormHelperText>}
                        </FormControl>

                        <FormControl error={errors.new_password} fullWidth className="mt-3">
                            <label><b>New Password</b></label>
                            <TextField
                                error={errors.current_password}
                                placeholder="New Password"
                                size="small"
                                type="password"
                                {...register("new_password", { required: true })}
                            />
                            {errors.new_password && <FormHelperText>Current password is required</FormHelperText>}
                        </FormControl>

                        <FormControl error={errors.confirm_new_password} fullWidth className="mt-3">
                            <label><b>Confirm New Password</b></label>
                            <TextField
                                error={errors.current_password}
                                placeholder="Confirm New Password"
                                size="small"
                                type="password"
                                {...register("confirm_new_password", { required: true })}
                            />
                            {errors.confirm_new_password && <FormHelperText>Confirm new password is required</FormHelperText>}
                        </FormControl>

                        <div className="mt-3">
                            <Button 
                                type="submit" 
                                variant="contained" 
                                fullWidth
                                color={theme.action_btn} 
                                disabled={context.state.is_resetting_password}
                            >
                                {context.state.is_resetting_password ? (
                                    <BtnSpinner/>
                                ): "CHANGE PASSWORD"}
                            </Button>
                        </div> 

                    </div>
                </Form>
            </div>
        </div>
    );
}

export default ResetPassword;