import React, { useContext } from 'react';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import AppsIcon from '@mui/icons-material/Apps';
import HandshakeRoundedIcon from '@mui/icons-material/HandshakeRounded';
import DescriptionIcon from '@mui/icons-material/Description';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Tooltip from '@mui/material/Tooltip';

import Context from "../context";
import { loadData, getAllCreditNotes, getAllSalesInvoices } from '../service';


export default function IconMenu() {
    const context = useContext(Context);

    return (
        <MenuList>
            <MenuItem onClick={() => loadData(context.state.page_size, null, context.dispatch)}>
                <ListItemIcon>
                    <AppsIcon />
                </ListItemIcon>
            </MenuItem>
            <MenuItem onClick={() => loadData(context.state.page_size, null, context.dispatch)}>
                <ListItemIcon>
                    <HandshakeRoundedIcon />
                </ListItemIcon>
            </MenuItem>
            <MenuItem onClick={() => getAllCreditNotes(1, context.dispatch)}>
                <ListItemIcon>
                    <Tooltip title="CREDIT NOTES" placement="right">
                        <ReceiptIcon/>
                    </Tooltip>
                </ListItemIcon>
            </MenuItem>
            <MenuItem onClick={() => getAllSalesInvoices(1, context.dispatch)}>
                <ListItemIcon>
                    <Tooltip title="SALES INVOICES" placement="right">
                        <DescriptionIcon/>
                    </Tooltip>    
                </ListItemIcon>
            </MenuItem>
            <Divider />
            {context.state.is_show_load_move_btn && (
                <MenuItem onClick={() => loadData(context.state.page_size, context.state.last_doc, context.dispatch)}>
                    <ListItemIcon>
                        <PublishedWithChangesIcon />
                    </ListItemIcon>
                </MenuItem>
            )} 
        </MenuList>
    );
}