import React, { useContext } from "react";
import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Work';

import Address from "./components/address";
import Context from "./context";
import FtmPayments from "./components/ftmPayments";
import ExtraPayments from "./components/extraPayments";
import JobValues from "./components/jobValues";
import { Button } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import { theme } from "../../../theme";
import { getJobsheetPDF } from "./service";
import BtnSpinner from "../../../components/btnSpinner";

const Html = () => {
    const context = useContext(Context);

    return(
        <>
            <Address
                icon = {<WorkIcon/>}
                primary = {<b>{context.state.details.company_settings.company_name}</b>}
                secondary = {context.state.details.company_settings.company_address}
            />
            <hr/>
            <Address
                icon = {<HomeIcon/>}
                primary = {<b>{context.state.details.customer.customer_full_name}</b>}
                secondary = {context.state.details.job_address}
            />
            <hr/>
            <FtmPayments/>
            <hr/>
            <ExtraPayments/>
            <hr/>
            <JobValues/>

            <div className="offcanvas-footer">
                <div className="d-grid gap-2" style={{marginTop: '20%'}}>
                    <Select
                        defaultValue={context.state.selected_invoice_type}
                        onChange={(event => context.dispatch({type: 'SELECTED_INVOICE_TYPE', selected_invoice_type: event.target.value}))}
                        style={{ height: '40px', backgroundColor: '#ffffff' }}
                    >
                        <MenuItem value="customer">Customer Invoice</MenuItem>
                        <MenuItem value="tradesperson">Tradesperson Invoice</MenuItem>
                        <MenuItem value="commission">Commission Invoice</MenuItem>
                    </Select>
                    <Button 
                        variant="contained" 
                        color={theme.action_btn} 
                        disabled={context.state.is_btn_loading}
                        onClick={()=> getJobsheetPDF(context.job_id, context.state.selected_invoice_type, context.dispatch)}
                    >
                        {context.state.is_btn_loading ? (
                            <BtnSpinner/>
                        ): "GENERATE PDF"}
                    </Button>
                </div> 
            </div>
        </>
    );
}

export default Html;