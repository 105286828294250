import React, { useContext } from "react";
import Context from "../context";
import CardWithHeaderTemplate from "../../../components/cardWithHeaderTemplate";

const PersonalDocuments = () => {
    const context = useContext(Context);

    return(

        <div className="col-12 mt-4">
            <CardWithHeaderTemplate
                header={
                    <div className="row">
                        <div className="col-8 my-auto">
                            <div className="numbers">
                                <h6 className="text-white font-weight-bolder mb-0 ms-3">
                                    PERSONAL DOCUMENTS
                                </h6>
                            </div>
                        </div>
                    </div>
                }
                body={
                    <div className="row mt-5">
                        {[
                            {
                                image: context.state.ftm.ftm_vehicle_image || '/assets/img/200-120.png',
                                name: 'Vehicle'
                            }, 
                            {
                                image: context.state.ftm.ftm_residence_image_path || '/assets/img/200-120.png',
                                name: 'Residence'
                            }, 
                            {
                                image: context.state.ftm.ftm_bank_image_path || '/assets/img/200-120.png',
                                name: 'Bank'
                            }, 
                            {
                                image: context.state.ftm.ftm_passport_image_path || '/assets/img/200-120.png',
                                name: 'Passport'
                            },
                            {
                                image: context.state.ftm.ftm_driving_license_front || '/assets/img/200-120.png',
                                name: 'Driving license front'
                            }, 
                            {
                                image: context.state.ftm.ftm_driving_license_back || '/assets/img/200-120.png',
                                name: 'Driving license back'
                            },
                            {
                                image: context.state.ftm.ftm_public_liability || '/assets/img/200-120.png',
                                name: 'Public liability'
                            },
                            {
                                image: context.state.ftm.ftm_crb || '/assets/img/200-120.png',
                                name: 'CRB'
                            },
                        ].map((document, index) => ( 
                            <div className="col-xl-3 col-md-6 mb-xl-0 mb-4" key={index}>
                                <div className="card card-blog card-plain">
                                    <div className="card-header p-0 mt-n4 mx-3">
                                        <a 
                                            className="d-block shadow-xl border-radius-xl" 
                                            href={document.image} alt="image" 
                                            target="_blank" 
                                            style={{ width: "200px", height: "120px" }} 
                                            rel="noreferrer"
                                        >
                                            <img 
                                                src={document.image} 
                                                alt="img-blur-shadow" 
                                                className="img-fluid shadow border-radius-xl"
                                                style={{ width: "200px", height: "120px" }}
                                            />
                                        </a>
                                    </div>
                                    <div className="card-body p-3 mb-4">
                                        {/* <p className="mb-0 text-sm">Document #{index+1}</p> */}
                                        <a href={document.image}>
                                            <h5>{document.name}</h5>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                }
                footer={null}
                extraCardClassName={`blur shadow-blur max-height-vh-70 `}
                extraBodyClassName={`overflow-auto overflow-x-hidden`}
            />
        </div>
    );
}

export default PersonalDocuments;