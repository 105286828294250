import React, { useContext, useMemo } from "react";
import Template from "../components/template";
import Stats from "./components/stats";
import Map from "./components/map";
import Box from "./components/box";
import { moneyFormat } from "../utils";
import RunningJobs from "./components/runningJobs";
import Loader from "../components/loading";
import { Context } from "./provider";

const Html = () => {
    const context = useContext(Context);
    const runningJobs = useMemo(() => <RunningJobs/>, [context.state.running_jobs_current_page]);

    return (
        <>
            {!context.state.is_loading ? (
                <Template>
                    <div className="container-fluid py-4">
                        <div className="row">
                            {[
                                {
                                    color : "info",
                                    title : "ONLINE TRADESPERSONS",
                                    sub_title : "",
                                    icon : "person",
                                    value: context.state.details.total_online_ftms,
                                    link : "/ftm/list?is_online=1",
                                },
                                {
                                    color : "success",
                                    title : "COMPLETED JOBS",
                                    sub_title : "",
                                    icon : "inventory_2",
                                    value : context.state.details.total_compiled_jobs,
                                    link : "/jobs/complied/paid",
                                },
                                {
                                    color : "warning",
                                    title : "REVENUE",
                                    sub_title : "",
                                    icon : "sell",
                                    value : moneyFormat(context.state.details.total_ftm_charges),
                                    link : "/ftm/revenue",
                                },
                                {
                                    color : "danger",
                                    title : "COMMISSION (PER DAY)",
                                    sub_title : "",
                                    icon : "paid",
                                    value: context.state.details.company_commission,
                                    link : "/all/payments",
                                }
                            ].map((item, index) => (
                                <Stats
                                    key={index}
                                    color={item.color}
                                    title={item.title}
                                    sub_title={item.sub_title}
                                    icon={item.icon}
                                    value={item.value}
                                    link={item.link}
                                />
                            ))}
                        </div>

                        <div className="row mt-4">
                            <div className="col-lg-8">
                                <Map /> 
                            </div>
                            <div className="col-lg-4 mt-lg-0 mt-4">
                                {runningJobs}
                            </div>
                        </div>

                        <div className="container-fluid py-4">
                            <div className="row">
                                {[
                                    {
                                        title : "LATEST COMPLETED JOBS",
                                        jobs : context.state.details.today_complied_jobs,
                                        link : "/jobs/complied/paid",
                                        btn_name : "COMPLETED JOBS",
                                    }, 
                                    {
                                        title : "LATEST ONGOING JOBS",
                                        jobs : context.state.details.today_complied_jobs,
                                        link : "/jobs/ongoing",
                                        btn_name : "ONGOING JOBS",
                                    }
                                ].map((item, index) => (
                                    <Box
                                        key={index}
                                        title={item.title}
                                        jobs={item.jobs}
                                        link={item.link}
                                        btn_name={item.btn_name}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </Template>
            ): (<Loader/>)}
        </>
    );
}

export default Html;