import React from "react";
import { theme } from "../../../../theme";
import { formatEtaTime } from "../../../../utils";
import ImageGallery from "./imageGallery";

const Left = ({message}) => {
    return (
        <div className="msg left-msg">
            {/* <div
                className="msg-img"
                style={{backgroundImage: 'url(/assets/img/lock_user.png)'}}
            /> */}

            <div 
                className="msg-bubble" 
                style={{
                    backgroundColor: message.message_sender?.user_type === "FTM" ? theme.left_msg_bg_color_ftm : theme.left_msg_bg_color_cus,
                    color: message.message_sender?.user_type === "FTM" ? theme.right_msg_text_color : theme.left_msg_text_color_cus
                }}
            >
                <div className="msg-info">
                    <div className="msg-info-name">{message.message_sender?.user_name} ({message.message_sender?.user_type})</div>
                    <div className="msg-info-time">{formatEtaTime(message?.message_time)}</div>
                </div>

                <div className="msg-text">
                    {message.message_content?.text}
                    {message.message_content?.files.length > 0 ? <ImageGallery images = {message.message_content?.files}/> : null}
                </div>
            </div>
        </div>
    );
}

export default Left;