export const initialState = {
    is_loading: true,
    is_loading_btn: false,
    messages_list: [],
    images: [],
    dispute_is_resolved: false,
    is_open_refund_job_payment_modal: false,
    refund_type: '',
    processing_refund: false,
    job_payment_refunded: false
}