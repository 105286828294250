import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import { Container } from 'react-bootstrap';
import Context from '../context';
import CertificateTypes from '../../certificateTypes';
import ModalTemplate from '../../../components/modal/modalTemplate';

const ParentCertificateTypes = () => {
    const context = useContext(Context);  

    return (
        <>
            <ModalTemplate
                show={context.state.is_show_certificate_types}
                closeFun={() => context.dispatch({type: 'IS_SHOW_CERTIFICATE_TYPES', is_show_certificate_types: false, selected_skills: {}})}
                size = {null}
                centered = {false}
                title = {null}
                body = {
                    <Container>
                        <Box sx={{ m: 2 }}>
                            <CertificateTypes
                                skill= {{
                                    skill_id: context.state.selected_skills.skill_id,
                                    skill_name: context.state.selected_skills.skill_name
                                }}
                                key = {null}
                                type = {0}
                            />
                        </Box>
                    </Container>
                }
                footer = {null}
            />
        </>
    );
};

export default ParentCertificateTypes;
