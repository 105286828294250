import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Pagination, Stack } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useNavigate} from "react-router-dom";

import { formatEtaTime, truncateText } from "../../utils";
import { theme } from "../../theme";
import DivSpinner from "../../components/divSpinner";
import Search from "../../components/searchField";
import { Context } from "../provider";
import RunningJobModal from "./runningJobModal";

const RunningJobs = () => {
    const context = useContext(Context);
    let navigate = useNavigate();

    useEffect(() => {
        context.getAllRunningJobs();
    }, [context.state.running_jobs_current_page, context.state.running_jobs_search_value]);

    return (
        <div className="card h-100">
            <div className="card-header pb-0 p-3">
                <div className="d-flex justify-content-between">
                    <h6 className="mb-0">BOOKING TIME SLOT</h6>
                    <Search
                        onSearch={(search_text) => {
                            context.dispatch({
                                type: "SET_RUNNING_JOBS_SERACH_VALUE",
                                running_jobs_search_value: search_text
                            });
                        }}
                        width={'250px'}
                    />
                </div>
            </div>
            <div className="card-body p-3">
                {context.state.is_loading_running_jobs ? (
                    <div style={{ height: `${window.innerHeight - 651}px` }}>
                        <DivSpinner />
                    </div>
                ) : (
                    <ul
                        className="list-group list-group-flush list my--3"
                        style={{
                            height: '310px',
                            overflow: 'auto',
                            overflowX: 'hidden'
                        }}
                    >
                        {context.state.running_jobs?.map((item, index) => (
                            <li className="list-group-item px-0 border-0" key={index}>
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <Link to={`jobs/view/${item.job_id}`}>
                                            #{item.job_id}
                                        </Link>
                                    </div>
                                    <div className="col">
                                        <p className="text-xs font-weight-bold mb-0">CUSTOMER:</p>
                                        <h6 className="text-sm font-weight-normal mb-0">{truncateText(item.customer.customer_full_name, 12)}</h6>
                                    </div>
                                    <div className="col text-center">
                                        <p className="text-xs font-weight-bold mb-0">ETA START:</p>
                                        <h6 className="text-sm font-weight-normal mb-0">{formatEtaTime(item.job_eta_start_time)}</h6>
                                    </div>
                                    <div className="col text-center">
                                        <p className="text-xs font-weight-bold mb-0">ETA END:</p>
                                        <h6 className="text-sm font-weight-normal mb-0">{formatEtaTime(item.job_eta_end_time)}</h6>
                                    </div>
                                    <div className="col text-center">
                                        <p className="text-xs font-weight-bold mb-0"></p>
                                        <h6 className="text-sm font-weight-normal mb-0">
                                            <VisibilityIcon
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => context.handleShowRunningJobsDetailsModal(item)}
                                            />
                                        </h6>
                                    </div>
                                </div>
                                <hr className="horizontal dark mt-3 mb-1" />
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            <div className="card-footer">
                {!context.state.is_loading_running_jobs && (
                    <div className="row">
                        <div className="col-9 d-flex align-items-center">
                            <Stack
                                spacing={2}
                                className="mb-3"
                                style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                <Pagination
                                    count={context.state.running_jobs_total_pages}
                                    page={context.state.running_jobs_current_page}
                                    onChange={(event, newPage) => context.dispatch({
                                        type: "UPDATE_RUNNING_JOBS_CURRENT_PAGE",
                                        running_jobs_current_page: newPage
                                    })}
                                    color={theme.main}
                                />
                            </Stack>
                        </div>
                        <div className="col-3 text-end">
                            <button 
                                className={`btn btn-${theme.redirect_btn} btn-sm mb-0`}
                                onClick={() => navigate(`/running/jobs/schedule`)} 
                            >View All</button>
                        </div>
                    </div>
                )}
            </div>

            <RunningJobModal />
        </div>
    );
}

export default RunningJobs;