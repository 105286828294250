import React, { useContext } from "react";

import Context from "../../context";
import { getStatusColor, getStatusText } from "../../service";

const Profile = () => {
    const context = useContext(Context);

    return(
        <div className="col-lg-12 col-sm-6 mb-xl-0 mb-4">
            <div className="card">
                <div className="card-header p-3 pt-2">
                    <div className="icon icon-lg icon-shape text-center border-radius-xl mt-n4 position-absolute">
                        <img 
                            src={context.state.selected_ftm?.ftm?.ftm_profile_image_path || "/assets/img/lock_user.png"} 
                            className="avatar avatar-lg  border-radius-lg" 
                            alt="user1"
                        />
                    </div>
                    <div className="text-end pt-1">
                        <span 
                            className={`badge badge-sm bg-gradient-${getStatusColor(context.state.selected_ftm?.ftm?.ftm_is_new, context.state.selected_ftm?.ftm?.ftm_is_verify)}`}
                        >
                            {getStatusText(context.state.selected_ftm?.ftm?.ftm_is_new, context.state.selected_ftm?.ftm?.ftm_is_verify)}
                        </span>
                        <h4 className="mb-0">
                            {context.state.selected_ftm?.ftm?.ftm_forename} {context.state.selected_ftm?.ftm?.ftm_surname}
                        </h4>
                    </div>
                </div>
                <hr className="dark horizontal my-0"/>
                <div className="card-footer p-3">
                    <p className="mb-0">
                        <span className="text-dark text-sm font-weight-bolder">#TRADESPERSON </span>{context.state.selected_ftm?.ftm?.ftm_id}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Profile;