import React, { useContext, useEffect } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';
import InventoryIcon from '@mui/icons-material/Inventory';
import PersonIcon from '@mui/icons-material/Person';
import Grid from '@mui/material/Grid';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Button from '@mui/material/Button';
import { getFtmPaymentPDF, getPaymentHistory } from "../service";
import Context from "../context";
import DivSpinner from "../../../components/divSpinner";
import Box from "./box";
import { theme } from '../../../theme';
import JobBox from "./jobBox";
import BtnSpinner from "../../../components/btnSpinner";
import OffcanvasTemplate from "../../../components/offcanvasTemplate";

const History = () => {
    const context = useContext(Context);

    useEffect(() => {
        getPaymentHistory(context.state.selected_history?.ftm_payment_history_id, context.dispatch);
    }, [context.state.selected_history?.ftm_payment_history_id]); 

    return(
        <OffcanvasTemplate
            open={context.state.is_show_history} 
            handleClose = {() => context.dispatch({type: 'CLOSE_HISTORY'})} 
            placement = {'end'}  
            title = {`PAYMENTS`}
            body = {context.state.is_loading_history ? (
                <DivSpinner/>
            ) : (
                <>
                    <Box
                        icon = {<PersonIcon/>}
                        primary = {<b>PAY BY</b>}
                        secondary = {context.state.history?.admin_user?.admin_full_name}
                        color={null}
                    />

                    <Grid container spacing={2} sx={{mt: 1}}>
                        <Grid item xs={12}>
                            <Box
                                icon = {<FileUploadIcon/>}
                                primary = {<b>{theme.currency+context.state.history?.ftm_payment_history_total}</b>}
                                secondary = "Total payment"
                                color={theme.ftm_pay}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                icon = {<FileDownloadIcon/>}
                                primary = {<b>{theme.currency+context.state.history?.ftm_payment_history_total_penalty}</b>}
                                secondary = "Total penalty"
                                color={theme.ftm_penalty}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                icon = {<InventoryIcon/>}
                                primary = {<b>{theme.currency+context.state.history?.ftm_payment_history_total_job_cancel_charge}</b>}
                                secondary = "Total cancel charges"
                                color={theme.ftm_job_cancel_charges}
                            />
                        </Grid>
                    </Grid>

                    <Grid sx={{mt: 3}}>
                        <ul className="list-group">
                            {context.state.history?.ftm_payment_histories_jobs_lists.map((history, index) => (
                                <JobBox
                                    job_id = {history.ftm_payment_history_jobs_list_job_id}
                                    job = {history.job}
                                    job_value = {history.job_value}
                                    type = {history.ftm_payment_history_jobs_list_type}
                                />
                            ))}
                        </ul>
                    </Grid>
                </>
            )}
            footer = {
                <div className="container">
                    <div className="row" style={{marginTop: '10px'}}>
                        <div className="col">
                            <div className="d-grid gap-2 mb-3" >
                                <Button 
                                    variant="contained" 
                                    fullWidth 
                                    color={theme.main}
                                    disabled={context.state.is_btn_loading}
                                    onClick={()=> getFtmPaymentPDF(context.state.selected_history?.ftm_payment_history_id, context.dispatch)}
                                >
                                    {context.state.is_btn_loading ? (
                                        <BtnSpinner/>
                                    ): "GENERATE PDF"}
                                </Button>
                            </div>          
                        </div>
                    </div>
                </div>
            }
        />
    );
}

export default History