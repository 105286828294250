import React, { useContext } from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

import PostcodeSearch from './postcodeSearch';
import { Context } from '../provider';
import { CircularProgress } from '@mui/material';
import { theme } from '../../../../theme';

const PersonalInformation = ({ register, reset, errors, setValue, control}) => {
    const context = useContext(Context);

    return (
        <>
            <div className="row">
                    <div className="col-6">
                        <FormControl error={!!errors.ftm_forename} fullWidth>
                            <label><b>Forename</b></label>
                            <TextField
                                error={!!errors.ftm_forename}
                                placeholder="Forename"
                                size="small"
                                type="text"
                                defaultValue={context.state.ftm?.ftm_forename || ''}
                                {...register("ftm_forename", { required: 'Forename is required' })}
                            />
                            {errors.ftm_forename && <FormHelperText>{errors.ftm_forename.message}</FormHelperText>}
                        </FormControl>
                    </div>
                    <div className="col-6">
                        <FormControl error={!!errors.ftm_surname} fullWidth>
                            <label><b>Surname</b></label>
                            <TextField
                                error={!!errors.ftm_surname}
                                placeholder="Surname"
                                size="small"
                                type="text"
                                defaultValue={context.state.ftm?.ftm_surname || ''}
                                {...register("ftm_surname", { required: 'Surname is required' })}
                            />
                            {errors.ftm_surname && <FormHelperText>{errors.ftm_surname.message}</FormHelperText>}
                        </FormControl>
                    </div>
                </div>

                <div className="row mt-3 mb-3">
                    <div className={`col-${context.state.is_loading_address_detail ? 11 : 12}`}>
                        <label><b>Enter your postcode</b></label>
                        <PostcodeSearch setValue={setValue}/>
                    </div>

                    {context.state.is_loading_address_detail && (
                        <div className="col-1">
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: theme.main,
                                    marginTop: '40px'
                                }}
                            />
                        </div>
                    )}
                </div>

                <div className="row">
                    <div className="col-4">
                        <FormControl error={!!errors.street_address} fullWidth>
                            <label><b>Street address</b></label>
                            <TextField
                                error={!!errors.street_address}
                                placeholder="Street address"
                                size="small"
                                type="text"
                                name="street_address"
                                defaultValue={context.state.ftm?.ftm_street_address}
                                {...register("street_address", { required: 'Street address is required' })}
                            />
                            {errors.street_address && <FormHelperText>{errors.street_address.message}</FormHelperText>}
                        </FormControl>
                    </div>
                    <div className="col-4">
                        <FormControl error={!!errors.city} fullWidth>
                            <label><b>City</b></label>
                            <TextField
                                error={!!errors.city}
                                placeholder="City"
                                size="small"
                                type="text"
                                name="city"
                                defaultValue={context.state.ftm?.ftm_place}
                                {...register("city", { required: 'City is required' })}
                            />
                            {errors.city && <FormHelperText>{errors.city.message}</FormHelperText>}
                        </FormControl>
                    </div>
                    <div className="col-4">
                        <FormControl error={!!errors.postcode} fullWidth>
                            <label><b>Postcode</b></label>
                            <TextField
                                error={!!errors.postcode}
                                placeholder="Postcode"
                                size="small"
                                type="text"
                                name="postcode"
                                defaultValue={context.state.ftm?.ftm_postcode}
                                {...register("postcode", { required: 'Postcode is required' })}
                            />
                            {errors.postcode && <FormHelperText>{errors.postcode.message}</FormHelperText>}
                        </FormControl>
                    </div>
                </div>

                <div className="row mt-3 mb-3">
                    <div className="col-6">
                        <FormControl error={!!errors.ftm_email} fullWidth>
                            <label><b>Email</b></label>
                            <TextField
                                error={!!errors.ftm_email}
                                placeholder="Email"
                                size="small"
                                type="email"
                                defaultValue={context.state.ftm?.ftm_email || ''}
                                {...register("ftm_email", { required: 'Email is required' })}
                            />
                            {errors.ftm_email && <FormHelperText>{errors.ftm_email.message}</FormHelperText>}
                        </FormControl>
                    </div>
                    <div className="col-6">
                    <FormControl error={!!errors.ftm_contact_number} fullWidth>
                        <label><b>Phone number</b></label>
                        <Controller
                            name="ftm_contact_number"
                            control={control}
                            defaultValue={context.state.ftm?.ftm_contact_number || ''} 
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    placeholder="Phone number"
                                    size="small"
                                    type="text"
                                    error={!!errors.ftm_contact_number}
                                    InputProps={{
                                        inputComponent: InputMask,
                                        inputProps: {
                                            mask: "+44 799 999 9999",
                                            maskChar: ""
                                        }
                                    }}
                                />
                            )}
                        />
                        {errors.ftm_contact_number && <FormHelperText>{errors.ftm_contact_number.message}</FormHelperText>}
                    </FormControl>

                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <FormControl error={!!errors.ftm_birth_of_date} fullWidth>
                            <label><b>Date of Birth</b></label>
                            <TextField
                                error={!!errors.ftm_birth_of_date}
                                placeholder="Date of Birth"
                                size="small"
                                type="date"
                                defaultValue={context.state.ftm?.ftm_birth_of_date || ''}
                                {...register("ftm_birth_of_date", { required: 'Date of Birth is required' })}
                            />
                            {errors.ftm_birth_of_date && <FormHelperText>{errors.ftm_birth_of_date.message}</FormHelperText>}
                        </FormControl>
                    </div>
                    <div className="col-6">
                        <FormControl error={!!errors.ftm_gender} fullWidth>
                            <label><b>Gender</b></label>
                            <TextField
                                error={!!errors.ftm_gender}
                                size="small"
                                select
                                defaultValue={context.state.ftm?.ftm_gender}
                                {...register("ftm_gender", { required: 'Gender is required' })}
                            >
                                <MenuItem value={0}>Male</MenuItem>
                                <MenuItem value={1}>Female</MenuItem>
                                <MenuItem value={2}>Non-binary</MenuItem>
                            </TextField>
                            {errors.ftm_gender && <FormHelperText>{errors.ftm_gender.message}</FormHelperText>}
                        </FormControl>
                    </div>
                </div>
        </>
    )
}

export default PersonalInformation;