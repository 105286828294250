import React, { useContext } from "react";
import Template from "../../components/template";
import Context from "./context";
import CustomerInfo from "./components/customerInfo";
import ProfileImage from "./components/profileImage";
import CardDetails from "./components/cardDetails";
import JobTable from "./components/jobList/jobTable";
import AddNewCard from "./components/addNewCard";
import CustomerMap from "./components/customerMap";
import Chat from "../../components/userChat/chat";
import Btn from "./components/btn";

const Html = () => {
    const context = useContext(Context);

    return(
        <Template>
            <div className="container-fluid">
                <CustomerMap/>
                <div className="card card-body mx-3 mx-md-4 mt-n6">
                    <div className="row gx-4 mb-2">
                        <ProfileImage/>
                        <Btn/>
                    </div>
                    <div className="row">
                        <div className="col-md-5 mb-md-0 mb-4">
                            <div className="card card-body h-100">
                                <CustomerInfo/>
                                <CardDetails/>
                            </div>
                        </div>
                        <div className="col-md-7 mb-md-0 mb-4">
                            <JobTable
                                jobs = {context.state.customer_jobs}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <AddNewCard/>

            <Chat
                user_name = {context.state.customer.customer_full_name}
                user_firebase_document_id = {context.state.customer.customer_firebase_document_id}
                show = {context.state.is_show_chat}
                handleClose = {() => context.dispatch({
                    type: 'IS_SHOW_CHAT',
                    is_show_chat: false
                })}
            />
        </Template>
    )
}

export default Html;