export const setUserInfo = (info) => {
    localStorage.setItem("userInfo", JSON.stringify(info));
}

export const getUserInfo = () => {
    let info = JSON.parse(localStorage.getItem("userInfo"));
    return info;
}

export const removeUserInfo = () => {
    localStorage.removeItem("userInfo");
}

export const setIsShowFullSidebar = (is_show) => {
    localStorage.setItem("isShowFullSidebar", is_show);
}

export const getIsShowFullSidebar = () => {
    let info = JSON.parse(localStorage.getItem("isShowFullSidebar"));
    return info;
}