import React, { useEffect, useReducer } from "react";
import { useLocation } from 'react-router-dom';

import Html from "./html";
import Context from "./context";
import Reducer from "./reducer";
import { initialState } from "./initialState";
import { getNotifications } from "./service";
import JobChat from "../jobs/components/chat";
import DisputesChat from "../jobs/disputes/chat";
import UserChat from "../components/userChat/chat";

const Notifications = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type');

    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        getNotifications({
            page_number: state.current_page, 
            dispatch: dispatch,
            search_value: type !== null ?  type : "NEW_DIRECT_MESSAGE"
        });
    }, [state.current_page]); 

    useEffect(() => {
        dispatch({
            type: 'SET_SEARCH_VALUE',
            search_value: type
        });
    }, [type]);

    return(
        <Context.Provider value={{state, dispatch}}>
            <Html/>

            <JobChat
                job_id = {state.job_id}
                job_firebase_document_id = {state.job_firebase_document_id}
                show = {state.is_show_job_chat}
                handleClose = {() => dispatch({
                    type: 'CLOSE_SHOW_JOB_CHAT'
                })}
            />

            <DisputesChat
                show = {state.is_show_disput_chat}
                dispute = {state.dispute_firebase}
                handleClose = {() => dispatch({
                    type: 'CLOSE_SHOW_DISPUT_CHAT'
                })}
            />

            <UserChat
                user_name = {"TRADESPERSONS CHAT"}
                user_firebase_document_id = {state?.office_chat_ftm?.doc_id}
                show = {state.is_show_Office_chat}
                handleClose = {() => dispatch({
                    type: 'CLOSE_SHOW_OFFICE_CHAT'
                })}
            />
        </Context.Provider>
    );
}

export default Notifications;