/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext } from "react";
import Badge from '@mui/material/Badge';

import Context from "../context";
import { fullDateFormat, truncateText } from "../../../../utils";
import { openChatView } from "../service";
import { theme } from "../../../../theme";
import useWindowDimensions from "../../../../components/hooks/useWindowDimensions";

export const DisputesList = () => {
    const context = useContext(Context);
    const { width } = useWindowDimensions();

    return (
        <div className={`col-md-5 col-lg-${Object.keys(context.state.selected_dispute).length > 0 ? width > 1320 ? 3 : 5 : 11}`} style={{marginTop: '-30px'}}>
            <div className="card blur shadow-blur max-height-vh-70 overflow-auto overflow-x-hidden mb-5 mb-lg-0 h-100">
                <div className="card-body p-2">
                    {context.state.disputes.map((dispute, index) => (
                        <a 
                            href="#" 
                            className={`d-block p-2 border-radius-lg dispute-item ${dispute.id == context.state.selected_dispute?.id && `bg-gradient-${theme.main}`}`} 
                            onClick = {() => openChatView(dispute, context.dispatch)} 
                            key={index}
                        >
                            <div className="d-flex p-2">
                                {/* <img alt="Image" src="../../../assets/img/413039.png" className="avatar " /> */}
                                <div className="ms-3">
                                    <Badge badgeContent={dispute.dispute_admin_unread_message_count} color={theme.sidebar_notification_count_color}>
                                        <h6 className={`mb-0 ${dispute.id == context.state.selected_dispute?.id ? 'text-white' : 'text-muted'}`}>
                                            {Object.keys(context.state.selected_dispute).length > 0 ? truncateText(dispute?.dispute_details?.type, 25) : dispute?.dispute_details?.type}
                                        </h6>
                                        {dispute.dispute_is_resolved && <i className="material-icons" style={{color: '#4CAF50'}}>check_circle</i>} 
                                    </Badge>
                                    <p className={`text-xs mb-2 ${dispute.id == context.state.selected_dispute?.id ? 'text-white' : 'text-muted'}`}>
                                        {fullDateFormat(dispute?.dispute_details?.createAt)}
                                    </p>
                                    <span className={`text-sm p-0 ${Object.keys(context.state.selected_dispute).length > 0 && 'col-11 text-truncate'} d-block ${dispute.id == context.state.selected_dispute?.id ? 'text-white' : 'text-muted'}`}>
                                        {Object.keys(context.state.selected_dispute).length > 0 ? truncateText(dispute?.dispute_job?.address, 30) : dispute?.dispute_job?.address}
                                    </span>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
}