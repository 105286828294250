import React, { useContext } from "react";
import Template from "../../components/template";
import CardWithHeaderTemplate from "../../components/cardWithHeaderTemplate";
import HeaderView from "./components/headerView";
import List from "./components/list";
import PaginationView from "./components/paginationView";
import History from "./components/history";
import Context from "./context";

const Html = () => {
    const context = useContext(Context);

    return(
        <Template>
            <div className="container-fluid py-4">
                <div className="row">
                    <div className="col-12">
                        <CardWithHeaderTemplate
                            header={<HeaderView/>} 
                            body={<List/>} 
                            footer={<PaginationView/>}
                        />
                    </div>
                </div>
            </div>

            <History
                history_id = {context.state.selected_history?.ftm_payment_history_id}
                show = {context.state.is_show_history}
                handleClose = {() => context.dispatch({type: 'CLOSE_HISTORY'})}
            />
        </Template>
    );
}

export default Html;