import { useNavigate } from 'react-router-dom';

const useCustomNavigate = () => {
    const navigate = useNavigate();

    const navigateToHome = () => {
        navigate('/');
    };

    const navigateBack = () => {
        navigate(-1);
    };

    return {
        navigateToHome,
        navigateBack
    };
};

export default useCustomNavigate;
