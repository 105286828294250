import { GET } from "../../../API/get";

export const getLogs = async (ftm_id, page_number, search_text, dispatch) => {
    dispatch({ type: "IS_LOADING" });
    let response = await GET("get-ftms-logs-list/"+page_number+"?ftm_id="+ftm_id+"&search_text="+search_text);

    if(response.status === "Success"){
        dispatch({
            type: 'IS_LOADED',
            total_pages: response.total_pages, 
            logs_list: response.list, 
            current_page: page_number,
            search_text: search_text
        });
    }
}