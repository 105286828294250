export const initialState = {
    is_loading: false,
    search_type: 1,
    anchor_el: null,
    selector: 'ALL',
    search_text: '',
    list : [],
    search_option: '',
    search_option_list: [
        {
            type: "ALL_JOBS",
            label: "All jobs",
            is_checked: false
        },
        {
            type: "ONGOING_JOBS",
            label: "Ongoing jobs",
            is_checked: false
        },
        {
            type: "COMPLETED_JOBS",
            label: "Completed jobs",
            is_checked: false
        },
        {
            type: "NOT_ATTENDED_JOBS",
            label: "Not attended jobs",
            is_checked: false
        }
    ]
}