import React, { useContext } from "react";

import Context from "../context";
import { textReplace } from "../../../utils";

const Notification = () => {
    const context = useContext(Context);

    return (
        <div className="card mb-4 mt-4 mt-lg-0" style={{backgroundColor: context.state.job_details?.job_status_text.job_status_color, cursor: "pointer"}} onClick={() => context.dispatch({type: 'IS_SHOW_JOB_TIMELINE', is_show_job_timeline: true})} >
            <div className="card-body p-3">
                <div className="row">
                    <div className="col-8 my-auto">
                        <div className="numbers">
                            <p className="text-white text-sm mb-0 text-capitalize font-weight-bold opacity-7">CURRNT JOB STATUS</p>
                            <h5 className="text-white font-weight-bolder mb-0">
                                {textReplace({
                                    text : context.state.job_details?.job_status_text.job_status_name, 
                                    search: "[[FTM]]", 
                                    replace: context.state?.ftms?.ftm_forename+" "+context.state?.ftms?.ftm_surname
                                })}
                            </h5>
                        </div>
                    </div>
                    <div className="col-4 text-end">
                        <i className="material-icons text-white opacity-10" style={{fontSize: '45px'}}>
                            {context.state.job_details?.job_status_text.job_status_icon}
                        </i> 
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Notification;