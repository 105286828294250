import React, { useContext } from 'react';

import moment from "moment";

import Context from '../context';
import WithCommissions from './invoices/withCommissions';
import WithoutCommissions from './invoices/withoutCommissions';

const InvoiceInfo = () => {
    const context = useContext(Context);

    return (
        <>
            <div className="container-fluid my-3 py-3">
                <div className="row">
                <div className="col-md-12">
              <div className="invoice">
                 <div className="invoice-company text-inverse f-w-600">
                    <img src="http://185.239.208.29:8000/system-images/serviceteam_logo.png" style={{width: '30%'}} alt=""/>
                 </div>
                 <div className="invoice-header">
                    <div className="invoice-from">
                       <small>From</small>
                       <address className="m-t-5 m-b-5">
                          <strong className="text-inverse">{context.state.invoice_info.company_settings.company_name}.</strong> <br/>
                          <span style={{fontSize: '14px'}}>
                            {context.state.invoice_info.company_settings.company_address}<br></br>
                            Phone: {context.state.invoice_info.company_settings.company_contact_number}<br/>
                            Email: {context.state.invoice_info.company_settings.company_email}<br/>
                          </span>
                       </address>
                    </div>
                    <div className="invoice-to">
                       <small>To</small>
                       <address className="m-t-5 m-b-5">
                          <strong className="text-inverse">{context.state.invoice_info.customer.customer_full_name}</strong><br/>
                          <span style={{fontSize: '14px'}}>
                            Phone: {context.state.invoice_info.customer.customer_contact_number}<br/>
                            Email: {context.state.invoice_info.customer.customer_email}
                          </span>
                       </address>
                    </div>
                    <div className="invoice-date">
                       <small>Invoice</small>
                       <div className="date text-inverse m-t-5">{moment(context.state.invoice_info.job_createdAt).format("YYYY-MM-DD HH:mm:ss")}</div>
                       <div className="invoice-detail" style={{fontSize: '14px'}}>
                          #INV{context.state.invoice_info.job_id}<br/>
                          Services Product
                       </div>
                    </div>
                 </div>

                 <div className="invoice-content">
                    {context.state.invoice_type == 'with_commissions' ? (
                        <WithCommissions />
                    ) : (
                        <WithoutCommissions />
                    )}
                 </div>
                 <div className="invoice-note" style={{fontSize: '12px'}}>
                    * Make all cheques payable to <br/>
                    * Payment is due within 30 days<br/>
                    * If you have any questions concerning this invoice, contact  [Info]
                 </div>

                 <div className="invoice-footer">
                    <p className="text-center m-b-5 f-w-600">
                       THANK YOU FOR YOUR BUSINESS
                    </p>
                    <p className="text-center" style={{fontSize: '12px'}}>
                       <span className="m-r-10"><i className="fa fa-fw fa-lg fa-globe"></i> serviceteam.co.uk</span>
                       <span className="m-r-10"><i className="fa fa-fw fa-lg fa-phone-volume"></i> T:{context.state.invoice_info.company_settings.company_contact_number}</span>
                       <span className="m-r-10"><i className="fa fa-fw fa-lg fa-envelope"></i> {context.state.invoice_info.company_settings.company_email}</span>
                    </p>
                 </div>
              </div>
           </div>
        </div>
    </div>
        </>
    );
}

export default InvoiceInfo;