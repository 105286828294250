import React, { useContext } from "react";

import AttachFileIcon from '@mui/icons-material/AttachFile';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import ArticleIcon from '@mui/icons-material/Article';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import VisibilityIcon from '@mui/icons-material/Visibility';

import Template from "../../components/template";
import Profile from "./components/profile";
import AddSkills from "./components/addSkills";
import HolidaysCalendar from "./components/holidaysCalendar";
import Logs from "../logs/each";
import Context from "./context";
import EachFtmNotes from "../note/each";
import Chat from "../../components/userChat/chat";
import FtmAttachments from "../components/attachments";
import EditFtm from "../components/editFtm";
import SpeedDialBtn from "../../components/speedDialBtn";

const Html = () => {
    const context = useContext(Context);

    const speed_dial_actions = [
        {
            icon: <AttachFileIcon />, 
            name: 'ATTACHMENTS', 
            onClickFn: () => context.dispatch({
                type: 'IS_SHOW_FTM_ATTACHMENTS', 
                is_show_ftm_attachments: true
            })
        },
        {
            icon: <HolidayVillageIcon />, 
            name: 'HOLIDAYS', 
            onClickFn: () => context.dispatch({
                type: 'IS_OPEN_TRADESPERSON_HOLIDAYS', 
                is_open_tradesperson_holidays: true
            })
        },
        {
            icon: <ArticleIcon />, 
            name: 'LOGS', 
            onClickFn: () => context.dispatch({
                type: 'IS_OPEN_LOGS', 
                is_open_logs: true
            })
        },
        {
            icon: <DocumentScannerIcon />, 
            name: 'NOTES', 
            onClickFn: () => context.dispatch({
                type: 'IS_OPEN_FTM_NOTE', 
                is_open_ftm_note: true
            })
        }
    ];

    return(
        <Template
            children={
                <>
                    <Profile/>
                    <AddSkills/>
                    <HolidaysCalendar 
                        ftm_id = {context.ftm_id} 
                    />
                    
                    <Logs
                        ftm_id = {context.ftm_id}
                        open = {context.state.is_open_logs}
                        handleClose = {() => context.dispatch({
                            type: 'IS_OPEN_LOGS', 
                            is_open_logs: false
                        })}
                    />

                    <EachFtmNotes
                        ftm_id = {context.ftm_id}
                        open = {context.state.is_open_ftm_note}
                        handleClose = {() => context.dispatch({
                            type: 'IS_OPEN_FTM_NOTE', 
                            is_open_ftm_note: false
                        })}
                    />

                    <Chat
                        user_name = {context.state.ftm.ftm_forename+" "+context.state.ftm.ftm_surname}
                        user_firebase_document_id = {context.state.ftm.ftm_firebase_document_id}
                        show = {context.state.is_show_chat}
                        handleClose = {() => context.dispatch({
                            type: 'IS_SHOW_CHAT',
                            is_show_chat: false
                        })}
                    />

                    <FtmAttachments
                        ftm_id = {context.ftm_id}
                        open = {context.state.is_show_ftm_attachments}
                        handleClose = {() => context.dispatch({
                            type: 'IS_SHOW_FTM_ATTACHMENTS', 
                            is_show_ftm_attachments: false
                        })}
                    />

                    <EditFtm
                        ftm_id = {context.ftm_id}
                        open = {context.state.is_show_edit_ftm}
                        handleClose = {() => context.dispatch({
                            type: 'IS_SHOW_EDIT_FTM', 
                            is_show_edit_ftm: false
                        })}
                        handleSubmitClose = {() => context.dispatch({
                            type: 'SET_FTM_EDIT_COUNT', 
                            ftm_edit_count: context.state.ftm_edit_count+1
                        })}
                    />
                </>
            }
            footer = {
                <SpeedDialBtn
                    actions={speed_dial_actions}
                    icon={<VisibilityIcon />}
                />
            }
        />
    )
}

export default Html;