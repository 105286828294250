import React, { useContext } from "react";
import ModalTemplate from "../../../../components/modal/modalTemplate";
import { Context } from "../provider";
import FormSubmit from "./formSubmit";

const AddNewAttachment = () => {
    const context = useContext(Context);

    return(
        <ModalTemplate
            show = {context.state.is_show_add_attachment_modal}
            closeFun = {() => context.dispatch({
                type: "IS_SHOW_ADD_ATTACHMENT_MODAL",
                is_show_add_attachment_modal: false
            })}
            size = {null}
            centered = {true}
            title = {"ADD NEW ATTACHMENT"}
            body = {<FormSubmit/>}
            footer = {null}
        />
    );
}

export default AddNewAttachment;