import React, {useContext} from "react";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Badge from '@mui/material/Badge';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';

import Context from "../context";
import { fullDateFormat } from "../../../../utils";
import { theme } from "../../../../theme";
import { openChatView } from "../service";

const BoxView = ({index, dispute}) => {
    const context = useContext(Context);

    return(
        <Grid 
            item 
            xs={12} 
            md={6} 
            key={index}
            // onClick = {() => context.dispatch({
            //     type: 'IS_OPEN_CHAT',
            //     is_open_chat: true,
            //     selected_dispute: dispute
            // })}
            onClick = {() => openChatView(dispute, context.dispatch)}
        >
            <CardActionArea >
                <Card sx={{ display: 'flex', border: dispute.dispute_is_resolved ? '2px solid #4CAF50' : null }}>
                    <CardContent sx={{ flex: 1 }}>
                        <Badge badgeContent={dispute.dispute_admin_unread_message_count} color={theme.sidebar_notification_count_color}>
                            <Typography component="h2" variant="h5">
                                {dispute?.dispute_details?.type.slice(0, 450)}
                            </Typography>
                        </Badge>
                        <Typography variant="subtitle1" color="text.secondary">
                            {dispute?.dispute_details?.description}
                        </Typography>
                        <Typography variant="subtitle1" paragraph>
                            #{dispute?.dispute_job?.job_id} | <b>Created</b> : {fullDateFormat(dispute?.dispute_details?.createAt)} | <b>Address</b> : {dispute?.dispute_job?.address} | <b>Type</b> : {dispute?.dispute_job?.service_category?.skill?.name} - {dispute?.dispute_job?.service_category?.sub_skill?.name} 
                        </Typography>
                        <Typography variant="subtitle1" color={theme.main}>
                            CONTINUE CHATTING...
                        </Typography>
                    </CardContent>
                    {dispute.dispute_is_resolved && (
                        <div style={{ position: 'absolute', top: 8, right: 8 }}>
                            <CheckCircleSharpIcon style={{ color: 'green' }} />
                        </div>
                    )}
                    <CardMedia
                        component="img"
                        sx={{ width: 160, height: 195, display: { xs: 'none', sm: 'block' } }}
                        image={dispute?.dispute_details?.images?.length > 0 ? dispute?.dispute_details?.images[0]?.path : '/assets/img/no-image.jpg'}
                    />
                </Card>
            </CardActionArea>
        </Grid>
    );
}

export default BoxView;