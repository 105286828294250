import React, { useContext } from 'react';

import Context from '../../context';

const WithCommissions = () => {
    const context = useContext(Context);

    return (
        <>
        <div style={{paddingBottom: '10px'}}><b>FTM PAYMENTS</b></div>
        <div className="table-responsive">
            <table className="table table-invoice">
                <thead>
                    <tr style={{fontSize: '14px'}}>
                    <th width="20%">DESCRIPTION</th>
                    <th width="20%">TIME</th>
                    <th width="20%">RATE</th>
                    <th width="20%">COMPANY COMMISSION</th>
                    <th width="20%">SUB TOTAL</th>
                    </tr>
                </thead>
                <tbody>
                {context.state.invoice_info.job_value_calculator.ftm_time_diff > 0 ? (
                    <tr style={{fontSize: '12px'}}>
                        <td>
                            <span className="text-inverse">{context.state.invoice_info.ftms.ftm_forename} {context.state.invoice_info.ftms.ftm_surname}</span><br/>
                            <small>{context.state.invoice_info.skill.skill_name} - {context.state.invoice_info.skill.sub_skill.sub_skill_name}</small>
                        </td>
                        <td>{context.state.invoice_info.job_value_calculator.ftm_time_diff} min</td>
                        <td>&#163; {context.state.invoice_info.hourly_rate}</td>
                        <td>&#163; {context.state.invoice_info.job_value_calculator.company_commission}</td>
                        <td>&#163; {context.state.invoice_info.job_value_calculator.job_value}</td>
                    </tr>
                ) : (
                    null
                )}
                </tbody>
            </table>
        </div>
        {context.state.invoice_info.extra_payments.length > 0 ? (
            <>
                <div style={{paddingBottom: '10px'}}><b>EXTRA PAYMENTS</b></div>
                <div className="table-responsive">
                    <table className="table table-invoice">
                    <thead>
                        <tr style={{fontSize: '14px'}}>
                            <th width="20%">DESCRIPTION</th>
                            <th width="20%">ITEM</th>
                            <th width="10%">QTY</th>
                            <th width="10%">UNIT PRICE</th>
                            <th width="20%">COMPANY COMMISSION</th>
                            <th width="20%">SUB TOTAL</th>
                        </tr>
                    </thead>
                    <tbody>
                    {context.state.invoice_info.extra_payments.map((payment, index) => (
                        <tr key={index} style={{fontSize: '12px'}}>
                            <td>
                                <span className="text-inverse">{payment.extra_payment_description}</span><br/>
                            </td>
                            <td>{payment.extra_payment_item}</td>
                            <td>{payment.extra_payment_qty}</td>
                            <td>&#163; {payment.extra_payment_unit_price}</td>
                            <td>&#163; {payment.extra_payment_company_commission}</td>
                            <td>&#163; {payment.extra_payment_sub_total_with_commissions}</td>
                        </tr>
                    ))}
                    </tbody>
                    </table>
                </div>
                </>
        ) : (
            null
            )}
        <div className="invoice-price">
            <div className="invoice-price-left">
                <div className="invoice-price-row" style={{fontSize: '14px'}}>
                    <div className="sub-price">
                    <small>SUBTOTAL</small>
                    <span className="text-inverse">&#163; {context.state.invoice_info.job_value_calculator.final_total_value_without_vat}</span>
                    </div>
                    <div className="sub-price">
                    <i className="fa fa-plus text-muted"></i>
                    </div>
                    <div className="sub-price">
                    <small>TAX (%)</small>
                    <span className="text-inverse">&#163; {context.state.invoice_info.job_value_calculator.vat}</span>
                    </div>
                </div>
            </div>
            <div className="invoice-price-right" style={{fontSize: '15px'}}>
                <small>TOTAL</small> <span className="f-w-600">&#163; {context.state.invoice_info.job_value_calculator.final_total_value_with_vat}</span>
            </div>
        </div>
        </>
    );
}

export default WithCommissions;