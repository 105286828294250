import React, { useContext } from "react";

import Body from "./body";
import { Context } from "../provider";

const List = () => {
    const context = useContext(Context);

    return (
        <>
            {context.state.details.map((item, index) => (
                <React.Fragment key={index}>
                    <Body
                        ftm_request_description = {item?.ftm_request_description}
                        request_images = {item?.request_images}
                        extra_time_minute = {item?.extra_times?.extra_time_minute}
                        extra_time_payment = {item?.extra_time_payment}
                        materials = {item?.materials}
                        total_material_price = {item?.total_materials_payment}
                    />
                    <hr/>
                </React.Fragment>
            ))}
        </>      
    );
}

export default List;