import React, { useReducer, useEffect } from "react";
import Reducer from "./reducer";
import { initialState } from "./initialState";
import Html from "./html";
import Context from "./context";
import { useParams } from "react-router-dom";
import { getJobDetails } from "./service";
import Loader from "../../components/loading";

const JobView = () => {
    let { job_id } = useParams();
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        getJobDetails(job_id, dispatch);
    }, [job_id]);

    return (
        <Context.Provider value={{job_id, state, dispatch}}>
            {!state.is_loading ? <Html/> : <Loader/>}
        </Context.Provider>
    );
}

export default JobView;