import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import AddBoxIcon from '@mui/icons-material/AddBox';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

import Context from "../context";


const CollapseButton = ({key, type}) => {
    const context = React.useContext(Context);

    const toggleCertificateTypes = (key, is_open_certificate_types, dispatch) => {
        let newState = {...context.state};
        if(key === is_open_certificate_types) {
            newState.is_open_certificate_types = "";
        } else {
            newState.is_open_certificate_types = key;
        }

        dispatch({
            type: 'IS_OPEN_CERTIFICATE_TYPES',
            is_open_certificate_types: newState.is_open_certificate_types,
            certificate_types: context.state.certificate_types
        })
    };

    return (
        <Grid container alignItems="center">
            <Grid item xs>
                <Button 
                    onClick={() => toggleCertificateTypes(key, context.state.is_open_certificate_types, context.dispatch)}
                >
                    Certificate types {type === 1 && ((context.state.is_open_certificate_types === key) ? <ExpandLess /> : <ExpandMore />)}
                </Button>
            </Grid>
            <Grid item>
                <IconButton
                    className="cursor-pointer"
                    aria-label="add"
                    onClick={() => context.dispatch({
                        type: 'IS_SHOW_ADD_CERTIFICATE_TYPES', 
                        is_show_add_certificate_types: true
                    })}
                >
                    <AddBoxIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
}

export default CollapseButton;