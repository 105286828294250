import { useContext } from "react";
import Badge from '@mui/material/Badge';

import { Context } from "../provider";
import { theme } from "../../../theme";
import PleaseWait from "../../components/pleaseWait";

const UsersListBody = () => {
    const context = useContext(Context);

    return(
        <>
            {context.state.users.length > 0 ? (
                <>
                    {context.filteredUsers.map((user, index) => (
                        <div 
                            className="d-block p-2" 
                            key={index} 
                            onClick={() => context.handleChatLoading(user)}
                            style={{
                                cursor: 'pointer',
                                backgroundColor: context.state.selected_user?.doc_id === user?.doc_id && theme.main_code,
                                borderRadius: context.state.selected_user?.doc_id === user?.doc_id && '10px'
                            }}
                        >
                            <div className="d-flex p-2">
                                <Badge badgeContent={user?.user_total_office_admin_unread_message_count} color={theme.sidebar_notification_count_color}>
                                    <img
                                        alt="profile_image"
                                        src="/assets/img/lock_user-w.png"
                                        className="avatar shadow"
                                        style={{
                                            width: '40px',
                                            height: '40px'
                                        }}
                                    />
                                </Badge>
                                <div className="ms-3">
                                    <h6 
                                        className="mb-0"
                                        style={{color: context.state.selected_user?.doc_id === user?.doc_id && 'white'}}
                                    >
                                        {user.user_name}
                                    </h6>
                                    <span 
                                        className={`${context.state.selected_user?.doc_id === user?.doc_id ? 'text-white' : 'text-muted'} text-sm col-11 p-0 text-truncate d-block`}
                                    >
                                        {user.user_email}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </>  
            ) : (
                <PleaseWait/>
            )}  
        </>
    );
}

export default UsersListBody;