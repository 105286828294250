import React, { useEffect, useReducer } from "react";
import Reducer from "./reducer";
import Context from "./context";
import { initialState } from "./initialState";
import { getNotes } from "./service";
import Html from "./html";
import EditNote from "./components/editNote";

const EachFtmNotes = ({ftm_id, open, handleClose}) => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        getNotes(ftm_id, dispatch);
    }, []);

    return(
        <Context.Provider value={{state, dispatch}}>
            <Html
                ftm_id = {ftm_id}
                open = {open}
                handleClose = {handleClose}
            />
            <EditNote />
        </Context.Provider>
    );
}

export default EachFtmNotes;