import React, { createContext, useEffect, useReducer } from "react";
import { initialState } from "./initialState";
import Reducer from "./reducer";
import { fetchData } from "./service";

const Context = createContext();

const Provider = ({ children, job_id }) => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        const getData = async () => {
            try{
                let response = await fetchData({job_id});

                dispatch({ 
                    type: "SET_DETAILS", 
                    details: response.details
                });
            }catch(error){
                console.log(error);
            }
        };

        getData();
    }, [state.current_page, state.search_value]); 

    return (
        <Context.Provider value={{state, dispatch}}>
            {children}
        </Context.Provider>
    );
}

export { Context, Provider };