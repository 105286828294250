import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import Tooltip from '@mui/material/Tooltip';

import Context from "../context";
import DivSpinner from "../../../components/divSpinner";
import { truncateText } from "../../../utils";
import { cancelFTMHoliday } from "../service";
import HolidayStatuses from "./holidayStatuses";

const Table = () => {
    const context = useContext(Context);
    let navigate = useNavigate();

    return(
        <>
            {context.state.is_loading ? (
                <div style={{height : `${window.innerHeight-387}px`}}>
                    <DivSpinner/>
                </div>
            ) : (
                <div className="table-responsive p-0 m-3">
                    <table className="table align-items-center mb-0">
                        <thead>
                            <tr>
                                {[
                                    'Tradesperson', 
                                    'Start date', 
                                    'End date', 
                                    'Reason', 
                                    'Duration',
                                    'Status',
                                    'Cancellation note'
                                ].map((text, index) => (
                                    <th 
                                        key={index}
                                        className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                                    >
                                        {text}
                                    </th>
                                ))}
                                <th className="text-secondary opacity-7"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {context.state.holiday_list.length > 0 ? (
                                <>
                                    {context.state.holiday_list.map((holiday, index) => (
                                        <tr key={index}>
                                            {[
                                                holiday?.ftm?.ftm_forename + " " + holiday?.ftm?.ftm_surname,
                                                holiday?.ftm_holiday_start_date,
                                                holiday?.ftm_holiday_end_date,
                                                truncateText(holiday?.ftm_holiday_reason, 20)
                                            ].map((text, index) => (
                                                <td className="align-middle" key={index}>
                                                    <span className="text-secondary text-sm font-weight-bold">
                                                        {text}
                                                    </span>
                                                </td>
                                            ))}
                                            <td className="align-middle" style={{textAlign: 'center', width: '20px'}}>
                                                <span className="text-secondary text-sm font-weight-bold">
                                                    {holiday?.holiday_duration}
                                                    {holiday?.holiday_duration > 4 ? (
                                                    <i  className="material-icons font-weight-bold text-danger">
                                                        flag
                                                    </i>
                                                    ) : (
                                                        null
                                                    )}
                                                </span>
                                            </td>
                                            <td className="align-middle">
                                                <HolidayStatuses holiday={holiday} />
                                                {holiday.ftm_holiday_status == 1 ? (
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => cancelFTMHoliday(holiday.ftm_holiday_id, context.state.current_page, context.dispatch)}
                                                    >
                                                        <Tooltip title="Cancel Holiday Request" arrow>
                                                            <CancelIcon color="error" />
                                                        </Tooltip>
                                                    </IconButton>
                                                ) : (
                                                    null
                                                )}
                                            </td>
                                            <td className="align-middle" style={{textAlign: 'center', width: '20px'}}>
                                                {holiday.ftm_holiday_cancelled_note ? (
                                                    <span className="text-secondary text-sm font-weight-bold">
                                                        {truncateText(holiday.ftm_holiday_cancelled_note, 20)}
                                                    </span>
                                                ) : (
                                                    <span className="text-secondary text-sm font-weight-bold">
                                                        N/A
                                                    </span>
                                                )}
                                            </td>
                                            <td className="align-middle">
                                                <VisibilityIcon 
                                                    style={{cursor: 'pointer'}}
                                                    onClick={() => navigate(`/ftm/view/${holiday?.ftm_holiday_ftm_id}`)} 
                                                />
                                            </td> 
                                        </tr>
                                    ))}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan="6">Sorry, no data found!</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
}

export default Table;