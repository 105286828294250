import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import ChatImageUpload from "./chatImageUpload";
import Context from "../context";
import { sendTextMessages } from "../service";
import { theme } from "../../../../theme";

const FormSubmit = () => {
    const {
        register,
        handleSubmit,
        reset
    } = useForm();

    const context = useContext(Context);

    return (
        // <div className="offcanvas-footer">
        //     <div className="container">
        //         <div className="row" style={{marginTop: '10px'}}>
        //             <div className="col">
        //                 <div className="d-grid gap-2 mb-3" >
        //                     <form 
        //                         className="msger-inputarea" 
        //                         onSubmit={handleSubmit((data) => sendTextMessages(data, context.job_firebase_document_id, reset))}
        //                     >
        //                         <input 
        //                             type="text" 
        //                             name="text" 
        //                             className="msger-input" 
        //                             placeholder="Enter your message..."
        //                             {...register("text", { required: true })}
        //                         />
        //                         <ChatImageUpload/>
        //                         <button type="submit" className="msger-send-btn">Send</button>
        //                     </form>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>

        <form className="align-items-center" onSubmit={handleSubmit((data) => sendTextMessages(data, context.job_firebase_document_id, reset))}>
            <div className="input-group input-group-outline d-flex">
                <input 
                    type="text" 
                    name="text" 
                    className="form-control form-control-lg"
                    placeholder="Type your message"
                    {...register("text", { required: true })}
                />
                <ChatImageUpload/>
                <button className={`btn bg-gradient-${theme.main} mb-0`}>
                    <i className="material-icons">send</i>
                </button>
            </div>
        </form>
    );
}

export default FormSubmit;