/* eslint-disable jsx-a11y/alt-text */
import React, {useContext} from "react";
import Context from "../context";

const Avatar = () => {
    const context = useContext(Context);

    return(
        <div className="card card-body mb-4">
            <div className="row">
                <div className="col-lg-3 my-auto">
                    <img className="avatar avatar-xxl border-radius-lg mt-n5" src="assets/img/lock_user.png"/>
                </div>
                <div className="col-lg-9">
                    <h5 className="mb-2">{context.state.details.admin_full_name}</h5>
                    <span className="badge badge-sm rounded-pill text-dark">Admin</span>
                    <br/>
                </div>
            </div>
        </div>
    );
}

export default Avatar;