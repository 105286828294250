export default function reducer(prevState, action){
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true 
            };
        case "IS_LOADED":
            return {
                ...prevState,
                is_loading: false,
                total_pages: action.total_pages, 
                logs_list: action.logs_list, 
                current_page: action.current_page,
                search_text: action.search_text
            };
        default:
            throw new Error();
    }
}