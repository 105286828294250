import {POST} from "../API/post";

import { setUserInfo } from "../storage";

export const submitLogin = async (data, dispatch, reset, navigate) => {
    dispatch({type: 'LOADING'});

    let response = await POST("admin-login", {
        admin_email: data.email,
        admin_password: data.password
    });

    if(response.status === "Success"){
        reset();
        setUserInfo({
            admin_id: response.admin_id,
            admin_name: response.admin_name,
            admin_firebase_document_id: response.admin_firebase_document_id,
            token: response.token,
            refreshToken: response.refreshToken
        });

        navigate("/dashboard");
    }else{
        dispatch({type: 'LOADED'});
    }
}

export const sendForgotPasswordEmail = async (data, dispatch, reset) => {
    dispatch({type: 'LOADING'});

    let response = await POST("admin/send/forgot/password/email", {
        admin_email: data.email
    });

    if(response.status === "Success"){
        reset();
        dispatch({
            type: 'IS_SEND_FORGOT_PASSWORD_EMAIL'
        });
    }else{
        dispatch({type: 'LOADED'});
    }
}