import React from "react";
import Button from 'react-bootstrap/Button';

import { theme } from "../../theme";
import CreditNotesList from "./components/creditNotesList";
import ModalTemplate from "../modal/modalTemplate";

const Html = ({
    show,
    closeFun
}) => {
    return (
        <ModalTemplate
            show = {show}
            closeFun = {closeFun}
            size = {"lg"}
            centered = {true}
            title = {"Credit Notes"}
            body = {<CreditNotesList />}
            footer = {
                <Button
                    variant={theme.close_btn}
                    onClick={closeFun}
                    size="sm"
                >
                    CLOSE
                </Button>
            }
        />
    );
}

export default Html;