import React, { useContext } from "react";

import Context from "../../context";

const NicLicenceVehicleDetails = () => {
    const context = useContext(Context);

    return(
        <div className="col-lg-12">
            <div className="card h-100">
                <div className="card-header pb-0 p-3">
                    <div className="row">
                        <div className="col-12 d-flex align-items-center">
                            <h6 className="mb-0">LICENCE & VEHICLE DETAILS</h6>
                        </div>
                    </div>
                </div>
                <div className="card-body p-3 pb-0">
                    <ul className="list-group">
                        <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                            <div className="d-flex flex-column">
                                <h6 className="mb-1 text-dark font-weight-bold text-sm">Vehicle registration</h6>
                            </div>
                            <div className="d-flex align-items-center text-sm">
                                {context.state.selected_ftm?.ftm?.ftm_vehicle_number}
                            </div>
                        </li>
                        <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                            <div className="d-flex flex-column">
                                <h6 className="mb-1 text-dark font-weight-bold text-sm">Driving licence number</h6>
                            </div>
                            <div className="d-flex align-items-center text-sm">
                                {context.state.selected_ftm?.ftm?.ftm_driving_license_number}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default NicLicenceVehicleDetails;