import React, { useContext } from "react";
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { Context } from "../provider";

const TaxAndInsuranceDetails = ({ register, reset, errors, control }) => {
    const context = useContext(Context);

    return (
        <div className="row">
            <div className="col-6">
                <FormControl error={!!errors.ftm_utr_number} fullWidth>
                    <label><b>Personal UTR number</b></label>
                    <Controller
                        name="ftm_utr_number"
                        control={control}
                        defaultValue={context.state.ftm?.ftm_utr_number || ''}
                        // {...register("ftm_utr_number", { required: false })}
                        render={({ field }) => (
                            <InputMask
                                {...field}
                                mask="9999999999"
                                maskChar=""
                            >
                                {(inputProps) => (
                                    <TextField
                                        {...inputProps}
                                        placeholder="Personal UTR number"
                                        size="small"
                                        type="text"
                                        error={!!errors.ftm_utr_number}
                                    />
                                )}
                            </InputMask>
                        )}
                    />
                    {errors.ftm_utr_number && <FormHelperText>{errors.ftm_utr_number.message}</FormHelperText>}
                </FormControl>
            </div>
            <div className="col-6">
                <FormControl error={!!errors.ftm_ni_number} fullWidth>
                    <label><b>NI number</b></label>
                    <TextField
                        error={!!errors.ftm_ni_number}
                        placeholder="Ex: QQ123456B"
                        size="small"
                        type="text"
                        defaultValue={context.state.ftm?.ftm_ni_number || ''} 
                        {...register("ftm_ni_number", { required: true })}
                    />
                    {errors.ftm_ni_number && <FormHelperText>NI number must be in the format QQ123456B</FormHelperText>}
                </FormControl>
            </div>
        </div>
    );
}

export default TaxAndInsuranceDetails;