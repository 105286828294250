import React, { useContext } from "react";
import Context from "../context";
import { theme } from "../../../theme";
import useWindowDimensions from "../../../components/hooks/useWindowDimensions";

const JobValues = () => {
    const { width } = useWindowDimensions();
    const context = useContext(Context);

    return (
        <div className={`col-12 col-xl-${width > 1320 ? 4 : 6}`}>
            <div className="card card-plain">
                <div className="card-header pb-0 p-3">
                    <h6 className="mb-0">MATERIAL VALUE</h6>
                </div>
                <div className="card-body p-3">
                    <ul className="list-group">

                        <li className="list-group-item border-0 d-flex justify-content-between ps-0  border-radius-lg">
                            <div className="d-flex flex-column">
                                <h6 className="mb-1 text-dark font-weight-bold text-sm">TIME</h6>
                            </div>
                            <div className="d-flex align-items-center text-sm">
                                <div className="text-dark text-sm mb-0 px-0 ms-4">
                                    {context.state.job_details.job_value.ftm_time_diff} min
                                </div>
                            </div>
                        </li>
                        <li className="list-group-item border-0 d-flex justify-content-between ps-0  border-radius-lg">
                            <div className="d-flex flex-column">
                                <h6 className="text-dark mb-1 font-weight-bold text-sm">JOB SALE</h6>
                            </div>
                            <div className="d-flex align-items-center text-sm">
                                <div className="text-dark text-sm mb-0 px-0 ms-4"> 
                                    {theme.currency} {context.state.job_details.job_value.ftm_hourly_rate}
                                </div>
                            </div>
                        </li>
                        <li className="list-group-item border-0 d-flex justify-content-between ps-0  border-radius-lg">
                            <div className="d-flex flex-column">
                                <h6 className="text-dark mb-1 font-weight-bold text-sm">COMMISSION</h6>
                            </div>
                            <div className="d-flex align-items-center text-sm">
                                <div className="text-dark text-sm mb-0 px-0 ms-4"> 
                                    {theme.currency} {context.state.job_details.job_value.company_commission}
                                </div>
                            </div>
                        </li>
                        <li className="list-group-item border-0 d-flex justify-content-between ps-0  border-radius-lg">
                            <div className="d-flex flex-column">
                                <h6 className="text-dark mb-1 font-weight-bold text-sm">TRADESPERSON PAYMENT</h6>
                            </div>
                            <div className="d-flex align-items-center text-sm">
                                <div className="text-dark text-sm mb-0 px-0 ms-4"> 
                                    {theme.currency} {context.state.job_details.job_value.job_ftm_value}
                                </div>
                            </div>
                        </li>
                        <li className="list-group-item border-0 d-flex justify-content-between ps-0 border-radius-lg">
                            <div className="d-flex flex-column">
                                <h6 className="text-dark mb-1 font-weight-bold text-sm">TOTAL</h6>
                            </div>
                            <div className="d-flex align-items-center text-sm">
                                <div className="text-dark text-sm mb-0 px-0 ms-4">
                                    {theme.currency} {context.state.job_details.job_value.job_value}
                                </div>
                            </div>
                        </li>
                        <li className="list-group-item border-0 d-flex justify-content-between ps-0 border-radius-lg">
                            <div className="d-flex flex-column">
                                <h6 className="text-dark mb-1 font-weight-bold text-sm">COMPANY COMMISSION</h6>
                            </div>
                            <div className="d-flex align-items-center text-sm">
                                <div className="text-dark text-sm mb-0 px-0 ms-4">
                                    {theme.currency} {context.state.job_details.job_value.company_commission}
                                </div>
                            </div>
                        </li>
                        <li className="list-group-item border-0 d-flex justify-content-between ps-0 border-radius-lg">
                            <div className="d-flex flex-column">
                                <h6 className="text-dark mb-1 font-weight-bold text-sm">TRADESPERSON IS PAY</h6>
                            </div>
                            <div className="d-flex align-items-center text-sm">
                                <div className="text-dark text-sm mb-0 px-0 ms-4">
                                    <span 
                                        className={`ms-sm-2 font-weight-bold`} 
                                        style={{color: context.state.job_details.job_is_pay_ftm == 1 ? theme.tradesperson_pay : theme.tradesperson_not_pay}}>
                                        {context.state.job_details.job_is_pay_ftm == 1 ? <span style={{color: 'green'}}>YES</span> : <span style={{color: 'red'}}>NO</span>}
                                    </span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default JobValues;