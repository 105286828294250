import { useContext } from "react";
import Badge from '@mui/material/Badge';

import { Context } from "../provider";
import { theme } from "../../../theme";
import PleaseWait from "../../components/pleaseWait";
import NoData from "../../components/noData";

const DisputesListBody = () => {
    const context = useContext(Context);

    return(
        <>
            {context.state.disputes.length > 0 ? (
                <>
                    {context.state.disputes.map((dispute, index) => ( 
                        <div 
                            className="d-block p-2" 
                            key={index} 
                            onClick={() => context.handleChatLoading(dispute)}
                            style={{
                                cursor: 'pointer',
                                backgroundColor: context.state.selected_dispute?.doc_id === dispute?.doc_id && theme.main_code,
                                borderRadius: context.state.selected_dispute?.doc_id === dispute?.doc_id && '10px'
                            }}
                        >
                            <div className="d-flex p-2">
                                <Badge badgeContent={dispute?.dispute_admin_unread_message_count} color={theme.sidebar_notification_count_color}>
                                    <img
                                        alt="profile_image"
                                        src="/assets/img/413039.png"
                                        // className="avatar shadow"
                                        style={{
                                            width: '40px',
                                            height: '40px'
                                        }}
                                    />
                                </Badge>
                                <div className="ms-3">
                                    <h6 
                                        className="mb-0"
                                        style={{color: context.state.selected_dispute?.doc_id === dispute?.doc_id && 'white'}}
                                    >
                                        {dispute.dispute_details.type} {dispute.dispute_is_resolved && <i className="material-icons" style={{color: '#FB8C00'}}>check_circle</i>}
                                    </h6>
                                    <span 
                                        className={`${context.state.selected_dispute?.doc_id === dispute?.doc_id ? 'text-white' : 'text-muted'} text-sm col-11 p-0 text-truncate d-block`}
                                    >
                                        JOB # {dispute?.dispute_job?.job_id} DISP # {dispute?.dispute_id}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </>  
            ) : (
                <>
                    {context.state.is_loading_disputes ? <PleaseWait/> : <NoData/>}
                </>
            )}  
        </>
    );
}

export default DisputesListBody;