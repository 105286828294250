import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

import { GET } from "../../API/get";
import { PUT } from '../../API/put';
import { theme } from '../../theme';
import { getUserInfo } from '../../storage';

export const loadData = async (page_number, search_value, dispatch) => {
    dispatch({ type: "IS_LOADING" });
    
    let response = await GET(`get-all-tradespersons-holidays/${page_number}?search=${search_value}`);

    if(response.status === "Success"){
        dispatch({ 
            type: "IS_LOADED", 
            total_holidays: response.total_holidays, 
            total_pages: response.total_pages, 
            holiday_list: response.holiday_list, 
            current_page: page_number 
        });
    }
}

export const confirmFTMHoliday = async (holiday_id, current_page, dispatch) => {
    Swal.fire({
        title: 'Holiday Approval',
        text: "Please confirm your decision regarding the holiday leave request?",
        showDenyButton: true,
        confirmButtonColor: theme.action_btn_code,
        cancelButtonColor: theme.cancel_btn_code,
        confirmButtonText: 'Approve',
        denyButtonText: `Reject`,
        showLoaderOnConfirm: true,
        showLoaderOnDeny: true,
        preConfirm: async () => {
            let response = await PUT("is/approve/ftm/holiday", {
                is_approve: 1,
                holiday_id: holiday_id
            });

            return response;
        },
        preDeny: async () => {
            Swal.showLoading();
            let response = await PUT("is/approve/ftm/holiday", {
                is_approve: -2,
                holiday_id: holiday_id
            });

            return response;
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
        if (result.isConfirmed) {
            if(result.value.status === "Success"){
                loadData(current_page, '', dispatch);

                toast.success(result.value.message);
            } else {
                toast.error(result.value.message);
            }
        } else if (result.isDenied) {
            if(result.value.status === "Success"){
                loadData(current_page, '', dispatch);

                toast.success(result.value.message);
            } else {
                toast.error(result.value.message);
            }
        }
    })
}

export const cancelFTMHoliday = async (holiday_id, current_page, dispatch) => {
    Swal.fire({
        title: 'Warning',
        text: "Are you sure you want to proceed?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.cancel_btn_code,
        cancelButtonColor: theme.action_btn_code,
        confirmButtonText: 'Yes',
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
            let userInfo = getUserInfo();
            let response = await PUT("/cancel/ftm/holiday/by/admin", {
                holiday_id: holiday_id,
                admin_id: userInfo.admin_id
            });
            return response;
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
        if (result.isConfirmed) {
            console.log('result :', result);

            if(result.value.status === "Success"){
                toast.success(result.value.message);
                loadData(current_page, '', dispatch);
            }
        }
    })
}