import { GET } from "../../../API/get";

export const loadData = async (job_id, dispatch) => {
    dispatch({ type: "LOADING" });
    let response = await GET(`get-job-sheet-details/${job_id}`);

    if(response.status === "Success"){
        dispatch({ 
            type: "SET_JOBSHEET", 
            job_sheet: response.job_sheet
        });
    }
}

export const getJobsheetPDF = async (job_id, dispatch) => {
    dispatch({ type: "IS_BTN_LOADING", is_btn_loading: true });
    let response = await GET(`get-job-sheet-pdf/${job_id}`);

    if(response.status === "Success"){
        window.open(response.pdf, '_blank', 'noopener,noreferrer');

        dispatch({ 
            type: "IS_BTN_LOADING", 
            is_btn_loading: false
        });
    }
}