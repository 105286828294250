export const initialState = {
    is_loading: true,
    notifications: [],
    current_page: 1,
    total_notifications: 0,
    total_pages: 0,
    is_check_notifications_status: false,
    is_show_job_chat: false,
    is_show_disput_chat: false,
    is_show_Office_chat: false,
    job_id: null,
    job_firebase_document_id: null,
    dispute_firebase: null,
    office_chat_ftm: null,
    search_value: null,
    notifications_count: null
}