export default function Reducer(prevState, action){
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true,
            };
        case "IS_LOADED": 
            return {
                ...prevState,
                jobs_list: action.jobs_list,
                total_jobs: action.total_jobs,
                total_pages: action.total_pages,
                is_loading: false,
            }; 
        case "SET_SERACH_VALUE":
            return {
                ...prevState,
                current_page: 1,
                search_value: action.search_value,
            };
        case "UPDATE_CURRENT_PAGE":
            return {
                ...prevState,
                current_page: action.current_page,
            };
        default:
            throw new Error();
    }
}