import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import ModalTemplateWithForm from "../../../components/modal/modalTemplateWithForm";
import Body from "./components/body";
import { Context } from "./provider";
import DivSpinner from "../../../components/divSpinner";

const schema = yup.object().shape({
    ftm_bank_sort_code: yup
        .string()
        .matches(/^\d{2}-\d{2}-\d{2}$/, 'Bank sort code must be in the format XX-XX-XX')
        .required('Bank sort code is required'),
    ftm_bank_account_number: yup
        .string()
        .matches(/^\d{4}-\d{4}$/, 'Bank account number must be in the format XXXX-XXXX')
        .required('Bank account number is required'),
    ftm_vehicle_number: yup
        .string()
        .matches(/^[A-Z]{2}\d{2}\s[A-Z]{3}$/, 'Vehicle registration must be in the format AB12 XYZ')
        .required('Vehicle registration is required'),
    ftm_contact_number: yup
        .string()
        .matches(/^\+44 7\d{2} \d{3} \d{4}$/, 'Phone number must be in the format +44 7XX XXX XXXX')
        .required('Phone number is required'),
    ftm_next_of_kin_contact_number: yup
        .string()
        .matches(/^\+44 7\d{2} \d{3} \d{4}$/, 'Next of kin phone number must be in the format +44 7XX XXX XXXX')
        .required('Phone number is required'),
    // ftm_business_utr_number: yup
    //     .string()
    //     .matches(/^\d{10}$/, 'Business UTR number must be 10 digits')
    //     .required('Business UTR number is required'),
    // ftm_utr_number: yup
    //     .string()
    //     .matches(/^\d{10}$/, 'Personal UTR number must be 10 digits')
    //     .required('Personal UTR number is required'),
});
const Html = ({ open, handleClose }) => {
    const context = useContext(Context);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        setValue
    } = useForm({
        resolver: yupResolver(schema),
    });

    return (
        <ModalTemplateWithForm
            show={open}
            closeFun={handleClose}
            size={"lg"}
            fullscreen={false}
            centered={true}
            dialogClassName={"modal-1200w"}
            title={'UPLOAD YOUR IMAGE'}
            body={
                <>
                    {context.state.is_loading ? (
                        <div style={{ height: `${window.innerHeight - 387}px` }}>
                            <DivSpinner />
                        </div>
                    ) : (
                        <Body
                            register={register}
                            reset={reset}
                            errors={errors}
                            setValue={setValue}
                            handleClose={handleClose}
                            control = {control}
                        />
                    )}
                </>
            }
            footer={null}
            onSubmit={handleSubmit((data) => context.callFtmUpdate(data))}
        />
    );
};

export default Html;