import React, { useContext } from 'react';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import DescriptionIcon from '@mui/icons-material/Description';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Tooltip from '@mui/material/Tooltip';
import { Context } from '../provider';

// import Context from "../context";
// import { loadData, getAllCreditNotes, getAllSalesInvoices } from '../service';


export default function IconMenu() {
    const context = useContext(Context);

    return (
        <MenuList>
            <MenuItem 
                onClick = {() => context.dispatch({
                    type: 'IS_SHOW_CREDIT_NOTES',
                    is_show_credit_notes: true
                })}
            >
                <ListItemIcon>
                    <Tooltip title="CREDIT NOTES" placement="right">
                        <ReceiptIcon/>
                    </Tooltip>
                </ListItemIcon>
            </MenuItem>
            <MenuItem 
                onClick = {() => context.dispatch({
                    type: 'IS_SHOW_SALES_INVOICES',
                    is_show_sales_invoices: true
                })}
            >
                <ListItemIcon>
                    <Tooltip title="SALES INVOICES" placement="right">
                        <DescriptionIcon/>
                    </Tooltip>    
                </ListItemIcon>
            </MenuItem>
            <Divider />
            
        </MenuList>
    );
}