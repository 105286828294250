import React, { useEffect, useContext } from "react";
import { useGoogleMaps } from "react-hook-google-maps";

import { addCustomerJobLocations } from "../service";
import Context from "../context";

const CustomerMap = () => {
    const { ref, map, google } = useGoogleMaps(
        process.env.REACT_APP_GOOGLE_MAP_KEY,
        {
            center: { lat: 51.5286416, lng: -0.1015987 },
            zoom: 10
        }
    );

    const context = useContext(Context);

    useEffect(() => {
        addCustomerJobLocations({
            map: map,
            google: google,
            state: context.state,
        });
    }, [google, map]);

    return(
        <div 
        className="page-header min-height-300 border-radius-xl mt-4" 
        style={{cursor: 'pointer'}}>
            <div 
                ref={ref} 
                style={{ width: '100%', height: 350 }} 
            />
            <span className="mask    opacity-6"></span>
        </div>
    );
}

export default CustomerMap;