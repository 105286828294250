/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useContext, useEffect, useState} from "react";
import Button from '@mui/material/Button';
import DescriptionIcon from '@mui/icons-material/Description';
import WorkIcon from '@mui/icons-material/Work';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { Badge } from "@mui/material";

import Context from "../context";
import { getJobDetailsInFirebase, openChatView } from "../service";
import { theme } from "../../../theme";

const NavBtn = () => {
    const context = useContext(Context);

    useEffect(() => {
        getJobDetailsInFirebase(context.state.job_details.job_firebase_document_id, context.dispatch);
    }, []);

    return(
        <div className="col-lg-6 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
            <div className="nav-wrapper position-relative end-0">
                <ul className="nav nav-pills nav-fill p-1" role="tablist">
                    <li className="nav-item">
                        <Button
                            className="nav-link mb-0 px-0 py-1 active"
                            startIcon={<DescriptionIcon/>} 
                            onClick = {() => context.dispatch({
                                type: 'IS_SHOW_INVOICE',
                                is_show_invoice: true
                            })}
                        >
                            INVOICE
                        </Button>
                    </li>
                    <li className="nav-item">
                        <Button
                            className="nav-link mb-0 px-0 py-1 active" 
                            startIcon={<WorkIcon/>} 
                            onClick = {() => context.dispatch({
                                type: 'IS_SHOW_JOBSHEET',
                                is_show_jobsheet: true
                            })}
                        >
                            JOBSHEET
                        </Button>
                    </li>
                    <li className="nav-item">
                        <Badge 
                            badgeContent={context.state.job_unread_message_count} 
                            color={theme.sidebar_notification_count_color}
                        >
                            <Button
                                className="nav-link mb-0 px-0 py-1 active"
                                startIcon={<QuestionAnswerIcon/>} 
                                onClick = {() => openChatView(context.state.job_details.job_firebase_document_id, context.dispatch, context.job_id)}
                            >
                                CHAT
                            </Button>
                        </Badge>
                    </li>
                    <li className="nav-item">
                        <Button
                            className="nav-link mb-0 px-0 py-1 active"
                            startIcon={<NotificationsIcon/>} 
                            onClick = {() => context.dispatch({
                                type: 'IS_SHOW_FTM_REQUEST',
                                is_show_ftm_request: true
                            })}
                        >
                            {/* TRADESPERSON REQUEST */}
                            REQUEST
                        </Button>
                    </li>
                    {/* <li className="nav-item">
                        <a className="nav-link mb-0 px-0 py-1 " data-bs-toggle="tab" href="#" role="tab" aria-selected="false">
                            <i className="material-icons text-lg position-relative">settings</i>
                            <span className="ms-1">Settings</span>
                        </a>
                    </li> */}
                </ul>
            </div>

        </div>
    )
}

export default NavBtn;