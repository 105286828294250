
import React, { useContext } from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { Context } from '../provider';
import { ftmAvailabilityStatuses } from '../../../../constants';

const FtmAvailability = ({ register, reset, errors}) => {
    const context = useContext(Context);
    const statuses = Object.entries(ftmAvailabilityStatuses);

    return (
        <div className="row">
            <div className="col-12">
                <FormControl error={!!errors.ftm_availability_status} fullWidth>
                    {/* <label><b>Gender</b></label> */}
                    <TextField
                        error={!!errors.ftm_availability_status}
                        size="small"
                        select
                        defaultValue={context.state.ftm?.ftm_availability_status}
                        {...register("ftm_availability_status", { required: 'Availability is required' })}
                    >
                        {statuses.map(([key, status]) => (
                            <MenuItem key={key} value={key}>
                                {status}
                            </MenuItem>
                        ))}
                    </TextField>
                    {errors.ftm_availability_status && <FormHelperText>{errors.ftm_availability_status.message}</FormHelperText>}
                </FormControl>
            </div>
        </div>
    );
}

export default FtmAvailability;