import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import IconButton from '@mui/material/IconButton';
import HandshakeRoundedIcon from '@mui/icons-material/HandshakeRounded';

import ChatImageUpload from "./chatImageUpload";
import Context from "../context";
import { markDisputeResolved, sendTextMessages } from "../service";
import { theme } from "../../../../theme";

/**
 * Function to render the chat form submit component
 */
const FormSubmit = ({isInbox}) => {
    // Get hook to manage form validation
    const {
        register,
        handleSubmit,
        reset
    } = useForm();

    // Get context value
    const context = useContext(Context);

    const onSubmit = (data) => {
        sendTextMessages(data, context.dispute, reset);
    };

    return (
        // <React.Fragment>
        //     {isInbox ? (
        //         <div className="card-footer d-block">
        //             <form className="align-items-center" onSubmit={handleSubmit(onSubmit)}>
        //                 <div className="input-group input-group-outline d-flex">
        //                     <input 
        //                         type="text"
        //                         name="text"  
        //                         className="form-control form-control-lg" 
        //                         placeholder="Enter your message..."
        //                         {...register("text", { required: true })}
        //                     />
        //                     <IconButton onClick={() => markDisputeResolved(context.dispute, context.dispatch)}>
        //                         <HandshakeRoundedIcon />
        //                     </IconButton>
        //                     <ChatImageUpload isInbox = {isInbox}/>
        //                     <button type="submit" className={`btn bg-gradient-${theme.main} mb-0`}>
        //                         <i className="material-icons">send</i>
        //                     </button>
        //                 </div>
        //             </form>
        //         </div>
        //     ) : (
        //         <div className="offcanvas-footer">
        //             <div className="container">
        //                 <div className="row" style={{marginTop: '10px'}}>
        //                     <div className="col">
        //                         <div className="d-grid gap-2 mb-3" >
        //                             {/* Form to submit chat messages */}
        //                             <form className="msger-inputarea" onSubmit={handleSubmit(onSubmit)}>
        //                                 <input 
        //                                     // Register input field for form validation
        //                                     type="text" 
        //                                     name="text" 
        //                                     className="msger-input" 
        //                                     placeholder="Enter your message..."
        //                                     {...register("text", { required: true })}
        //                                 />
        //                                 {/* Component to upload chat images */}
        //                                 <ChatImageUpload />
        //                                 {/* Button to submit chat messages */}
        //                                 <button type="submit" className="msger-send-btn">Send</button>
        //                             </form>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     )}
        // </React.Fragment>

        <form className="align-items-center" onSubmit={handleSubmit(onSubmit)}>
            <div className="input-group input-group-outline d-flex">
                <input 
                    type="text" 
                    name="text" 
                    className="form-control form-control-lg"
                    placeholder="Type your message"
                    {...register("text", { required: true })}
                />
                <ChatImageUpload/>
                <button className={`btn bg-gradient-${theme.main} mb-0`}>
                    <i className="material-icons">send</i>
                </button>
            </div>
        </form>
    );
}

export default FormSubmit;