import api from "./api";
import {errorAlert, badRequestError} from "./errorHandling";

export const POST = async (URL, body) => {
    try{
        let response = await api.post(URL, body);
        if(response?.data?.status === "Success"){
            return response.data
        }else{
            return errorAlert(response?.data?.message);
        }
    }catch(error){
        return badRequestError(error);
    }
};

const hasFileArray = (obj) => {
    for (let key in obj) {
        if (
          obj.hasOwnProperty(key) &&
          Array.isArray(obj[key]) &&
          obj[key].every(item => item instanceof File)
        ) {
          return {
            is_file_arr : true,
            key: key
          };
        }
      }
      return {
        is_file_arr : false
      };
}

const getFormBody = (body) => {
    let is_have_file_array = hasFileArray(body);
    let formBody = new FormData();
    for (let [key, value] of Object.entries(body)) {
        if(is_have_file_array.is_file_arr){
            if(key === is_have_file_array.key){
                for (let file of value) {
                    formBody.append(is_have_file_array.key, file);
                }
            }else{
                formBody.append(key, value);
            }
        }else{
            formBody.append(key, value);
        }
    }

    return formBody;
}

export const FORMPOST = async (URL, body) => {
    console.log('body :', body);
    try{
        let formBody = getFormBody(body);

        let response = await api.post(URL, formBody);
        if(response.data.status === "Success"){
            return response.data
        }else{
            return errorAlert(response?.data?.message);
        }
    }catch(error){
        console.log('error :', error);
        return badRequestError(error);
    }
};