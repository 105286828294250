import React,{useReducer} from "react";

import SearchInput from "./components/searchInput";
import SearchList from "./components/searchList";
import Reducer from "./reducer";
import Context from "./context";
import { initialState } from "./initialState";
import ModalTemplate from "../modal/modalTemplate";

const SearchModal = (props) => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    return(
        <Context.Provider value={{state, dispatch}}>
            <ModalTemplate
                show = {props.showModal}
                closeFun = {() => props.handleClose()}
                size = {"lg"}
                centered = {false}
                title = {null}
                body = {
                    <>
                        <SearchInput/>
                        <SearchList/>
                    </>
                }
                footer = {null}
            />
        </Context.Provider>
    );
}

export default SearchModal