import React, { useContext } from "react";
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import InventoryIcon from '@mui/icons-material/Inventory';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Grid from '@mui/material/Grid';

import Context from "../context";
import DivSpinner from "../../../components/divSpinner";
import { theme } from "../../../theme";
import { convertTimestampToFormat } from "../../../utils";

const List = () => {
    const context = useContext(Context);

    return(
        <>
            {context.state.is_loading ? (
                <div style={{height : `${window.innerHeight-387}px`}}>
                    <DivSpinner/>
                </div>
            ) : (
                <ul className="list-group ps-5 pe-5">
                    {context.state.list.length > 0 ? (
                        <>
                            {context.state.list.map((item, index) => (
                                <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg" key={index}>
                                    <div className="d-flex flex-column">
                                        <h6 className="mb-1 text-dark font-weight-bold text-sm">
                                            {convertTimestampToFormat(item.ftm_payment_history_timestream)}
                                        </h6>
                                        <span className="text-xs">#by {item.admin_user.admin_full_name}</span>
                                    </div>
                                    <div className="d-flex align-items-center text-sm font-weight-bold">
                                        
                                        <div className="d-flex flex-column">
                                            <h6 className="mb-1 text-dark font-weight-bold text-sm">
                                                <span className="me-4">
                                                    <ArrowCircleUpOutlinedIcon style={{color: theme.ftm_pay}}/>&#160;{theme.currency}{item.ftm_payment_history_total.toFixed(2)}
                                                </span>
                                            </h6>
                                            <span className="text-xs">Total payment</span>
                                        </div>

                                        <div className="d-flex flex-column">
                                            <h6 className="mb-1 text-dark font-weight-bold text-sm">
                                                <span className="me-4">
                                                    <ArrowCircleDownOutlinedIcon style={{color: theme.ftm_penalty}}/>&#160;{theme.currency}{item.ftm_payment_history_total_penalty.toFixed(2)}
                                                </span>
                                            </h6>
                                            <span className="text-xs">Total penalty</span>
                                        </div>

                                        <div className="d-flex flex-column">
                                            <h6 className="mb-1 text-dark font-weight-bold text-sm">
                                                <span className="me-4">
                                                    <InventoryIcon style={{color: theme.ftm_job_cancel_charges}}/>&#160;{theme.currency}{item.ftm_payment_history_total_job_cancel_charge.toFixed(2)}
                                                </span>
                                            </h6>
                                            <span className="text-xs">Total cancel charges</span>
                                        </div>
                                        
                                        <button 
                                            className="btn btn-link text-dark text-sm mb-0 px-0 ms-4" 
                                            onClick = {() => context.dispatch({
                                                type: 'SELECTED_HISTORY',
                                                selected_history: item
                                            })}
                                        >
                                            <RemoveRedEyeIcon/> VIEW
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </>
                    ) : (
                        <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                            <div className="d-flex flex-column">
                                <h6 className="mb-1 text-dark font-weight-bold text-sm">There are no payment records associated with this Tradeperson</h6>
                            </div>
                        </li>
                    )}
                </ul>
            )}
        </>
    );
}

export default List;