import React, { useContext } from "react";

import {theme} from "../../../theme"
import { Context } from "../provider";

const UserSelectionButton = () => {
    const context = useContext(Context);

    return (
        <div className="nav-wrapper position-relative end-0" style={{background: '#FFFDFD'}}>
            <ul className="nav nav-pills nav-fill p-1" role="tablist" style={{backgroundColor: '#FFFDFD'}}>
                <li className="nav-item" role="presentation">
                    <button
                        className="nav-link mb-0 px-0 py-1"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-selected="true"
                        style={context.state.users_type === "CUSTOMER" ? actionButton : null}
                        onClick={() => context.dispatch({type: 'SET_USERS_TYPE', users_type: 'CUSTOMER'})}
                    >
                        <i className="material-icons text-lg position-relative">home</i>
                        <span className="ms-1">CUSTOMERS</span>
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className="nav-link mb-0 px-0 py-1"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-selected="false"
                        tabIndex="-1"
                        style={context.state.users_type === "FTM" ? actionButton : null}
                        onClick={() => context.dispatch({type: 'SET_USERS_TYPE', users_type: 'FTM'})}
                    >
                        <i className="material-icons text-lg position-relative">email</i>
                        <span className="ms-1">TRADESPERSONS</span>
                    </button>
                </li>
            </ul>
        </div>
    );
};

const actionButton = {
    border:`2px solid ${theme.main_code}`,
    borderRadius: '4px',
    color: theme.main_code
}

export default UserSelectionButton;
