import React, {useContext} from "react";
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

import RestartAltIcon from '@mui/icons-material/RestartAlt';

import RefundJobPayment from './refundJobPayment';
import Context from "../context";
import DivSpinner from '../../../../components/divSpinner';

const ChatClosedWithRefundBtn = () => {
    const context = useContext(Context);

    return(
        <>
        {!context.state.job_payment_refunded ? (
            <Button
                variant="text"
                size="small"
                sx={{borderRadius: '0'}}
                startIcon={<RestartAltIcon />}
                onClick={() => context.dispatch({
                    type: 'IS_OPEN_REFUND_JOB_PAYMENT_MODAL',
                    is_open_refund_job_payment_modal: true
                })}
            >
                Refund Job Payment
            </Button>
        ) : (
            null
        )}
            <Alert variant="filled" severity="success" sx={{borderRadius: '0'}}>
                The Dispute has been resolved (closed). {context.state.job_payment_refunded ? (<span>The Job payment is being refunded.</span>) : ( null )}
            </Alert>

            <RefundJobPayment />
        </>
    );
}

export default ChatClosedWithRefundBtn;