import React, { useContext } from "react";
import Button from '@mui/material/Button';

import AddIcon from '@mui/icons-material/Add';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

import Context from "../context";
import { theme } from "../../../theme";
import { setDefaultCard, deleteCard } from '../service';

const CardDetails = () => {
    const context = useContext(Context);

    return(
        <div className="card mt-4 mb-4">
            <div className="card-header pb-0 p-3">
                <div className="row">
                    <div className="col-6 d-flex align-items-center">
                        <h5 className="mb-0">PAYMENT METHOD</h5>
                    </div>
                    <div className="col-6 text-end">
                        <Button 
                            variant="outlined" 
                            size="small"
                            color={theme.main}
                            startIcon={<AddIcon />}
                            onClick={() => context.dispatch({
                                type: 'IS_OPEN_ADD_CARD_MODAL',
                                is_open_add_card_modal: true
                            })}
                        >
                                ADD NEW CARD
                        </Button>
                    </div>
                </div>
            </div>
            <div className="card-body p-3" style={{maxHeight: '210px', overflow:'auto'}}>
            {context.state.customer.customer_cards.length > 0 ? (
                <>
                    {context.state.customer.customer_cards.map((card, index) => {
                        return(
                            <div className="row mt-2 g-1" key={index}>
                                <div className="col-md-11">
                                    {card.card_is_default ? (
                                        <div className="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
                                            <img className="w-10 me-3 mb-0" src={card.card_type === 'MC' ? "/assets/img/logos/mastercard.png" : "/assets/img/logos/visa.png"} alt="visa" />
                                            <h6 className="mb-0">{card.card_last_four_digits}</h6>
                                            <CheckCircleRoundedIcon color="success" className="ms-auto" />
                                        </div>
                                    ):(
                                        <a 
                                            href="#" 
                                            className="hvr-underline-from-center" 
                                            onClick={() => setDefaultCard(card.card_id, card.card_customer_id, context.dispatch)} 
                                            data-bs-toggle="tooltip" 
                                            data-bs-placement="top" 
                                            title="Set as Default">
                                            <div className="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
                                                <img className="w-10 me-3 mb-0" src={card.card_type === 'MC' ? "/assets/img/logos/mastercard.png" : "/assets/img/logos/visa.png"} alt="visa" />
                                                <h6 className="mb-0">{card.card_last_four_digits}</h6>
                                            </div>
                                        </a>
                                    )}
                                </div>
                                <div 
                                    className="col-md-1" 
                                    style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}} 
                                    onClick={() => deleteCard(card.card_id, card.card_customer_id, context.dispatch)}>
                                     <i className="material-icons cursor-pointer" data-bs-toggle="tooltip" data-bs-placement="top" title="Remove Card" style={{color: theme.delete_btn_code}}>delete</i>
                                </div>
                            </div>
                        )
                    })}
                </>
            ) : (
                <>
                    No Card Found
                </>
            )}
            </div>
        </div>
    );
}

export default CardDetails;