import React from "react";
import { theme } from "../../../theme";

const Box = ({icon, title, value}) => {
    return(
        <div className="col-md-3 col-3">
            <div className="card">
                <div className="card-header mx-4 p-3 text-center">
                    <div className={`icon icon-shape icon-lg bg-gradient-${theme.main} shadow text-center border-radius-lg`}>
                        <i className="material-icons opacity-10">{icon}</i>
                    </div>
                </div>
                <div className="card-body pt-0 p-3 text-center">
                    <h6 className="text-center mb-0">{title}</h6>
                    <hr className="horizontal dark my-3"/>
                    <h5 className="mb-0">{theme.currency} {value}</h5>
                </div>
            </div>
        </div>
    );
}

export default Box;