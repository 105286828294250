import { GET } from "../../API/get";
import { PUT } from "../../API/put";
import { getUserInfo } from "../../storage";

export const loadData = async ({ftm_id, page_number, dispatch}) => {
    dispatch({ type: "IS_LOADING" });
    let response = await GET(`ftm/all/jobs/payments/${ftm_id}/${page_number}`);

    if(response.status === "Success"){
        dispatch({ 
            type: "SET_PAYMENTS", 
            total_jobs: response.total_jobs, 
            total_pages: response.total_pages, 
            ftm: response.details.ftm,
            payments: response.details.list, 
            current_page: page_number 
        });
    }
}

export const filterDate = (ftm_id, data, reset, dispatch) => {
    console.log('data :', data);

    if(data.start_date === '' && data.end_date === ''){
        dispatch({
            type: "SET_ERROR",
            error: 'Please select start date and end date.'
        });
    }else{
        if(data.start_date === ''){
            dispatch({
                type: "SET_ERROR",
                error: 'Please select start date.'
            });
        }else{
            if(data.end_date === ''){
                dispatch({
                    type: "SET_ERROR",
                    error: 'Please select end date.'
                });
            }else{
                dispatch({
                    type: "SET_ERROR",
                    error: null
                });
                reset();
                filterPayments({
                    ftm_id: ftm_id,
                    page_number: 1, 
                    start_date: data.start_date, 
                    end_date : data.end_date,
                    dispatch: dispatch
                });
            }
        }
    }
}

export const filterPayments = async ({
    ftm_id,
    page_number, 
    start_date, 
    end_date,
    dispatch
}) => {
    dispatch({
        type: "IS_SHOW_FTM_PAY_MODAL",
        is_show_ftm_pay_modal: true,
        is_filtering: true
    });

    let response;
    if(start_date !== "" && end_date !== ""){
        response = await GET(`filter/ftm/payments/${ftm_id}?start_date=${start_date}&end_date=${end_date}`);
    }else{
        response = await GET(`filter/ftm/payments/${page_number}`);
    }

    if(response.status === "Success"){
        let filter_data = [];

        for(let payment of response.details.list){
            filter_data.push({
                id: `checkbox_${payment.job_id}`, 
                checked: false,
                ...payment
            });
        }

        dispatch({ 
            type: "SET_FILTER_DATA", 
            filter_data: filter_data,
            total_ftm_payment: 0
        });
    }
}

export const dateFilter = (start, end) => {
    console.log('end :', end);
    console.log('start :', start);
}

export const handleCheckboxChange = (id, filter_data, dispatch) => {
    const updatedCheckboxes = filter_data.map((checkbox) => {
        if(checkbox.id === id){
            dispatch({ 
                type: "TOTAL_FTM_PAYMENT", 
                total_ftm_payment: parseFloat(checkbox.job_ftm_value)+parseFloat(checkbox.total_extra_payments_ftm_payment)
            });

            return {
                ...checkbox, 
                checked: !checkbox.checked 
            };
        }else{
            return checkbox;
        }
    });

    console.log("updatedCheckboxes ", updatedCheckboxes);

    let total = 0;
    for(let checkbox of updatedCheckboxes){
        if(checkbox.checked){
            total =total+(parseFloat(checkbox.job_ftm_value)+parseFloat(checkbox.total_extra_payments_ftm_payment))
        }
    }

    dispatch({ 
        type: "SET_FILTER_DATA", 
        filter_data: updatedCheckboxes,
        total_ftm_payment: total
    });
};

export const handleSelectAllChange = (filter_data, dispatch) => {
    const allChecked = filter_data.every((checkbox) => checkbox.checked);
    const updatedCheckboxes = filter_data.map((checkbox) => ({
        ...checkbox,
        checked: !allChecked,
    }));

    let total = 0;
    for(let checkbox of updatedCheckboxes){
        if(checkbox.checked && checkbox.job_is_pay_ftm != 1){
            total =total+(parseFloat(checkbox.job_ftm_value)+parseFloat(checkbox.total_extra_payments_ftm_payment))
        }
    }

    dispatch({ 
        type: "SET_FILTER_DATA", 
        filter_data: updatedCheckboxes,
        total_ftm_payment: total
    });
};

export const jobValuePayInFtm = async (option, dispatch, navigate) => {
    let job_ids = option.filter_data.filter((checkbox) => checkbox.checked);
    dispatch({type: 'IS_FILTERING'});

    let user_info = getUserInfo();
    let response = await PUT(`job/value/pay/ftm`,{
        admin_id: user_info.admin_id,
        ftm_id: option.ftm_id,
        job_ids: job_ids
    });

    if(response.status === "Success"){
        loadData({
            ftm_id: option.ftm_id, 
            page_number: option.page_number, 
            dispatch: dispatch
        });

        filterPayments({
            ftm_id: option.ftm_id, 
            page_number: option.page_number,
            start_date: option.start_date,
            end_date: option.end_date,
            dispatch: dispatch
        });

        navigate(`/ftm/payments/histories/${option.ftm_id}`);
    }
}

export const getJobInvoiceInfo = async (job_id, invoice_type, dispatch) => {
    dispatch({ 
        type: "IS_LOADING_INVOICE_INFO",
        invoice_type: invoice_type
    });

    let response = await GET(`get-invoice-details/${job_id}`);

    if(response.status === "Success"){
        dispatch({ 
            type: "SET_INVOICE_INFO", 
            invoice_info: response.details
        });
    }
}