import React, { useContext } from "react";
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';

import { Context } from "../provider";

const LicenceAndVehicleDetails = ({ register, reset, errors, control }) => {
    const context = useContext(Context);

    return (
        <div className="row">
            <div className="col-6">
                <FormControl error={!!errors.ftm_driving_license_number} fullWidth>
                    <label><b>Driving licence number</b></label>
                    <TextField
                        error={!!errors.ftm_driving_license_number}
                        placeholder="Driving licence number"
                        size="small"
                        type="text"
                        defaultValue={context.state.ftm?.ftm_driving_license_number || ''} 
                        {...register("ftm_driving_license_number", { required: true })}
                    />
                    {errors.ftm_driving_license_number && <FormHelperText>Driving licence number is required</FormHelperText>}
                </FormControl>
            </div>
            <div className="col-6">
                <FormControl error={!!!!errors.ftm_vehicle_number} fullWidth>
                    <label><b>Vehicle registration</b></label>
                    <Controller
                        name="ftm_vehicle_number"
                        control={control}
                        defaultValue={context.state.ftm?.ftm_vehicle_number || ''} 
                        render={({ field }) => (
                            <TextField
                                {...field}
                                placeholder="Ex:- AB12 XYZ"
                                size="small"
                                type="text"
                                error={!!!!errors.ftm_vehicle_number}
                            />
                        )}
                    />
                    {errors.ftm_vehicle_number && <FormHelperText>{errors.ftm_vehicle_number.message}</FormHelperText>} 
                </FormControl>
            </div>
        </div>
    );
}

export default LicenceAndVehicleDetails;