export default function reducer(prevState, action) {
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true
            };

        case "IS_LOADED":
            return {
                ...prevState,
                is_loading: false
            };

        case "SET_ADMIN_INFO":
            return {
                ...prevState,
                admin_id: action.admin_id,
                admin_info: action.admin_info
            };

        case "ADMIN_PASSWORD":
            return {
                ...prevState,
                admin_password: action.admin_password
            };

        default:
            throw new Error();
    }
}