import React, { createContext, useEffect, useReducer } from "react";
import toast from 'react-hot-toast';
import { initialState } from "./initialState";
import Reducer from "./reducer";
import { fetchFtmProfile, fetchPostcodeAddress, fetchPostcodeAddressList, updateFtm } from "./service";

const Context = createContext();

const Provider = ({ ftm_id, children, handleSubmitClose }) => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        const getData = async () => {
            try{
                dispatch({ type: "IS_LOADING" });
                let response = await fetchFtmProfile({
                    ftm_id : ftm_id
                });

                dispatch({ 
                    type: "IS_LOADED", 
                    ftm: response.ftm,
                    address_details: {
                        ftm_street_address: response.ftm?.ftm_street_address,
                        ftm_place: response.ftm?.ftm_place,
                        ftm_postcode: response.ftm?.ftm_postcode,
                    }
                });
            }catch(error){
                console.log(error);
            }
        };

        getData();
    }, [ftm_id]); 

    const isValidUKPostcode = (postcode) => {
        let postcodeRegex = /^[A-Za-z]{1,2}[0-9Rr][0-9A-Za-z]? [0-9][A-Za-z]{2}$/;
        return postcodeRegex.test(postcode);
    }

    const getPostcodeAddressList = async (inputValue, callback) => {
        try {
            if (isValidUKPostcode(inputValue)) {
                let response = await fetchPostcodeAddressList(inputValue);
                let addresses = response?.list; 
    
                let options = addresses?.map((address) => ({
                    value: address, 
                    label: `${address?.StreetAddress?.toUpperCase()}, ${address?.Place?.toUpperCase()}`,
                }));
    
                callback(options);
            } else {
                callback([]);
            }
        } catch (error) {
            console.error('Error fetching addresses:', error);
            callback([]);
        }
    }

    const getPostcodeAddressDetails = async (value, setValue) => {
        dispatch({type: 'IS_LOADING_ADDRESS_DETAIL'}); 

        console.log("🚀 ~ getPostcodeAddressDetails ~ value:", value)
        let response = await fetchPostcodeAddress(`${value.StreetAddress}, ${value.Place}`);
        console.log("🚀 ~ getPostcodeAddressDetails ~ response:", response)

        dispatch({
            type: 'SET_ADDRESS_DETAILS',
            address_details: {
                ftm_street_address: response.details?.street_name_and_number,
                ftm_place: response.details?.postal_town,
                ftm_postcode: response.details?.postcode
            }
        });

        setValue("street_address", response.details?.street_name_and_number);
        setValue("city", response.details?.postal_town);
        setValue("postcode", response.details?.postcode);
    }

    const callFtmUpdate = async (data) => {
        console.log("🚀 ~ callFtmUpdate ~ data:", data)
        dispatch({type: 'IS_FTM_UPDATING', is_ftm_updating: true}); 

        let response = await updateFtm({
            ftm_id: ftm_id,
            data: data,
            ftm_profile_image: state.ftm_profile_image
        });

        if(response){
            dispatch({type: 'IS_FTM_UPDATING', is_ftm_updating: false}); 
            toast.success(response.message);
            handleSubmitClose();

        }
    }

    return (
        <Context.Provider value={{state, dispatch, getPostcodeAddressList, getPostcodeAddressDetails, callFtmUpdate}}>
            {children}
        </Context.Provider>
    );
}

export { Context, Provider };