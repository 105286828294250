import React, { useReducer, useEffect, useRef } from "react";
import Html from "./html";
import Reducer from "./reducer";
import Context from "./context";
import { initialState } from "./initialState";
import { loadData } from "./service";

const Disputes = () => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        loadData(state.page_size, null, dispatch);
    }, []); 

    return (
        <Context.Provider value={{state, dispatch}}>
            <Html/>
        </Context.Provider>
    );
}

export default Disputes;