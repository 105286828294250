import React from "react";

const HeaderView = ({header_name}) => {
    return(
        <div className="row">
            <div className="col-lg-6 col-7">
                <h6 className="text-uppercase text-white text-capitalize ps-3">
                    {header_name}
                </h6>
            </div>
        </div>
    );
}

export default HeaderView;