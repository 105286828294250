/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import Tooltip from '@mui/material/Tooltip';
import { CSVLink } from "react-csv";

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

import Context from "../context";
import { saveAsCSV } from "../service";
import Search from "../../../components/searchField";

const HeaderView = () => {
    const context = useContext(Context);

    return (
        <>
            <div className="row">
                <div className="col-lg-6 col-7">
                    <h6 className="text-uppercase text-white text-capitalize ps-3">
                        PAYMENT INFORMATION (ALL JOBS)
                    </h6>
                </div>
                <div className="col-lg-6 col-5 my-auto text-end">
                    <div className="row">
                        <div className="col-md-10">
                            <Search
                                onSearch={(search_text) => {
                                    context.dispatch({ type: "SET_SERACH_VALUE", search_value: search_text });
                                }}
                            />
                        </div>
                        <div className="col-md-2">
                            <div className="dropdown  pe-4">
                                <a
                                    className="text-white cursor-pointer"
                                    onClick={() => context.dispatch({
                                        type: 'IS_SHOW_DATE_FILTER',
                                        is_show_date_filter: true
                                    })}
                                >
                                    <CalendarMonthIcon />
                                </a>
                                &nbsp;&nbsp;
                                <CSVLink
                                    className="text-white"
                                    data={context.state.csv_data}
                                    headers={context.state.csv_headers}
                                    filename={"instafix-all-jobs-payments.csv"}
                                    onClick={() => saveAsCSV(context.state.payments, context.dispatch)}>
                                    <Tooltip title="Save as CSV" arrow>
                                        <SaveAltIcon />
                                    </Tooltip>
                                </CSVLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeaderView;