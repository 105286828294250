export default function Reducer(prevState, action){
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true,
                is_show_date_filter: false,
            };
        case "IS_LOADED": 
            return {
                ...prevState,
                is_loading: false,
            }; 
        case "IS_SHOW_DATE_FILTER":
            return {
                ...prevState,
                is_show_date_filter: action.is_show_date_filter,
            };
        case "SET_PAYMENTS": 
            return {
                ...prevState,
                payments: action.payments,
                total_jobs: action.total_jobs, 
                total_pages: action.total_pages,
                current_page: action.current_page,
                is_loading: false,
            }; 
        case "SET_DATE_RANGE": 
            return {
                ...prevState,
                start_date: action.start_date,
                end_date: action.end_date
            }; 
        case "SET_ERROR": 
            return {
                ...prevState,
                error: action.error
            };
        case "SET_CSV_DATA":
            return {
                ...prevState,
                csv_data: action.csv_data,
                csv_headers: action.csv_headers
            };
        case "UPDATE_CURRENT_PAGE":
            return {
                ...prevState,
                current_page: action.current_page,
            };
        case "SET_SERACH_VALUE":
            return {
                ...prevState,
                current_page: 1,
                search_value: action.search_value,
            };
        default:
            throw new Error();
    }
}