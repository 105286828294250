export const initialState = {
    is_loading: true,
    total_jobs: 0,
    total_pages: 0,
    current_page: 1,
    ftm: null,
    list: [],
    selected_history: null,
    is_show_history: false,
    is_loading_history: false,
    history: null,
    is_btn_loading: false,
}