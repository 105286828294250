import React, { useContext } from "react";

import Template from "../components/template";
import CardWithHeaderTemplate from "../components/cardWithHeaderTemplate";
import Footer from "./components/footer";
import Header from "./components/header";
import Body from "./components/body";
import Context from "./context";
import { theme } from "../theme";
import { getNotifications } from "./service";

const Html = () => {
    const context = useContext(Context);

    return (
        <Template>
            <div className="container-fluid py-4">
                <div className="row">
                    <div className="col-lg-8 col-md-10 mx-auto">
                        <div className="row mt-4">
                            {[
                                {
                                    title: "Direct",
                                    type: "DIRECT",
                                    count: context.state.is_loading ? "Loading..." : context.state.notifications_count?.direct_message_notifications_count,
                                    icon: "work", 
                                    search_value: "NEW_DIRECT_MESSAGE",
                                    onClickFn : () => {
                                        getNotifications({
                                            page_number: 1, 
                                            dispatch: context.dispatch, 
                                            search_value: "NEW_DIRECT_MESSAGE"
                                        });
                                    }
                                },
                                {
                                    title: "Dispute",
                                    type: "DISPUTE",
                                    count: context.state.is_loading ? "Loading..." : context.state.notifications_count?.dispute_message_notifications_count,
                                    icon: "mood_bad", 
                                    search_value: "NEW_DISPUTE_MESSAGE",
                                    onClickFn : () => {
                                        getNotifications({
                                            page_number: 1, 
                                            dispatch: context.dispatch, 
                                            search_value: "NEW_DISPUTE_MESSAGE"
                                        });
                                    }
                                },
                                {
                                    title: "Office",
                                    type: "OFFICE",
                                    count: context.state.is_loading ? "Loading..." : context.state.notifications_count?.office_message_notifications_count,
                                    icon: "sms", 
                                    search_value: "NEW_OFFICE_MESSAGE",
                                    onClickFn : () => {
                                        getNotifications({
                                            page_number: 1, 
                                            dispatch: context.dispatch, 
                                            search_value: "NEW_OFFICE_MESSAGE"
                                        });
                                    }
                                }
                            ].map((item, index) => (
                                <div 
                                    className="col-lg-4 col-sm-6" 
                                    key={index} 
                                    style={{cursor: "pointer"}}
                                    onClick={() => item.onClickFn()}
                                >
                                    <div className="card mb-4">
                                        <div className="card-body p-3">
                                            <div className="row">
                                                <div className="col-4">
                                                    <div className={`icon icon-lg icon-shape bg-gradient-${theme.main} shadow text-center border-radius-md`}>
                                                        <i className="material-icons opacity-10">{item.icon}</i>
                                                    </div>
                                                </div>
                                                <div className="col-8 my-auto text-end">
                                                    <p className="text-sm mb-0 opacity-7">{item.title}</p>
                                                    <h5 className="font-weight-bolder mb-0">
                                                        {item.count}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <CardWithHeaderTemplate
                            header={<Header />}
                            body={<Body />}
                            footer={<Footer />}
                        />
                    </div>
                </div>
            </div>
        </Template>
    );
}

export default Html;