export default function Reducer(prevState, action) {
    switch (action.type) {
        case "IS_LOADING_JOBS":
            return {
                ...prevState,
                is_loading_jobs: true,
            };
        case "IS_LOADED_JOBS":
            return {
                ...prevState,
                jobs: action.jobs,
                is_loading_jobs: false,
            };
        case "SET_JOBS_TYPE":
            return {
                ...prevState,
                jobs_type: action.jobs_type
            }
        case "SET_JOBS_LIST_SEARCH_TEXT":
            return {
                ...prevState,
                jobs_list_search_text: action.jobs_list_search_text
            }
        case "SELECTED_JOB":
            return {
                ...prevState,
                // is_get_archive_job: false,
                selected_job: action.selected_job
            }
        case "IS_GET_ARCHIVE_JOB":
            return {
                ...prevState,
                is_get_archive_job: action.is_get_archive_job
            }
        default:
            console.log("🚀 ~ Reducer ~ action.type:", action.type)
            throw new Error();
    }
}