import { usersRef } from "../../firebase";
import { getUserInfo } from "../../storage";

export const getAdminDetailsInFirebase = async (setAdminDetails) => {
    let user_info = getUserInfo();
    if(user_info?.admin_firebase_document_id !== ""){
        let query = usersRef.doc(user_info?.admin_firebase_document_id);

        query.onSnapshot(async (doc) => {
            if (doc.exists) {
                console.log("getAdminDetailsInFirebase doc :", {
                    id: doc.id,
                    ...doc.data()
                });

                setAdminDetails({
                    doc_id: doc.id,
                    ...doc.data()
                });
            } 
        });
    }
}