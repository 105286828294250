import { GET } from "../../API/get";

export const getFtmRequest = async ({job_id, dispatch}) => {
    dispatch({ type: "IS_LOADING" });
    let response = await GET(`more-time-and-materials-by-app/${job_id}`);
    if(response?.status === "Success"){
        dispatch({
            type: "SET_DETAILS", 
            details: response.details
        });
    }
}

export const fetchData = async ({job_id}) => {
    let response = await GET(`more-time-and-materials-by-app/${job_id}`);
    if(response?.status === "Success"){
        return response;
    }

    return null;
}