import * as React from 'react';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import PaidIcon from '@mui/icons-material/Paid';
import HistoryIcon from '@mui/icons-material/History';
import MapIcon from '@mui/icons-material/Map';

import { Divider } from '@mui/material';
import { deleteFTM } from '../service';
import Context from "../context";

export default function ActionMenu(props) {
    let navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const context = React.useContext(Context);

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Action">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <MoreVertIcon sx={{ width: 32, height: 32 }}/>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {/* <Divider /> */}
                <MenuItem 
                    onClick={() => {
                        handleClose();
                        navigate(`/ftm/view/${props.ftm_id}`);
                    }}
                >
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    View
                </MenuItem>
                <MenuItem 
                    onClick={() => {
                        handleClose();
                        navigate(`/ftm/jobs/cancel/${props.ftm_id}`);
                    }}
                >
                    <ListItemIcon>
                        <CancelIcon fontSize="small" />
                    </ListItemIcon>
                    Cancelled Jobs
                </MenuItem>
                <MenuItem 
                    onClick={() => {
                        handleClose();
                        navigate(`/ftm/payments/${props.ftm_id}`);
                    }}
                >
                    <ListItemIcon>
                        <PaidIcon fontSize="small" />
                    </ListItemIcon>
                    Payments
                </MenuItem>
                <MenuItem 
                    onClick={() => {
                        handleClose();
                        navigate(`/ftm/payments/histories/${props.ftm_id}`);
                    }}
                >
                    <ListItemIcon>
                        <HistoryIcon fontSize="small" />
                    </ListItemIcon>
                    Tradespersons Payments Histories
                </MenuItem>
                <MenuItem 
                    onClick={() => {
                        handleClose();
                        navigate(`/ftm/history/${props.ftm_id}`);
                    }}
                >
                    <ListItemIcon>
                        <MapIcon fontSize="small" />
                    </ListItemIcon>
                    Vehicle History
                </MenuItem>
                {/* <Divider />
                <MenuItem onClick={() => deleteFTM(props.ftm_id, context.state.page_number, context.state.search_type, context.dispatch)} style={{color: 'red'}}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" style={{color: 'red'}}/>
                    </ListItemIcon>
                    Delete
                </MenuItem> */}
            </Menu>
        </React.Fragment>
    );
}