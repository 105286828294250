import React, { useContext } from "react";
import { Container, Button, Form } from 'react-bootstrap';

import Context from "../context";
import { theme } from "../../../theme"
import { updateExpDate } from "../service";
import BtnSpinner from "../../../components/btnSpinner";
import { formatDateString } from "../../../utils";
import ModalTemplateWithForm from "../../../components/modal/modalTemplateWithForm";

const SetExpiryDate = (props) => {
    const context = useContext(Context);

    return (
        <>
            <Button 
                variant="dark" 
                size="sm"
                onClick={() => 
                    context.dispatch({
                        type: "IS_SHOW_SET_EXPIRY_DATE",
                        is_show_set_expiry_date: true,
                        ftm_document_id: props.ftm_document_id,
                        ftm_document_expiry_date: props.ftm_document_expiry_date,
                        ftm_document_comment: props.ftm_document_comment
                    })
                } 
                fullWidth>
                {props.ftm_document_expiry_date ? "Expiry date: " + formatDateString(props.ftm_document_expiry_date) : "Set expiry date"}
            </Button>
            

            <ModalTemplateWithForm
                show = {context.state.is_show_set_expiry_date}
                closeFun = {() => context.dispatch({
                    type: 'IS_SHOW_SET_EXPIRY_DATE', 
                    is_show_set_expiry_date: false, 
                    ftm_document_id: null, 
                    ftm_document_expiry_date: null, 
                    ftm_document_comment: null
                })}
                size = {null}
                centered = {false}
                fullscreen = {false}
                dialogClassName = {null}
                title = {"Set Expiry Date"}
                body = {
                    <Container>
                        <Form.Group className="mb-3">
                            <Form.Label>EXPIRY DATE</Form.Label>
                            <Form.Control 
                                type="date" 
                                style={{
                                    backgroundColor: '#fff',
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                    fontSize: '15px',
                                }}
                                name="expiry_date"
                                onChange={(e) => 
                                    context.dispatch({
                                        type: "IS_SHOW_SET_EXPIRY_DATE",
                                        is_show_set_expiry_date: true,
                                        ftm_document_id: context.state.ftm_document_id,
                                        ftm_document_expiry_date: e.target.value,
                                        ftm_document_comment: context.state.ftm_document_comment
                                    })
                                }
                                value={context.state.ftm_document_expiry_date}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>COMMENT</Form.Label>
                            <Form.Control
                                as="textarea" 
                                rows={4}
                                max-rows={4}      
                                style={{
                                    backgroundColor: '#fff',
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                    fontSize: '15px',
                                }}  
                                name="comment"
                                defaultValue={context.state.ftm_document_comment}
                            />
                        </Form.Group>
                    </Container>
                }
                footer = {
                    <>
                        <Button
                            variant={theme.close_btn}
                            size="sm"
                            onClick={() => 
                                context.dispatch({
                                    type: 'IS_SHOW_SET_EXPIRY_DATE', 
                                    is_show_set_expiry_date: false,
                                    ftm_document_id: null,
                                    ftm_document_expiry_date: null, 
                                    ftm_document_comment: null
                                })
                            }
                            disabled={context.state.is_sending_req}
                        >
                            {context.state.is_sending_req ? <BtnSpinner /> : "CLOSE"}
                        </Button>
                        <Button
                            variant={theme.action_btn}
                            type="submit"
                            size="sm"
                            disabled = {context.state.is_sending_req || !context.state.ftm_document_expiry_date}
                        >
                            {context.state.is_sending_req ? <BtnSpinner/> : "SAVE"}
                        </Button>
                    </>
                }
                onSubmit = {(event) => updateExpDate(event, context.state.ftm_document_id, context.ftm_id, context.dispatch)}
            />
        </>
    );
};

export default SetExpiryDate;