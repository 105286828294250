/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import Context from "../context";

const HeaderView = () => {
    const context = useContext(Context);

    return(
        <>
            <div className="row">
                <div className="col-lg-6 col-7">
                    <h6 className="text-uppercase text-white text-capitalize ps-3">
                        {/* CANCEL JOBS - {context.state.ftm?.ftm_name} */}
                        {context.state.is_loading ? "PLEASE WAITING..." : `CANCEL JOBS - ${context.state.ftm?.ftm_name}`}
                    </h6>
                </div>
                <div className="col-lg-6 col-5 my-auto text-end">
                    <div className="dropdown  pe-4"></div>
                </div>
            </div>
        </>
    );
}

export default HeaderView;