import React from "react";

const ImageGallery = (props) => {
    return(
        <div className="scroll-container mt-3">
            {props.images?.map((image, imageIndex) => (
                <a href={image.path} target="_blank" rel="noreferrer" key={imageIndex}>
                    <img 
                        src={image.path} 
                        alt="Cinque Terre"  
                        className="mb-1"
                        style={{
                            minWidth: '50px',
                            maxWidth: '50px',
                            minHeight: '50px',
                            maxHeight: '50px'
                        }}
                    />
                </a>
            ))}
        </div>
    );
}

export default ImageGallery