import React, { useContext } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';

import NoteList from "./components/noteList";
import Context from "./context";
import DivSpinner from "../../../components/divSpinner";
import Footer from "./components/footer";
import OffcanvasTemplate from "../../../components/offcanvasTemplate";

const Html = ({ftm_id, open, handleClose}) => {
    const context = useContext(Context);

    return(
        <OffcanvasTemplate
            open={open} 
            handleClose = {handleClose} 
            placement = {'end'}  
            title = {`NOTES`}
            body = {context.state.is_loading ? <DivSpinner/> : <NoteList/>} 
            footer = {!context.state.is_loading ?  <Footer ftm_id = {ftm_id} /> : null }
        />
    );
}

export default Html;