export default function reducer(prevState, action){
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true,
            };
        case "IS_LOADED": 
            return {
                ...prevState,
                customer: action.customer,
                customer_jobs: action.customer_jobs,
                customer_jobs_count: action.customer_jobs_count,
                customer_jobs_total_pages: action.customer_jobs_total_pages,
                is_loading: false,
            };
        case "SHOW_EDIT_CANVAS":
            return {
                ...prevState,
                show_edit_canvas: action.show_edit_canvas
            };
        case "SET_CUSTOMER_PROFILE_IMAGE":
            return {
                ...prevState,
                customer_profile_image: action.customer_profile_image
            };
        case "IS_SAVING_EDIT_CUSTOMER_INFO":
            return {
                ...prevState,
                is_saving_edit_customer_info: action.is_saving_edit_customer_info
            };
        case "IS_OPEN_ADD_CARD_MODAL":
            return {
                ...prevState,
                is_open_add_card_modal: action.is_open_add_card_modal
            };
        case "IS_IFRAME_LOADED":
            return {
                ...prevState,
                is_iframe_loaded: action.is_iframe_loaded
            };
        case "SET_CUSTOMER_JOBS":
            return {
                ...prevState,
                customer_jobs: action.customer_jobs,
                customer_jobs_count: action.customer_jobs_count,
                customer_jobs_total_pages: action.customer_jobs_total_pages,
                page_number: action.page_number,
            };
        case "IS_SHOW_CHAT":
            return {
                ...prevState,
                is_show_chat: action.is_show_chat,
            };
        case "ADMIN_UNREAD_MESSAGE_COUNT":
            return {
                ...prevState,
                admin_unread_message_count: action.admin_unread_message_count,
            };
        default:
            console.log("action.type: ", action.type)
            throw new Error();
    }
}