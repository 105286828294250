import Swal from 'sweetalert2';

import { GET } from "../../API/get";
import { POST } from "../../API/post";
import { theme } from '../../theme';
import { DELETE } from '../../API/delete';
import { getUserInfo } from '../../storage';

export const loadData = async (page_number, dispatch) => {
    dispatch({ type: "IS_LOADING" });
    let response = await GET(`admin/users/list/${page_number}`);

    if(response.status === "Success"){
        dispatch({ 
            type: "IS_LOADED",
            total_users: response.total_users,
            total_pages: response.total_pages,
            list: response.list,
            page_number: page_number
        });
    }
}

export const createUser = async (data, dispatch, reset) => {
    console.log('data :', data);
    dispatch({
        type: 'IS_CREATING_NEW_USER',
        is_creating_new_user: true
    });

    let response = await POST(`create/admin/register`,{
        admin_full_name: data.admin_full_name,
        admin_email: data.admin_email,
        admin_password: data.admin_password,
        admin_contact_number: data.admin_contact_number,
    });

    if(response.status === "Success"){
        reset();
        loadData(1, dispatch);
    }
}

export const deleteUser = async (admin_id, dispatch) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to delete this user",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.delete_btn_code,
        cancelButtonColor: theme.close_btn_code,
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
            let response = await DELETE(`admin/user/${admin_id}`);
            return response;
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
        if (result.isConfirmed) {
            console.log('result :', result);

            if(result.value.status === "Success"){
                loadData(1, dispatch);
            }
        }
    })
}