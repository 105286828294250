import React, { createContext, useEffect, useReducer } from "react";
import Reducer from "./reducer";
import { initialState } from "./initialState";
import { fetchUsers } from "./service";
import { isShowChatNotifications } from "../../utils";
import { getUserInfo } from "../../storage";
import { usersRef } from "../../firebase";

const Context = createContext();

const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        const getUsers = async () => {
            try {
                dispatch({type: 'IS_LOADING_USERS'});
                const users = await fetchUsers({
                    users_type: state.users_type,
                    is_get_archive_user: state.is_get_archive_user,
                });

                console.log("🚀 ~ getUsers ~ users:", users)

                dispatch({
                    type: 'IS_LOADED_USERS',
                    users: users
                }); 
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        const unsubscribe = usersRef.onSnapshot(() => {
            getUsers();
        });

        return () => unsubscribe();
    }, [state.is_get_archive_user, state.users_type]);

    const handleChatLoading = (user) => {
        isShowChatNotifications(0, "NEW_OFFICE_MESSAGE");

        // Get user info
        let user_info = getUserInfo();
        let ref = usersRef.doc(user.doc_id);

        ref.update({
            user_last_message_seen_user: user_info.admin_firebase_document_id,
            user_last_message_seen_timestamp: new Date().getTime()
        });

        dispatch({ type: "SELECTED_USER", selected_user: user })
    }

    const handleDocumentArchive = (user) => {
        console.log("🚀 ~ handleDocumentArchive ~ user:", user)
        let ref = usersRef.doc(user.doc_id);

        ref.update({
            user_archive: !user.user_archive
        });
    }

    const filteredUsers = state.users
        // .filter(user => user.user_archive === state.is_get_archive_user)
        .filter(user =>
            user.user_name.toLowerCase().includes(state.users_list_search_text.toLowerCase())
        );

    return (
        <Context.Provider value={{state, filteredUsers, dispatch, handleChatLoading, handleDocumentArchive}}>
            {children}
        </Context.Provider>
    );
}

export { Context, Provider };