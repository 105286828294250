import React, { useContext, useState } from 'react';
import {useNavigate} from "react-router-dom";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import VisibilityIcon from '@mui/icons-material/Visibility';

import DivSpinner from "../../../components/divSpinner";
import { truncateText } from '../../../utils';
import { theme } from "../../../theme";
import { Context } from '../provider';

function Row({row}) {
    let navigate = useNavigate();
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <TableRow className='remedial-table-row'>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                        className='remedial-table-content'
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <Typography variant="subtitle2" className='remedial-table-content'>{row.job_id}</Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                    <Typography variant="subtitle2">{row?.ftm?.ftm_forename} {row?.ftm?.ftm_surname}</Typography>
                    <Typography variant="caption" display="block" className='remedial-table-content' gutterBottom>Created {row?.job_createdAt}</Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                    <Typography variant="subtitle2" className='remedial-table-content'>{row?.customer?.customer_full_name}</Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                    <Typography variant="subtitle2" className='remedial-table-content'>{truncateText(row?.job_description, 20)}</Typography>
                </TableCell>
                <TableCell align="right">
                    <Typography variant="subtitle2" className='remedial-table-content'>{row?.job_address === null ? 'N/A' : truncateText(row?.job_address, 20)}</Typography>
                </TableCell>
                <TableCell align="right">
                    <Typography variant="subtitle2" className='remedial-table-content'>{row?.job_postcode === null ? 'N/A' : row?.job_postcode}</Typography>
                </TableCell>
                <TableCell align="right">
                    <Typography variant="subtitle2" className='remedial-table-content'>{row?.sub_skill?.skill?.skill_name}</Typography>
                </TableCell>
                <TableCell align="right">
                    <Typography variant="subtitle2" className='remedial-table-content'>{row?.sub_skill?.sub_skill_name}</Typography>
                </TableCell>
                <TableCell align="right">
                    <VisibilityIcon 
                        style={{cursor: 'pointer'}}
                        onClick={() => navigate(`/jobs/view/${row?.job_id}`)} 
                        className='remedial-table-content'
                    />
                </TableCell>
            </TableRow>
            <TableRow className='remedial-table-row'>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6">
                                Materials
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow className='remedial-table-row'>
                                        <TableCell>DESCRIPTION</TableCell>
                                        <TableCell align="right">ITEM</TableCell>
                                        <TableCell align="right">QTY</TableCell>
                                        <TableCell align="right">UNIT PRICE</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.extra_payments.length > 0 ? (
                                        <>
                                            {row.extra_payments.map((extra, index) => (
                                                <TableRow key={index} className='remedial-table-row'>
                                                    <TableCell component="th" scope="row">
                                                        <Typography variant="subtitle2" className='remedial-table-content'>
                                                            {extra.extra_payment_description}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Typography variant="subtitle2" className='remedial-table-content'>
                                                            {extra.extra_payment_item}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Typography variant="subtitle2" className='remedial-table-content'>
                                                            {extra.extra_payment_qty}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Typography variant="subtitle2" className='remedial-table-content'>
                                                            {theme.currency} {extra.extra_payment_unit_price.toFixed(2)}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </>
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={4} align="center">
                                                <Typography variant="subtitle2" className='remedial-table-content'>
                                                    Sorry, no data found!
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function CollapsibleTable() {
    const context = useContext(Context);

    return (
        <>
            {context.state.is_loading ? (
                <div style={{height : `${window.innerHeight-387}px`}}>
                    <DivSpinner/>
                </div>
            ) : (
                <div className="table-responsive p-0 m-3">
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table" size={'small'}>
                            <TableHead>
                                <TableRow className='remedial-table-row'>
                                    <TableCell />
                                    <TableCell>#</TableCell>
                                    <TableCell>FTM NAME</TableCell>
                                    <TableCell>CUSTOMER</TableCell>
                                    <TableCell>DESCRIPTION</TableCell>
                                    <TableCell align="right">ADDRESS</TableCell>
                                    <TableCell align="right">POSTCODE</TableCell>
                                    <TableCell align="right">SERVICE CATEGORIES</TableCell>
                                    <TableCell align="right">SERVICE TYPES</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {context.state.jobs_list.length > 0 ? (
                                <>
                                    {context.state.jobs_list.map((row, index) => (
                                        <Row key={index} row={row} />
                                    ))}
                                </>
                            ) : (
                                <TableRow className='remedial-table-row'>
                                    <TableCell colSpan={9} align="center">Sorry, no data found!</TableCell>
                                </TableRow>
                            )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            )}
        </>
    );
}