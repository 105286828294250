import React, { useContext } from "react";
import LoginForm from "./components/loginForm";
import SocialMedia from "./components/socialMedia";
import Text from "./components/Text";
import Context from "./context";
import ForgotPassword from "./components/forgotPassword";

const Html = () => {
    const context = useContext(Context);

    return(
        <main className="main-content  mt-0">
            <div className="page-header align-items-start min-vh-100" >
            <span className="mask bg-gradient-dark opacity-6"></span>
                <div className="container my-auto">
                    <div className="row">
                        <div className="col-lg-4 col-md-8 col-12 ">
                            <div className="card z-index-0 fadeIn3 fadeInBottom">
                                <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                                    <SocialMedia/>
                                </div>
                                <div className="card-body">
                                    {context.state.is_forgot_password ? <ForgotPassword/> : <LoginForm/>}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8 col-12 mx-auto">
                            <Text/>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Html;