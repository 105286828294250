import React, { useContext } from "react";
import Template from "../../components/template";
import Context from "./context";
import PreviousDetails from "./components/previousDetails";
import CardWithHeaderTemplate from "../../components/cardWithHeaderTemplate";
import { Pagination, Stack } from "@mui/material";
import Table from "./components/table";
import { loadData } from "./service";
import { theme } from "../../theme";
import Search from "../../components/searchField";

const Html = () => {
    const context = useContext(Context);

    return (
        <Template>
            <div className="container-fluid py-4">
                <div className="row">
                    <div className="col-12">
                        <CardWithHeaderTemplate
                            header={
                                <div className="row">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h6 className="text-white text-capitalize ps-3">PENDING APPLICATION</h6>
                                        </div>
                                        <div className="col-md-6">
                                            <Search
                                                onSearch={(search_text) => {
                                                    context.dispatch({
                                                        type: 'SET_SERACH_TEXT',
                                                        serach_text: search_text
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            body={
                                <Table />
                            }
                            footer={
                                <>
                                    {!context.state.is_loading && (
                                        <Stack
                                            spacing={2}
                                            className="mb-3"
                                            style={{
                                                alignItems: 'center',
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <Pagination
                                                count={context.state.total_pages}
                                                page={context.state.page_number}
                                                onChange={(event, newPage) => context.dispatch({
                                                    type: "UPDATE_CURRENT_PAGE",
                                                    page_number: newPage
                                                })}
                                                color={theme.main}
                                            />
                                        </Stack>
                                    )}
                                </>
                            }
                        />
                    </div>
                </div>
            </div>
            <PreviousDetails />
        </Template>
    );
}

export default Html;