export default function Reducer(prevState, action){
    switch (action.type) {
        case "IS_LOADING_USERS":
            return {
                ...prevState,
                is_loading_users: true,
            };
        case "IS_LOADED_USERS": 
            return {
                ...prevState,
                users: action.users,
                is_loading_users: false,
            }; 
        case "SET_USERS_TYPE":
            return {
                ...prevState,
                users_type: action.users_type
            }
        case "SET_USERS_LIST_SEARCH_TEXT":
            return {
                ...prevState,
                users_list_search_text: action.users_list_search_text
            }
        case "SELECTED_USER":
            return {
                ...prevState,
                // is_get_archive_user: false,
                selected_user: action.selected_user
            }
        case "IS_GET_ARCHIVE_USER":
            return {
                ...prevState,
                is_get_archive_user: action.is_get_archive_user
            }
        default:
            throw new Error();
    }
}