import { GET } from "../../API/get";

export const fetchData = async ({page_number, search_value}) => {
    let response = await GET(`get-ongoing-jobs/${page_number}?search=${search_value}`);

    if(response.status === "Success"){
        return response;
    }

    return null;
}