export const customStylesInSelect = {
    control: (base, state) => ({
        ...base,
        background: process.env.REACT_APP_THIRD_COLOR,
        borderColor: state.isFocused ? "#212529" : process.env.REACT_APP_THIRD_COLOR,
        "&:hover": {
            borderColor: state.isFocused ? "#212529" : process.env.REACT_APP_THIRD_COLOR
        }
    }),
    option: (base, state) => ({
        ...base,
        backgroundColor: state.isSelected ? "#212529" : process.env.REACT_APP_THIRD_COLOR,
        ':active': {
            backgroundColor: state.isSelected ? "#212529" : process.env.REACT_APP_THIRD_COLOR
        },
        ":hover": {
            backgroundColor: state.isFocused ? "#3a3d40" : process.env.REACT_APP_THIRD_COLOR,
            color: state.isFocused ? process.env.REACT_APP_TEXT_COLOR : null
        }
    })
}