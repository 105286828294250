import React from "react";
import { Box, Link } from "@mui/material";
import useCustomNavigate from '../components/navigation';
import { theme } from "../../theme";

const LinkToLogin = () => {
    const { navigateToHome } = useCustomNavigate();
    
    return(
        <Box>
            <Link 
                component="button"
                underline="hover"
                className={`text-${theme.main}`}
                onClick={navigateToHome}>
                    Or sign in as a different user
            </Link>
        </Box>
    );
}

export default LinkToLogin;