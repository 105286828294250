import React from 'react';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

const ActionButtons = ({is_remove_add_btn, addFunction, editFunction, deleteFunction}) => {
    return(
        <>
            {is_remove_add_btn ? (
                <IconButton edge="end">
                    <AddBoxIcon 
                        onClick = {addFunction}
                    />
                </IconButton>
            ) : null}

            <IconButton edge="end">
                <DriveFileRenameOutlineIcon 
                    onClick = {editFunction}
                />
            </IconButton>

            <IconButton edge="end">
                <DeleteIcon 
                    onClick = {deleteFunction}
                />
            </IconButton>
        </>
    );
}

export default ActionButtons