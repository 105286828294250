import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";

import Context from '../../context';
import FtmPayTable from './ftmPayTable';
import DivSpinner from '../../../../components/divSpinner';
import { theme } from '../../../../theme';
import { jobValuePayInFtm } from '../../service';
import BtnSpinner from '../../../../components/btnSpinner';
import ModalTemplate from '../../../../components/modal/modalTemplate';

const FtmPay = () => {
    const context = useContext(Context);
    let navigate = useNavigate();

    return (
        <>
            <ModalTemplate
                show={context.state.is_show_ftm_pay_modal}
                closeFun={() => context.dispatch({
                    type: 'IS_SHOW_FTM_PAY_MODAL', 
                    is_show_ftm_pay_modal: false
                })}
                size = {"xl"}
                centered = {true}
                title = {`FTM PAYMENTS`}
                body = {context.state.is_filtering ? <DivSpinner/> : <FtmPayTable/>}
                footer = {
                    <>
                        <h3 style={{marginRight: '100px'}}>TOTAL : {context.state.total_ftm_payment.toFixed(2)}</h3>
                        <Button 
                            variant={theme.close_btn} 
                            size="sm" 
                            onClick={() => context.dispatch({
                                type: 'IS_SHOW_FTM_PAY_MODAL', 
                                is_show_ftm_pay_modal: false
                            })}
                            disabled={context.state.is_filtering}
                        >
                            {context.state.is_filtering ? <BtnSpinner/> : "CLOSE"}
                        </Button>
                        <Button 
                            variant={theme.action_btn} 
                            size="sm" 
                            onClick={() => jobValuePayInFtm({
                                ftm_id: context.ftm_id,
                                page_number: context.state.current_page,
                                start_date: context.state.start_date,
                                end_date: context.state.end_date,
                                filter_data: context.state.filter_data
                            }, context.dispatch, navigate)}
                            disabled={context.state.is_filtering}
                        >
                            {context.state.is_filtering ? <BtnSpinner/> : "PAY"}
                        </Button>
                    </>
                }
            />
        </>
    );
}

export default FtmPay;