import React, { useContext } from "react";
import Context from "../context";

const ExtraPayments = () => {
    const context = useContext(Context);
    let total_extra_payment_company_payment = 0;
    let total_extra_payment_sub_total = 0;

    return (
        <div className="col-lg-12 col-md-6 mb-md-0 mb-4">
            <div className="card">
                <div className="card-header pb-0">
                    <div className="row">
                        <div className="col-lg-6 col-7">
                            <h6>EXTRA CHARGES</h6>
                        </div>
                    </div>
                </div>
                <div className="card-body px-0 pb-2">
                    <div className="table-responsive">
                        <table className="table align-items-center mb-0">
                            <thead>
                                <tr>
                                    <th className="text-uppercase text-dark text-sm font-weight-bolder opacity-7">Description</th>
                                    <th className="text-uppercase text-dark text-sm font-weight-bolder opacity-7">Items</th>
                                    <th className="text-end text-uppercase text-dark text-sm font-weight-bolder opacity-7">Qty</th>
                                    <th className="text-end text-uppercase text-dark text-sm font-weight-bolder opacity-7">Unit<br/>Price</th>
                                    <th className="text-end text-uppercase text-dark text-sm font-weight-bolder opacity-7">Commissions</th>
                                    <th className="text-end text-uppercase text-dark text-sm font-weight-bolder opacity-7">Company<br/>payment</th>
                                    <th className="text-end text-uppercase text-dark text-sm font-weight-bolder opacity-7">Sub Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {context.state.extra_payments.length > 0 ? (
                                    <>
                                        {context.state.extra_payments.map((payment, index) => {
                                            total_extra_payment_company_payment += payment.extra_payment_company_payment;
                                            total_extra_payment_sub_total += payment.extra_payment_sub_total;

                                            return(
                                                <tr key={index}>
                                                    <td>
                                                        <h6 className="mb-0 text-sm">{payment.extra_payment_description}</h6>
                                                    </td>
                                                    <td>
                                                        <h6 className="mb-0 text-sm">{payment.extra_payment_item}</h6>
                                                    </td>
                                                    <td className="align-middle text-end text-sm">
                                                        <span className="text-sm font-weight-bold">{payment.extra_payment_qty}</span>
                                                    </td>
                                                    <td className="align-middle text-end text-sm">
                                                        <span className="text-sm font-weight-bold">&#163; {payment.extra_payment_unit_price.toFixed(2)}</span>
                                                    </td>
                                                    <td className="align-middle text-end text-sm">
                                                        <span className="text-sm font-weight-bold">{payment.extra_payment_commissions_percentage}%</span>
                                                    </td>
                                                    <td className="align-middle text-end text-sm">
                                                        <span className="text-sm font-weight-bold">&#163; {payment.extra_payment_company_payment.toFixed(2)}</span>
                                                    </td>
                                                    <td className="align-middle text-end text-sm">
                                                        <span className="text-sm font-weight-bold">&#163; {payment.extra_payment_sub_total.toFixed(2)}</span>
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                        <tr>
                                            <td colSpan="5" className="align-middle text-end text-sm text-uppercase">
                                                <span className="text-lg font-weight-bold">Total</span>
                                            </td>
                                            <td className="align-middle text-end text-sm">
                                                <span className="text-lg font-weight-bold">&#163; {total_extra_payment_company_payment.toFixed(2)}</span>
                                            </td>
                                            <td className="align-middle text-end text-sm">
                                                <span className="text-lg font-weight-bold">&#163; {total_extra_payment_sub_total.toFixed(2)}</span>
                                            </td>
                                        </tr>
                                    </>
                                ) : (
                                    <tr>
                                        <td colSpan="8">Sorry, no data found!</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExtraPayments;