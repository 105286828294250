import React from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';

import PaginationView from "./paginationView";
import { truncateText } from "../../../../utils";
import useCustomNavigate from "../../../../components/hooks/useCustomNavigate";

const JobTable = (props) => {
    const { navigateToCustom } = useCustomNavigate();

    return (
        <div className="card h-100">
            <div className="card-header pb-0">
                <div className="row">
                    <div className="col-lg-6 col-7">
                        <h6>ALL JOBS</h6>
                    </div>
                </div>
            </div>
            <div className="card-body px-0 pb-2">
                <div className="table-responsive">
                    <table className="table align-items-center mb-0">
                        <thead>
                            <tr>
                                <th className="text-uppercase text-dark text-sm font-weight-bolder opacity-7">Job ID</th>
                                <th className="text-uppercase text-dark text-sm font-weight-bolder opacity-7">Tradesperson</th>
                                <th className="text-start text-uppercase text-dark text-sm font-weight-bolder opacity-7">Job Description</th>
                                <th className="text-start text-uppercase text-dark text-sm font-weight-bolder opacity-7">Job Address</th>
                                <th className="text-start text-uppercase text-dark text-sm font-weight-bolder opacity-7">Service Categories</th>
                                <th className="text-start text-uppercase text-dark text-sm font-weight-bolder opacity-7">Service Types</th>
                                <th className="text-start text-uppercase text-dark text-sm font-weight-bolder opacity-7"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.jobs.length > 0 ? (
                                <>
                                    {props.jobs.map((job, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <h6 className="mb-0 text-sm">{job?.job_id}</h6>
                                                </td>
                                                <td>
                                                    <h6 className="mb-0 text-sm">{job?.tradesperson}</h6>
                                                </td>
                                                <td className="align-middle text-start text-sm">
                                                    <span className="text-sm font-weight-bold">{truncateText(job?.job_description, 20)}</span>
                                                </td>
                                                <td className="align-middle text-start text-sm">
                                                    <span className="text-sm font-weight-bold">{truncateText(job?.job_address, 20)}</span>
                                                </td>
                                                <td className="align-middle text-start text-sm">
                                                    <span className="text-sm font-weight-bold">{job?.skill?.skill_name}</span>
                                                </td>
                                                <td className="align-middle text-start text-sm">
                                                    <span className="text-sm font-weight-bold">{job?.skill?.sub_skill?.sub_skill_name}</span>
                                                </td>
                                                <td className="align-middle text-end text-sm">
                                                    <VisibilityIcon
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => navigateToCustom(`/jobs/view/${job?.job_id}`)}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan="7">Sorry, no data found!</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <PaginationView />
            </div>
        </div>
    );
}

export default JobTable;

// import React from "react";
// import Tooltip from '@mui/material/Tooltip';
// import {useNavigate} from "react-router-dom";

// import VisibilityIcon from '@mui/icons-material/Visibility';

// const JobTable = (props) => {
//     let navigate = useNavigate();

//     return(
//         <table className="table align-items-center mb-0">
//             <thead>
//                 <tr>
//                     <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7">#</th>
//                     <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Tradesperson</th>
//                     <th className="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-7">Job Address</th>
//                     <th className="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-7">Job Type</th>
//                     <th className="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-7"></th>
//                 </tr>
//             </thead>
//             <tbody>
//                 {props.jobs.length > 0 ? (
//                     <>
//                         {props.jobs.map((job, index) => (
//                             <tr key={index}>
//                                 <td>{job?.job_id}</td>
//                                 <td>
//                                     <div className="d-flex px-2 py-1">
//                                         <div className="d-flex flex-column justify-content-center">
//                                             <h6 className="mb-0 text-sm">{job?.tradesperson}</h6>
//                                             <p className="text-sm text-secondary mb-0">Created {job?.job_createdAt}</p>
//                                         </div>
//                                     </div>
//                                 </td>

//                                 <td className="align-middle text-center">
//                                     <span className="text-secondary text-sm font-weight-bold">{job?.job_address}</span>
//                                 </td>

//                                 <td className="align-middle text-center">
//                                     <span className="text-secondary text-sm font-weight-bold">{job?.skill?.skill?.skill_name+' - '+job?.skill?.sub_skill?.sub_skill_name}</span>
//                                 </td>

//                                 <td className="align-middle text-center">
//                                     <Tooltip title="View" arrow>
//                                         <VisibilityIcon 
//                                             style={{cursor: 'pointer'}}
//                                             onClick={() => navigate(`/jobs/view/${job?.job_id}`)} 
//                                         />
//                                     </Tooltip>
//                                 </td>
//                             </tr>
//                         ))}
//                     </>
//                 ) : (
//                     <tr>
//                         <td colSpan="5">
//                             <span className="text-secondary">Sorry, no data found!</span>
//                         </td>
//                     </tr>
//                 )}
//             </tbody>
//         </table>
//     );
// }

// export default JobTable;