export default function Reducer(prevState, action){
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true,
            };
        case "IS_LOADED": 
            return {
                ...prevState,
                skills: action.skills,
                btn_loading: false,
                is_show_add_skills: false,
                is_show_edit_skills: false,
                is_loading: false,
            }; 
        case "IS_SHOW_SUB_SKILLS": 
            return {
                ...prevState,
                is_show_sub_skills: action.is_show_sub_skills,
            };
        case "SET_SELECTED_SKILLS": 
            return {
                ...prevState,
                is_show_sub_skills: true,
                selected_skills: action.selected_skills,
            };
        case "SET_IS_LOADING_BTN": 
            return {
                ...prevState,
                btn_loading: action.btn_loading,
            }; 
        case "SET_ERROR": 
            return {
                ...prevState,
                btn_loading: false,
                error: action.error,
            }; 
        case "IS_SHOW_ADD_SKILLS": 
            return {
                ...prevState,
                is_show_add_skills: action.is_show_add_skills,
            };  
        case "IS_SHOW_CERTIFICATE_TYPES": 
            return {
                ...prevState,
                is_show_certificate_types: action.is_show_certificate_types,
                selected_skills: action.selected_skills,
            };  
        case "IS_SHOW_EDIT_SKILLS": 
            return {
                ...prevState,
                is_show_edit_skills: action.is_show_edit_skills,
                selected_skills: action.selected_skills,
                error: null
            };   
        default:
            throw new Error();
    }
}