import React, {useContext} from "react";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Button from 'react-bootstrap/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { useForm } from 'react-hook-form';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

import Context from "../context";
import BtnSpinner from '../../../../components/btnSpinner';
import { theme } from '../../../../theme';
import { refundJobPayment } from '../service';
import ModalTemplateWithForm from "../../../../components/modal/modalTemplateWithForm";

const RefundJobPayment = () => {
    const context = useContext(Context);

    const {
        reset
    } = useForm();

    return (
        <ModalTemplateWithForm
            show={context.state.is_open_refund_job_payment_modal} 
            closeFun = {() => context.dispatch({
                type: 'IS_OPEN_REFUND_JOB_PAYMENT_MODAL',
                is_open_refund_job_payment_modal: false
            })}
            size = {null}
            centered = {true}
            fullscreen = {false}
            dialogClassName = {null}
            title = {
                <>
                    Refund Job Payment
                    <Tooltip title="This action will send a credit note for the customer reflecting the refund amount, and a revised sales invoice for the tradesperson showing the deducted amount." sx={{ marginLeft: 1, color: '#03a9f4' }}>
                        <InfoIcon />
                    </Tooltip>
                </>
            }
            body = {
                <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                        <label style={{ color: 'black' }}>REFUND TYPE</label>
                        <Select
                            name="refund_type"
                            onChange={event => context.dispatch({
                                type: 'SET_REFUND_TYPE',
                                refund_type: event.target.value
                            })}
                            defaultValue="full"
                            style={{ backgroundColor: '#ffffff' }}
                            size="small"
                        >
                            <MenuItem value="full">Full</MenuItem>
                            <MenuItem value="partial">Partial</MenuItem>
                        </Select>
                    </FormControl>
                    {context.state.refund_type === 'partial' && (
                        <FormControl fullWidth sx={{ mt: 3 }}>
                            <label style={{ color: 'black' }}>REFUND AMOUNT</label>
                            <OutlinedInput
                                startAdornment={<InputAdornment position="start">£</InputAdornment>}
                                name="refund_amount"
                                style={{ backgroundColor: '#ffffff' }}
                                size="small"
                            />
                        </FormControl>
                    )}
                </Box>
            }
            footer = {
                <>
                   <Button
                        variant={theme.close_btn}
                        onClick={() => context.dispatch({
                            type: 'IS_OPEN_REFUND_JOB_PAYMENT_MODAL',
                            is_open_refund_job_payment_modal: false
                        })}
                        size="sm"
                        disabled={context.state.processing_refund}
                    >
                        {context.state.processing_refund ? <BtnSpinner /> : 'CLOSE'}
                    </Button>
                    <Button
                        variant={theme.action_btn}
                        type="submit"
                        size="sm"
                        disabled={context.state.processing_refund}
                    >
                        {context.state.processing_refund ? <BtnSpinner /> : 'REFUND'}
                    </Button>
                </>
            }
            onSubmit = {(event) => refundJobPayment(event, context.dispute, context.dispatch, reset)}
        />
    );
}

export default RefundJobPayment;