import React from "react";

const Text = () => {
    return(
        <div className="h-100 d-flex align-items-center justify-content-center" >
            <div className="container">
                <div className="row">
                    <h1 style={{color: '#fff'}}>WELCOME</h1>
                </div>
                <div className="row">
                    <p style={{color: '#fff', fontWeight: '600'}}>Nunc vel sodales quam. Fusce ut enim sed eros gravida commodo vitae ac diam. Maecenas ultrices viverra lectus id egestas. Pellentesque sodales placerat leo, quis scelerisque augue vestibulum at. Aenean in mauris aliquam, elementum sem in, mollis nulla. Praesent in iaculis augue. Suspendisse turpis turpis, sollicitudin at viverra eget, varius et dui. Cras id mi hendrerit, sodales massa in, euismod ex. Nam at molestie odio. Phasellus malesuada elit tellus, vitae tristique quam tempor sit amet.</p>
                </div>
            </div>
        </div>
    );
}

export default Text;