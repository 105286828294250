import React, { useContext } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useNavigate} from "react-router-dom";

import DivSpinner from "../../../components/divSpinner";
import { truncateText } from "../../../utils";
import { Context } from "../provider";

const Table = () => {
    const context = useContext(Context);
    let navigate = useNavigate();

    return(
        <>
            {context.state.is_loading ? (
                <div style={{height : `${window.innerHeight-387}px`}}>
                    <DivSpinner/>
                </div>
            ) : (
                <div className="table-responsive p-0 m-3">
                    <table className="table align-items-center mb-0">
                        <thead>
                            <tr>
                                <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7">#</th>
                                <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7">Created<br/>date</th>
                                <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7">
                                    Customer
                                </th>
                                <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7">Description</th>
                                <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7">Address</th>
                                <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7">Postcode</th>
                                <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7">Service Categories</th>
                                <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7">Service Types</th>
                                <th className="text-secondary opacity-7"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {context.state.jobs_list.length > 0 ? (
                                <>
                                    {context.state.jobs_list.map((job, index) => (
                                        <tr key={index}>
                                            <td className="align-middle">
                                                <span className="text-secondary text-sm font-weight-bold">{job?.job_id}</span>
                                            </td>
                                            <td className="align-middle">
                                                <span className="text-secondary text-sm font-weight-bold">
                                                    {job?.job_createdAt}
                                                </span>
                                            </td>
                                            <td className="align-middle">
                                                <span className="text-secondary text-sm font-weight-bold">
                                                    {job?.customer?.customer_full_name}
                                                </span>
                                            </td>
                                            <td className="align-middle">
                                                <span className="text-secondary text-sm font-weight-bold">
                                                    {truncateText(job?.job_description, 20)}
                                                </span>
                                            </td>
                                            <td className="align-middle">
                                                <span className="text-secondary text-sm font-weight-bold">
                                                    {truncateText(job?.job_address, 20)}
                                                </span>
                                            </td>
                                            <td className="align-middle">
                                                <span className="text-secondary text-sm font-weight-bold">
                                                    {job?.job_postcode}
                                                </span>
                                            </td>
                                            <td className="align-middle">
                                                <span className="text-secondary text-sm font-weight-bold">
                                                    {job?.sub_skill?.skill?.skill_name}
                                                </span>
                                            </td>
                                            <td className="align-middle">
                                                <span className="text-secondary text-sm font-weight-bold">
                                                    {job?.sub_skill?.sub_skill_name}
                                                </span>
                                            </td>
                                            <td className="align-middle">
                                                <VisibilityIcon 
                                                    style={{cursor: 'pointer'}}
                                                    onClick={() => navigate(`/jobs/view/${job?.job_id}`)} 
                                                />
                                            </td> 
                                        </tr>
                                    ))}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan="8">Sorry, no data found!</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
}

export default Table;