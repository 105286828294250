import React, { useContext } from "react";
import { NavLink } from 'react-router-dom';
import Context from "../context";
import useWindowDimensions from "../../../components/hooks/useWindowDimensions";

const Ftm = () => {
    const { width } = useWindowDimensions();
    const context = useContext(Context);
    
    return(
        <div className={`col-12 col-xl-${width > 1320 ? 4 : 6}`}>
            <div className="card card-plain h-100">
                <div className="card-header pb-0 p-3">
                    {/* <h6 className="mb-0">FTM</h6> */}
                    <div className="row">
                        <div className="col-md-8 d-flex align-items-center">
                            <h6 className="mb-0">TRADESPERSON</h6>
                        </div>
                        <div className="col-md-4 text-end">
                            <NavLink to={`/ftm/view/${context.state?.ftms?.ftm_id}`}>
                                <i className="fas fa-eye text-secondary text-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="View Profile"></i>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className="card-body p-3">
                    <ul className="list-group">
                        <li className="list-group-item border-0 d-flex align-items-center px-0 mb-2 pt-0">
                            <div className="avatar me-3">
                                <img src={context.state?.ftms?.ftm_profile_image_path || "/assets/img/lock_user.png"} alt="kal" className="border-radius-lg shadow"/>
                            </div>
                            <div className="d-flex align-items-start flex-column justify-content-center">
                                <h6 className="mb-0 text-sm">{context.state?.ftms?.ftm_forename} {context.state?.ftms?.ftm_surname}</h6>
                            </div>
                        </li>
                    </ul>

                    <div className="card-body p-3">
                        <ul className="list-group">
                            <li className="list-group-item border-0 ps-0 text-sm">
                                <strong className="text-dark">Mobile:</strong> &nbsp; {context.state?.ftms?.ftm_contact_number}
                            </li>
                            <li className="list-group-item border-0 ps-0 text-sm">
                                <strong className="text-dark">Email:</strong> &nbsp; {context.state?.ftms?.ftm_email}
                            </li>
                            <li className="list-group-item border-0 ps-0 text-sm">
                                <strong className="text-dark">Location:</strong> &nbsp; {context.state?.ftms?.ftm_postcode}
                            </li>
                            <li className="list-group-item border-0 ps-0 text-sm">
                                <strong className="text-dark">Check in:</strong> 
                                &nbsp; {context.state.job_details.job_check_in_time !== null ? context.state.job_details.job_check_in_time : "N/A"}
                            </li>
                            <li className="list-group-item border-0 ps-0 text-sm">
                                <strong className="text-dark">Check out:</strong> 
                                &nbsp; {context.state.job_details.job_check_out_time !== null ? context.state.job_details.job_check_in_time : "N/A"}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Ftm;