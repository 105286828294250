import firebase from 'firebase/compat/app';
import { getDatabase } from "firebase/database";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { getMessaging, getToken, onMessage } from 'firebase/messaging';

let firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
};

const Firebase = firebase.initializeApp(firebaseConfig);
const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(
    messaging, 
    { vapidKey: `BHBlbxmz3eQ5_85beZICOM4vGbwmCkMoDbTHD0JJktazxWFhr7Pjp47qIRMHJO1axnbR9w7nKm8AxZ9054mrrKg` }
  ).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      return currentToken;
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
    }
  }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
  });
};

export const onMessageListener = () =>
  new Promise((resolve) => {    
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});

export const firestore =  Firebase.firestore();
export const disputesRef = Firebase.firestore().collection('disputes');
export const chatsRef = Firebase.firestore().collection('chats');
export const messagesRef = Firebase.firestore().collection('messages');
export const usersRef = Firebase.firestore().collection('users');
export const jobsRef = Firebase.firestore().collection('jobs');
export const realtime  =  getDatabase(Firebase);