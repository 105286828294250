import React, { useContext } from "react";
import Context from "../../context";
import { handleCheckboxChange, handleSelectAllChange } from "../../service";
import Checkbox from "./checkbox";
import { theme } from "../../../../theme";

const FtmPayTable = () => {
    const context = useContext(Context);

    return (
        <>
            <div 
                className="table-responsive" 
                style={{
                    height: '450px',
                    overflowY: 'auto',
                }}
            >
                <div className="table-responsive p-0">
                    <table className="table align-items-center mb-0">
                        <thead>
                            <tr
                                style={{
                                    backgroundColor: theme.box_bg_color
                                }}
                            >
                                {[
                                    <div className="form-check form-check-inline">
                                        <Checkbox 
                                            id="select-all" 
                                            label="Select All" 
                                            checked={context.state.filter_data.every((checkbox) => checkbox.checked)} 
                                            onChange={() => handleSelectAllChange(context.state.filter_data, context.dispatch)} 
                                        />
                                    </div>, 
                                    '#', 
                                    'Time', 
                                    <span>Hourly<br/>rate</span>,
                                    <span>Job<br/>value</span>,
                                    <span>Job<br/>company<br/>commission</span>,
                                    <span>Extra<br/>payment<br/>commission</span>,
                                    <span>Job<br/>FTM<br/>value</span>,
                                    <span>Extra<br/>payment<br/>FTM</span>,
                                    'VAT',
                                    <span>Payment<br/>released</span>,
                                ].map((text, index) => (
                                    <th 
                                        key={index}
                                        className="text-uppercase text-white text-sm font-weight-bolder opacity-7"
                                    >
                                        {text}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {context.state.filter_data.length > 0 ? (
                            <>
                                {context.state.filter_data?.map((payment, index) => (
                                    <tr key={index}>
                                        {[
                                            <div className="form-check form-check-inline">
                                                <Checkbox
                                                    key={payment.id}
                                                    id={payment.id}
                                                    checked={payment.checked}
                                                    job_ftm_value={payment.job_ftm_value}
                                                    total_extra_payments_ftm_payment={payment.total_extra_payments_ftm_payment}
                                                    job_id={payment.job_id}
                                                    job_is_pay_ftm={payment?.job_is_pay_ftm}
                                                    dispatch={context.dispatch}
                                                    onChange={() => handleCheckboxChange(payment.id, context.state.filter_data, context.dispatch)}
                                                />
                                            </div>,
                                            payment.job_id,
                                            payment.ftm_time_diff+' min',
                                            `${theme.currency} ${payment.ftm_hourly_rate}`,
                                            `${theme.currency} ${payment.job_value}`,
                                            `${theme.currency} ${payment.company_commission}`,
                                            `${theme.currency} ${payment.total_extra_payments_company_commission}`,
                                            `${theme.currency} ${payment.job_ftm_value}`,
                                            `${theme.currency} ${payment.total_extra_payments_ftm_payment}`,
                                            `${theme.currency} ${payment.vat}`,
                                            payment?.job_is_pay_ftm === 1 ? (
                                                <i 
                                                    className="material-icons text-lg position-relative" 
                                                    style={{color: 'green'}}
                                                >
                                                    check_circle
                                                </i>
                                            ) : (
                                                <i 
                                                    className="material-icons text-lg position-relative" 
                                                    style={{color: 'red'}}
                                                >
                                                    cancel
                                                </i>
                                            )
                                        ].map((text, index) => (
                                            <td className="align-middle" key={index}>
                                                <span className="text-dark text-sm font-weight-bold">
                                                    {text}
                                                </span>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </>) : (
                                    <tr>
                                        <td colSpan="11">There are no payment records associated with this Tradeperson</td>
                                    </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default FtmPayTable;