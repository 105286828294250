export default function reducer(prevState, action){
    console.log("🚀 ~ reducer ~ action.type:", action.type)

    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true,
            };
        case "IS_LOADED": 
            return {
                ...prevState,
                total_customers: action.total_customers,
                total_pages: action.total_pages,
                list: action.list,
                is_loading: false,
            };
        case "UPDATE_CURRENT_PAGE":
            return {
                ...prevState,
                page_number: action.page_number,
            };
        case "SET_SERACH_TEXT":
            return {
                ...prevState,
                page_number: 1,
                serach_text: action.serach_text,
            }; 
        default:
            throw new Error();
    }
}