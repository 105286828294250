export default function Reducer(prevState, action){
    switch (action.type) {
        case "LOADING":
            return {
                ...prevState,
                is_loading: true
            };
        case "LOADED":
            return {
                ...prevState,
                is_loading: false
            };
        case "IS_FORGOT_PASSWORD":
            return {
                ...prevState,
                is_forgot_password: action.is_forgot_password
            };
        case "IS_SEND_FORGOT_PASSWORD_EMAIL":
            return {
                ...prevState,
                is_send_forgot_password_email: true,
                is_loading: false,
            };
        default:
            console.log("action.type : ", action.type);
            throw new Error();
    }
}