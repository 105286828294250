import React, { useReducer, useEffect } from "react";
import Reducer from "./reducer";
import { initialState } from "./initialState";
import Html from "./html";
import Context from "./context";
import { loadData } from "./service";

const CancelJobs = () => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        loadData({
            page_number: state.current_page,
            search_value: state.search_value,
            dispatch: dispatch,
            filer: state.filer
        });
    }, [state.current_page, state.search_value, state.filer]); 

    return (
        <Context.Provider value={{state, dispatch}}>
            <Html/>
        </Context.Provider>
    );
}

export default CancelJobs;