import io from 'socket.io-client';

import { GET } from "../API/get";
import { getUserInfo } from "../storage";
import { map_settings } from "../map";

export const fetchData = async () => {
    let userInfo = getUserInfo();
    let response = await GET(`admin-dashboard-details/${userInfo.admin_id}`);

    if(response.status === "Success"){
        return response;
    }

    return null;
}

export const ftmLiveMap = (option) => {
    const ftm_markers = [];
    let socket;

    if(option.map){
        let bounds = new option.google.maps.LatLngBounds();
        for(let ftm of option.state.details.ftm_details){
            console.log('ftm :', ftm);
            let ftm_lat = parseFloat(ftm.ftm_latitude);
            let ftm_lng = parseFloat(ftm.ftm_longitude);

            let ftm_LatLng = new option.google.maps.LatLng(ftm_lat, ftm_lng);
            bounds.extend(ftm_LatLng);

            let ftm_marker = new option.google.maps.Marker({
                position: { lat: ftm_lat, lng: ftm_lng },
                icon:map_settings.ftm_map_icon ,
                map: option.map,
            });

            ftm_markers.push({
                ftm_id: ftm.ftm_id,
                ftm_marker: ftm_marker
            });
        }

        option.map.fitBounds(bounds);

        socket = io.connect(process.env.REACT_APP_SOCKET_URL+option.state.details.token);
        socket.on('locations', (data) => {
            console.log('data :', data);

            let index = ftm_markers.findIndex((ftm_marker) => ftm_marker.ftm_id == data.ftm_id);

            let marker = new option.google.maps.Marker({
                position: { lat: parseFloat(data.ftm_latitude), lng: parseFloat(data.ftm_longitude) },
                icon:map_settings.ftm_map_icon ,
                map: option.map,
            });

            if(index > -1){
                ftm_markers[index].ftm_marker.setMap(null);
                ftm_markers[index].ftm_marker = marker;
            }else{
                ftm_markers.push({
                    ftm_id: data.ftm_id,
                    ftm_marker: marker
                });
            }
        });
    }

    return {
        cleanup: () => {
            if (socket) {
                socket.disconnect();
            }
            ftm_markers.forEach(({ ftm_marker }) => ftm_marker.setMap(null));
        }
    };
}

export const fetchRunningJobs = async ({page_number, search_value}) => {
    let response = await GET(`all/running/jobs/${page_number}/5?search=${search_value}`);
    if(response.status === "Success"){
        return response;
    }

    return null;
}