import { GET } from '../../API/get';

export const getSearchList = async (option) => {
    console.log('getSearchList option :', option);
    if(option.text?.length > 0){
        option.dispatch({type: 'LOADING'});
        let type = 1;

        // switch(option.type){
        //     case "ALL":
        //         type = 1;
        //         break;
        //     case "FTM":
        //         type = 2;
        //         break;
        //     case "CUSTOMER":
        //         type = 3;
        //         break;
        //     case "POSTCODE":
        //         type = 4;
        //         break;
        //     case "JOBS":
        //         type = 5;
        //         break;
        //     case "JOBS_CREATEDAT":
        //         type = 6;
        //         break;
        //     default:
        //         type = 1;
        // }

        let column = getColumnIndex(type);
        let URL = `search?type=1&text=${option.text}&column=${column}&option=${option.search_option}`;
        if(option.search_option === "FTM"){
            URL = `search?type=2&text=${option.text}`;
        }

        let response = await GET(URL);

        if(response.status === "Success"){
            option.dispatch({ 
                type: "SET_LIST",
                search_text: option.text,
                list: response.list,
                search_option: option.search_option
            });
        }else{
            option.dispatch({ 
                type: "SET_LIST",
                search_text: option.text,
                list: [],
                search_option: option.search_option
            }); 
        }
    }else{
        option.dispatch({ 
            type: "SET_LIST",
            search_text: '',
            list: [],
            search_option: option.search_option
        }); 
    }
}

export const handleSearchOption = (type, context) => {
    // .state.search_option, context.dispatch
    let updatedCheckboxes = context.state.search_option.map((checkbox) => {
        if (checkbox.type === type) {
            return {
                ...checkbox, 
                is_checked: !checkbox.is_checked 
            };
        }else{
            return checkbox;
        }
    });

    console.log('handleOption updatedCheckboxes :', updatedCheckboxes);
    context.dispatch({
        type: 'SET_SEARCH_OPTION',
        search_option: updatedCheckboxes
    });

    getSearchList({
        text: context.state.search_text,
        type: context.state.selector,
        dispatch: context.dispatch,
        search_option: context.state.search_option
    });
}

export const getColumnIndex = (type_name) => {
    let type = 1;
    switch(type_name){
        case "ALL":
            type = 1;
            break;
        case "FTM":
            type = 2;
            break;
        case "CUSTOMER":
            type = 3;
            break;
        case "POSTCODE":
            type = 4;
            break;
        case "JOBS":
            type = 5;
            break;
        case "JOBS_CREATEDAT":
            type = 6;
            break;
        default:
            type = 1;
    }

    return type;
}