import React from "react";
import Template from "../../components/template";
import CardWithHeaderTemplate from "../../components/cardWithHeaderTemplate";
import HeaderView from "./components/headerView";
import Table from "./components/table";
import PaginationView from "./components/paginationView";
import JobList from "./components/jobsList";

const Html = () => {
    return(
        <Template>
            <div className="container-fluid py-4">
                <div className="row">
                    <div className="col-12">
                        <CardWithHeaderTemplate
                            header={<HeaderView/> } 
                            body={<Table />} 
                            footer={<PaginationView />}
                        />
                    </div>
                </div>
            </div>
            <JobList/>
        </Template>
    );
}

export default Html;