import React from "react";
import Card from 'react-bootstrap/Card';

import Title from "../../../components/title";
import ImageGallery from "../../../components/imageGallery";
import MaterialsList from "../../../components/materialsList";
import ExtraMaterialsTotal from "../../../components/extraMaterialsTotal";
import Total from "../../../components/total";
import { timeConvert } from "../../../utils";

const Body = ({ftm_request_description, request_images, extra_time_minute, extra_time_payment, materials, total_material_price}) => {
    return(
        <Card body>
            <Title text = {"Description"}/>
            <hr/>
            {ftm_request_description}

            <Title text = {"Reason Images"}/>
            <hr/>
            <ImageGallery
                images = {request_images}
            />

            {extra_time_minute != undefined ? (
                <>
                    <Title text = {"Request Extra Hours"}/>
                    <hr/>
                    {timeConvert(extra_time_minute, extra_time_payment)}
                </>
            ) : null}

            <Title text = {"Requested Extra Materials"}/>
            <hr/>                        
            
            <MaterialsList
                materials = {materials}
            />  

            <hr/>

            <ExtraMaterialsTotal
                total_material_price = {(total_material_price)?.toFixed(2)}
            />

            <hr/>
            <Total
                total = {(extra_time_payment+total_material_price)?.toFixed(2)}
            />
        </Card> 
    );

}

export default Body;