export default function Reducer(prevState, action){
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true,
            };
        case "IS_LOADED": 
            return {
                ...prevState,
                notifications: action.notifications,
                total_notifications: action.total_notifications,
                current_page: action.current_page,
                total_pages: action.total_pages,
                search_value: action.search_value,
                notifications_count: action.notifications_count,
                is_loading: false,
            }; 
        case "IS_CHECK_NOTIFICATIONS_STATUS":
            return {
                ...prevState,
                is_check_notifications_status: action.is_check_notifications_status
            };
        case "OPEN_SHOW_JOB_CHAT":
            return {
                ...prevState,
                is_show_job_chat: true,
                job_id: action.job_id,
                job_firebase_document_id: action.job_firebase_document_id
            };
        case "OPEN_SHOW_DISPUT_CHAT":
            return {
                ...prevState,
                is_show_disput_chat: true,
                job_id: action.job_id,
                dispute_firebase: action.dispute_firebase
            };
        case "OPEN_SHOW_OFFICE_CHAT_FTM":
            return {
                ...prevState,
                is_show_Office_chat: true,
                office_chat_ftm: action.office_chat_ftm
            };
        case "CLOSE_SHOW_JOB_CHAT":
            return {
                ...prevState,
                is_show_job_chat: false
            };
        case "CLOSE_SHOW_DISPUT_CHAT":
            return {
                ...prevState,
                is_show_disput_chat: false
            };
        case "CLOSE_SHOW_OFFICE_CHAT":
            return {
                ...prevState,
                is_show_Office_chat: false
            };
        case 'SET_SEARCH_VALUE':
            return {
                ...prevState,
                current_page: 1,
                search_value: action.search_value,
            }; 
        case "UPDATE_CURRENT_PAGE":
            return {
                ...prevState,
                current_page: action.current_page,
            };
        default:
            throw new Error();
    }
}