/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Context from "../context";

const HeaderView = () => {
    const context = useContext(Context);

    return(
        <>
            <div className="row">
                <div className="col-lg-6 col-7">
                    <h6 className="text-uppercase text-white text-capitalize ps-3">
                    {context.state.payments.length > 0 ? `PAYMENT INFORMATION - TRADEPERSON (${context.state.ftm.ftm_forename} ${context.state.ftm.ftm_surname})` : "Please waiting..."}
                    </h6>
                </div>
                <div className="col-lg-6 col-5 my-auto text-end">
                    <div className="dropdown  pe-4">
                        <a 
                            className="text-white cursor-pointer" 
                            onClick={() => context.dispatch({
                                type: 'IS_SHOW_DATE_FILTER',
                                is_show_date_filter: true
                            })}
                        >
                            <b>TRADESPERSON PAY</b> <CalendarMonthIcon/>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeaderView;