import { GET } from "../API/get";
import { disputesRef, jobsRef, usersRef } from "../firebase";
import { getUserInfo } from "../storage";
import { getFirebaseDisputes, getFirebaseJob, getFirebaseUsers, isShowChatNotifications } from "../utils";

export const getNotifications = async ({ page_number, dispatch, search_value }) => {
    dispatch({ type: 'IS_LOADING' });

    let URL = `admin/notifications/${page_number}`;
    if (search_value) {
        URL = `admin/notifications/${page_number}?title=${search_value}`
    }

    let response = await GET(URL);
    if (response?.status === "Success") {
        dispatch({
            type: 'IS_LOADED',
            notifications: response?.notifications,
            total_notifications: response?.notifications,
            current_page: page_number,
            total_pages: response?.total_pages,
            search_value: search_value,
            notifications_count: response?.notifications_count
        });
    }
};

export const openNotification = (notification, dispatch) => {
    switch (notification?.notification_title) {
        case "NEW_DIRECT_MESSAGE":
            openJobChatView(notification?.notification_job_id, dispatch);
            break;
        case "NEW_DISPUTE_MESSAGE":
            openDisputChatView(notification?.notification_job_id, dispatch);
            break;
        case "NEW_OFFICE_MESSAGE":
            openOfficeChatView(notification?.notification_user_id, dispatch);
            break;
        default:
            break;
    }
}

export const checkAdminNotificationsStatus = async ({current_page, notification, dispatch, search_value}) => {
    console.log("🚀 ~ checkCustomerNotificationsStatus ~ current_page:", current_page)
    openNotification(notification, dispatch);
    await getNotifications({ page_number: current_page, dispatch , search_value});
}

export const openJobChatView = async (job_id, dispatch) => {
    console.log("🚀 ~ openJobChatView ~ job_id:", job_id)
    try {
        isShowChatNotifications(job_id, "NEW_DIRECT_MESSAGE");

        let job_details = await getFirebaseJob(job_id);
        console.log("🚀 ~ openJobChatView ~ job_details:", job_details)

        // Get user info
        let user_info = getUserInfo();
        console.log("🚀 ~ openJobChatView ~ user_info:", user_info)
        let ref = jobsRef.doc(job_details.doc_id);

        ref.update({
            job_last_message_seen_user: user_info.admin_firebase_document_id,
            job_last_message_seen_user_timestamp: new Date().getTime()
        });

        dispatch({
            type: 'OPEN_SHOW_JOB_CHAT',
            job_id: job_id,
            job_firebase_document_id: job_details.doc_id
        });

    } catch (error) {
        console.error("Error open chat documents:", error);
    }
};

export const openDisputChatView = async (job_id, dispatch) => {
    console.log("🚀 ~ openDisputChatView ~ job_id:", job_id)
    try {
        isShowChatNotifications(job_id, "NEW_DISPUTE_MESSAGE");

        let dispute = await getFirebaseDisputes(job_id);
        console.log("🚀 ~ openDisputChatView ~ dispute:", dispute)

        // Get user info
        let user_info = getUserInfo();
        let ref = disputesRef.doc(dispute.doc_id);

        ref.update({
            dispute_last_message_seen_user: user_info.admin_firebase_document_id,
            dispute_last_message_seen_user_timestamp: new Date().getTime()
        });

        dispatch({
            type: 'OPEN_SHOW_DISPUT_CHAT',
            job_id: job_id,
            dispute_firebase: dispute
        });

    } catch (error) {
        console.error("Error open chat documents:", error);
    }
};

export const openOfficeChatView = async (user_id, dispatch) => {
    try {
        isShowChatNotifications(0, "NEW_OFFICE_MESSAGE");

        let user = await getFirebaseUsers(user_id);
        console.log("🚀 ~ openOfficeChatView ~ user:", user)

        // Get user info
        let user_info = getUserInfo();
        let ref = usersRef.doc(user_info.admin_firebase_document_id);

        ref.update({
            user_last_message_seen_user: user_info.admin_firebase_document_id,
            user_last_message_seen_user_timestamp: new Date().getTime()
        });

        dispatch({
            type: 'OPEN_SHOW_OFFICE_CHAT_FTM',
            office_chat_ftm: user
        });

    } catch (error) {
        console.error("Error open chat documents:", error);
    }
};