export default function Reducer(prevState, action){
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true,
                is_show_date_filter: false,
            };
        case "IS_LOADED": 
            return {
                ...prevState,
                is_loading: false,
            };
        case "SET_LIST": 
            return {
                ...prevState,
                list: action.list,
                ftm: action.ftm,
                total_jobs: action.total_jobs, 
                total_pages: action.total_pages,
                current_page: action.current_page,
                is_loading: false,
            };
        case "SELECTED_HISTORY": 
            return {
                ...prevState,
                selected_history: action.selected_history,
                is_show_history: true
            };
        case "CLOSE_HISTORY": 
            return {
                ...prevState,
                is_show_history: false,
                selected_history: null
            }; 
        case "IS_LOADING_HISTORY": 
            return {
                ...prevState,
                is_loading_history: true,
            };
        case "SET_HISTORY": 
            return {
                ...prevState,
                history: action.history,
                is_loading_history: false,
            };
        case "IS_BTN_LOADING": 
            return {
                ...prevState,
                is_btn_loading: action.is_btn_loading,
            };
        case "UPDATE_CURRENT_PAGE":
            return {
                ...prevState,
                current_page: action.current_page,
            };
        default:
            throw new Error();
    }
}