import React, { useContext } from "react";

import Context from "../context";
// import EditCustomer from "./editCustomer";

const CustomerInfo = () => {
    const context = useContext(Context);

    return(
        <div className="card mt-4 mb-4">
            <div className="card-header pb-0 p-3">
                <div className="row">
                    <div className="col-6 d-flex align-items-center">
                        <h5 className="mb-0">PROFILE INFORMATION</h5>
                    </div>
                    <div className="col-6 text-end">
                        {/* <EditCustomer/> */}
                    </div>
                </div>
            </div>
            <div className="card-body p-3">
                <div className="row">
                    <div className="col-md-12 mb-md-0 mb-4">
                        <div className="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
                            <ul className="list-group">
                                <li className="list-group-item border-0 ps-0 pt-0 text-sm">
                                    <strong className="text-dark">Full Name:</strong> 
                                    &nbsp; {context.state.customer.customer_full_name}
                                </li>
                                <li className="list-group-item border-0 ps-0 text-sm">
                                    <strong className="text-dark">Mobile:</strong> 
                                    &nbsp; {context.state.customer.customer_contact_number}
                                </li>
                                <li className="list-group-item border-0 ps-0 text-sm">
                                    <strong className="text-dark">Email:</strong> 
                                    &nbsp; {context.state.customer.customer_email}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomerInfo;