import React from "react";
import Card from 'react-bootstrap/Card';

const JobImages = (props) => {
    return(
        <div className="col-lg-12 col-md-6">
            <div className="card h-100 mb-4">
                <div className="card-header pb-0 px-3">
                    <div className="row">
                        <div className="col-md-12">
                            <h6 className="mb-0">JOB PHOTOS</h6>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-4 p-3">
                    {props.images.length > 0 ? (
                        <div className="scroll-container">
                            {props.images?.map((image, imageIndex) => (
                                <a href={image.job_image_path} target="_blank" rel="noreferrer">
                                    <img src={image.job_image_path} alt="Cinque Terre" key={imageIndex} className="mb-1"/>
                                </a>
                            ))}
                        </div>
                    ) : (
                        <span className="text-md">Sorry, no data found!</span>
                    )}
                </div>
            </div>
        </div>
            
    );
}

export default JobImages