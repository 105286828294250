import React from "react";
import Html from "./html";
import { Provider } from "./provider";

const FtmAttachments = ({
    ftm_id,
    open,
    handleClose
}) => {
    return (
        <Provider
            ftm_id = {ftm_id}
            children = {
                <Html
                    open = {open}
                    handleClose = {handleClose}
                />
            }
        />
    );
}

export default FtmAttachments;