import React, { useContext } from "react";
import Context from "./context";
import CardView from "./components/cardView";

const Html = () => {
    const context = useContext(Context);
    
    return(
        <>
            {context.state.sub_skills.map((sub_skill, index) => (
                <React.Fragment key = {index}>
                    <CardView
                        sub_skill = {sub_skill}
                        key = {index}
                    />
                    <hr/>
                </React.Fragment>
            ))}
        </>
    );
}

export default Html;