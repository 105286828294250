import React, { useContext } from "react";
import Header from "./header";
import UserChat from "../../../components/userChat/chat";
import { Context } from "../provider";
import EmptyChat from "../../components/emptyChat";

const ChatBody = () => {
    const context = useContext(Context);

    return (
        <UserChat
            user_name = {context.state.selected_user?.user_name}
            user_firebase_document_id = {context.state?.selected_user?.doc_id}
            show = {false}
            is_inbox = {true}
            handleClose = {() => {}}
            header = {<Header/>}
            emptyChat = {<EmptyChat/>}
            is_have_selected_user = {context.state.selected_user !== null}
        /> 
    );
}

export default ChatBody;