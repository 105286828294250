import { GET } from "../../API/get";

export const loadData = async ({page_number, search_value, dispatch}) => {
    dispatch({ type: "IS_LOADING" });
    let response = await GET(`all/jobs/${page_number}?search=${search_value}`);

    if(response.status === "Success"){
        dispatch({ 
            type: "IS_LOADED", 
            total_jobs: response.details.job_count, 
            total_pages: response.details.total_pages, 
            jobs_list: response.details.jobs, 
            current_page: page_number 
        });
    }
}