/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, {useContext} from "react";
import Context from "../context";

const Info = () => {
    const context = useContext(Context);

    return(
        <div className="card  mb-4">
            <div className="card-header pb-0 p-3">
                <div className="row">
                    <div className="col-md-8 d-flex align-items-center">
                        <h5 className="mb-0">PROFILE INFORMATION</h5>
                    </div>
                    {/* <div className="col-md-4 text-end">
                        <a href="#">
                            <i className="fas fa-user-edit text-secondary text-sm" data-bs-toggle="tooltip" data-bs-placement="top" aria-hidden="true" aria-label="Edit Profile" data-original-title="" title=""></i><span className="sr-only">Edit Profile</span>
                        </a>
                    </div> */}
                </div>
            </div>
            <div className="card-body p-3">
                {/* <p className="text-sm">
                    Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality).
                </p> */}
                {/* <hr className="horizontal gray-light my-4"/> */}
                <ul className="list-group mt-4">
                    {[
                        {
                            name: "Id",
                            value: context.state.details.admin_id,
                        },
                        {
                            name: "Full name",
                            value: context.state.details.admin_full_name,
                        },
                        {
                            name: "Contact number",
                            value: context.state.details.admin_contact_number,
                        },
                        {
                            name: "Email",
                            value: context.state.details.admin_email,
                        },
                        {
                            name: "created",
                            value: context.state.details.admin_createdAt,
                        },
                        {
                            name: "Status",
                            value: context.state.details.admin_status,
                        },
                    ].map((item, index) => (
                        <li className="list-group-item border-0 ps-0 pt-0 text-sm" key={index}>
                            <strong className="text-dark">{item.name}:</strong> &nbsp; {item.value}
                        </li>
                    ))}
                    
                    {/* <li className="list-group-item border-0 ps-0 text-sm"><strong className="text-dark">Mobile:</strong> &nbsp; (44) 123 1234 123</li>
                    <li className="list-group-item border-0 ps-0 text-sm"><strong className="text-dark">Email:</strong> &nbsp; alecthompson@mail.com</li>
                    <li className="list-group-item border-0 ps-0 text-sm"><strong className="text-dark">Location:</strong> &nbsp; USA</li> */}
                </ul>
            </div>
        </div>
    );
}

export default Info;