import React, { useContext } from "react";
import Context from "../context";
import { updateDevice, removeDevice } from "../service";
import { theme } from "../../../theme";

const ConnectTracker = () => {
    const context = useContext(Context);

    return (
        <div className="col-md-6 mb-lg-0 mb-4">
            <div className="card card-plain">
                <div className="card-body p-3">
                    <div className="row">
                        <div className="col-md-12 mb-md-0 mb-4">
                            <div className="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
                                <img className="w-10 me-3 mb-0" src="/assets/img/gps_tracker.png" alt="logo"/>
                                <h5 className="mb-0">{context.state?.ftm?.ftm_device_id !== "" ? context.state?.ftm?.ftm_device_id : "NOT SET DEVICE"}</h5>
                                <div className="d-flex flex-column ms-auto">
                                    <i 
                                        className="material-icons ms-auto text-dark cursor-pointer" 
                                        data-bs-toggle="tooltip" 
                                        data-bs-placement="top"
                                        onClick={() => updateDevice(context.state?.ftm?.ftm_id, context.state?.ftm?.ftm_device_id, context.dispatch)}
                                    >edit</i>
                                    {context.state?.ftm?.ftm_device_id ? (
                                        <i
                                            className="material-icons ms-auto cursor-pointer"
                                            data-bs-toggle="tooltip" 
                                            data-bs-placement="top"
                                            title="Remove"
                                            style={{color: theme.delete_btn_code}}
                                            onClick={() => removeDevice(context.state?.ftm?.ftm_id, context.dispatch)}>
                                            delete
                                        </i>
                                    ) : (
                                        null
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConnectTracker;