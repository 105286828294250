/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useContext, useEffect} from "react";
import Button from '@mui/material/Button';
import DescriptionIcon from '@mui/icons-material/Description';
import ReceiptIcon from '@mui/icons-material/Receipt';

import Context from "../context";
import { getAllCreditNotes, getAllSalesInvoices } from "../service";

const NavBtn = () => {
    const context = useContext(Context);

    useEffect(() => {
        
    }, []);

    return(
        <div className="col-lg-6 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
            <div className="nav-wrapper position-relative end-0">
                <ul className="nav nav-pills nav-fill p-1" role="tablist">
                    <li className="nav-item">
                        <Button
                            className="nav-link mb-0 px-0 py-1 active"
                            startIcon={<ReceiptIcon/>} 
                            onClick={() => getAllCreditNotes(1, context.dispatch)}
                        >
                            CREDIT NOTES
                        </Button>
                    </li>
                    <li className="nav-item">
                        <Button
                            className="nav-link mb-0 px-0 py-1 active" 
                            startIcon={<DescriptionIcon/>} 
                            onClick = {() => getAllSalesInvoices(1, context.dispatch)}
                        >
                            SALES INVOICES
                        </Button>
                    </li>
                </ul>
            </div>

        </div>
    )
}

export default NavBtn;