export default function Reducer(prevState, action){
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true,
            };
        case "IS_LOADED": 
            return {
                ...prevState,
                attachments: action.attachments,
                is_loading: false,
            };
        case "IS_SHOW_ADD_ATTACHMENT_MODAL":
            return {
                ...prevState,
                is_show_add_attachment_modal: action.is_show_add_attachment_modal
            };
        case "IS_SHOW_FTM_ATTACHMENTS":
            return {
                ...prevState,
                is_show_ftm_attachments: action.is_show_ftm_attachments
            };
        case "UPLOADED_DOCUMENT":
            return {
                ...prevState,
                uploaded_document: action.uploaded_document
            };
        case "IS_LOADING_SAVE_BTN":
            return {
                ...prevState,
                is_loading_save_btn: action.is_loading_save_btn
            };
        case "ADDED_NEW_ATTACHMENT":
            return {
                ...prevState,
                is_loading_save_btn: false,
                is_show_add_attachment_modal: false,
                uploaded_document: null,
                new_attachment_count: action.new_attachment_count
            };
        default:
            throw new Error();
    }
}