import React from "react";

const HeaderView = () => {
    return(
        <div className="row">
            <div className="col-lg-6 col-7">
                <h6 className="text-uppercase text-white text-capitalize ps-3">
                    Search Results
                </h6>
            </div>
            <div className="col-lg-6 col-5 my-auto text-end">
                <div className="dropdown  pe-4">
                    
                </div>
            </div>
        </div>
    );
}

export default HeaderView;