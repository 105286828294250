import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const DivSpinner = () => {
    return(
        <Box 
            sx={{
                display: 'flex',
                justifyContent: 'center', // Center horizontally
                alignItems: 'center',    // Center vertically
                height: '100%',         // Ensure the container takes full height
            }}
        >
            <CircularProgress color="inherit"/>
        </Box>
    );
}

export default DivSpinner;