/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ChecklistIcon from '@mui/icons-material/Checklist';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import PortableWifiOffIcon from '@mui/icons-material/PortableWifiOff';

import Context from "../context";
import { loadData } from '../service';

export default function FilterMenu() {
    const context = React.useContext(Context);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <div className="my-auto text-end">
                <div className="dropdown  pe-4">
                    <a className="text-white cursor-pointer" onClick={handleClick}>
                        <FilterAltIcon/>
                    </a>
                </div>
            </div>
            
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {/* <Divider /> */}
                <MenuItem 
                    onClick={() => {
                        handleClose();
                        context.dispatch({
                            type: "SET_SEARCH_TYPE",
                            search_type: ''
                        });
                    }}
                >
                    <ListItemIcon>
                        <ChecklistIcon fontSize="small" />
                    </ListItemIcon>
                    ALL
                </MenuItem>
                <MenuItem 
                    onClick={() => {
                        handleClose();
                        context.dispatch({
                            type: "SET_SEARCH_TYPE",
                            search_type: 'Rejected'
                        });
                    }}
                >
                    <ListItemIcon>
                        <ThumbDownIcon fontSize="small" />
                    </ListItemIcon>
                    REJECTED
                </MenuItem>
                <MenuItem 
                    onClick={() => {
                        handleClose();
                        context.dispatch({
                            type: "SET_SEARCH_TYPE",
                            search_type: 'Verified'
                        });
                    }}
                >
                    <ListItemIcon>
                        <VerifiedUserIcon fontSize="small" />
                    </ListItemIcon>
                    VERIFIED
                </MenuItem>
                <MenuItem 
                    onClick={() => {
                        handleClose();
                        context.dispatch({
                            type: "SET_SEARCH_TYPE",
                            search_type: 'Waiting Approval'
                        });
                    }}
                >
                    <ListItemIcon>
                        <HourglassFullIcon fontSize="small" />
                    </ListItemIcon>
                    WAITING APPROVAL
                </MenuItem>
                <MenuItem 
                    onClick={() => {
                        handleClose();
                        context.dispatch({
                            type: "SET_SEARCH_TYPE",
                            search_type: 'Online'
                        });
                    }}
                >
                    <ListItemIcon>
                        <OnlinePredictionIcon fontSize="small" />
                    </ListItemIcon>
                    ONLINE
                </MenuItem>
                <MenuItem 
                    onClick={() => {
                        handleClose();
                        context.dispatch({
                            type: "SET_SEARCH_TYPE",
                            search_type: 'Offline'
                        });
                    }}
                >
                    <ListItemIcon>
                        <PortableWifiOffIcon fontSize="small" />
                    </ListItemIcon>
                    OFFLINE
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}