export default function Reducer(prevState, action){
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true,
            };
        case "IS_LOADED": 
            return {
                ...prevState,
                certificate_types: action.certificate_types,
                btn_loading: false,
                is_loading: false,
                is_show_add_certificate_types: false,
                is_show_edit_certificate_types: false,
                error: null,
            };
        case "IS_SHOW_ADD_CERTIFICATE_TYPES":
            return {
                ...prevState,
                is_show_add_certificate_types: action.is_show_add_certificate_types,
            };
        case "IS_SHOW_EDIT_CERTIFICATE_TYPES":
            return {
                ...prevState,
                is_show_edit_certificate_types: action.is_show_edit_certificate_types,
            };
        case "SET_ERROR": 
            return {
                ...prevState,
                btn_loading: false,
                error: action.error,
            }; 
        case "SET_IS_LOADING_BTN": 
            return {
                ...prevState,
                btn_loading: action.btn_loading,
            }; 
        case "SELECTED_CERTIFICATE_TYPE": 
            return {
                ...prevState,
                selected_certificate_type: action.selected_certificate_type,
                is_show_edit_certificate_types: true,
            }; 
        case "IS_OPEN_CERTIFICATE_TYPES":
            return {
                ...prevState,
                is_open_certificate_types: action.is_open_certificate_types,
                certificate_types: action.certificate_types
            };
        default:
            throw new Error();
    }
}