import React, { useContext } from "react";
import {Link} from 'react-router-dom';

import Context from "../context";
import { theme } from "../../../theme";

const Details = () => {
    const context = useContext(Context);

    return(
        <div className="col-12 col-xl-4">
            <div className="card card-plain h-100">
                <div className="card-header pb-0 p-3">
                    <div className="row">
                        <div className="col-md-8 d-flex align-items-center">
                            <h6 className="mb-0">DESCRIPTION</h6>
                        </div>
                    </div>
                </div>
                <div className="card-body p-3">
                    <p className="text-sm">
                        {context.state.job_details.job_description}
                    </p>
                    <hr className="horizontal gray-light my-4"/>
                    <ul className="list-group">
                        <li className="list-group-item border-0 ps-0 pt-0 text-sm">
                            <Link to={"/customers/view/"+context.state.job_details.job_customer_id} className="link-style">
                                <strong className="text-dark">Full Name:</strong> &nbsp; {context.state.job_details.customer.customer_full_name}
                            </Link>
                        </li>
                        <li className="list-group-item border-0 ps-0 text-sm">
                            <strong className="text-dark">Mobile:</strong> &nbsp; {context.state.job_details.customer.customer_contact_number}
                        </li>
                        <li className="list-group-item border-0 ps-0 text-sm">
                            <strong className="text-dark">Email:</strong> &nbsp; {context.state.job_details.customer.customer_email}
                        </li>
                        <li className="list-group-item border-0 ps-0 text-sm">
                            <strong className="text-dark">Location:</strong> &nbsp; {context.state.job_details.job_address}
                        </li>
                        <li className="list-group-item border-0 ps-0 text-sm">
                            <strong className="text-dark">Customer Site:</strong> &nbsp; {context.state.job_details.job_postcode}
                        </li>
                        <li className="list-group-item border-0 ps-0 text-sm">
                            <strong className="text-dark">Booked for:</strong> &nbsp; {context.state.job_details.job_hours} Hours
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Details;