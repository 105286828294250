import React, { useContext } from 'react';
import NavList from './navList';
import ChangeProfile from './changeProfile';
import PersonalInformation from './personalInformation';
import LicenceAndVehicleDetails from './licenceAndVehicleDetails';
import TaxAndInsuranceDetails from './taxAndInsuranceDetails';
import BusinessDetails from './businessDetails';
import BankDetails from './bankDetails';
import { theme } from '../../../../theme';
import { Context } from '../provider';
import BtnSpinner from '../../../../components/btnSpinner';
import NextOfKin from './nextOfKin';
import FtmAvailability from './ftmAvailability';

const Card = ({ id, title, children }) => (
    <div className="card mt-4" id={id}>
        <div className="card-header" style={{paddingBottom: '5px'}}>
            <h5>{title}</h5>
        </div>
        <div className="card-body pt-0">
            {children}
        </div>
    </div>
);

const Body = ({ register, reset, errors, setValue, handleClose, control }) => {
    const { state: { is_ftm_updating } } = useContext(Context);

    const commonProps = { register, reset, errors, control };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-3">
                    <NavList />
                </div>
                <div className="col-lg-9 mt-lg-0 mt-4">
                    <ChangeProfile />

                    <Card id="personalInformation" title="PERSONAL INFORMATION">
                        <PersonalInformation {...commonProps} setValue={setValue} />
                    </Card>
                    <Card id="licenceAndVehicleDetails" title="Licence & Vehicle Details">
                        <LicenceAndVehicleDetails {...commonProps} />
                    </Card>
                    <Card id="nextOfKin" title="Next Of Kin">
                        <NextOfKin {...commonProps} />
                    </Card>
                    <Card id="taxAndInsuranceDetails" title="Tax and Insurance Details">
                        <TaxAndInsuranceDetails {...commonProps} />
                    </Card>
                    <Card id="businessDetails" title="Business Details">
                        <BusinessDetails {...commonProps} />
                    </Card>
                    <Card id="bankDetails" title="Bank Details">
                        <BankDetails {...commonProps} />
                    </Card>
                    <Card id="ftmAvailability" title="Availability">
                        <FtmAvailability register={register} reset={reset} errors={errors} />
                    </Card>

                    <div className="card mt-4" id="updateTradesperson">
                        <div className="card-body">
                            <div className="d-flex align-items-center mb-sm-0 mb-4">
                                <div className="w-50">
                                    <h5>UPDATE TRADESPERSON</h5>
                                    {/* <p className="text-sm mb-0">Once you delete your account, there is no going back. Please be certain.</p> */}
                                </div>
                                <div className="w-50 text-end">
                                    <button
                                        className={`btn btn-outline-${theme.close_btn} mb-3 mb-md-0 ms-auto btn-sm`}
                                        type="button" onClick={handleClose}
                                        disabled={is_ftm_updating}
                                    >CLOSE</button>
                                    <button
                                        className={`btn bg-gradient-${theme.main} mb-0 ms-2 btn-sm`}
                                        type="submit"
                                        disabled={is_ftm_updating}
                                    >
                                        {is_ftm_updating ? <BtnSpinner /> : "UPDATE"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Body;