import React, { useContext } from "react";
import FilterButton from "./filterButton";
import Context from "../context";

const Header = () => {
    const context = useContext(Context);

    return (
        <div className="row">
            <div className="col-lg-6 col-7">
                <h6 className="text-uppercase text-white text-capitalize ps-3">
                    Notifications ({context.state.search_value?.replace(/_/g, " ")})
                </h6>
            </div>
            <div className="col-lg-6 col-5 my-auto text-end">
                {/* <button className="btn bg-gradient-dark mb-0 btn-sm me-3">
                    <i className="material-icons text-sm">clear_all</i>&nbsp;&nbsp;CLEAR ALL
                </button> */}
                {/* <FilterButton/> */}
            </div>
        </div>
    );
}

export default Header;