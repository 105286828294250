import React from "react";
import { AiFillCamera } from "react-icons/ai";

const ImageUpload = (props) => {

    return(
        <>
            <div className="mb-3" style={{marginTop: '5%'}}>
                <input 
                    type="file" 
                    id="upload" 
                    hidden 
                    multiple 
                    onChange={(event) => props.loadImage(props.images, event.target.files, props.dispatch)}
                />
                <div className="d-grid gap-2" style={{marginTop: '30px'}}>
                    <label 
                        htmlFor="upload"
                        className={`btn btn-block btn-sm btn-outline-dark ${props.is_loading ? "disabled" : ""}`}
                        style={{
                            fontWeight: "unset"
                        }}
                        disabled={props.is_loading}
                    >
                        <AiFillCamera style={{fontSize: '18px'}}/>
                        {"  "}UPLOAD IMAGES
                    </label>
                </div>
            </div>

                
            {props.images !== null || typeof props.images !== 'undefined' ? ( 
                <div 
                    className="mb-3" 
                    style={{
                        borderStyle: "dotted", 
                        textAlign: "center", 
                        padding: "15px 0"
                    }}
                > 
                    {props.filePreView(props.images, props.dispatch)}
                </div>
            ) : null}
        </>
    );
}

export default ImageUpload;