import React, {useContext} from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';

import Form from 'react-bootstrap/Form';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';

import {theme} from '../../theme';

import BtnSpinner from "../../components/btnSpinner";

import Context from "../context";
import { submitLogin } from "../service";

const LoginForm = () => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const context = useContext(Context);
    let navigate = useNavigate();

    return(
        <Form onSubmit={handleSubmit((data) => submitLogin(data, context.dispatch, reset, navigate))}>
            <FormControl error={errors.email} fullWidth>
                <label><b>Email</b></label>
                <TextField
                    error={errors.email}
                    placeholder="Email"
                    size="small"
                    type="email"
                    {...register("email", { required: true })}
                />
                {errors.email && <FormHelperText>Email is required</FormHelperText>}
            </FormControl>

            <FormControl error={errors.password} fullWidth className="mt-3">
                <label><b>Password</b></label>
                <TextField
                    error={errors.password}
                    placeholder="Password"
                    size="small"
                    type="password"
                    {...register("password", { required: true })}
                />
                {errors.password && <FormHelperText>Password is required</FormHelperText>}
            </FormControl>

            <p className="text-sm text-left mt-2">
                <Button 
                    variant="text" 
                    className={`text-${theme.main} text-gradient font-weight-bold`}
                    onClick={()=>context.dispatch({
                        type: 'IS_FORGOT_PASSWORD',
                        is_forgot_password: true
                    })}
                >
                    Forgot password
                </Button>
            </p>

            <div className="text-center">
                <button type="submit" className={`btn bg-gradient-${theme.main} w-100  mb-2`} disabled={context.state.is_loading}>
                    {context.state.is_loading ? (
                        <>
                            <BtnSpinner/>
                        </>
                    ) : "LOGIN"}
                </button>
            </div>
        </Form>
    );
}

export default LoginForm;