import { jobsRef, usersRef} from '../../firebase';

export const newFtmCount = async (setFtmCount) => {
    try {
        // Assuming usersRef is a valid Firestore collection reference
        usersRef
            .where('user_is_new', '==', true)
            .where('user_type', '==', 'FTM')
            .onSnapshot((querySnapshot) => {
                setFtmCount(querySnapshot.size);
                console.log('snapshot.size:', querySnapshot.size);
            });
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const cancelJobCount = async (setCancelJobCount) => {
    try {
        jobsRef
            .where('job_details.status', '==', 9)
            .onSnapshot((querySnapshot) => {
                setCancelJobCount(querySnapshot.size);
                console.log('cancelJobCount snapshot.size:', querySnapshot.size);
            });
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}