import React, { useReducer, useEffect } from "react";
import Reducer from "./reducer";
import Context from "./context";
import { initialState } from "./initialState";
import Html from "./html";
import { loadData } from "./service";

const RejectedJobs = () => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        loadData(state.current_page, state.search_value , dispatch);
    }, [state.current_page, state.search_value]);

    return(
        <Context.Provider value={{state, dispatch}}>
            <Html/>
        </Context.Provider>
    );
}

export default RejectedJobs;