/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Template from "../../components/template";
import CardWithHeaderTemplate from "../../components/cardWithHeaderTemplate";
import HeaderView from "./components/headerView";
import Table from "./components/table";
import PaginationView from "./components/paginationView";
import CreateForm from "./components/createForm";

const Html = () => {
    return(
        <Template>
            <div className="row mb-4">
                <div className="col-lg-8 col-md-6 mb-md-0 mb-4">
                    <CardWithHeaderTemplate
                        header={
                            <HeaderView 
                                header_name="USERS LIST"
                            />
                        } 
                        body={<Table/>} 
                        footer={<PaginationView/>}
                    />
                </div>
                <div className="col-lg-4 col-md-6">
                    <CardWithHeaderTemplate
                        header={
                            <HeaderView 
                                header_name="CREATE USERS"
                            /> 
                        } 
                        body={<CreateForm/>} 
                        footer={<></>}
                    />
                </div>
            </div>
        </Template>
    );
}

export default Html;