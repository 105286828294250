export const theme = {
    main: process.env.REACT_APP_MAIN,
    main_code:process.env.REACT_APP_MAIN_CODE,
    main_bg_color:process.env.REACT_APP_MAIN_BG_COLOR,
    main_text_code:process.env.REACT_APP_MAIN_TEXT_CODE,
    action_btn: process.env.REACT_APP_ACTION_BTN,
    action_btn_code: process.env.REACT_APP_ACTION_BTN_CODE,
    close_btn: process.env.REACT_APP_CLOSE_BTN,
    close_btn_code: process.env.REACT_APP_CLOSE_BTN_CODE,
    redirect_btn: process.env.REACT_APP_REDIRECT_BTN,
    cancel_btn: process.env.REACT_APP_CANCEL_BTN,
    delete_btn: process.env.REACT_APP_DELETE_BTN,
    delete_btn_code: process.env.REACT_APP_DELETE_BTN_CODE,
    cancel_btn_code: process.env.REACT_APP_CANCEL_BTN_CODE,
    cancel_text_code: process.env.REACT_APP_CANCEL_TEXT_CODE,
    box_bg_color: process.env.REACT_APP_BOX_BG_COLOR, 
    box_text_color: process.env.REACT_APP_BOX_TEXT_COLOR,
    currency: process.env.REACT_APP_CURRENCY,
    left_msg_bg_color_ftm: process.env.REACT_APP_LEFT_MSG_BG_COLOR_FTM,
    left_msg_bg_color_cus: process.env.REACT_APP_LEFT_MSG_BG_COLOR_CUS,
    left_msg_text_color_cus: process.env.REACT_APP_LEFT_MSG_TEXT_COLOR_CUS,
    left_msg_text_color: process.env.REACT_APP_LEFT_MSG_TEXT_COLOR,
    right_msg_bg_color: process.env.REACT_APP_RIGHT_MSG_BG_COLOR,
    right_msg_text_color: process.env.REACT_APP_RIGHT_MSG_TEXT_COLOR,
    checkbox_fill_color: process.env.REACT_APP_CHECKBOX_FILL_COLOR,
    sidebar_notification_count_color: process.env.REACT_APP_SIDEBAR_NOTIFICATION_COUNT_COLOR,
    tradesperson_pay: process.env.REACT_APP_TRADESPERSON_PAY,
    tradesperson_not_pay: process.env.REACT_APP_TRADESPERSON_NOT_PAY,
    ftm_pay: process.env.REACT_APP_FTM_PAY,
    ftm_penalty: process.env.REACT_APP_FTM_PENALTY,
    ftm_job_cancel_charges: process.env.REACT_APP_FTM_JOB_CANCEL_CHARGES,
}