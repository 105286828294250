import React from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IconButton from '@mui/material/IconButton';

import { useReducer } from "react";
import Reducer from "./reducer";
import { initialState } from "./initialState";
import { useEffect } from "react";
import { loadData } from "./service";
import Context from "./context";
import DivSpinner from "../../components/divSpinner";
import Html from "./html";
import CertificateTypes from "../certificateTypes";
import AddSubSkills from "./components/addSubSkill";
import EditSubSkills from "./components/editSubSkills";
import MaterialCommissions from "./components/materialCommissions";
import OffcanvasTemplate from "../../components/offcanvasTemplate";

const SubSkills = ({show, skills, handleClose}) => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        loadData(skills.skill_id, dispatch);
    }, [skills.skill_id]);

    return(
        <Context.Provider value={{skill_id: skills.skill_id, state: state, dispatch: dispatch}}>
            <OffcanvasTemplate
                open={show} 
                handleClose = {handleClose} 
                placement = {'end'}  
                title = {skills.skill_name}
                body = {state.is_loading ? <DivSpinner/> : <Html/>}
                footer = {null}
                search = {
                    <IconButton
                        edge="end"
                        className="cursor-pointer"
                        onClick={() => dispatch({type: 'IS_SHOW_ADD_SUB_SKILLS', is_show_add_sub_skills: true})}
                    >
                        <AddBoxIcon />
                    </IconButton>
                }
            />

            <AddSubSkills/>
            <EditSubSkills/>
            <MaterialCommissions/>
        </Context.Provider>
    );
}

export default SubSkills;