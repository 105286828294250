import React from "react";
import { NavLink } from 'react-router-dom';

const Header = ({is_icon_sidebar}) => {
    return(
        <div className="sidenav-header">
            <i 
                className="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none" 
                aria-hidden="true" 
                id="iconSidenav"
            />
            <NavLink className="navbar-brand m-0" to="/dashboard">
                <img 
                    src={`/assets/img/${is_icon_sidebar ? 'instafix-favicon.png' : 'instafix-logo-1.png'}`} 
                    className="navbar-brand-img h-100" 
                    alt="main_logo"
                />
                {/* {!is_icon_sidebar ? <span className="ms-1 font-weight-bold text-white">{process.env.REACT_APP_SYSTEM_NAME}</span> : null} */}
            </NavLink>
        </div>
    );
}

export default Header;