import React, { useState, useEffect } from "react";

import { cancelJobCount, newFtmCount } from "./service";
import FullSidebar from "./components/fullSidebar";
import IconSidebar from "./components/iconSidebar";

const Sidebar = ({isFullSidebar, setIsFullSidebar}) => {
    const [ftmCount, setFtmCount] = useState(0);
    const [jobCount, setCancelJobCount] = useState(0);

    useEffect(() => {
        newFtmCount(setFtmCount);
        cancelJobCount(setCancelJobCount);
    }, []);


    return(
        <>
            {isFullSidebar ? (
                <FullSidebar 
                    ftmCount = {ftmCount}
                    jobCount = {jobCount}
                    isFullSidebar = {isFullSidebar}
                    setIsFullSidebar = {setIsFullSidebar}
                />
            ) : (
                <IconSidebar 
                    ftmCount = {ftmCount}
                    jobCount = {jobCount}
                    isFullSidebar = {isFullSidebar}
                    setIsFullSidebar = {setIsFullSidebar}
                />
            )}
        </>
    );
}

export default Sidebar;