const NoData = () => {
    return (
        <div className="d-block p-2">
            <div className="d-flex p-2">
                <div className="ms-3">
                    <h6 className="mb-0" >
                        Sorry, No data
                    </h6>
                </div>
            </div>
        </div>
    );
}

export default NoData;