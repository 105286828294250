export const initialState = {
    is_loading: true,
    disputes: [],
    is_open_chat: false,
    current_page: 1,
    selected_dispute: {},
    page_size: 10,
    last_doc: null,
    is_show_load_move_btn: true,
    is_show_credit_notes: false,
    is_show_sales_invoices: false,
    credit_notes: [],
    is_loading_credit_notes: true,
    sales_invoices: [],
    is_loading_sales_invoices: true,
    current_page_cn: 1,
    current_page_si: 1
}