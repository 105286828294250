import React from "react";
import { Box, Link } from "@mui/material";
import useCustomNavigate from '../components/navigation';

const LinkToLogin = () => {
    const { navigateToHome } = useCustomNavigate();
    
    return(
        <Box>
            <Link 
                component="button"
                underline="hover"
                className="text-success"
                onClick={navigateToHome}>
                    Or sign in as a different user
            </Link>
        </Box>
    );
}

export default LinkToLogin;