import React, { useContext } from "react";
import Context from "../context";
import CardWithHeaderTemplate from "../../../components/cardWithHeaderTemplate";

const BankInfo = () => {
    const context = useContext(Context);

    return(
        <CardWithHeaderTemplate
            header={
                <div className="row">
                    <div className="col-lg-6 col-7">
                        <div className="d-flex align-items-center">
                            <div className="ms-3">
                                <h6 className="mb-0 d-block text-white">
                                    BANK DETAILS
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            }
            body={
                <ul className="list-group">
                    <li className="list-group-item border-0 ps-0 text-sm">
                        <strong className="text-dark">Bank Name:</strong> 
                        &nbsp; {context.state.ftm.ftm_bank_name}
                    </li>
                    <li className="list-group-item border-0 ps-0 text-sm">
                        <strong className="text-dark">Bank Sort Code:</strong> 
                        &nbsp; {context.state.ftm.ftm_bank_sort_code}
                    </li>
                    <li className="list-group-item border-0 ps-0 text-sm">
                        <strong className="text-dark">Bank Account Number:</strong> 
                        &nbsp; {context.state.ftm.ftm_bank_account_number}
                    </li>
                </ul>
            }
            footer={null}
            extraCardClassName={`blur shadow-blur max-height-vh-70 h-100`}
            extraBodyClassName={`overflow-auto overflow-x-hidden`}
        />
    );
}

export default BankInfo;