export const HeaderAvatar = () => {
    return (
        <div className="row gx-4 mb-2">
            <div className="col-auto">
                <div className="avatar avatar-xl position-relative">
                    <img src="../../../assets/img/413039.png" alt="profile_image" className="w-100 border-radius-lg shadow-sm"/>
                </div>
            </div>
            <div className="col-auto my-auto">
                <div className="h-100">
                    <h5 className="mb-1">
                        Disputes
                    </h5>
                    {/* <p className="mb-0 font-weight-normal text-sm">
                        CEO / Co-Founder
                    </p> */}
                </div>
            </div>
        </div>
    )
}