import React, { useContext } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from '@mui/material/Button';

import Context from "../../context";
import Profile from "./profile";
import BasicInformation from "./basicInformation";
import NicLicenceVehicleDetails from "./licenceVehicleDetails";
import AddressInformation from "./addressInformation";
import { theme } from "../../../../theme";
import OtherContactInformation from "./otherContactInformation";
import { useNavigate } from "react-router-dom";
import OffcanvasTemplate from "../../../../components/offcanvasTemplate";

const PreviousDetails = () => {
    let navigate = useNavigate();
    const context = useContext(Context);

    return (
        <OffcanvasTemplate
            open={context.state.is_open_previous_details}
            handleClose={() => context.dispatch({ type: 'IS_OPEN_PREVIOUS_DETAILS', is_open_previous_details: false })}
            placement={'end'}
            title={`PERSONAL DETAILS`}
            body={
                <>
                    <Profile />
                    <br />
                    <BasicInformation />
                    <br />
                    <NicLicenceVehicleDetails />
                    <br />
                    {/* <AddressInformation /> */}

                    {context.state.selected_ftm?.ftm?.ftm_is_new != 1 && (
                        <>
                            <br />
                            <OtherContactInformation />
                        </>
                    )}
                </>
            }
            footer={
                <div className="d-grid gap-2" style={{ padding: '10px' }}>
                    <Button
                        variant="contained"
                        color={theme.action_btn}
                        onClick={() => navigate(`/ftm/view/${context.state.selected_ftm?.ftm?.ftm_id}`)}
                    >
                        REVIEW
                    </Button>
                </div>
            }
        />
    );
}

export default PreviousDetails;