import React, { useContext, useRef, useEffect } from "react";
import Context from "../context";
import FtmInfo from "./ftmInfo";
import PersonalDocuments from "./personalDocuments";
import FtmDocuments from "./ftmDocuments";
import Skills from "./skills";
import Btn from "./btn";
import ProfileImage from "./profileImage";
import FtmMap from "./ftmMap";
import FooterBtn from "./footerBtn";
import Notification from "./notification";
import BankInfo from "./bankInfo";
import ConnectTracker from "./connectTracker";
import TrackerDispatch from "./trackerDispatch";
import CardWithHeaderTemplate from "../../../components/cardWithHeaderTemplate";
import LicenceAndVehicleDetails from "./licenceAndVehicleDetails";
import NextOfKin from "./nextOfKin";
import TaxAndInsuranceDetails from "./taxAndInsuranceDetails";
import BusinessDetails from "./businessDetails";
import useWindowDimensions from "../../../components/hooks/useWindowDimensions";

const Profile = () => {
    const context = useContext(Context);
    const { width } = useWindowDimensions();
    const documentsRef = useRef(null);
    const trackerRef = useRef(null);
    const footerBtnRef = useRef(null);

    useEffect(() => {

        if (context.state.scroll_to_documents_view) {
            documentsRef.current?.scrollIntoView({ block: "start", behavior: "smooth" });
        }

        if (context.state.scroll_to_tracker_view) {
            trackerRef.current?.scrollIntoView({ block: "start", behavior: "smooth" });
        }

        if (context.state.scroll_to_footer_btns_view) {
            footerBtnRef.current?.scrollIntoView({ block: "start", behavior: "smooth" });
        }

        context.dispatch({
            type: "SCROLL_IN_TO_VIEW",
            scroll_to_documents_view: false,
            scroll_to_tracker_view: false,
            scroll_to_footer_btns_view: false,
        })
    }, [context.state.scroll_to_documents_view, context.state.scroll_to_tracker_view, context.state.scroll_to_footer_btns_view]);

    return(
        <div className="container-fluid px-2 px-md-4">
            {context.state?.ftm?.ftm_is_verify != 1 && context.state?.ftm?.ftm_is_new == 1 ? (
                <div className="min-height-100 border-radius-xl mt-4" style={{minHeight: '50px !important'}}></div>
            ):(
                <FtmMap/>
            )} 
            <div className="card card-body mx-3 mx-md-4 mt-n6">
                <Notification/>
                <div className="row gx-4 mb-2">
                    <ProfileImage/>
                    <Btn/>
                </div>
                
                <div className="row mt-4">

                        <div className="col-12 col-xl-12">
                            <div className="row">
                                <div className="col-12 col-xl-8">
                                    <CardWithHeaderTemplate
                                        header={null}
                                        body={
                                            <>
                                                <div className="row">
                                                    <div className="col-12 col-xl-6">
                                                        <FtmInfo/>
                                                    </div>
                                                    <div className="col-12 col-xl-6">
                                                        <LicenceAndVehicleDetails/>
                                                    </div>
                                                </div>

                                                <div className="row mt-5">
                                                    <div className="col-12 col-xl-6">
                                                        <NextOfKin/>
                                                    </div>
                                                    <div className="col-12 col-xl-6">
                                                        <TaxAndInsuranceDetails/>
                                                    </div>  
                                                </div>

                                                <div className="row mt-5">
                                                    <div className="col-12 col-xl-6">
                                                        <BusinessDetails/>
                                                    </div>
                                                    <div className="col-12 col-xl-6">
                                                        <BankInfo/>
                                                    </div>  
                                                </div>
                                            </>
                                        }
                                        footer={null}
                                        extraCardClassName={`card-plain h-100`}
                                        extraBodyClassName={`overflow-auto overflow-x-hidden`}
                                    />
                                </div>
                                <div className="col-12 col-xl-4" style={{marginTop: width < 1320 ? '50px':'44px'}}>
                                    <Skills/>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-xl-12 mt-5">
                            <CardWithHeaderTemplate
                                header={
                                    <div className="row">
                                        <div className="col-8 my-auto">
                                            <div className="numbers">
                                                <h6 className="text-white font-weight-bolder mb-0 ms-3">
                                                    TRACKER
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                }
                                body={
                                    <div className="row" ref={trackerRef}>
                                        <ConnectTracker/>
                                        <TrackerDispatch/>
                                    </div>
                                }
                                footer={null}
                            />
                        </div>
                        
                        <PersonalDocuments/>
                        <div ref={documentsRef}>
                            <FtmDocuments/>
                        </div>
                    {/* </div> */}
                </div>
                
                <div ref={footerBtnRef}>
                    <FooterBtn/>
                </div>
            </div>
        </div>
    )
}

export default Profile;