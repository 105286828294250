import React, { useContext } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import IconButton from '@mui/material/IconButton';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import DownloadIcon from '@mui/icons-material/Download';
import { Context } from '../provider';
import { downloadFtmAttachment } from '../service';

export default function ListBody() {
    const context = useContext(Context);

    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {context.state.attachments.length > 0 ? (
                <>
                    {context.state.attachments?.map((attachment, index) => (
                        <React.Fragment key={index}>
                            <ListItem 
                                alignItems="flex-start" 
                                secondaryAction={
                                    <IconButton 
                                        edge="end" 
                                        aria-label="download" 
                                        onClick={() => downloadFtmAttachment(attachment.ftm_attachment_id, attachment?.ftm_attachment_file_name.split('.').pop().toLowerCase())}
                                    >
                                        <DownloadIcon />
                                    </IconButton>
                                }
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                        <FilePresentIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={attachment.ftm_attachment_title}
                                    secondary={
                                        <React.Fragment>
                                            {attachment.ftm_attachment_description}
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
        
                            {context.state.attachments.length !== (index+1) && (
                                <Divider variant="inset" component="li" />
                            )}
                        </React.Fragment>  
                    ))}
                </>
            ) : (
                <ListItem>
                    <ListItemText 
                        primary="No attachments"
                        sx={{ textAlign: 'center' }} 
                    />
                </ListItem>
            )}                                                              
        </List>
    );
}
