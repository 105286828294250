import React, { useReducer, useEffect } from "react";
import Html from "./html";
import Reducer from "./reducer";
import Context from "./context";
import { initialState } from "./initialState";
import { loadData } from "./service";

const Skills = () => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        loadData(dispatch);
    }, []);

    return(
        <Context.Provider value={{state, dispatch}}>
            <Html/>
        </Context.Provider>
    );
}

export default Skills;