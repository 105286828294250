import React, { useContext } from "react";
import Header from "./header";
import DisputesChat from "../../../jobs/disputes/chat";
import { Context } from "../provider";
import EmptyChat from "../../components/emptyChat";

const DisputesBody = () => {
    const context = useContext(Context);

    return (
        <DisputesChat
            show = {false}
            dispute = {context.state.selected_dispute}
            is_inbox = {true}
            handleClose = {() => {}}
            header = {<Header/>}
            emptyChat = {<EmptyChat/>}
            is_have_selected_dispute = {context.state.selected_dispute !== null}
        /> 
    );
}

export default DisputesBody;