import { useContext, useEffect } from "react";
import { useGoogleMaps } from "react-hook-google-maps";

import { makeJobMarkers } from "../service";
import { Context } from "../provider";

export const Map = () => {
    const context = useContext(Context);
    const { ref, map, google } = useGoogleMaps(
        process.env.REACT_APP_GOOGLE_MAP_KEY,
        {
            center: { lat: 51.5286416, lng: -0.1015987 },
            zoom: 10
        }
    );

    useEffect(() => {
        makeJobMarkers({
            map: map, 
            google: google, 
            context: context
        });
    }, [map, context.state.selected_job]);

    return (
        <div className="card mt-4" id="basic-info">
            <div className="card-header">
                <h5>VEHICLE HISTORY</h5>
            </div>
            <div className="card-body pt-0">
                <div ref={ref} style={{ width: '100%', height: 800 }} />
            </div>
        </div>
    )
}