import { useContext } from "react";
import Context from "../context";
import ChatDisputes from "../../chat";
import { theme } from "../../../../theme";
import useWindowDimensions from "../../../../components/hooks/useWindowDimensions";

export const Chat = () => {
    const context = useContext(Context);
    const { width } = useWindowDimensions();

    return (
        <div className={`col-md-7 col-lg-${width > 1320 ? 5 : 6}`}> 
            <div className="card blur shadow-blur max-height-vh-70  h-100">
                <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
                    <div className={`bg-gradient-${theme.main} shadow-${theme.main} border-radius-lg p-3`}>
                        <div className="row">
                            <div className="col-md-9 col-lg-10">
                                <div className="d-flex align-items-center">
                                    <div className="ms-3">
                                        <h6 className="mb-0 d-block text-white">
                                            {context.state.selected_dispute?.dispute_details?.type}
                                        </h6>
                                        <span className="text-sm text-white opacity-8">
                                            {context.state.selected_dispute?.dispute_job?.service_category?.sub_skill?.name} - {context.state.selected_dispute?.dispute_job?.service_category?.skill?.name}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <ChatDisputes
                    show={false}
                    isInbox={true}
                    dispute={context.state.selected_dispute}
                    handleClose={() => context.dispatch({
                        type: 'IS_OPEN_CHAT',
                        is_open_chat: false,
                        selected_dispute: {}
                    })}
                />
            </div>
        </div>
    );
}