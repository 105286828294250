import React, { useContext } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

import Context from "../context";
import DivSpinner from "../../../components/divSpinner";
import { theme } from "../../../theme";
import { deleteUser } from "../service";

const Table = () => {
    const context = useContext(Context);

    return(
        <>
            {context.state.is_loading ? (
                <div style={{height : `${window.innerHeight-387}px`}}>
                    <DivSpinner/>
                </div>
            ) : (
                <div className="table-responsive p-0">
                    <table className="table align-items-center mb-0">
                        <thead>
                            <tr>
                                {[
                                    '#',
                                    'Full name',
                                    'Contact number',
                                    'Email',
                                    'Created',
                                    'Status',
                                ].map((item, index) => (
                                    <th 
                                        className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7" 
                                        key={index}
                                    >
                                        {item}
                                    </th>
                                ))}
                                <th className="text-secondary opacity-7"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {context.state.list.map((user, index) => (
                                <tr key={index}>
                                    {[
                                        user.admin_id,
                                        user.admin_full_name,
                                        user.admin_contact_number,
                                        user.admin_email,
                                        user.admin_createdAt,
                                        user.admin_status,
                                    ].map((item, index) => (
                                        <td className="align-middle">
                                            <span className="text-secondary text-sm font-weight-bold" key={index}>{item}</span>
                                        </td>
                                    ))}
                                    <td className="align-middle">
                                        <IconButton 
                                            aria-label="delete" 
                                            color={theme.delete_btn} 
                                            onClick={() => deleteUser(user.admin_id,context.dispatch)}
                                        >
                                            <DeleteIcon/>
                                        </IconButton>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
}

export default Table;