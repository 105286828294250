import React, { useContext } from "react";
import { TextField, InputAdornment } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseIcon from '@mui/icons-material/Close';

import Context from "./context";
import { theme } from '../../theme';

const Html = ({width, is_filter, onSearch}) => {
    const context = useContext(Context);

    const handleChange = (value) => {
        context.dispatch({
            type: 'SET_SEARCH_TEXT',
            search_text: value
        });
    };

    return(
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight:  '10px'}}>
            <TextField
                placeholder= {!is_filter ? "Search by Keyword..." : "Enter job id"}
                variant="outlined"
                size="small"
                sx={{ backgroundColor: theme.main_bg_color, borderRadius: '10px', width: width }}
                value={context.state.search_text}
                InputProps={{
                    startAdornment: (
                        !is_filter && (
                            <InputAdornment position="start">
                                <SearchOutlinedIcon />
                            </InputAdornment>
                        )
                    ),
                    endAdornment: (
                        !is_filter ? (
                            <IconButton 
                                sx={{ p: '5px' }} 
                                onClick={() => handleChange('')}
                            >
                                <CloseIcon />
                            </IconButton>
                        ) : (
                            <>
                                <IconButton 
                                    sx={{ p: '5px' }} 
                                    onClick={() => onSearch(context.state.search_text)}
                                >
                                    <SearchOutlinedIcon />
                                </IconButton>

                                <IconButton 
                                    sx={{ p: '5px' }} 
                                    onClick={() => {
                                        handleChange('');
                                        onSearch("");
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </>
                        )
                    ),
                }}
                onChange={(event) => handleChange(event.target.value)}
            /> 
        </div>   
    );
}

export default Html;