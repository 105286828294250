import { GET } from "../API/get";
import { POST } from "../API/post";
import { getUserInfo, setUserInfo } from '../storage';

export const loadData = async (dispatch) => {
    dispatch({ type: "IS_LOADING" });

    let userInfo = getUserInfo();
    let response = await GET("admin-info/"+userInfo.admin_id);

    if(response.status === "Success"){
        dispatch({ type: "IS_LOADED" });
        dispatch({ 
            type: "SET_ADMIN_INFO", 
            admin_id: userInfo.admin_id,
            admin_info: response.admin_info
        });
    }
}

export const submitLockScreenVerify = async (option) => {
    option.dispatch({ type: "IS_LOADING" });

    let response = await POST("admin-lockscreen-verify", {
        admin_id: option.admin_id,
        admin_password: option.admin_password
    });

    if(response.status === "Success"){
        setUserInfo({
            admin_id: response.admin_id,
            admin_name: response.admin_name,
            token: response.token,
            refreshToken: response.refreshToken
        });
        option.navigateBack();
    } else {
        option.dispatch({ type: "IS_LOADED" });
    }
}