import { useContext } from "react";
import Switch from '@mui/material/Switch';

import { Context } from "../provider";
import Search from "../../../components/searchField";

const label = { inputProps: { 'aria-label': 'Switch demo' } };
const ListHeader = () => {
    const context = useContext(Context);

    return(
        <div className="row">
            <div className="col-lg-6 col-7">
                <div className="d-flex align-items-center">
                    <div className="ms-3">
                        <h6 className="mb-0 d-block text-white">
                            CHAT LIST
                        </h6>
                        <span className="text-sm text-white opacity-8">
                            ARCHIVE <Switch {...label} color="warning" size="small" onClick={() => context.dispatch({ type: 'IS_GET_ARCHIVE_JOB', is_get_archive_job: !context.state.is_get_archive_job })}/>
                        </span>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-5 my-auto text-end">
                <Search
                    onSearch = {(search_text) => {
                        console.log("🚀 ~ ListHeader ~ search_text:", search_text)
                        context.dispatch({ type: "SET_JOBS_LIST_SEARCH_TEXT", jobs_list_search_text: search_text });
                    }}
                    is_filter = {true}
                />
            </div>
        </div>
    );
}

export default ListHeader;