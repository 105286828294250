import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Alert from '@mui/material/Alert';
import { useForm } from 'react-hook-form';
import { Container } from 'react-bootstrap';
import { Stack, TextField } from '@mui/material';
import { saveNewCertificate } from '../service';
import { theme } from '../../../theme';
import BtnSpinner from '../../../components/btnSpinner';
import Context from '../context';
import ModalTemplateWithForm from '../../../components/modal/modalTemplateWithForm';

const AddCertificate = () => {
    const {
        register,
        handleSubmit,
        reset
    } = useForm();

    const context = useContext(Context);

    return (
        <ModalTemplateWithForm
            show={context.state.is_show_add_certificate_types} 
            closeFun = {() => context.dispatch({type: 'IS_SHOW_ADD_CERTIFICATE_TYPES', is_show_add_certificate_types: false})}
            size = {null}
            centered = {false}
            fullscreen = {false}
            dialogClassName = {null}
            title = {`Add a new certificate type for ${context.skill_name}`}
            body = {
                <Container>
                    {context.state.error !== null ? (
                        <Stack sx={{ width: '100%' }} spacing={2} className="mb-3">
                            <Alert severity="error">{context.state.error}</Alert>
                        </Stack>
                    ) : null}

                    <label style={{ color: 'black' }}>CERTIFICATE TYPES</label>
                    <TextField
                        placeholder="Certificate type name"
                        fullWidth
                        size="small"
                        InputProps={{
                            style: { backgroundColor: '#fff' },
                        }}
                        {...register('certificate_type_name', { required: false })}
                    />
                </Container>
            }
            footer = {
                <>
                    <Button
                        variant={theme.close_btn}
                        onClick={() => context.dispatch({type: 'IS_SHOW_ADD_CERTIFICATE_TYPES', is_show_add_certificate_types: false})}
                        size="sm"
                        disabled={context.state.btn_loading}
                    >
                        {context.state.btn_loading ? <BtnSpinner /> : 'CLOSE'}
                    </Button>
                    <Button
                        variant={theme.action_btn}
                        type="submit"
                        size="sm"
                        disabled={context.state.btn_loading}
                    >
                        {context.state.btn_loading ? <BtnSpinner /> : 'SAVE'}
                    </Button>
                </>
            }
            onSubmit = {handleSubmit((data) => saveNewCertificate(context.skill_id, data, context.type, context.dispatch, reset))}
        />
    );
};

export default AddCertificate;