export default function Reducer(prevState, action){
    switch (action.type) {
        case "LOADING":
            return {
                ...prevState,
                is_loading: true,
            };
        case "LOADED":
            return {
                ...prevState,
                is_loading: false
            };
        case "IS_BTN_LOADING":
            return {
                ...prevState,
                is_btn_loading: action.is_btn_loading
            };
        case "SET_DETAILS":
            return {
                ...prevState,
                is_loading: false,
                details: action.details
            };
        case "SELECTED_INVOICE_TYPE":
            return {
                ...prevState,
                selected_invoice_type: action.selected_invoice_type
            };
        default:
            throw new Error();
    }
}