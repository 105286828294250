import React, { useContext } from "react";
import { Container, Box, Typography, Alert, Stack } from '@mui/material';
import Context from './context';
import CompanyLogo from './components/companyLogo';
import PasswordField from "./components/passwordField";
import LinkToLogin from "./components/linkToLogin";
import CardWithHeaderTemplate from "../components/cardWithHeaderTemplate";

const Html = () => {
    const context = useContext(Context);

    return (  
        <Container 
            className="container" 
            sx={{ height: '100vh', color: '#333333' }}>
                <Box 
                    className="text-center" 
                    display="flex" 
                    flexDirection="column" 
                    alignItems="center" 
                    justifyContent="center" 
                    height="100%"
                >
                    <CardWithHeaderTemplate
                        header={
                            <CompanyLogo />
                        } 
                        body={
                            <div>
                                {context.state.error !== '' ? (
                                    <Stack sx={{ width: '100%', px: 3}} spacing={2}>
                                        <Alert severity="error">{context.state.error}</Alert>
                                    </Stack>
                                ) : null}
                                <Box
                                    p={1}>
                                        <span style={{ fontWeight: '600', fontSize: '18px' }}>{context.state.details.admin_full_name}</span>
                                </Box>
                                <PasswordField />
                                <Typography variant="subtitle1" pl={3} pr={3}>Enter your new password and reset your password</Typography>
                                <LinkToLogin />
                            </div>
                        } 
                    />
                </Box>
        </Container>
    )
}

export default Html;