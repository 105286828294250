// import { firestore, jobsRef } from "../../firebase";

// // service.js
// // service.js
// export const fetchJobs = ({ is_get_archive_job, lastVisible, pageSize, searchText }) => {

//     console.log("🚀 ~ ************************************************")
//     console.log("🚀 ~ fetchJobs ~ searchText:", searchText)
//     console.log("🚀 ~ fetchJobs ~ searchText:", typeof searchText)
//     console.log("🚀 ~ fetchJobs ~ pageSize:", pageSize)
//     console.log("🚀 ~ fetchJobs ~ lastVisible:", lastVisible)
//     console.log("🚀 ~ fetchJobs ~ is_get_archive_job:", is_get_archive_job)
//     console.log("🚀 ~ ************************************************")
//     return new Promise((resolve, reject) => {
//         let query = jobsRef
//             .where('job_archive', '==', is_get_archive_job)
//             .orderBy("job_last_message_received_timestamp", "desc")
//             .orderBy('job_admin_unread_message_count', "desc")
//             .limit(pageSize);

//         if (lastVisible) {
//             query = query.startAfter(lastVisible);
//         }

//         if (searchText) {
//             query = query.where('search_keywords', 'array-contains-any', [searchText]);
//         }

//         query.get().then((snapshot) => {
//             let jobs = [];
//             snapshot.forEach((doc) => {
//                 let data = doc.data();
//                 jobs.push({
//                     doc_id: doc.id,
//                     ...data
//                 });
//             });

//             const lastVisible = snapshot.docs[snapshot.docs.length - 1];
//             const hasMoreJobs = snapshot.size === pageSize;

//             resolve({ jobs, lastVisible, hasMoreJobs });
//         }).catch((error) => {
//             reject(error);
//         });
//     });
// };

// export const updateAll = async () => {
//     // jobsRef.onSnapshot((snapshot) => {
//     //     snapshot.forEach((doc) => {
//     //         console.log("🚀 ~ snapshot.forEach ~ doc:", doc)
//     //         let ref = jobsRef.doc(doc.id);

//     //         ref.update({
//     //             job_archive: false
//     //         });
//     //     });
//     // });

//     const snapshot = await jobsRef.get();
//     const batch = firestore.batch();

//     snapshot.forEach(doc => {
//         let data = doc.data();
//         batch.update(doc.ref, {
//             search_keywords: [
//                 data.job_id.toString(),
//                 data.job_details?.service_category?.skill?.name,
//                 data.job_details?.service_category?.sub_skill?.name
//             ]
//         });
//     });

//     await batch.commit();
//     console.log('All documents updated successfully');
// }

import { jobsRef } from "../../firebase";

export const fetchJobs = ({ is_get_archive_job, jobs_list_search_text }) => {
    return new Promise((resolve, reject) => {
        let query = jobsRef
            .where("job_archive", "==", is_get_archive_job)
            .orderBy("job_last_message_received_timestamp", "desc")
            .orderBy("job_admin_unread_message_count", "desc")
            .limit(100);

        if (jobs_list_search_text !== "") {
            query = query.where('search_keywords', 'array-contains-any', [jobs_list_search_text.toLowerCase()]);
        }
            
        query.onSnapshot(
            (snapshot) => {
                let jobs = [];
                snapshot.forEach((doc) => {
                    let data = doc.data();
                    jobs.push({
                        doc_id: doc.id,
                        ...data,
                    });
                });
                resolve(jobs);
            },
            (error) => {
                reject(error);
            }
        );
    });
};
