export default function reducer(prevState, action) {
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true
            };

        case "IS_LOADED":
            return {
                ...prevState,
                is_loading: false
            };

        case "SET_ADMIN_DETAILS":
            return {
                ...prevState,
                is_loading: false,
                details: action.details
            };

        case "IS_SENDING":
            return {
                ...prevState,
                is_sending: action.is_sending,
                error: action.error,
            };
        default:
            throw new Error();
    }
}