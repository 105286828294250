import Offcanvas from "react-bootstrap/Offcanvas";

const OffcanvasTemplate = ({ open, handleClose, placement, title, body, footer, search }) => {
    return (
        <Offcanvas show={open} onHide={handleClose} placement={placement}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{title}</Offcanvas.Title>
                {search}
            </Offcanvas.Header>
            <Offcanvas.Body>
                {body}
            </Offcanvas.Body>
            {footer !== null && (
                <div className="offcanvas-footer">
                    {footer}
                </div>
            )}
        </Offcanvas>
    );
};

export default OffcanvasTemplate;
