import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import ChatImageUpload from "./chatImageUpload";
import Context from "../context";
import { sendTextMessages } from "../service";
import { theme } from "../../../../theme";

const FormSubmit = () => {
    const {
        register,
        handleSubmit,
        reset
    } = useForm();

    const context = useContext(Context);

    return (
        <form className="align-items-center" onSubmit={handleSubmit((data) => sendTextMessages(data, context.user_firebase_document_id, reset))}>
            <div className="input-group input-group-outline d-flex">
                <input 
                    type="text" 
                    name="text" 
                    className="form-control form-control-lg"
                    placeholder="Type your message"
                    {...register("text", { required: true })}
                />
                <ChatImageUpload/>
                <button className={`btn bg-gradient-${theme.main} mb-0`}>
                    <i className="material-icons">send</i>
                </button>
            </div>
        </form>
    );
}

export default FormSubmit;