import React, { useContext } from "react";
import Template from "../../components/template";
import JobsList from "./components/disputesList";
import ChatBody from "./components/chatBody";
import PageHeader from "../components/pageHeader";
import IconMenu from "./components/iconMenu";
import SalesInvoices from "../../components/salesInvoices";
import { Context } from "./provider";
import CreditNotes from "../../components/creditNote";
import Info from "./components/info";

const Html = () => {
    const context = useContext(Context);

    return (
        <Template>
            <div className="container-fluid px-2 px-md-4">
                <PageHeader/>
                <div className="card card-body mx-3 mx-md-4 mt-n6" style={{ backgroundColor: '#efefef' }}>
                    {/* <div className="row gx-4 mb-2">
                        <AdminInfo/>
                        <div className="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
                            <UserSelectionButton />
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="row mt-4">
                            <div className={`col-md-5 col-lg-1`} style={{marginTop: '-30px', width: '5%'}}>
                                <IconMenu/>
                            </div>
                            <div className="col-md-5 col-lg-4">
                                <JobsList/>
                            </div>
                            <div className="col-md-7 col-lg-7">
                                <ChatBody />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <SalesInvoices
                show = {context.state.is_show_sales_invoices}
                closeFun = {() => context.dispatch({
                    type: 'IS_SHOW_SALES_INVOICES',
                    is_show_sales_invoices: false
                })}
            />

            <CreditNotes 
                show = {context.state.is_show_credit_notes}
                closeFun = {() => context.dispatch({
                    type: 'IS_SHOW_CREDIT_NOTES',
                    is_show_credit_notes: false
                })}
            />

            <Info 
                show = {context.state.is_show_dispute_info}
                closeFun = {() => context.dispatch({
                    type: 'IS_SHOW_DISPUTE_INFO',
                    is_show_dispute_info: false
                })}
            />
        </Template>
    );
};

export default Html;
