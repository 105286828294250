import { FORMPOST } from "../../../API/post";
import { usersRef } from "../../../firebase";
import { getUserInfo } from "../../../storage";

export const loadMessages = async (user_firebase_document_id, dispatch) => {
    console.log("loadMessages job_id : ", user_firebase_document_id);
    
    if(user_firebase_document_id){
        dispatch({type: "IS_LOADING"});
        lastSeen(user_firebase_document_id);

        let query = usersRef.doc(user_firebase_document_id).collection('user_messages').orderBy('message_time');

        // Subscribe to changes in the messages collection
        const unsubscribe = query.onSnapshot((snapshot) => {
            console.log("snapshot: ", snapshot.docs.map(doc => doc.data()));
            dispatch({
                type: "IS_LOADED",
                messages_list: snapshot.docs.map(doc => doc.data()),
            });
        });

        // Ensure that unsubscribe is a function before returning it
        return unsubscribe;
    }
}

const lastSeen = (user_firebase_document_id) => {
    console.log("lastSeen user_firebase_document_id : ", user_firebase_document_id);
    let user_info = getUserInfo();
    let ref = usersRef.doc(user_firebase_document_id);

    ref.update({
        user_last_message_seen_user: user_info.admin_firebase_document_id,
        user_last_message_seen_user_timestamp: new Date().getTime()
    });
}

export const sendTextMessages = async (data, user_firebase_document_id, reset) => {
    // Get user info
    let user_info = getUserInfo();

    // Add a new message to the messages collection
    await usersRef.doc(user_firebase_document_id).collection('user_messages').add({
        message_content: {
            text: data.text,
            files: []
        },
        message_sender: {
            user_doc_id: user_info.admin_firebase_document_id,
            user_id: user_info.admin_id,
            user_name: user_info.admin_name,
            user_type: "ADMIN"
        },
        message_time: new Date().getTime()
    });

    // Reset the state or props as needed
    reset();
}

export const filePreView = (images, dispatch) => {
    let content = [];
    let count = 0;

    if(typeof images !== 'undefined'){
        for (let i = 0; i < images.length; i++) {
            content.push(
                <div className="img-wraps" key={count++}>
                    <span className="closes" title="Delete" onClick={() => removeImage(i, images, dispatch)}>×</span>
                    <img 
                        src={URL.createObjectURL(images[i])} 
                        style={{
                            height: "70px",
                            width: "70px"
                        }}
                        alt="Avatar"
                        className="img-responsive"
                    />
                </div>
            );
        }
    }

    return count > 0 ? content : <p><b>YOUR IMAGES</b></p>;
}

export const removeImage = (index, images, dispatch) => {
    images.splice(index, 1);
    
    dispatch({
        type: "SET_IMAGES",
        images: images
    });

    filePreView(images);
}

export const loadImage = (images, files, dispatch) => {
    console.log('files :', files);
    let files_list = typeof images !== 'undefined' ? images : [];

    for(let file of files){
        files_list.push(file);
    }

    dispatch({
        type: "SET_IMAGES",
        images: files_list
    });
}

export const sendMessageWithImages = async ({data, user_firebase_document_id, images, dispatch, reset, setIsOpen}) => {
    dispatch({type: "IS_LOADING_BTN", is_loading_btn: true});
    let user_info = getUserInfo();

    let upload_images = [];
    if(images.length > 0){
        let response = await FORMPOST("massages/files/upload", {
            images: images
        });

        if(response.status === "Success"){
            dispatch({type: "IS_LOADING_BTN", is_loading_btn: false});
            for(let item of response.list){
                upload_images.push({
                    path: item.image_path
                });
            }
        }
    }

    // console.log("sendMessageWithImages", {
    //     message_content: {
    //         text: data.caption,
    //         files: upload_images
    //     },
    //     message_sender: {
    //         user_doc_id: user_info.admin_firebase_document_id,
    //         user_id: user_info.admin_id,
    //         user_name: user_info.admin_name,
    //         user_type: "ADMIN"
    //     },
    //     message_time: new Date().getTime()
    // });

    await usersRef.doc(user_firebase_document_id).collection('user_messages').add({
        message_content: {
            text: data.caption,
            files: upload_images
        },
        message_sender: {
            user_doc_id: user_info.admin_firebase_document_id,
            user_id: user_info.admin_id,
            user_name: user_info.admin_name,
            user_type: "ADMIN"
        },
        message_time: new Date().getTime()
    });                                            

    reset();
    dispatch({
        type: "SET_IMAGES",
        images: []
    });
    setIsOpen(false);
}