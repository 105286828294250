import React from "react";
import { materialSubTotal, materialTotal } from "../utils";

const MaterialsList = (props) => {
    return(
        <>
            {props?.materials?.map((item, index) => (       
                <>               
                    <div className="card" key={index}>
                        <div className="card-body">
                            <h6>{item.request_material_item}</h6>
                            <p style={{marginBottom: '0px'}}>{item.request_material_description}</p>
                            <div className="row text-center">
                                <div className="col-6 border-end border-light">
                                    <p className="text-muted mt-1 mb-2 fw-normal">Qty</p>
                                    <p className="mb-0 fw-bold">{item.request_material_qty}</p>
                                </div>
                                <div className="col-6">
                                    <p className="text-muted mt-1 mb-2 fw-normal">Unit price (&#163;)</p>
                                    <p className="mb-0 fw-bold">
                                        {materialSubTotal(item)}
                                    </p>
                                </div>
                            </div>
                            <hr/>
                            <p 
                                style={{
                                    marginBottom: '0px'
                                }}
                            >
                                Total : &#163;{materialTotal(item)}
                            </p>
                        </div>
                    </div>
                    <hr/>
                </>  
            ))}
        </>
    );
}

export default MaterialsList;