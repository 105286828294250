import React, { useContext } from "react";

import Context from "./context";
import Template from "../../../components/template";
import CardWithHeaderTemplate from "../../../components/cardWithHeaderTemplate";
import Body from "./components/body";
import { Pagination, Stack } from "@mui/material";
import Search from "../../../components/searchField";
import { getLogs } from "./service";
import { theme } from "../../../theme";

const Html = () => {
    const context = useContext(Context);

    const handleSearch = (search_text) => {
        getLogs(1, search_text, context.dispatch);
    };

    return(
        <Template>
            <CardWithHeaderTemplate
                header={
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <h6 className="text-white text-capitalize ps-3">TRADESPERSON ACTIVITIES</h6> 
                            </div>
                            <div className="col-md-6">
                                <Search
                                    search_type = 'ALL'
                                    search_option = 'FTM_NAME'
                                    ftm_id = ''
                                    onSearch = {handleSearch}
                                />
                            </div>
                        </div>
                    </div>
                } 
                body={<Body/>} 
                footer={
                    <>
                        {!context.state.is_loading ? (
                            <Stack 
                                spacing={2} 
                                className="mb-3" 
                                style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}      
                            >
                                <Pagination
                                    count={context.state.total_pages} 
                                    page={context.state.current_page} 
                                    onChange={(event, newPage) => getLogs(newPage, context.state.search_text, context.dispatch)}
                                    color={theme.main}
                                />
                            </Stack>
                        ) : null}
                    </>
                }
            />
        </Template>
    );
}

export default Html