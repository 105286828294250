import React, { useContext } from "react";
import Search from "../../../components/searchField";
import Context from "../context";

const HeaderView = () => {
    const context = useContext(Context);

    return(
        <div className="row">
            <div className="row">
                <div className="col-md-6">
                    <h6 className="text-white text-capitalize ps-3">ALL CUSTOMERS</h6> 
                </div>
                <div className="col-md-6">
                    <Search
                        onSearch = {(search_text) => {
                            context.dispatch({
                                type: 'SET_SERACH_TEXT',
                                serach_text: search_text
                            });
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default HeaderView;