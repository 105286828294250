import React, { useContext } from "react";
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { Context } from "../provider";

const BankDetails = ({ register, reset, errors, control }) => {
    const context = useContext(Context);

    return (
        <>
            <div className="row">
                <div className="col-4">
                    <FormControl error={!!errors.ftm_bank_name} fullWidth>
                        <label><b>Bank name</b></label>
                        <TextField
                            error={!!errors.ftm_bank_name}
                            placeholder="Bank name"
                            size="small"
                            type="text"
                            defaultValue={context.state.ftm?.ftm_bank_name || ''}
                            {...register("ftm_bank_name", { required: true })}
                        />
                        {errors.ftm_bank_name && <FormHelperText>Bank name is required</FormHelperText>}
                    </FormControl>
                </div>
                <div className="col-4">
                    <FormControl error={!!errors.ftm_bank_sort_code} fullWidth>
                        <label><b>Bank sort code</b></label>
                        <Controller
                            name="ftm_bank_sort_code"
                            control={control}
                            defaultValue={context.state.ftm?.ftm_bank_sort_code || ''}
                            render={({ field }) => (
                                <InputMask
                                    {...field}
                                    mask="99-99-99"
                                    maskChar=""
                                >
                                    {(inputProps) => (
                                        <TextField
                                            {...inputProps}
                                            placeholder="Bank sort code"
                                            size="small"
                                            type="text"
                                            error={!!errors.ftm_bank_sort_code}
                                        />
                                    )}
                                </InputMask>
                            )}
                        />
                        {errors.ftm_bank_sort_code && <FormHelperText>{errors.ftm_bank_sort_code.message}</FormHelperText>}
                    </FormControl>
                </div>
                <div className="col-4">
                    <FormControl error={!!errors.ftm_bank_account_number} fullWidth>
                        <label><b>Bank account number</b></label>
                        <Controller
                            name="ftm_bank_account_number"
                            control={control}
                            defaultValue={context.state.ftm?.ftm_bank_account_number || ''} 
                            render={({ field }) => (
                                <InputMask
                                    {...field}
                                    mask="9999-9999"
                                    maskChar=""
                                >
                                    {(inputProps) => (
                                        <TextField
                                            {...inputProps}
                                            placeholder="Bank account number"
                                            size="small"
                                            type="text"
                                            error={!!errors.ftm_bank_account_number}
                                        />
                                    )}
                                </InputMask>
                            )}
                        />
                        {errors.ftm_bank_account_number && <FormHelperText>{errors.ftm_bank_account_number.message}</FormHelperText>}
                    </FormControl>
                </div>
            </div>
        </>
    );
}

export default BankDetails;