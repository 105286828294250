import React, { useContext } from "react";
import { Button, Stack } from "@mui/material";
import { approveFtm, rejectFtm } from "../service";
import Context from "../context";

const FooterBtn = () => {
    const context = useContext(Context);

    return(
        <Stack direction="row" spacing={2}>
            <Button 
                variant="contained" 
                startIcon={<i className="material-icons text-lg position-relative">check_circle</i>} 
                size="small" 
                color="success" 
                onClick={() => approveFtm(context.ftm_id, context.dispatch)}
                disabled = {context.state?.ftm?.ftm_is_verify == 1 ? true : false}
            >
                {context.state?.ftm?.ftm_is_verify == 1 ? "APPROVED" : "APPROVE"}
            </Button>
            <Button 
                variant="contained" 
                startIcon={<i className="material-icons text-lg position-relative">cancel</i>} 
                size="small" 
                color="error"
                disabled = {context.state?.ftm?.ftm_is_verify == -1 ? true : false}
                onClick={() => rejectFtm(context.ftm_id, context.dispatch)}
            >
                {context.state?.ftm?.ftm_is_verify == -1 ? "REJECTED" : "REJECT"}
            </Button>
        </Stack>
    );
}

export default FooterBtn;