import React, { useContext } from "react";
import { Container, Box, Typography } from '@mui/material';
import Context from './context';
import CompanyLogo from './components/companyLogo';
import PasswordField from "./components/passwordField";
import LinkToLogin from "./components/linkToLogin";
import CardWithHeaderTemplate from "../components/cardWithHeaderTemplate";

const Html = () => {
    const context = useContext(Context);

    return (  
        <Container 
            className="container" 
            sx={{ height: '100vh', color: '#333333' }}>
                <Box 
                    className="text-center" 
                    display="flex" 
                    flexDirection="column" 
                    alignItems="center" 
                    justifyContent="center" 
                    height="100%"
                >
                    <CardWithHeaderTemplate
                        header={
                            <CompanyLogo />
                        } 
                        body={
                            <div>
                                <Box
                                    p={1}>
                                        <span style={{ fontWeight: '600', fontSize: '18px' }}>{context.state.admin_info.admin_full_name}</span>
                                </Box>
                                <PasswordField />
                                <Typography variant="subtitle1" pl={3} pr={3}>Enter your password to retrieve your session</Typography>
                                <LinkToLogin />
                            </div>
                        } 
                    />
                        
                </Box>
        </Container>
    )
}

export default Html;