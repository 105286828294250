import React, { useContext } from "react";
import Context from "../context";
import FilterMenu from "./filterMenu";
import Search from "../../../../components/searchField";

const HeaderView = () => {
    const context = useContext(Context);

    return(
        <div className="row">
            <div className="col-md-6">
                <h6 className="text-uppercase text-white text-capitalize ps-3">
                    CANCELLED JOBS
                </h6>
            </div>
            <div className="col-md-6">
                <div className="row">
                    <div className="col-md-11">
                        <Search
                            onSearch = {(search_text) => {
                                context.dispatch({ type: "SET_SERACH_VALUE", search_value: search_text });
                            }}
                        />
                    </div>
                    <div className="col-md-1">
                        <FilterMenu/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeaderView;