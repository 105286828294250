import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

import { GET } from "../../API/get";
import { FORMPOST, POST } from "../../API/post";
import { FORMPUT, PUT } from '../../API/put';
import { getUserInfo } from '../../storage';
import { theme } from '../../theme';
import { map_settings } from '../../map';
import { DELETE } from '../../API/delete';
import { usersRef } from '../../firebase';
import { isShowChatNotifications } from '../../utils';

export const loadData = async (ftm_id, dispatch) => {
    dispatch({ type: "IS_LOADING" });
    let response = await GET("ftm-profile/"+ftm_id);

    if(response.status === "Success"){
        // let ftm_documents = response.documents;
        let ftm_documents = {
            parent: response.documents.filter(doc => doc.ftm_document_types_type === 0),
            sub: response.documents.filter(doc => doc.ftm_document_types_type === 1),
        };

        console.log(`ftm_documents : `, ftm_documents);
        // let documentCount = response.documents.length;

        let skills = await getSkills();
        dispatch({ 
            type: "IS_LOADED",
            ftm: response.ftm,
            ftm_job_count: response.ftm_job_count,
            monthly_income: response.monthly_income,
            all_income: response.all_income,
            documents: ftm_documents,
            ftm_skills: response.skills,
            skills: skills
        });
    }
}

export const getAdminDetailsInFirebase = async (ftm_firebase_document_id, dispatch) => {
    if(ftm_firebase_document_id !== null){
        let query = usersRef.doc(ftm_firebase_document_id);

        query.onSnapshot(async (doc) => {
            if (doc.exists) {
                console.log("getAdminDetailsInFirebase doc :", {
                    id: doc.id,
                    ...doc.data()
                });

                dispatch({
                    type: 'ADMIN_UNREAD_MESSAGE_COUNT',
                    admin_unread_message_count: doc.data().user_total_office_admin_unread_message_count
                });
            } 
        });
    }
}

export const getSkills = async () => {
    let response = await GET("skills");

    if(response.status === "Success"){
        return response.list;
    }else{
        return [];
    }
}

// check if an image exists
export const imageExists = (url, callback) => {
    let img = new Image(); 
    img.src = url;
    img.onload = () => callback(true);
    img.onerror = () => callback(false);
};

export const addNewSkills = async (option, dispatch) => {
    console.log('option :', option);
    dispatch({
        type: 'IS_SENDING_REQ',
        is_sending_req: true
    });

    let response = await FORMPOST("add-skills-details", {
        ftm_id: option.ftm_id,
        skill_id: option.skill?.value?.skill_id,
        sub_skill_id: option.sub_skill?.value?.sub_skill_id,
        document_type_id: option.document_type?.value?.documents_types_id,
        document_type_name: option.document_type?.value?.documents_types_name,
        ftm_document: option.files,
    });

    console.log('response :', response);
    if(response.status === "Success"){
        dispatch({type: 'RESET_ADD_SKILLS'});
        toast.success(response.message);

        loadData(option.ftm_id, dispatch);
    }else{
        toast.error(response.message);
    }
}

export const updateExpDate = async (event, document_id, ftm_id, dispatch) => {
    event.preventDefault();
    console.log(document_id);
    console.log(event);
    console.log(ftm_id);

    dispatch({
        type: 'IS_SENDING_REQ',
        is_sending_req: true
    });

    let date = new Date(event.target.elements.expiry_date.value);
    let comment = event.target.elements.comment.value;

    let d = date.getDate();
    let m = date.getMonth() + 1;
    let y = date.getFullYear();

    let dateString = y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    console.log(dateString);

    let response = await PUT("update-ftm-documents-exp-date", {
        ftm_document_id: document_id,
        ftm_document_expiry_date: dateString,
        ftm_document_comment: comment
    });

    if(response.status === "Success"){
        dispatch({type: 'RESET_SET_EXPIRY_DATE'});
        toast.success(response.message);

        loadData(ftm_id, dispatch);
    }else{
        toast.error(response.message);
    }

    // FtmDocumentsService.updateFtmDocumentsExpDate(document_id, dateString).then(
    //     (response) => {
    //         console.log(response);
    //     },
    //     (error) => {
    //         const _content =
    //             (error.response && error.response.data) ||
    //             error.message ||
    //             error.toString();
    
    //         console.log(_content);
    //     }
    // );
}

export const loadOptions = async (inputValue, callback) => {
    if (!inputValue) {
      callback([]);
      return;
    }

    if(inputValue.length >= 8){
        let response = await GET(`get-postcode-list/${inputValue}`);

        if(response.status === "Success"){
            let options = response.locations.map((location) => ({
                value: {
                    postcode: inputValue,
                    location: location,
                }, 
                label: location.StreetAddress 
            }));

            callback(options);
        }else{
            return [];
        }
    }else{
        return [];
    }
};

export const updateFtmDetails = async (data, context, reset) => {
    console.log('data :', data);

    context.dispatch({
        type: 'IS_SAVING_EDIT_FTM_INFO',
        is_saving_edit_ftm_info: true
    });

    let ftm_street_address = context.state.ftm.ftm_street_address
    if(data.hasOwnProperty('street')){
        ftm_street_address = data.street;
    }

    let ftm_place = context.state.ftm.ftm_place
    if(data.hasOwnProperty('city')){
        ftm_place = data.city;
    }

    let ftm_postcode = context.state.ftm.ftm_postcode
    if(data.hasOwnProperty('postcode')){
        ftm_postcode = data.postcode;
    }

    let ftm = {
        ftm_id: context.ftm_id,
        ftm_forename: data.ftm_forename,
        ftm_surname: data.ftm_surname,
        ftm_vehicle_number: data.vehicle_number,
        ftm_contact_number: data.ftm_contact_number,
        ftm_next_of_kin: data.ftm_next_of_kin,
        ftm_next_of_kin_contact_number: data.ftm_next_of_kin_contact_number,
        ftm_utr_number: data.ftm_utr_number,
        ftm_ni_number: data.ftm_ni_number,
        ftm_bank_name: data.ftm_bank_name,
        ftm_bank_sort_code: data.ftm_bank_sort_code,
        ftm_bank_account_number: data.ftm_bank_account_number,
        ftm_name_of_business: data.ftm_name_of_business,
        ftm_company_reg_number: data.ftm_company_reg_number,
        ftm_street_address: ftm_street_address,
        ftm_place: ftm_place,
        ftm_postcode: ftm_postcode,
        is_ftm_app: 0,
        ftm_availability_status: data.ftm_availability_status,
    }

    if(context.state.ftm_profile_image != null){
        ftm.ftm_document = context.state.ftm_profile_image;
    }

    let response = await FORMPUT("update-ftm-details", ftm);

    console.log('response :', response);
    context.dispatch({
        type: 'IS_SAVING_EDIT_FTM_INFO',
        is_saving_edit_ftm_info: false
    });

    if(response.status === "Success"){
        toast.success(response.message);
        context.dispatch({
            type: 'SHOW_EDIT_CANVAS',
            show_edit_canvas: false
        });
        loadData(context.ftm_id, context.dispatch);
    }else{
        toast.error(response.message);
    }
}

export const approveFtm = async (ftm_id, dispatch) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to approve this Tradesperson?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.action_btn_code,
        cancelButtonColor: theme.cancel_btn_code,
        confirmButtonText: 'Yes',
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
            let userInfo = getUserInfo();
            let response = await PUT("ftm-is-verify", {
                ftm_id: ftm_id,
                ftm_is_verify: 1,
                ftm_reject_reason: "",
                admin_id: userInfo.admin_id
            });

            return response;
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
        if (result.isConfirmed) {
            console.log('result :', result);

            if(result.value.status === "Success"){
                dispatch({ 
                    type: "SCROLL_IN_TO_VIEW", 
                    scroll_to_documents_view: false,
                    scroll_to_tracker_view: false,
                    scroll_to_footer_btns_view: true,
                });
                loadData(ftm_id, dispatch);
            }
        }
    })
}

export const rejectFtm = async (ftm_id, dispatch) => {
    Swal.fire({
        title: 'Why do you reject this Tradeperson? ',
        // text: "Do you want to reject this FTM",
        icon: 'warning',
        input: 'textarea',
        showCancelButton: true,
        confirmButtonColor: theme.action_btn_code,
        cancelButtonColor: theme.cancel_btn_code,
        confirmButtonText: 'Yes',
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: async (reason) => {
            console.log('reason :', reason);
            if(reason == ""){
                Swal.showValidationMessage(
                    `Reason required`
                )
            }else{
                let userInfo = getUserInfo();
                let response = await PUT("ftm-is-verify", {
                    ftm_id: ftm_id,
                    ftm_is_verify: -1,
                    ftm_reject_reason: reason,
                    admin_id: userInfo.admin_id
                });

                return response;
            }
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
        console.log('result :', result);
        if (result.isConfirmed) {
            console.log('result :', result);

            if(result.value.status === "Success"){
                dispatch({ 
                    type: "SCROLL_IN_TO_VIEW", 
                    scroll_to_documents_view: false,
                    scroll_to_tracker_view: false,
                    scroll_to_footer_btns_view: true,
                });
                
                loadData(ftm_id, dispatch);
            }
        }
    })
}

export const addFtmLocation = (option) => {
    if(option.map){
        let bounds = new option.google.maps.LatLngBounds();

        let ftm_lat = parseFloat(option.ftm_latitude);
        let ftm_lng = parseFloat(option.ftm_longitude);

        let ftm_LatLng = new option.google.maps.LatLng(ftm_lat, ftm_lng);
        bounds.extend(ftm_LatLng);

        new option.google.maps.Marker({
            position: { lat: ftm_lat, lng: ftm_lng },
            icon:map_settings.ftm_map_icon ,
            map: option.map,
        });

        
        option.map.setCenter(ftm_LatLng);
        option.map.setZoom(10);
        
    }
}

export const updateDevice = (ftm_id, ftm_device_id, dispatch) => {
    Swal.fire({
        title: "SET GPS DEVICE",
        input: "text",
        inputAttributes: {
            autocapitalize: "off"
        },
        showCancelButton: true,
        confirmButtonColor: theme.main_code,
        cancelButtonColor: theme.close_btn_code,
        confirmButtonText: "Submit",
        showLoaderOnConfirm: true,
        preConfirm: async (device_id) => {
            console.log('device_id :', device_id);
            if(device_id == ""){
                Swal.showValidationMessage(
                    `Device id required`
                )
            }else if(device_id == ftm_device_id){
                Swal.showValidationMessage(
                    `Device ID already exists`
                )
            } else {
                let response = await POST("setup/gps/device", {
                    ftm_id: ftm_id,
                    ftm_device_id: device_id
                });

                return response;
            }
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            console.log('result :', result);

            if(result.value.status === "Success"){
                dispatch({ 
                    type: "SCROLL_IN_TO_VIEW", 
                    scroll_to_documents_view: false,
                    scroll_to_tracker_view: true,
                    scroll_to_footer_btns_view: false,
                });
                toast.success(result.value.message)
                loadData(ftm_id, dispatch);
            }
        }
    });
}

export const deleteSkill = (ftm_id, sub_skill_id, dispatch) => {
    Swal.fire({
        title: 'Warning',
        text: "Once deleted, the service type cannot be recovered. Please confirm deletion?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.cancel_btn_code,
        cancelButtonColor: theme.action_btn_code,
        confirmButtonText: 'Yes',
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
            let response = await DELETE("delete-ftm-sub-skills/"+ftm_id+"/"+sub_skill_id);
            return response;
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
        if (result.isConfirmed) {
            console.log('result :', result);

            if(result.value.status === "Success"){
                toast.success(result.value.message)
                loadData(ftm_id, dispatch);
            }
        }
    })
}
 
export const openChatView = async (document_id, dispatch) => {
    try {
        isShowChatNotifications(0, "NEW_OFFICE_MESSAGE");

        // Get user info
        let user_info = getUserInfo();
        let ref = usersRef.doc(document_id);

        ref.update({
            user_last_message_seen_user: user_info.admin_firebase_document_id,
            user_last_message_seen_timestamp: new Date().getTime()
        });

        dispatch({
            type: 'IS_SHOW_CHAT', 
            is_show_chat: true
        });

    } catch (error) {
        console.error("Error open chat documents:", error);
    }
}

export const removeDevice = (ftm_id, dispatch) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to remove this device",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.cancel_btn_code,
        cancelButtonColor: theme.action_btn_code,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
            let response = await PUT("remove/gps/device", {
                ftm_id: ftm_id
            });
            return response;
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
        if (result.isConfirmed) {
            console.log('result :', result);

            if(result.value.status === "Success"){
                dispatch({ 
                    type: "SCROLL_IN_TO_VIEW", 
                    scroll_to_documents_view: false,
                    scroll_to_tracker_view: true,
                    scroll_to_footer_btns_view: false,
                });

                toast.success(result.value.message)
                loadData(ftm_id, dispatch);
            }
        }
    });
}

export const loadFTMHolidaysCalendar = async ({ftm_id, start_date, end_date, dispatch}) => {
    let startTimestamp = new Date(start_date).getTime();
    let endTimestamp = new Date(end_date).getTime();

    let response = await GET(`ftm/calendar/holidays/${ftm_id}?start_date=${startTimestamp}&end_date=${endTimestamp}`);
    console.log('ftm holidays: ', response.holiday_list);
    if(response?.status === "Success"){
        dispatch({
            type: "LOAD_FTM_HOLIDAYS_CALENDAR", 
            holiday_list: response.holiday_list
        });
    }
}

export const approveSkill = (ftm_id, ftm_skill_id, dispatch) => {
    Swal.fire({
        title: 'Warning',
        text: "Approving this service type will allow the tradesperson to offer these services",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.action_btn_code,
        cancelButtonColor: theme.cancel_btn_code,
        confirmButtonText: 'Approve',
        cancelButtonText: 'Cancel',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
            let response = await PUT("is/approve/ftm/skill", {
                is_approve: 1,
                ftm_skill_id: ftm_skill_id,
                ftm_id: ftm_id
            });
            return response;
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
        if (result.isConfirmed) {
            console.log('result :', result);

            if(result.value.status === "Success"){
                loadData(ftm_id, dispatch);

                toast.success(result.value.message);
            }
        }
    });
}

export const approveFtmDocument = (ftm_id, ftm_document_id, dispatch) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to approve this certificate?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.action_btn_code,
        cancelButtonColor: theme.cancel_btn_code,
        confirmButtonText: 'Yes',
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
            let response = await PUT("is/approve/ftm/document/", {
                document_id: ftm_document_id
            });
            return response;
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
        if (result.isConfirmed) {
            console.log('result :', result);

            if(result.value.status === "Success"){
                dispatch({ 
                    type: "SCROLL_IN_TO_VIEW", 
                    scroll_to_documents_view: true,
                    scroll_to_tracker_view: false,
                    scroll_to_footer_btns_view: false
                });
                loadData(ftm_id, dispatch);

                toast.success(result.value.message);
            } else {
                toast.error(result.value.message);
            }
        }
    })
}

export const ftmTrackerDispatch = (ftm_id, dispatch) => {
    Swal.fire({
        title: "Are you sure tracker dispatch?",
        showCancelButton: true,
        confirmButtonColor: theme.main_code,
        cancelButtonColor: theme.close_btn_code,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
            console.log('ftm_id :', ftm_id);
            let response = await PUT("tracker/dispatched", {
                ftm_id: ftm_id
            });

            return response;
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            console.log('result :', result);

            if(result.value.status === "Success"){
                dispatch({ 
                    type: "SCROLL_IN_TO_VIEW", 
                    scroll_to_documents_view: false,
                    scroll_to_tracker_view: true,
                    scroll_to_footer_btns_view: false,
                });
                loadData(ftm_id, dispatch);

                toast.success(result.value.message);
            } else {
                toast.error(result.value.message);
            }
        }
    })
}

export const deleteFtmDocument = (ftm_id, ftm_documents_types_id, dispatch) => {
    Swal.fire({
        title: 'Warning',
        text: "Deleting this certificate is permanent. Please proceed with caution",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.cancel_btn_code,
        cancelButtonColor: theme.action_btn_code,
        confirmButtonText: 'Delete',
        cancelButtonText: "Cancel",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
            let response = await DELETE(`delete-ftm-document/${ftm_id}/${ftm_documents_types_id}`);
            return response;
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
        if (result.isConfirmed) {
            console.log('result :', result);

            if(result.value.status === "Success"){
                dispatch({ 
                    type: "SCROLL_IN_TO_VIEW", 
                    scroll_to_documents_view: true,
                    scroll_to_tracker_view: false,
                    scroll_to_footer_btns_view: false,
                });
                loadData(ftm_id, dispatch);

                toast.success(result.value.message);
            } else {
                toast.error(result.value.message);
            }
        }
    })
}

export const updateFtmOnlineStatus = async (ftm_id, ftm_is_online, dispatch) => {
    let response;

    if (ftm_is_online) {
        response = await PUT("update-ftm-online-status", {
            ftm_id: ftm_id,
            ftm_is_online: ftm_is_online
        });
    
        if(response.status === "Success"){
            loadData(ftm_id, dispatch);
            toast.success(response.message);
        } else {
            toast.error(response.message);
        }
    } else {
        Swal.fire({
            title: 'Are you sure?',
            text: "Tradeperson will not receive new job notifications until come back online",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: theme.cancel_btn_code,
            cancelButtonColor: theme.action_btn_code,
            confirmButtonText: 'Yes',
            cancelButtonText: "No",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                return await PUT("update-ftm-online-status", {
                    ftm_id: ftm_id,
                    ftm_is_online: ftm_is_online
                });
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {
            if (result.isConfirmed) {
                if(result.value.status === "Success"){
                    loadData(ftm_id, dispatch);
                    toast.success(result.value.message);
                } else {
                    toast.error(result.value.message);
                }
            }
        })
    }
}