import React, { useContext } from "react";
import Context from "../context";
import Edit from "./edit";
import CardWithHeaderTemplate from "../../../components/cardWithHeaderTemplate";

const NextOfKin = () => {
    const context = useContext(Context);

    return (
        <CardWithHeaderTemplate
            header={
                <div className="row">
                    <div className="col-8 my-auto">
                        <div className="numbers">
                            <h6 className="text-white font-weight-bolder mb-0 ms-3">
                                NEXT OF KIN
                            </h6>
                        </div>
                    </div>
                </div>
            }
            body={
                <ul className="list-group">
                    <li className="list-group-item border-0 ps-0 pt-0 text-sm">
                        <strong className="text-dark">Next of Kin:</strong>
                        &nbsp; {context.state.ftm.ftm_next_of_kin}
                    </li>
                    <li className="list-group-item border-0 ps-0 text-sm">
                        <strong className="text-dark">Next of Kin Contact:</strong>
                        &nbsp; {context.state.ftm.ftm_next_of_kin_contact_number}
                    </li>
                </ul>
            }
            footer={null}
            extraCardClassName={`blur shadow-blur max-height-vh-70 h-100`}
            extraBodyClassName={`overflow-auto overflow-x-hidden`}
        />
    );
}

export default NextOfKin;