import React, { useContext } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';

import { theme } from "../../../../theme";
import { deleteNote } from "../service";

import Context from "../context";
export default function NoteList() {
    const context = useContext(Context);

    return (
        <>
            {context.state.notes_list.length > 0 && (
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {context.state.notes_list.map((note, index) => (
                        <React.Fragment key={index}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar sx={{ width: 30, height: 30 }}>{context.state.notes_list.length - index}</Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={note.admin_user.admin_full_name}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {note.ftm_note}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                />
                                <IconButton
                                    sx={{ color: theme.delete_btn_code }}
                                    onClick={() => deleteNote(note.ftm_note_id, note.ftm_note_ftm_id, context.dispatch)}>
                                    <Tooltip title="Delete">
                                        <DeleteIcon sx={{ fontSize: 18 }} />
                                    </Tooltip>
                                </IconButton>
                                <IconButton
                                    onClick={() => context.dispatch({
                                        type: "SHOW_EDIT_NOTE",
                                        show_edit_note: true,
                                        ftm_note_id: note.ftm_note_id,
                                        ftm_id: note.ftm_note_ftm_id,
                                        ftm_note: note.ftm_note,
                                    })}>
                                    <Tooltip title="Edit">
                                        <EditIcon sx={{ fontSize: 18 }} />
                                    </Tooltip>
                                </IconButton>
                            </ListItem>
                            {index < context.state.notes_list.length - 1 && <Divider variant="inset" component="li" />}
                        </React.Fragment>
                    ))}
                </List>
            )}
        </>
    );
}