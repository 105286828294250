import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

const Html = ({register}) => {
    return (
        <Row>
            <Col>
                <Form.Group className="mb-3">
                    <Form.Label><b>START DATE</b></Form.Label>
                    <Form.Control 
                        type="date" 
                        style={{
                            backgroundColor: '#fff',
                            paddingLeft: '10px',
                            paddingRight: '10px'
                        }} 
                        {...register("start_date", { required: false })}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3">
                    <Form.Label><b>END DATE</b></Form.Label>
                    <Form.Control 
                        type="date" 
                        style={{
                            backgroundColor: '#fff',
                            paddingLeft: '10px',
                            paddingRight: '10px'
                        }}  
                        {...register("end_date", { required: false })}
                    />
                </Form.Group>
            </Col>
        </Row>
    );
}

export default Html;