export default function reducer(prevState, action){
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true,
            };
        case "IS_LOADED": 
            return {
                ...prevState,
                total_ftms: action.total_ftms,
                total_pages: action.total_pages,
                page_number: action.page_number,
                list: action.list,
                search_type: action.search_type,
                is_loading: false,
            }; 
        case "UPDATE_CURRENT_PAGE":
            return {
                ...prevState,
                page_number: action.page_number,
            };
        default:
            throw new Error();
    }
}