import React from "react";

const JobProfileImage = () => {
    return(
        <div className="col-auto">
            <div className="avatar avatar-xl position-relative">
              <img src="/assets/img/job.png" alt="profile_image" className="w-100 border-radius-lg shadow-sm"/>
            </div>
        </div>
    );
}

export default JobProfileImage;