/* eslint-disable jsx-a11y/alt-text */
import React, {useRef, useContext} from "react";

import Context from "../context";
import ModalTemplate from "../../../components/modal/modalTemplate";

const AddNewCard = () => {
    const context = useContext(Context);
    const iframeRef = useRef(null);

    return(
        <ModalTemplate
            show = {context.state.is_open_add_card_modal}
            closeFun = {() => context.dispatch({
                type: 'IS_OPEN_ADD_CARD_MODAL',
                is_open_add_card_modal: false
            })}
            size = {null}
            centered = {true}
            title = {null}
            body = {
                <>
                    <iframe
                        id="iframeCardPreAuth"
                        ref={iframeRef}
                        src={process.env.REACT_APP_DOMAIN+`/api/payment-redirect/${parseInt(context.state.customer.customer_id)}`}
                        title="Your IFrame"
                        width="100%"
                        height="600"
                        onLoad={() => context.dispatch({
                            type: 'IS_IFRAME_LOADED',
                            is_iframe_loaded: true
                        })}
                        style={{display: !context.state.is_iframe_loaded ? "none" : ""}}
                    />
                    {!context.state.is_iframe_loaded ? (
                        <img 
                            src="/assets/gif/cardLoading.gif" 
                            className="img-responsive center-block d-block mx-auto"
                        />
                    ) : null}
                </>
            }
            footer = {null}
        />
    );
}

export default AddNewCard;