/* eslint-disable eqeqeq */
import { disputesRef } from "../../../firebase";
import { getUserInfo } from "../../../storage";
import { isShowChatNotifications } from "../../../utils";
import { GET } from '../../../API/get';

/**
 * This function loads data for a given page size and last document.
 * It dispatches actions to update the state of the application.
 * @param {number} page_size - The number of documents to load per page.
 * @param {object|null} last_doc - The last document of the previous page.
 * @param {function} dispatch - The Redux dispatch function.
 */

// Declare a variable to store the unsubscribe function
let unsubscribe = null;

export const loadData = async (page_size, last_doc, dispatch, is_resolved = null) => {
    try {
        // Unsubscribe from the previous listener if it exists
        if (unsubscribe) {
            unsubscribe();
        }

        // Dispatch the IS_LOADING action to update the state.
        dispatch({ type: "IS_LOADING" });

        // Create a query to get the disputes from Firebase.
        let query = disputesRef.orderBy("dispute_last_message_received_timestamp", "desc").limit(page_size);

        if(is_resolved !== null){
            if(is_resolved == 1){
                query = query.where("dispute_is_resolved", "==", 1);
            }else{
                query = query.where("dispute_is_resolved", "!=", 1).orderBy("dispute_is_resolved");
            }
        }

        // If a last document is provided, start the query after that document.
        if (last_doc) {
            query = query.startAfter(last_doc);
        }

        // Execute the query and get the documents.
        unsubscribe = query.onSnapshot(async (snapshot) => {
            // Create an array of promises to get the job and chat details for each document.
            let promises = snapshot.docs.map(async (doc) => {
                return {
                    id: doc.id,
                    ...doc.data(),
                };
            });

            // Wait for all promises to resolve and get the disputes.
            let disputes = await Promise.all(promises);

            // Dispatch the IS_LOADED action to update the state with the disputes, last document, and show load more button.
            dispatch({
                type: "IS_LOADED",
                disputes,
                last_doc: snapshot.docs[snapshot.docs.length - 1],
                is_show_load_move_btn: snapshot.size > 0,
            });
                console.log("🚀 ~ unsubscribe=query.onSnapshot ~ snapshot.size:", snapshot.size)
        });
    } catch (error) {
        console.error("Error fetching paginated documents:", error);
    }
};


export const openChatView = async (dispute, dispatch) => {
    console.log("🚀 ~ openChatView ~ dispute:", dispute)
    try {
        isShowChatNotifications(dispute?.dispute_job?.job_id, "NEW_DISPUTE_MESSAGE");

        // Get user info
        let user_info = getUserInfo();
        let ref = disputesRef.doc(dispute.id);

        ref.update({
            dispute_last_message_seen_user: user_info.admin_firebase_document_id,
            dispute_last_message_seen_user_timestamp: new Date().getTime()
        });

        dispatch({
            type: 'IS_OPEN_CHAT',
            is_open_chat: true,
            selected_dispute: dispute
        });

    } catch (error) {
        console.error("Error open chat documents:", error);
    }
}

export const getAllCreditNotes = async (page_number, dispatch) => {
    try {
        dispatch({ 
            type: "IS_LOADING_CREDIT_NOTES",
            is_show_credit_notes: true 
        });
        let response = await GET("get-all-credit-notes/"+page_number);

        if(response.status === "Success"){
            dispatch({
                type: 'SET_CREDIT_NOTES',
                credit_notes: response.credit_notes,
                total_pages: response.total_pages,
                current_page_cn: page_number
            });
        }
    } catch (error) {
        console.error("Error getting credit notes:", error);
    }
}

export const getAllSalesInvoices = async (page_number, dispatch) => {
    try {
        dispatch({ 
            type: "IS_LOADING_SALES_INVOICES",
            is_show_sales_invoices: true
        });
        let response = await GET("get-all-sales-invoices/"+page_number);

        if(response.status === "Success"){
            dispatch({
                type: 'SET_SALES_INVOICES',
                sales_invoices: response.sales_invoices,
                total_pages: response.total_pages,
                current_page_si: page_number
            });
        }
    } catch (error) {
        console.error("Error getting sales invoices:", error);
    }
}

export const openCreditNoteOrSalesInvoicePDF = async (id, type) => {
    let url = getUrl(id, type);
    let response = await GET(url);

    if(response.status === "Success"){
        window.open(response.pdf, '_blank', 'noopener,noreferrer');
    }
}

const getUrl = (id, type) => {
    if (type === 'credit_note') {
        return `credit_note/pdf/${id}`;
    } else if (type === 'sales_invoice') {
        return `sales_invoice/pdf/${id}`;
    }
}

