import React, { useContext } from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { useForm } from "react-hook-form";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import Html from "./html";
import Context from "../../context";
import { filterDate } from "../../service";
import { theme } from "../../../../theme";
import ModalTemplateWithForm from "../../../../components/modal/modalTemplateWithForm";
// import FilterTable from "../filterTable";

const DateFilter = () => {
    const {
        register,
        handleSubmit,
        reset
    } = useForm();

    const context = useContext(Context);

    return(
        <ModalTemplateWithForm
            show={context.state.is_show_date_filter} 
            closeFun = {() => context.dispatch({
                type: 'IS_SHOW_DATE_FILTER',
                is_show_date_filter: false
            })}
            size = {null}
            centered = {false}
            fullscreen = {false}
            dialogClassName = {null}
            title = {"DATE FILTER"}
            body = {
                <Container>
                    {context.state.error !== null ? (
                        <Stack sx={{ width: '100%' }} spacing={2} className="mb-3">
                            <Alert severity="error">{context.state.error}</Alert>
                        </Stack>
                    ) : null}
                    <Html
                        register = {register}
                    />
                </Container>
            }
            footer = {
                <>
                    <Button 
                        variant={theme.close_btn} 
                        onClick={() => context.dispatch({
                            type: 'IS_SHOW_DATE_FILTER',
                            is_show_date_filter: false
                        })} 
                        size="sm"
                    >
                        Close
                    </Button>
                    <Button type="submit" variant={theme.main} size="sm">
                        Filter
                    </Button>
                </>
            }
            onSubmit = {handleSubmit((data) => filterDate(context.ftm_id, data, reset, context.dispatch))}
        />
    );
}

export default DateFilter;