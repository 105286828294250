import React from "react";

const navItems = [
    { id: "personalInformation", icon: "person", text: "Personal Information" },
    { id: "licenceAndVehicleDetails", icon: "local_shipping", text: "Licence And Vehicle Details" },
    { id: "nextOfKin", icon: "star", text: "Next Of Kin" },
    { id: "taxAndInsuranceDetails", icon: "receipt_long", text: "Tax And Insurance Details" },
    { id: "businessDetails", icon: "work", text: "Business Details" },
    { id: "bankDetails", icon: "account_balance", text: "Bank Details" },
    { id: "ftmAvailability", icon: "check", text: "Availability" },
    { id: "updateTradesperson", icon: "update", text: "Update Tradesperson" },
];

const NavList = () => {
    return (
        <div className="card position-sticky top-1">
            <ul className="nav flex-column bg-white border-radius-lg p-3">
                {navItems.map(item => (
                    <li className="nav-item pt-2" key={item.id}>
                        <a className="nav-link text-dark d-flex" data-scroll="" href={`#${item.id}`}>
                            <i className={`material-icons text-lg me-2`}>{item.icon}</i>
                            <span className="text-sm">{item.text}</span>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default NavList;