import React, { useContext } from "react";

import Context from './context';
import Template from "../../components/template";
import SkillsList from "./components/skillsList";
import HeaderView from "./components/headerView";
import CardWithHeaderTemplate from "../../components/cardWithHeaderTemplate";
import SubSkills from "../subSkills";
import AddSkills from "./components/addSkills";
import EditSkills from "./components/editSkills";
import CertificateTypes from "./components/parentCertificateTypes";

const Html = () => {
    const context = useContext(Context);
    
    return(
        <Template>
            <div className="container-fluid py-4">
                <div className="row">
                    <div className="col-12">
                        <CardWithHeaderTemplate
                            header={<HeaderView/> } 
                            body={<SkillsList/>} 
                            footer={<></>}
                        />
                    </div>
                </div>
            </div>

            <SubSkills
                show = {context.state.is_show_sub_skills}
                skills = {context.state.selected_skills}
                handleClose = {() => context.dispatch({
                    type: 'IS_SHOW_SUB_SKILLS',
                    is_show_sub_skills: false
                })}
            />

            <AddSkills/>
            <EditSkills/>
            <CertificateTypes/>
        </Template>
    );
}

export default Html;