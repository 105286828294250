import React, { useReducer, useEffect } from "react";
import Reducer from "./reducer";
import Context from "./context";
import { initialState } from "./initialState";
import Loader from "../components/loading";
import { loadData } from "./service";
import Html from "./html";

const Lockscreen = () => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        loadData(dispatch);
    }, []);

    return(
        <Context.Provider value={{state, dispatch}}>
            {!state.is_loading ? <Html/> : <Loader/>}
        </Context.Provider>
    );
}

export default Lockscreen;