import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ChecklistIcon from '@mui/icons-material/Checklist';
import HandymanIcon from '@mui/icons-material/Handyman';
import GroupIcon from '@mui/icons-material/Group';

import Context from "../context";
import { loadData } from '../service';

export default function FilterMenu() {
    const context = React.useContext(Context);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <div className="col-lg-6 col-5 my-auto text-end">
                <div className="dropdown  pe-4">
                    <a className="text-white cursor-pointer" onClick={handleClick}>
                        <FilterAltIcon/>
                    </a>
                </div>
            </div>
            
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {[
                    {
                        name: "ALL",
                        value: 0,
                        icon: <ChecklistIcon fontSize="small" />,
                        onClickFuntion: () => {
                            handleClose();
                            context.dispatch({ type: "SET_FILER_VALUE", filer: 0 });
                        }
                    },
                    {
                        name: "CUSTOMER",
                        value: 1,
                        icon: <GroupIcon fontSize="small" />,
                        onClickFuntion: () => {
                            handleClose();
                            context.dispatch({ type: "SET_FILER_VALUE", filer: 1 });
                        }
                    },
                    {
                        name: "TRADESPERSON",
                        value: 2,
                        icon: <HandymanIcon fontSize="small" />,
                        onClickFuntion: () => {
                            handleClose();
                            context.dispatch({ type: "SET_FILER_VALUE", filer: 2 });
                        }
                    },
                ].map((item, index) => (
                    <MenuItem 
                        key={index}
                        onClick={item.onClickFuntion}
                    >
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        {item.name}
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    );
}