import React, { useContext } from "react";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Tooltip from '@mui/material/Tooltip';

import DivSpinner from "../../divSpinner";
import { theme } from "../../../theme";
import SalesInvoicesPagination from "./salesInvoicesPagination";
import { Context } from "../provider";
import { openSalesInvoicePDF } from "../service";
// import DivSpinner from "../../../../components/divSpinner";
// import { theme } from "../../../../theme";
// import SalesInvoicesPagination from "./salesInvoicesPagination";
// import { openCreditNoteOrSalesInvoicePDF } from "../service";

const SalesInvoicesList = () => {
    const context = useContext(Context);

    return(
        <>
            {context.state.is_loading ? (
                <div style={{height : `${window.innerHeight-387}px`}}>
                    <DivSpinner/>
                </div>
            ) : (
                <div className="table-responsive p-0 m-3">
                    <table className="table align-items-center mb-0">
                        <thead>
                            <tr>
                                {[ 
                                    'Job ID', 
                                    'Tradesperson', 
                                    'Description', 
                                    'Amount'
                                ].map((text, index) => (
                                    <th 
                                        key={index}
                                        className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                                    >
                                        {text}
                                    </th>
                                ))}
                                <th className="text-secondary opacity-7"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {context.state.sales_invoices.length > 0 ? (
                                <>
                                    {context.state.sales_invoices.map((sales_invoice, index) => (
                                        <tr key={index}>
                                            <td className="align-middle">
                                                <span className="text-secondary text-sm font-weight-bold">{sales_invoice?.sales_invoice_job_id}</span>
                                            </td>
                                            {[
                                                sales_invoice?.ftm_full_name,
                                                sales_invoice?.sales_invoice_description,
                                                `${theme.currency} ${sales_invoice?.sales_invoice_amount}`
                                            ].map((text, index) => (
                                                <td className="align-middle" key={index}>
                                                    <span className="text-secondary text-sm font-weight-bold">
                                                        {text}
                                                    </span>
                                                </td>
                                            ))}
                                            <td className="align-middle">
                                            <Tooltip title="Open Sales Invoice" arrow>
                                                <PictureAsPdfIcon 
                                                    style={{cursor: 'pointer'}}
                                                    onClick={() => openSalesInvoicePDF(sales_invoice.sales_invoice_id)}
                                                />
                                            </Tooltip>
                                            </td> 
                                        </tr>
                                    ))}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan="8">Sorry, no data found!</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <SalesInvoicesPagination/>
                </div>
            )}
        </>
    );
}

export default SalesInvoicesList;