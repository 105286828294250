import React, { useContext } from "react";

import Context from "../context";
import DivSpinner from "../../../../components/divSpinner";

const Body = () => {
    const context = useContext(Context);

    return(
        <>
            {context.state.is_loading ? (
                <div style={{height : `${window.innerHeight-387}px`}}>
                    <DivSpinner/>
                </div>
            ) : (
                <div className="timeline timeline-one-side ms-5">
                    {context.state.logs_list.length > 0 ? (
                        <>
                            {context.state.logs_list?.map((log,index) => (
                                <div key = {index}>
                                    <div className="timeline-block mb-3">
                                        <span className="timeline-step">
                                            <i className={`fa fa-${log.ftm_log_icon}`} style={{fontSize: '20px', color: log.ftm_log_color}}></i>
                                        </span>
                                        <div className="timeline-content">
                                            <h6 className="text-dark text-sm font-weight-bold mb-0" dangerouslySetInnerHTML={{ __html: log.ftm_log_text }}/>
                                            <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">{log.ftm_log_createdAt}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    ) : (
                        <div>
                            Sorry, no data found!
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default Body;