import React from "react";
import { theme } from "../../../../theme";
import { formatEtaTime } from "../../../../utils";
import ImageGallery from "./imageGallery";

const Left = ({ message, ref }) => {
    return (
        <div className="msg left-msg" ref={ref}>
            <div 
                className="msg-bubble" 
                style={{
                    backgroundColor: message.message_sender?.user_type === "FTM" ? theme.left_msg_bg_color_ftm : theme.left_msg_bg_color_cus,
                    color: message.message_sender?.user_type === "FTM" ? theme.right_msg_text_color : theme.left_msg_text_color_cus
                }}
            >
                <div className="msg-info">
                    <div className="msg-info-name">{message.message_sender?.user_name} ({message.message_sender?.user_type})</div>
                    <div className="msg-info-time">{formatEtaTime(message.message_time)}</div>
                </div>

                <div className="msg-text">
                    {message.message_content?.text}
                    {message.message_content?.files.length > 0 ? <ImageGallery images = {message.message_content?.files}/> : null}
                </div>
            </div>
        </div>

        // <div className="row justify-content-start mb-4" ref={ref}>
        //     <div className="col-auto">
        //         <div 
        //             className={`card`}
        //             style={{
        //                 backgroundColor: message.message_sender?.user_type === "FTM" ? theme.left_msg_bg_color_ftm : theme.left_msg_bg_color_cus,
        //                 color: message.message_sender?.user_type === "FTM" ? theme.right_msg_text_color : theme.left_msg_text_color_cus
        //             }}
        //         >
        //             <div className="card-body py-2 px-3">
        //                 <div className="d-flex align-items-center text-sm opacity-6">
        //                     {/* <i className="ni ni-check-bold text-sm me-1"></i> */}
        //                     <small style={{fontWeight: '900'}}>{message.message_sender?.user_name} ({message.message_sender?.user_type})</small>
        //                 </div>
        //                 <p className="mb-1" >
        //                     {message.message_content?.text}
        //                 </p>
        //                 <div className="col-12 p-0">
        //                     {message.message_content?.files.length > 0 ? <ImageGallery images = {message.message_content?.files}/> : null}
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
}

export default Left;