import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from "react-router-dom";

import Context from '../context';
import { getColumnIndex, getSearchList } from '../service';

export default function OptionList() {
    let navigate = useNavigate();
    const context = React.useContext(Context);

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h6" component="div" className='mt-4'>
                            ONLY
                        </Typography>
                    </Grid>
                    {context.state.selector !== "FTM" ? (
                        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <Typography 
                                variant="h6" 
                                component="div" 
                                className='mt-4' 
                                style={{fontSize: '15px', cursor: 'pointer'}}
                                onClick={() => navigate(`/job/search/results?type=1&text=${context.state.search_text}&column=${getColumnIndex(context.state.selector)}&option=${context.state.search_option}`)}
                            >
                                VIEW ALL
                            </Typography>
                        </Grid>
                    ) : null}
                </Grid>
            </Box>

            <FormGroup row className='mb-4'>
                <RadioGroup row>
                    {context.state.search_option_list.map((item, index) => (
                        <FormControlLabel
                            key={index}
                            control={
                                <Radio
                                    onChange={() => getSearchList({
                                        text: context.state.search_text,
                                        type: context.state.selector,
                                        dispatch: context.dispatch,
                                        search_option: item.type
                                    })}
                                />
                            }
                            value={item.type}
                            label={item.label}
                        />
                    ))}

                    {context.state.selector === "FTM" ? (    
                        <FormControlLabel
                            control={
                                <Radio
                                    onChange={() => getSearchList({
                                        text: context.state.search_text,
                                        type: context.state.selector,
                                        dispatch: context.dispatch,
                                        search_option: 'FTM'
                                    })}
                                />
                            }
                            value={'FTM'}
                            label={'Ftm'}
                        />
                    ) : null}    
                </RadioGroup>
            </FormGroup>
        </>
    );
}