import { DOWNLOADFILE, GET } from "../../API/get";

export const fetchSalesInvoices = async ({ current_page, search_value }) => {
    let response = await GET(`get-all-sales-invoices/${current_page}?search=${search_value}`);
    if(response.status === "Success"){
        return response;
    }

    return null;
}

export const openSalesInvoicePDF = async (id) => {
    await DOWNLOADFILE(`sales_invoice/pdf/${id}`);
}