export default function reducer(prevState, action){
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true,
            };
        case "IS_LOADED": 
            return {
                ...prevState,
                details: action.details,
                is_loading: false,
                is_loading_job: false,
                selected_job: action.selected_job,
            }; 
        case "SET_SELECTED_JOB":
            return {
                ...prevState,
                selected_job: action.selected_job,
            };
        case "SET_SELECTED_JOB_MARKERS":
            return {
                ...prevState,
                selected_job_markers: action.selected_job_markers,
            };
        case "IS_LOADING_JOBS":
            return {
                ...prevState,
                is_loading_job_list: action.is_loading_job_list,
            };
        case "SET_JOBS_LIST":
            return {
                ...prevState,
                is_loading_job_list: false,
                job_list: action.job_list,
            };
        case "UPDATE_JOBS_CURRENT_PAGE":
            return {
                ...prevState,
                jobs_current_page: action.jobs_current_page,
            };
        case "SET_JOBS_LIST_SERACH_VALUE":
            return {
                ...prevState,
                jobs_current_page: 1,
                jobs_list_serach_value: action.jobs_list_serach_value,
            };
        default:
            throw new Error();
    }
}