import { useContext } from "react";
import Context from "../context";
import { theme } from "../../../../theme";

export const OrderDetailsHeader = () => {
    const context = useContext(Context);

    return (
        <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
            <div className={`bg-gradient-${theme.main} shadow-${theme.main} border-radius-lg p-3`}>
                <div className="row">
                    <div className="col-md-9 col-lg-10">
                        <div className="d-flex align-items-center">
                            <div className="ms-3">
                                <h6 className="mb-0 d-block text-white">
                                    Details
                                </h6>
                                <span className="text-sm text-white opacity-8">
                                    #{context.state.selected_dispute?.dispute_job?.job_id}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}