export default function Reducer(prevState, action){
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true,
            };
        case "IS_LOADED": 
            return {
                ...prevState,
                sub_skills: action.sub_skills,
                btn_loading: false,
                is_show_add_sub_skills: false,
                is_show_edit_sub_skills: false,
                is_loading: false,
                is_show_material_commissions: false
            };
        case "SET_SUB_SELECTED_SKILLS": 
            return {
                ...prevState,
                selected_sub_skills: action.selected_sub_skills,
                is_open_certificate_types: true,
            };
        case "IS_OPEN_CERTIFICATE_TYPES":
            return {
                ...prevState,
                is_open_certificate_types: action.is_open_certificate_types,
            };
        case "SET_IS_LOADING_BTN": 
            return {
                ...prevState,
                btn_loading: action.btn_loading,
            }; 
        case "SET_ERROR": 
            return {
                ...prevState,
                btn_loading: false,
                error: action.error,
            }; 
        case "IS_SHOW_ADD_SUB_SKILLS": 
            return {
                ...prevState,
                is_show_add_sub_skills: action.is_show_add_sub_skills,
                error: []
            };  
        case "IS_SHOW_EDIT_SUB_SKILLS": 
            return {
                ...prevState,
                is_show_edit_sub_skills: action.is_show_edit_sub_skills,
                selected_sub_skills: action.selected_sub_skills,
                error: []
            };
        case "IS_SHOW_MATERIAL_COMMISSIONS": 
            return {
                ...prevState,
                is_show_material_commissions: action.is_show_material_commissions,
                selected_sub_skills: action.selected_sub_skills,
                selected_charge_type: 0
            };
        case "SET_CHARGE_TYPES": 
            return {
                ...prevState,
                charge_types: action.charge_types,
                is_loading_charge_types: false
            };
        case "SET_COMMISSION_SELECTED_CHARGE_TYPE":
            return {
                ...prevState,
                selected_charge_type: action.selected_charge_type
            };
        default:
            console.log("action.type : ", action.type);
            throw new Error();
    }
}