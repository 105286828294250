import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Context } from '../provider';
import { theme } from '../../../../theme';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function InputFileUpload() {
    const context = useContext(Context);

    return (
        <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} fullWidth style={{backgroundColor: theme.main_code}} sx={{mb: 3}}>
            UPLOAD FILE
            <VisuallyHiddenInput 
                type="file" 
                onChange={(event) => context.dispatch({ type: "UPLOADED_DOCUMENT", uploaded_document: event.target.files[0]})}
            />
        </Button>
    );
}