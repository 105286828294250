import React, {useContext} from "react";
import { useForm } from "react-hook-form";
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';

import Form from 'react-bootstrap/Form';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';

import {theme} from '../../theme';

import BtnSpinner from "../../components/btnSpinner";

import Context from "../context";
import { sendForgotPasswordEmail } from "../service";

const ForgotPassword = () => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const context = useContext(Context);

    return(
        <Form onSubmit={handleSubmit((data) => sendForgotPasswordEmail(data, context.dispatch, reset))}>
            {context.state.is_send_forgot_password_email && (
                <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" sx={{mb: 2}}>
                    {process.env.REACT_APP_SYSTEM_NAME} has sent you an email containing password reset instructions.
                </Alert>
            )}
            
            <FormControl error={errors.email} fullWidth>
                <label><b>Email</b></label>
                <TextField
                    error={errors.email}
                    placeholder="Email"
                    size="small"
                    type="email"
                    {...register("email", { required: true })}
                />
                {errors.email && <FormHelperText>Email is required</FormHelperText>}
            </FormControl>

            <div className="text-center mt-4">
                <button type="submit" className={`btn bg-gradient-${theme.main} w-100  mb-2`} disabled={context.state.is_loading}>
                    {context.state.is_loading ? (
                        <BtnSpinner/>
                    ) : "Send Email"}
                </button>
            </div>

            <p className="text-sm text-center">
                Your account?
                <Button 
                    variant="text" 
                    className={`text-${theme.main} text-gradient font-weight-bold`}
                    onClick={()=>context.dispatch({
                        type: 'IS_FORGOT_PASSWORD',
                        is_forgot_password: false
                    })}
                >
                    Login
                </Button>
            </p>
            
        </Form>
    );
}

export default ForgotPassword;