import React, {useState,useRef, useContext} from "react";
import Button from 'react-bootstrap/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useForm } from "react-hook-form";

import { filePreView, loadImage, sendMessageWithImages } from "../service";
// import ImageUpload from "../../components/imageUpload";
import Context from "../context";
import ImageUpload from "../../../../components/imageUpload";
import { theme } from "../../../../theme";
import BtnSpinner from "../../../../components/btnSpinner";
import ModalTemplateWithForm from "../../../../components/modal/modalTemplateWithForm";

const ChatImageUpload = (props) => {
    const {
        register,
        handleSubmit,
        reset
    } = useForm();

    const context = useContext(Context);

    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    return(
        <>
            <IconButton onClick={() => setIsOpen(true)}><AttachFileIcon/></IconButton>

            <ModalTemplateWithForm
                show={isOpen} 
                closeFun = {() => setIsOpen(false)}
                size = {null}
                centered = {true}
                fullscreen = {false}
                dialogClassName = {null}
                title = {'UPLOAD YOUR IMAGE'}
                body = {
                    <>
                        <ImageUpload
                            is_loading = {false}
                            images = {context.state.images}
                            dispatch = {context.dispatch}
                            filePreView = {filePreView}
                            loadImage = {loadImage}
                        />

                        <TextField 
                            ref={ref} 
                            size="small"
                            className="form-control" 
                            placeholder="Write your caption"
                            {...register("caption", { required: false })}
                        />
                    </>
                }
                footer = {
                    <>
                        <Button 
                            variant={theme.close_btn} 
                            onClick={() => setIsOpen(false)} 
                            disabled={context.state.is_loading_btn}
                            size="sm"
                        >
                            {context.state.is_loading_btn ? (
                                <BtnSpinner/>
                            ) : (
                                "CLOSE"
                            )}
                            
                        </Button>
                        <Button 
                            variant={theme.action_btn} 
                            size="sm" 
                            disabled={context.state.is_loading_btn}
                            type="submit"
                        >
                            {context.state.is_loading_btn ? (
                                <BtnSpinner/>
                            ) : (
                                <>
                                    <i className="fas fa-paper-plane" style={{fontSize: '12px'}}/> SEND
                                </>
                            )}
                        </Button>
                    </>
                }
                onSubmit = {handleSubmit((data) => sendMessageWithImages({
                    data: data, 
                    job_firebase_document_id: context.job_firebase_document_id,
                    images: context.state.images, 
                    dispatch: context.dispatch,
                    reset: reset, 
                    setIsOpen: setIsOpen
                }))}
            />
        </>
    );
}

export default ChatImageUpload;