import React, {useContext} from "react";
import { Button, Grid } from "@mui/material";

import Template from "../../../components/template";
import BoxView from "./components/boxView";
import NavBtn from "./components/navBtn";
import CreditNotes from "./components/creditNotes";
import SalesInvoices from "./components/salesInvoices";
import Context from "./context";
import ChatDisputes from "../chat";
import { loadData } from "./service";
import DivSpinner from "../../../components/divSpinner";
import BtnSpinner from "../../../components/btnSpinner";
import { theme } from "../../../theme";

const Html = () => {
    const context = useContext(Context);

    return (
        <Template>
            {context.state.is_loading && context.state.disputes.length === 0 ? (
                <div style={{height : `${window.innerHeight-387}px`}}>
                    <DivSpinner/>
                </div>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <div style={{ width: '80%' }}>
                        <div className="row gx-4 mb-2">
                            <NavBtn/>
                        </div>
                        {context.state.disputes.map((dispute, index) => (
                            <>
                                <BoxView
                                    index = {index}
                                    dispute = {dispute}
                                />
                                <hr/>
                            </>
                        ))}

                        {context.state.is_show_load_move_btn ? (
                            <Grid 
                                item 
                                xs={12} 
                                md={6} 
                            >
                                <Button 
                                    variant="text" 
                                    fullWidth
                                    color={theme.main}
                                    onClick={() => loadData(context.state.page_size, context.state.last_doc, context.dispatch)}
                                >{context.state.is_loading ? <BtnSpinner/> : "LOAD MOVE"}</Button>
                            </Grid>
                        ) : null}    
                    </div>
                </div>
            )}
            
            <ChatDisputes
                show = {context.state.is_open_chat}
                dispute = {context.state.selected_dispute}
                handleClose = {() => context.dispatch({
                    type: 'IS_OPEN_CHAT',
                    is_open_chat: false,
                    selected_dispute: {}
                })}
            />
            <CreditNotes/>
            <SalesInvoices/>

        </Template>
    );
}
        
export default Html;