import React, { useReducer, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Reducer from "./reducer";
import Context from "./context";
import { initialState } from "./initialState";
import { loadData } from "./service";
import Html from "./html";

const FtmList = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const is_online = searchParams.get('is_online');
    
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        if(is_online == 1){
            loadData(state.page_number, 'Online', state.serach_text, dispatch);
        }else{
            loadData(state.page_number, state.search_type, state.serach_text, dispatch);
        }
    }, [state.page_number, state.search_type, state.serach_text]);

    return(
        <Context.Provider value={{state, dispatch}}>
            <Html/>
        </Context.Provider>
    );
}

export default FtmList;