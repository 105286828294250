import React, { useContext } from "react";
import Context from "../context";
import { Pagination, Stack } from "@mui/material";
import { getAllCreditNotes } from "../service";
import { theme } from "../../../../theme";

const CreditNotesPagination = () => {
    const context = useContext(Context);

    return(
        <>
            {!context.state.is_loading_credit_notes ? (
                <Stack 
                    spacing={2} 
                    className="mb-3" 
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center'
                    }}      
                >
                    <Pagination 
                        count={context.state.total_pages} 
                        page={context.state.current_page_cn} 
                        onChange={(event, newPage) => getAllCreditNotes(newPage, context.dispatch)}
                        color={theme.main}
                    />
                </Stack>
            ) : null }
        </>
    );
}

export default CreditNotesPagination;