import React from "react";
import { Box } from '@mui/material';

const CompanyLogo = () => {
    return(
        <Box
            p={1}>
                <img src="../assets/img/logo-ct.png" alt="main_logo" style={{ width: '40px', height: '40px' }}/>
                <span className="font-weight-bold text-white" style={{ fontSize: '20px' }}>{process.env.REACT_APP_SYSTEM_NAME}</span>
        </Box>
    );
}

export default CompanyLogo;