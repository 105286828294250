import React, {useEffect, useReducer} from "react";
import Html from "./html";
import { loadMessages} from "./service";
import Reducer from "./reducer";
import Context from "./context";
import { initialState } from "./initialState";
import FormSubmit from "./components/formSubmit";
import CardWithHeaderTemplate from "../../cardWithHeaderTemplate";
import OffcanvasTemplate from "../../offcanvasTemplate";

const Chat = ({
    show,
    user_name,
    user_firebase_document_id,
    handleClose,
    is_inbox,
    header, 
    emptyChat,
    is_have_selected_user
}) => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        let unsubscribe;
        const fetchData = async () => {
            if (user_firebase_document_id) {
                unsubscribe = await loadMessages(user_firebase_document_id, dispatch);
            }
        };
        fetchData();

        // Cleanup function to unsubscribe on unmount or when user_firebase_document_id changes
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [user_firebase_document_id]);

    if(is_inbox){
        return (
            <Context.Provider value={{user_firebase_document_id, state, dispatch}}>
                <CardWithHeaderTemplate
                    header={header} 
                    body={is_have_selected_user ? <Html/> : emptyChat} 
                    footer= {
                        is_have_selected_user && (
                            <div className="card-footer d-block">
                                <FormSubmit/>
                            </div>
                        )
                    }
                    extraCardClassName = {`blur shadow-blur max-height-vh-70 h-fix-100`}
                    extraBodyClassName = {`overflow-auto overflow-x-hidden`}
                />
            </Context.Provider>
        );
    }else{
        return (
            <Context.Provider value={{user_firebase_document_id, state, dispatch}}>
                <OffcanvasTemplate
                    open = {show}
                    handleClose = {() => handleClose()} 
                    placement = {'end'}  
                    title = {"Chat - "+user_name}
                    body = {<Html/>} 
                    footer = {
                        <div className="container">
                            <div className="row" style={{marginTop: '10px'}}>
                                <div className="col">
                                    <div className="d-grid gap-2 mb-3" >
                                        <FormSubmit/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    } 
                />
            </Context.Provider>
        );
    }
} 

export default Chat;