import React from "react";

const JobTable = (props) => {
    return(
        <ul className="list-group">
            {props.jobs.length > 0 ? (
                <>
                    {props.jobs.map((job, index) => (
                        <li className="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg">
                            <div className="d-flex flex-column">
                                <h6 className="mb-3 text-sm">{job?.ftm?.ftm_forename} {job?.ftm?.ftm_surname}</h6>
                                <span className="mb-2 text-xs">Customer Name: <span className="text-dark font-weight-bold ms-sm-2">{job?.customer?.customer_full_name}</span></span>
                                <span className="mb-2 text-xs">Postcode: <span className="text-dark ms-sm-2 font-weight-bold">{job?.job_postcode === null ? 'N/A' : job?.job_postcode}</span></span>
                                <span className="mb-2 text-xs">Job type: <span className="text-dark ms-sm-2 font-weight-bold">{job?.skill?.skill_name} - {job?.skill?.sub_skill?.sub_skill_name}</span></span>
                                <span className="text-xs">Payment: 
                                    <span className="text-dark ms-sm-2 font-weight-bold">
                                        {job?.job_is_pay_ftm === 1 ? (
                                            <span className="badge badge-sm bg-gradient-success">PAY</span>
                                        ) : (
                                            <span className="badge badge-sm bg-gradient-dark">NOT PAY</span>
                                        )}
                                    </span>
                                </span>
                            </div>
                            {/* <div className="ms-auto text-end">
                                <a className="btn btn-link text-danger text-gradient px-3 mb-0" href="">
                                    &#163; {job?.customer?.total_ftm_charges}
                                </a>
                                <a className="btn btn-link text-dark px-3 mb-0" href="#">
                                    &#163; {job?.customer?.company_commission}
                                </a>
                            </div> */}
                        </li>
                    ))}
                </>
            ) : (
                <li className="list-group-item border-0 d-flex p-4 bg-gray-100 border-radius-lg">
                    <div className="d-flex flex-column">
                        <h6 className="text-sm">N/A</h6>
                    </div>
                </li>
            )}
        </ul>
    );
}

export default JobTable;