export default function Reducer(prevState, action){
    switch (action.type) {
        case "LOADING":
            return {
                ...prevState,
                is_loading: true,
            };
        case "LOADED":
            return {
                ...prevState,
                is_loading: false
            };
        case "SET_LIST":
            return {
                ...prevState,
                is_loading: false,
                search_text: action.search_text,
                list: action.list,
                search_option: action.search_option
            };
        case "SET_SELECTOR":
            return {
                ...prevState,
                selector: action.selector
            };
        case "SET_ANCHOR_EL":
            return {
                ...prevState,
                anchor_el: action.anchor_el
            };
        case "SET_SEARCH_OPTION":
            return {
                ...prevState,
                search_option: action.search_option
            };
        default:
            throw new Error();
    }
}