import React, {useReducer} from "react";

import Reducer from "./reducer";
import Context from "./context";
import Html from "./html";

const Auth = () => {
    const [state, dispatch] = useReducer(Reducer,{
        is_loading: false,
        is_forgot_password : false,
        is_send_forgot_password_email: false
    });

    return(
        <Context.Provider value={{state, dispatch}}>
            <Html/>
        </Context.Provider>
    );
}

export default Auth;