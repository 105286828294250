import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { theme } from "../../../theme";
import { Context } from "../provider";
import { fullDateFormat } from "../../../utils";
import ImageGallery from "../../../jobs/disputes/chat/components/imageGallery";

const Info = ({ show, closeFun }) => {
    const context = useContext(Context);

    return (
        <Modal
            show={show}
            onHide={closeFun}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="card mb-3 mt-lg-0 mt-4 card-plain">
                    <div className="card-body pb-0">
                        <div className="row align-items-center mb-3">
                            <div className="col-9">
                                <h5 className={`mb-1 text-gradient text-${theme.main}`}>
                                    Dispute -{" "}
                                    {context.state.selected_dispute?.dispute_details.type}
                                </h5>
                            </div>
                        </div>
                        <p>{context.state.selected_dispute?.dispute_details.description}</p>
                        {context.state.selected_dispute?.dispute_details?.images?.length > 0 && <ImageGallery images = {context.state.selected_dispute?.dispute_details?.images}/>}
                        <div className="table-responsive">
                            <table className="table align-items-center mb-0">
                                <tbody>
                                    {[
                                        {
                                            title: "Customer",
                                            value: context.state.selected_dispute?.dispute_details?.customer?.name
                                        },
                                        {
                                            title: "Create date",
                                            value: fullDateFormat(context.state.selected_dispute?.dispute_details?.createAt)
                                        },
                                        {
                                            title: "Tradespersons",
                                            value: context.state.selected_dispute?.dispute_details?.ftm?.name
                                        },
                                        {
                                            title: "Job ID",
                                            value: context.state.selected_dispute?.dispute_job?.job_id
                                        },
                                        {
                                            title: "Address",
                                            value: context.state.selected_dispute?.dispute_job?.address
                                        },
                                        {
                                            title: "Type",
                                            value: context.state.selected_dispute?.dispute_job?.service_category.skill.name + " - " + context.state.selected_dispute?.dispute_job?.service_category.sub_skill.name
                                        },
                                    ].map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                <div className="d-flex px-2 py-0">
                                                    <div className="d-flex flex-column justify-content-center">
                                                        <h6 className="mb-0 text-sm">{item.title}</h6>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="align-middle text-right text-sm">
                                                <span className="text-sm"> {item.value} </span>
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={theme.close_btn} onClick={closeFun} size="sm">
                    CLOSE
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default Info;
