import { useContext } from "react";
import { Link } from "react-router-dom";

import { truncateText } from "../../../utils";
import { theme } from "../../../theme";
import { Context } from "../provider";

export const JobDetails = () => {
    const context = useContext(Context);

    return (
        <div className="card">
            <div className="card-body">
                <div className="d-flex align-items-center mb-sm-0 mb-4">
                    <div className="w-50">
                        <h5>{truncateText(context.state.selected_job.sub_skill.sub_skill_name, 15)}</h5>
                        <span className="text-sm">{context.state.selected_job.sub_skill.skill.skill_name}</span>
                    </div>
                    <div className="w-50 text-end">
                        <Link to={`/jobs/view/${context.state.selected_job.job_id}`} className={`btn btn-outline-${theme.main} mb-3 mb-md-0 ms-auto btn-sm`} type="button" name="button">View</Link>
                    </div>
                </div>
                <hr />
                <p className="text-sm mb-0">{truncateText(context.state.selected_job.job_description, 100)}</p>
                <hr />
                <div className="card card-plain">               
                    {/* <div className="card-header p-3 pb-0">
                        <h6 className="mb-0">Upcoming events</h6>
                        <p className="text-sm mb-0 text-capitalize font-weight-normal">Joined</p>
                    </div> */}
                    <div className="card-body border-radius-lg p-3">
                        <div className="d-flex">
                            <div>
                                <div className="icon icon-shape bg-gradient-dark icon-md text-center border-radius-md shadow-none">
                                    <i className="material-icons text-white opacity-10" aria-hidden="true">person</i>
                                </div>
                            </div>
                            <div className="ms-3">
                                <div className="numbers">
                                    <h6 className="mb-1 text-dark text-sm">CUSTOMER</h6>
                                    <span className="text-sm">{context.state.selected_job.customer.customer_full_name}</span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-4">
                            <div>
                                <div className="icon icon-shape bg-gradient-dark icon-md text-center border-radius-md shadow-none">
                                    <i className="material-icons text-white opacity-10" aria-hidden="true">home</i>
                                </div>
                            </div>
                            <div className="ms-3">
                                <div className="numbers">
                                    <h6 className="mb-1 text-dark text-sm">ADDRESS</h6>
                                    <span className="text-sm">{truncateText(context.state.selected_job.job_address, 25)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-4">
                            <div>
                                <div className="icon icon-shape bg-gradient-dark icon-md text-center border-radius-md shadow-none">
                                    <i className="material-icons text-white opacity-10" aria-hidden="true">location_on</i>
                                </div>
                            </div>
                            <div className="ms-3">
                                <div className="numbers">
                                    <h6 className="mb-1 text-dark text-sm">POSTCODE</h6>
                                    <span className="text-sm">{context.state.selected_job.job_postcode}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}