import React, { useContext } from "react";
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';

import Context from "./context";
import DivSpinner from "../../../components/divSpinner";
import Search from "../../../components/searchField";
import { getLogs } from "./service";
import { theme } from "../../../theme";
import ModalTemplate from "../../../components/modal/modalTemplate";

const Html = (props) => {
    const context = useContext(Context);

    const handleSearch = (search_text) => {
        getLogs(props.ftm_id, 1, search_text, context.dispatch);
    };

    return(
        <ModalTemplate
            show = {props.open}
            closeFun = {props.handleClose}
            size = {"lg"}
            centered = {true}
            title = {
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <h5>TRADESPERSON ACTIVITIES</h5>
                        </div>
                        <div className="col-md-6 d-flex justify-content-end">
                            <Search
                                onSearch = {handleSearch}
                             />
                        </div>
                    </div>
                </div>
            }
            body = {
                <>
                    {context.state.is_loading ? (
                    <div style={{height : `${window.innerHeight-387}px`}}>
                        <DivSpinner/>
                    </div>
                ) : (
                    <div className="col-lg-12 col-md-6">
                        <div className=" h-100">
                            <div className="card-body p-3">
                                <div className="timeline timeline-one-side">
                                    {context.state.logs_list.length > 0 ? (
                                        <>
                                            {context.state.logs_list?.map((log,index) => (
                                                <div key = {index}>
                                                    <div className="timeline-block mb-3">
                                                        <span className="timeline-step">
                                                            <i className={`fa fa-${log.ftm_log_icon}`} style={{fontSize: '20px', color: log.ftm_log_color}}></i>
                                                        </span>
                                                        <div className="timeline-content">
                                                            <h6 className="text-dark text-sm font-weight-bold mb-0" dangerouslySetInnerHTML={{ __html: log.ftm_log_text }}/>
                                                            <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">{log.ftm_log_createdAt}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    ) : (
                                        <div>
                                            Sorry, no data found!
                                        </div>
                                    )}
                                </div>
                            </div>
                            <Stack 
                                spacing={2} 
                                className="mb-3" 
                                style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}      
                            >

                                <Pagination
                                    count={context.state.total_pages} 
                                    page={context.state.current_page} 
                                    onChange={(event, newPage) => getLogs(props.ftm_id, newPage, context.state.search_text, context.dispatch)}
                                    color={theme.main}
                                />
                            </Stack>
                        </div>
                    </div>
                )}
                </>
            }
            footer = {null}
            is_custom = {true}
        />
    );
}

export default Html;