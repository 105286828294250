import React from "react";
import { truncateText } from "../../../utils";
import { theme } from "../../../theme";

const JobBox = ({job_id, job, job_value, type}) => {
    return(
        <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
            <div className="d-flex align-items-center ms-3">
                <button className={`btn btn-icon-only btn-rounded btn-outline-${type == 1 ? 'success' : type == 2 ? 'danger': 'info'} mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center`}>
                    <i className="material-icons text-lg">{type == 1 || type == 3 ? 'expand_less' : 'expand_more'}</i>
                </button>
                <div className="d-flex flex-column">
                    <h6 className="mb-1 text-dark text-sm">
                        {truncateText("#"+job_id+" "+job.sub_skill.skill.skill_name+" "+job.sub_skill.sub_skill_name, 30)}
                    </h6>
                    <span className="text-xs">{job.job_postcode}</span>
                    <span className="text-xs">{job.job_createdAt}</span>
                </div>
            </div>
            <div className={`d-flex align-items-center text-${type == 1 ? 'success' : type == 2 ? 'danger': 'info'} text-gradient text-sm font-weight-bold`}>
                {theme.currency}
                {type == 1 ? job_value.total_ftm_payment  : type == 2 ? "- "+job_value.ftm_penalty_value : job_value.customer_penalty_value}
            </div>
        </li>
    );
}

export default JobBox;