import React, { useContext } from "react";
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { Context } from "../provider";

const NextOfKin = ({ register, reset, errors, control }) => {
    const context = useContext(Context);

    return (
        <div className="row">
            <div className="col-6">
                <FormControl error={!!errors.ftm_next_of_kin} fullWidth>
                    <label><b>Next of kin</b></label>
                    <TextField
                        error={!!errors.ftm_next_of_kin}
                        placeholder="Next of kin"
                        size="small"
                        type="text"
                        defaultValue={context.state.ftm?.ftm_next_of_kin || ''} 
                        {...register("ftm_next_of_kin", { required: true })}
                    />
                    {errors.ftm_next_of_kin && <FormHelperText>Next of kin must be in the format QQ123456B</FormHelperText>}
                </FormControl>
            </div>
            <div className="col-6">
                <FormControl error={!!errors.ftm_next_of_kin_contact_number} fullWidth>
                    <label><b>Next of Kin Contact</b></label>
                    <Controller
                        name="ftm_next_of_kin_contact_number"
                        control={control}
                        defaultValue={context.state.ftm?.ftm_next_of_kin_contact_number || ''} 
                        render={({ field }) => (
                            <TextField
                                {...field}
                                placeholder="Phone number"
                                size="small"
                                type="text"
                                error={!!errors.ftm_next_of_kin_contact_number}
                                InputProps={{
                                    inputComponent: InputMask,
                                    inputProps: {
                                        mask: "+44 799 999 9999",
                                        maskChar: ""
                                    }
                                }}
                            />
                        )}
                    />
                    {errors.ftm_next_of_kin_contact_number && <FormHelperText>{errors.ftm_next_of_kin_contact_number.message}</FormHelperText>}
                </FormControl>
            </div>
        </div>
    );
}

export default NextOfKin;