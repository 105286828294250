import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

import { GET } from "../../API/get";
import { PUT } from "../../API/put";
import { DELETE } from '../../API/delete';
import { theme } from '../../theme';
import { map_settings } from '../../map';
import { getUserInfo } from '../../storage';
import { usersRef } from '../../firebase';
import { isShowChatNotifications } from '../../utils';

export const loadData = async (customer_id, dispatch) => {
    dispatch({ type: "IS_LOADING" });
    let response = await GET("customer-info/"+customer_id);

    if(response.status === "Success"){
        dispatch({ 
            type: "IS_LOADED",
            customer: response.customer_info,
            customer_jobs: response.customer_jobs,
            customer_jobs_count: response.customer_jobs_count,
            customer_jobs_total_pages: response.customer_jobs_total_pages
        });
    }
}

export const getJobsOfCustomer = async (customer_id, page_number, dispatch) => {
    let response = await GET("customer/all/jobs/"+customer_id+"/"+page_number);

    if(response.status === "Success"){
        dispatch({
            type: "SET_CUSTOMER_JOBS",
            customer_jobs: response.customer_jobs,
            customer_jobs_count: response.total_jobs,
            customer_jobs_total_pages: response.total_pages,
            page_number: page_number
        });
    }
}

export const getAdminDetailsInFirebase = async (dispatch) => {
    let userInfo = getUserInfo();
    let query = usersRef.doc(userInfo.admin_firebase_document_id);

    query.onSnapshot(async (doc) => {
        if (doc.exists) {
            console.log("getAdminDetailsInFirebase doc :", {
                id: doc.id,
                ...doc.data()
            });

            dispatch({
                type: 'ADMIN_UNREAD_MESSAGE_COUNT',
                admin_unread_message_count: doc.data().user_total_office_admin_unread_message_count
            });
        } 
    });
}

export const setDefaultCard = async (card_id, customer_id, dispatch) => {
    let response = await PUT("default/card", {
        customer_id: customer_id,
        card_id: card_id
    });

    if(response.status === "Success"){
        toast.success(response.message);

        loadData(customer_id, dispatch);
    }else{
        toast.error(response.message);
    }
}

export const deleteCard = async (card_id, customer_id, dispatch) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to delete this card",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.cancel_btn_code,
        cancelButtonColor: theme.action_btn_code,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
            let response = await DELETE("customer/card/"+card_id+"/"+customer_id);
            return response;
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
        if (result.isConfirmed) {
            console.log('result :', result);

            if(result.value.status === "Success"){
                toast.success("Card removed successfully.")
                loadData(customer_id, dispatch);
            }
        }
    })
}

export const handlePaymentSuccess = async (event, customer_id, dispatch) => {
    //Assuming the message event contains the payment status information
    if (event.data && event.data.status) {
        const paymentStatus = event.data.status;
        if (paymentStatus?.status === 'closeModal') {
            toast.success("New card added successfully.")
            dispatch({ type: "IS_OPEN_ADD_CARD_MODAL", is_open_add_card_modal: false });

            loadData(customer_id, dispatch);
        }
    }
}

export const addCustomerJobLocations = (option) => {
    if(option.map){
        let bounds = new option.google.maps.LatLngBounds();
        
        if (option.state.customer_jobs.length > 0) {
            for(let job of option.state.customer_jobs){
                let job_latitude = parseFloat(job.job_latitude);
                let job_longitude = parseFloat(job.job_longitude);
    
                let job_LatLng = new option.google.maps.LatLng(job_latitude, job_longitude);
                bounds.extend(job_LatLng);
    
                new option.google.maps.Marker({
                    position: { lat: job_latitude, lng: job_longitude },
                    icon:map_settings.customer_map_icon ,
                    map: option.map,
                });
            }

            option.map.fitBounds(bounds);
        } else {
            let job_LatLng = new option.google.maps.LatLng(51.5286416, -0.1015987);
            option.map.setCenter(job_LatLng);
            option.map.setZoom(10);
        }
    }
}

export const openChatView = async (document_id, dispatch) => {
    try {
        isShowChatNotifications(0, "NEW_OFFICE_MESSAGE");

        // Get user info
        let user_info = getUserInfo();
        let ref = usersRef.doc(document_id);

        ref.update({
            user_last_message_seen_user: user_info.admin_firebase_document_id,
            user_last_message_seen_timestamp: new Date().getTime()
        });

        dispatch({
            type: 'IS_SHOW_CHAT', 
            is_show_chat: true
        });

    } catch (error) {
        console.error("Error open chat documents:", error);
    }
}