import React,{useContext} from "react";

import Context from "../context";
import CalendarView from "./calendarView";
import ModalTemplate from "../../../components/modal/modalTemplate";

const HolidaysCalendar = (props) => {
    const context = useContext(Context);
        
    return (
        <ModalTemplate
            show = {context.state.is_open_tradesperson_holidays}
            closeFun = {() => context.dispatch({
                type: 'IS_OPEN_TRADESPERSON_HOLIDAYS', 
                is_open_tradesperson_holidays: false
            })}
            size = {"lg"}
            centered = {true}
            title = {"TRADESPERSON HOLIDAYS"}
            body = {<CalendarView ftm_id = {props.ftm_id} />}
            footer = {null}
        />
    );
}

export default HolidaysCalendar;