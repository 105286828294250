import React, { useContext } from "react";
import Context from "../context";

const JobType = () => {
    const context = useContext(Context);

    return(
        <div className="col-auto my-auto">
            <div className="h-100">
                <h5 className="mb-1">
                    {context.state.job_details.skill.sub_skill.sub_skill_name}
                </h5>
                <p className="mb-0 font-weight-normal text-sm">
                    {context.state.job_details.skill.skill_name}
                </p>
            </div>
        </div>
    );
}

export default JobType;