import React, { useContext } from "react";

import Context from "../context";
import ImagesButton from "../../../components/imagesButton";
import FtmOnlineOfflineSwitch from "./ftmOnlineOfflineSwitch";

const ProfileImage = () => {
    const context = useContext(Context);

    return (
        <>
            <div className="col-auto">
                {/* <div className="avatar-xl position-relative">
                    <img 
                        src={context.state.ftm.ftm_profile_image_path || "/assets/img/lock_user.png"} 
                        alt="profile_image" 
                        className="w-100 border-radius-lg shadow-sm"
                        style={{
                            minWidth: '74px',
                            maxWidth: '74px',
                            minHeight: '74px',
                            maxHeight: '74px'
                        }}          
                    />
                </div> */}
                <div className="avatar-xl position-relative">
                    <ImagesButton
                        images = {[
                            {
                                url: context.state.ftm.ftm_profile_image_path || "/assets/img/lock_user.png",
                                title: 'View',
                            }
                        ]}
                    />
                </div>
            </div>
            <div className="col-auto my-auto">
                <div className="h-100">
                    <h5 className="mb-1">
                        {context.state.ftm.ftm_forename} {context.state.ftm.ftm_surname} 
                        {" "}
                        {context.state.ftm.ftm_is_verify == 1 ? 
                            <>
                                <i 
                                    className="material-icons text-lg position-relative" 
                                    style={{color: 'green'}}
                                >
                                    thumb_up
                                </i>
                                &nbsp;
                                <i 
                                    className="material-icons text-lg position-relative" 
                                    // style={{color: 'green'}}
                                >
                                    satellite_alt
                                </i>
                                <FtmOnlineOfflineSwitch />
                            </> : 
                            <i 
                                className="material-icons text-lg position-relative" 
                                style={{color: 'red'}}
                            >
                                thumb_down
                            </i>
                        }
                    </h5>
                    <p className="mb-0 font-weight-normal text-sm">
                        {context.state.ftm.ftm_vehicle_number}
                    </p>
                </div>
            </div>
        </>
    );
}

export default ProfileImage;