import React, { useContext, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import { Container } from 'react-bootstrap';
import { TextField, MenuItem, Box } from '@mui/material';

import { getChargeTypes, saveCommissions, handleCommisionModalClose } from '../service';
import { theme } from '../../../theme';
import BtnSpinner from '../../../components/btnSpinner';
import Context from '../context';
import DivSpinner from '../../../components/divSpinner';
import ModalTemplateWithForm from '../../../components/modal/modalTemplateWithForm';

const MaterialCommissions = () => {
    const {
        register,
        handleSubmit,
        reset
    } = useForm();

    const context = useContext(Context);

    useEffect(() => {
        getChargeTypes(context.dispatch);
    }, [])

    return (
        <>
            <ModalTemplateWithForm
                show={context.state.is_show_material_commissions} 
                closeFun={() => {
                    reset();
                    context.dispatch({
                        type: 'IS_SHOW_MATERIAL_COMMISSIONS', 
                        is_show_material_commissions: false,
                        selected_sub_skills: {}
                    })
                }}
                size = {null}
                centered = {false}
                fullscreen = {false}
                dialogClassName = {null}
                title = {`${context.state.selected_sub_skills.sub_skill_name} Commission`}
                body = {
                    <>
                        {context.state.is_loading_charge_types ? (
                            <DivSpinner/>
                        ) : (
                            <Container>
                                <label style={{ color: 'black' }}>CHARGE TYPE</label>
                                <TextField
                                    select
                                    defaultValue ="0"
                                    size="small"
                                    fullWidth
                                    InputProps={{
                                        style: { backgroundColor: '#fff' },
                                    }}
                                    name="types"
                                    {...register('types', { required: false })}
                                    onChange={(event) => context.dispatch({ type: "SET_COMMISSION_SELECTED_CHARGE_TYPE", selected_charge_type: event.target.value })}
                                >
                                    <MenuItem value={0}>--- Please select ---</MenuItem>
                                    {context.state.charge_types.map((type, index) => (
                                        <MenuItem key={index} value={type.charge_type_id}>{type.charge_type_name}</MenuItem>
                                    ))}
                                </TextField>

                                {context.state.selected_charge_type !== 0 && (
                                    <Box>
                                        <label style={{ color: 'black' }} className="mt-3">COMMISSION</label>
                                        <TextField
                                            placeholder="Commission"
                                            type='number'
                                            step="any"
                                            fullWidth
                                            size="small"
                                            InputProps={{
                                                style: { backgroundColor: '#fff' },
                                                inputProps: { min: 0 }
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="commission"
                                            defaultValue={0}
                                            {...register('commission', { required: false })}
                                        />
                                    </Box>
                                )}
                            </Container>
                        )}
                    </>
                }
                footer = {
                    <>
                        <Button
                            variant={theme.close_btn}
                            onClick={(event) => handleCommisionModalClose(event, context.dispatch, reset)}
                            size="sm"
                            disabled={context.state.btn_loading}
                        >
                            {context.state.btn_loading ? <BtnSpinner /> : 'CLOSE'}
                        </Button>
                        <Button
                            variant={theme.action_btn}
                            type="submit"
                            size="sm"
                            disabled={context.state.btn_loading || !context.state.selected_charge_type}
                        >
                            {context.state.btn_loading ? <BtnSpinner /> : 'ADD'}
                        </Button>
                    </>
                }
                onSubmit = {handleSubmit((data) => saveCommissions(context.state.selected_sub_skills, data, context.dispatch, reset))}
            />
        </>
    );
};

export default MaterialCommissions;