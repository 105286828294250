import { GET } from "../API/get";
import { POST } from "../API/post";
import { setUserInfo } from '../storage';

export const loadData = async (forgot_password_key, dispatch) => {
    dispatch({ type: "IS_LOADING" });
    let response = await GET("admin/forgot/password/details/"+forgot_password_key);

    if(response.status === "Success"){
        dispatch({ 
            type: "SET_ADMIN_DETAILS", 
            details: response.details
        });
    }
}

export const submitLockScreenVerify = async (option) => {
    option.dispatch({ type: "IS_LOADING" });

    let response = await POST("admin-lockscreen-verify", {
        admin_id: option.admin_id,
        admin_password: option.admin_password
    });

    if(response.status === "Success"){
        setUserInfo({
            admin_id: response.admin_id,
            admin_name: response.admin_name,
            token: response.token,
            refreshToken: response.refreshToken
        });
        option.navigateBack();
    } else {
        option.dispatch({ type: "IS_LOADED" });
    }
}

export const resetPassword = async (forgot_password_key, data, dispatch, navigateBack, reset) => {
    dispatch({ 
        type: "IS_SENDING", 
        is_sending: true,
        error: ''
    });
    console.log('data :', data);

    let response = await POST("admin/forgot/password", {
        forgot_password_key: forgot_password_key,
        new_password: data.new_password
    });

    if(response.status === "Success"){
        reset();
        navigateBack();
    }else{
        dispatch({ 
            type: "IS_SENDING", 
            is_sending: false,
            error: response.message
        });
    }
}                                                                                                                                                                                    