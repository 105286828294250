import { disputesRef, firestore } from "../../firebase";

export const fetchDisputes = ({ is_get_archive_dispute, disputes_list_search_text }) => {
    return new Promise((resolve, reject) => {
        let query = disputesRef
            .where("dispute_archive", "==", is_get_archive_dispute)
            .orderBy("dispute_last_message_received_timestamp", "desc")
            .orderBy("dispute_admin_unread_message_count", "desc")
            .limit(100);

        if (disputes_list_search_text !== "") {
            query = query.where('search_keywords', 'array-contains-any', [disputes_list_search_text.toLowerCase()]);
        }
            
        query.onSnapshot(
            (snapshot) => {
                let disputes = [];
                snapshot.forEach((doc) => {
                    let data = doc.data();
                    disputes.push({
                        doc_id: doc.id,
                        ...data,
                    });
                });
                resolve(disputes);
            },
            (error) => {
                reject(error);
            }
        );
    });
};

// export const updateAll = async () => {
//     const snapshot = await disputesRef.get();
//     const batch = firestore.batch();

//     snapshot.forEach(doc => {
//         let data = doc.data();
//         batch.update(doc.ref, {
//             dispute_archive: false,
//             search_keywords: [
//                 data?.dispute_job?.job_id.toString(),
//                 data?.dispute_id.toString(),
//                 data?.dispute_details.type,
//                 data?.dispute_job?.service_category?.skill?.name,
//                 data?.dispute_job?.service_category?.sub_skill?.name
//             ]
//         });
//     });

//     await batch.commit();
//     console.log('All documents updated successfully');
// }
