import React, { useReducer, useEffect } from "react";
import { useParams } from "react-router-dom";
import Reducer from "./reducer";
import Context from "./context";
import { initialState } from "./initialState";
import Loader from "../components/loading";
import { loadData } from "./service";
import Html from "./html";

const ForgotPassword = () => {
    let { forgot_password_key } = useParams();
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        loadData(forgot_password_key, dispatch);
    }, []);

    return(
        <Context.Provider value={{forgot_password_key, state, dispatch}}>
            {!state.is_loading ? <Html/> : <Loader/>}
        </Context.Provider>
    );
}

export default ForgotPassword;