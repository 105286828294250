import React,{useContext} from "react";
import Button from 'react-bootstrap/Button';

import Context from "../context";
import {theme} from "../../../theme"
import DropDown from "./dropDown";
import InputFileUpload from "./inputFileUpload";
import File from "./file";
import { addNewSkills } from "../service";
import BtnSpinner from "../../../components/btnSpinner";
import ModalTemplate from "../../../components/modal/modalTemplate";

const AddSkills = () => {
    const context = useContext(Context);
        
    return (
        <ModalTemplate
            show = {context.state.is_show_skill_modal}
            closeFun = {() => context.dispatch({
                type: "IS_SHOW_SKILL_MODAL",
                is_show_skill_modal: false
            })}
            size = {null}
            centered = {true}
            title = {"ADD SERVICE CATEGORY AND CERTIFICATE"}
            body = {
                <div className="card" >
                    <div className="card-body">
                        <DropDown/>
                        <InputFileUpload/>
                        <File/>
                    </div>
                </div>
            }
            footer = {
                <>
                    <Button 
                        variant={theme.close_btn}
                        size="sm"
                        onClick={() => context.dispatch({
                            type: "IS_SHOW_SKILL_MODAL",
                            is_show_skill_modal: false
                        })}
                        disabled = {context.state.is_sending_req}
                    >
                        {context.state.is_sending_req ? <BtnSpinner/> : "CLOSE"}
                    </Button>
                    <Button 
                        variant={theme.action_btn} 
                        size="sm"
                        onClick={() => addNewSkills({
                            ftm_id: context.ftm_id,
                            skill: context.state.selected_skills,
                            sub_skill: context.state.selected_sub_skills,
                            document_type: context.state.selected_document_type,
                            files: context.state.uploaded_document,
                        }, context.dispatch)}
                        disabled = {context.state.is_sending_req || !context.state.selected_skills || !context.state.selected_sub_skills || !context.state.selected_document_type || !context.state.uploaded_document}
                    >
                        {context.state.is_sending_req ? <BtnSpinner/> : "SAVE"}
                    </Button>
                </>
            }
        />
    );
}

export default AddSkills;