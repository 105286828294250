export default function Reducer(prevState, action) {
    switch (action.type) {
        case "IS_LOADING_DISPUTES":
            return {
                ...prevState,
                is_loading_disputes: true,
            };
        case "IS_LOADED_DISPUTES":
            return {
                ...prevState,
                disputes: action.disputes,
                is_loading_disputes: false,
            };
        case "IS_SHOW_SALES_INVOICES":
            return {
                ...prevState,
                is_show_sales_invoices: action.is_show_sales_invoices
            }
        case "SELECTED_DISPUTE":
            return {
                ...prevState,
                selected_dispute: action.selected_dispute
            }
        case "IS_SHOW_CREDIT_NOTES":
            return {
                ...prevState,
                is_show_credit_notes: action.is_show_credit_notes
            }
        case "IS_SHOW_DISPUTE_INFO":
            return {
                ...prevState,
                is_show_dispute_info: action.is_show_dispute_info
            }
        case "IS_GET_ARCHIVE_DISPUTE":
            return {
                ...prevState,
                is_get_archive_dispute: action.is_get_archive_dispute
            }
        default:
            console.log("🚀 ~ Reducer ~ action.type:", action.type)
            throw new Error();
    }
}