import React, { useContext, useEffect } from "react";
import { useGoogleMaps } from "react-hook-google-maps";
import { ftmLiveMap } from "../service";
import { Context } from "../provider";

const Map = () => {
    const context = useContext(Context);
    const { ref, map, google } = useGoogleMaps(
        process.env.REACT_APP_GOOGLE_MAP_KEY,
        {
            center: { lat: 51.5286416, lng: -0.1015987 },
            zoom: 10
        }
    );

    useEffect(() => {
        const { cleanup } = ftmLiveMap({
            map: map,
            google: google,
            state: context.state,
        });

        return () => {
            if (cleanup) {
                cleanup();
            }
        };
    }, [google, map]);

    return (
        <div className="card h-100">
            <div className="card-header pb-0 p-3">
                <div className="d-flex justify-content-between">
                    <h6 className="mb-0">TRADESPERSONS LIVE MAP</h6>
                </div>
            </div>
            <div className="card-body p-3">
                <div ref={ref} style={{ width: '100%', height: 400 }} />
            </div>
        </div>
    );
}

export default Map;