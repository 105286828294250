export default function Reducer(prevState, action) {
    switch (action.type) {
        case 'IS_LOADING':
            return {
                ...prevState,
                is_loading: true,
                messages_list: []
            };
        case 'IS_LOADING_BTN':
            return {
                ...prevState,
                is_loading_btn: action.is_loading_btn,
            };
        case 'IS_LOADED':
            return {
                ...prevState,
                is_loading: false,
                messages_list: action.messages_list
            };  
        case "SET_IMAGES":
            return {
                ...prevState,
                images: action.images
            }; 
        case "DISPUTE_IS_RESOLVED":
            return {
                ...prevState,
                dispute_is_resolved: true
            };
        case "IS_OPEN_REFUND_JOB_PAYMENT_MODAL":
            return {
                ...prevState,
                is_open_refund_job_payment_modal: action.is_open_refund_job_payment_modal,
                refund_type: ''
            };
        case "SET_REFUND_TYPE":
            return {
                ...prevState,
                refund_type: action.refund_type
            };
        case 'PROCESSING_REFUND':
            return {
                ...prevState,
                processing_refund: action.processing_refund,
                is_open_refund_job_payment_modal: action.is_open_refund_job_payment_modal,
            };
        case "JOB_PAYMENT_REFUNDED":
            return {
                ...prevState,
                job_payment_refunded: action.job_payment_refunded
            };
        default:
            throw new Error();
    }
}