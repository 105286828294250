export default function Reducer(prevState, action) {
    switch (action.type) {
        case 'IS_LOADING':
            return {
                ...prevState,
                is_loading: true,
            };
        case 'IS_LOADED':
            return {
                ...prevState,
                is_loading: false,
                disputes: [...prevState.disputes, ...action.disputes],
                // disputes: action.disputes,
                last_doc: action.last_doc,
                is_show_load_move_btn: action.is_show_load_move_btn
            };
        case 'IS_OPEN_CHAT':
            return {
                ...prevState,
                is_open_chat: action.is_open_chat,
                selected_dispute: action.selected_dispute
            };   
        case 'IS_SHOW_CREDIT_NOTES':
            return {
                ...prevState,
                is_show_credit_notes: action.is_show_credit_notes,
            };       
        case 'IS_SHOW_SALES_INVOICES':
            return {
                ...prevState,
                is_show_sales_invoices: action.is_show_sales_invoices
            };
        case 'SET_CREDIT_NOTES':
            return {
                ...prevState,
                credit_notes: action.credit_notes,
                total_pages: action.total_pages,
                current_page_cn: action.current_page_cn,
                is_loading_credit_notes: false
            };  
        case 'IS_LOADING_CREDIT_NOTES':
            return {
                ...prevState,
                is_loading_credit_notes: true,
                is_show_credit_notes: action.is_show_credit_notes
            };
        case 'SET_SALES_INVOICES':
            return {
                ...prevState,
                sales_invoices: action.sales_invoices,
                total_pages: action.total_pages,
                current_page_si: action.current_page_si,
                is_loading_sales_invoices: false
            };  
        case 'IS_LOADING_SALES_INVOICES':
            return {
                ...prevState,
                is_loading_sales_invoices: true,
                is_show_sales_invoices: action.is_show_sales_invoices
            }; 
        default:
            throw new Error();
    }
}