import React, { useContext } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';

import moment from "moment";

import Context from "../context";
import DivSpinner from "../../../components/divSpinner";
// import { getStatusColor, getStatusText, loadData } from "../service";

const Table = () => {
    const context = useContext(Context);

    return(
        <>
            {context.state.is_loading ? (
                <div style={{height : `${window.innerHeight-387}px`}}>
                    <DivSpinner/>
                </div>
            ) : (
                <div className="table-responsive p-0">
                    <table className="table align-items-center mb-0">
                        <thead>
                            <tr>
                                <th 
                                    className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                                >
                                    FTM
                                </th>
                                <th 
                                    className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                                >
                                    Contact Number
                                </th>
                                <th 
                                    className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                                >
                                    Email
                                </th>
                                <th 
                                    className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                                >
                                    address
                                </th>
                                <th 
                                    className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                                >
                                    Vehicle number
                                </th>
                                <th 
                                    className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                                >
                                    created At
                                </th>
                                <th 
                                    className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                                >
                                    Next of kin
                                </th>
                                <th className="text-secondary opacity-7"></th>
                            </tr>
                        </thead>
                        <tbody> 
                            {context.state.ftms.length > 0 ? (
                            <>
                                {context.state.ftms.map((ftm_details, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div className="d-flex px-2 py-1">
                                                <div>
                                                    <img src={ftm_details.ftm.ftm_profile_image_path || "/assets/img/lock_user.png"} className="avatar avatar-sm me-3 border-radius-lg" alt="user1"/>
                                                </div>
                                                <div className="d-flex flex-column justify-content-center">
                                                    <h6 className="mb-0 text-sm">
                                                        {ftm_details.ftm.ftm_forename} {ftm_details.ftm.ftm_surname}
                                                    </h6>
                                                    <p className="text-xs text-secondary mb-0">#FTM ID {ftm_details.ftm.ftm_id}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span className="text-secondary text-sm font-weight-bold">
                                                {ftm_details.ftm.ftm_contact_number}
                                            </span>
                                        </td>
                                        <td>
                                            <span className="text-secondary text-sm font-weight-bold">
                                                {ftm_details.ftm.ftm_email}
                                            </span>
                                        </td>
                                        <td className="text-sm">
                                            <span className="text-secondary text-sm font-weight-bold">
                                                {ftm_details.ftm.ftm_street_address}<br/>{ftm_details.ftm.ftm_place}<br/>{ftm_details.ftm.ftm_postcode}
                                            </span>
                                        </td>
                                        <td>
                                            <span className="text-secondary text-sm font-weight-bold">
                                                {ftm_details.ftm.ftm_vehicle_number}
                                            </span>
                                        </td>
                                        <td>
                                            <span className="text-secondary text-sm font-weight-bold">
                                                {moment(ftm_details.ftm.ftm_createdAt).format("YYYY-MM-DD HH:mm:ss")}
                                            </span>
                                        </td>
                                        <td>
                                            <div className="d-flex px-2 py-1">
                                                <div className="d-flex flex-column justify-content-center">
                                                    <h6 className="mb-0 text-sm">
                                                        {ftm_details.ftm.ftm_next_of_kin}
                                                    </h6>
                                                    <p className="text-xs text-secondary mb-0">
                                                        {ftm_details.ftm.ftm_next_of_kin_contact_number}
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="align-middle">
                                            <VisibilityIcon 
                                                style={{cursor: 'pointer'}}
                                                onClick={() => context.dispatch({type: 'SELECTED_FTM', selected_ftm: ftm_details})} 
                                            />
                                        </td> 
                                    </tr>
                                ))}
                            </>
                            ) : (
                                <tr>
                                    <td colSpan="4">
                                        <span className="text-secondary text-sm font-weight-bold" style={{marginLeft: '20px'}}>N/A</span>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
}

export default Table;